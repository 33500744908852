import { FC, useEffect } from 'react';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { FormattedMessage } from 'react-intl';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { Utente } from 'types/utente';
import { PaginationResult } from 'types/paginationResult';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import ISortOptions from 'interfaces/ISortOptions';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { isNotNullOrUndefined } from 'utils/common';

interface UsersPageProps {
    users: PaginationResult<Utente>

    fromExternal: boolean;
    assignUserButtonText?: string;
    alreadyAssignedUsersIds?: Array<string>;
    assignUser?: (userId: string) => void;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;

    isPageLoading: boolean;

    setupQrCodeCommunicationGeneration: (userId: string) => void;
}

const UsersPage: FC<UsersPageProps> = (props) => {
    const { users, fromExternal, assignUserButtonText, alreadyAssignedUsersIds, assignUser, setSortOptions, sortOptions, isPageLoading, setupQrCodeCommunicationGeneration } = props;

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="cognome"
                            title={<FormattedMessage id="surname" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="nome"
                            title={<FormattedMessage id="name" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="comune"
                            title={<FormattedMessage id="residenceMunicipality" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="indirizzo"
                            title={<FormattedMessage id="address" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="codiceFiscale"
                            title={<FormattedMessage id="fiscalCode" />}
                        />
                        <TableCell> <FormattedMessage id="actions" /> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isPageLoading ?
                            (<TableRowsLoader rowsNum={10} colsNum={6} />) :
                            (<>
                                {users && users.items?.map((user) => (
                                    <AlternateTableRow key={user.id}>
                                        <TableCell>
                                            <Grid item> {user.cognome} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {user.nome} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {user.comune} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {user.indirizzo} {user.civico} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {user.codiceFiscale} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                fromExternal ?
                                                    assignUserButtonText !== '' && (
                                                        <Button
                                                            onClick={() => { assignUser!(user.id!) }}
                                                            variant="outlined"
                                                            disabled={alreadyAssignedUsersIds?.includes(user.id!)}
                                                            startIcon={<PersonAddIcon />}
                                                        >
                                                            <FormattedMessage id={assignUserButtonText} />
                                                        </Button>
                                                    )
                                                    :
                                                    <>
                                                        <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                                            <IconButton component={RouterLink} to={`edit?userId=${user.id}`}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {
                                                            !isNotNullOrUndefined(user.mail) && !isNotNullOrUndefined(user.cellulare) && user.showQrCodeCommunicationButton === true &&
                                                            <Tooltip title={<FormattedMessage id="generateQrCodeCommunication" />} aria-label="Generate Qr Code Communication">
                                                                <IconButton onClick={() => setupQrCodeCommunicationGeneration(user.id!)}>
                                                                    <QrCode2Icon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </>}
                                        </TableCell>
                                    </AlternateTableRow>
                                ))}
                            </>)
                    }

                </TableBody>
            </Table >
        </>
    );
};

export default UsersPage;
