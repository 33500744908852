import { Button, Card, CardHeader, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TablePagination, TextField, debounce, useMediaQuery, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { StrutturaService } from "services/StrutturaServices";
import { VialeService } from "services/VialeServices";
import { ZonaService } from "services/ZonaServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { Struttura } from "types/struttura";
import { Viale } from "types/viale";
import { Zona } from "types/zona";
import { getFormValue, isNotNullOrUndefined, retrieveCookieByKey, saveFiltersPaginationCookie } from "utils/common";
import { Link as RouterLink } from 'react-router-dom';
import StrutturePageMobile from "./StrutturePageMobile";
import StrutturePage from "./StrutturePage";
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from "store";
import { tipiStruttura, TipoStruttura } from "types/enums/TipoStruttura";

interface StruttureFilters {
    denominazione: string;
    numero: string;
    zonaId: string;
    vialeId: string;
    structureType: TipoStruttura | null
}

interface StruttureListProps {
    userView?: boolean;
}

const StruttureList: FC<StruttureListProps> = (props) => {
    const { userView } = props;

    const struttureService = new StrutturaService();
    const vialeService = new VialeService();
    const zonaService = new ZonaService();

    const { loggedUser } = useSelector((state) => state.loggedUser);

    const [strutture, setStrutture] = useState<PaginationResult<Struttura>>(voidPagination);

    const [viali, setViali] = useState<Viale[]>([]);
    const [zone, setZone] = useState<Zona[]>([]);

    const [filters, setFilters] = useState<StruttureFilters>({
        denominazione: "",
        numero: "",
        zonaId: "",
        vialeId: "",
        structureType: null
    });

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }));
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }));
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    useEffect(() => {
        const filtersPaginationCookie = retrieveCookieByKey(loggedUser.currentTenantId, 'structuresListFilters');
        if (isNotNullOrUndefined(filtersPaginationCookie)) {
            setFilters(filtersPaginationCookie.filters);
            setPaginationQuery(filtersPaginationCookie.paginationQuery);
        }
    }, [])

    const getStrutture = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: StruttureFilters) => {
        const retrievedStrutture = userView ? await struttureService.GetUserStructures(paginationQuery, filters) : await struttureService.GetStrutture(paginationQuery, filters);
        setStrutture(retrievedStrutture);

        setIsPageLoading(false);
    }, 700), [userView]);

    const updateStrutture = () => {
        setIsPageLoading(true);
        getStrutture(paginationQuery, filters);

        saveFiltersPaginationCookie(loggedUser.currentTenantId, 'structuresListFilters', {
            filters: filters,
            paginationQuery: paginationQuery
        }, 1);
    }

    useEffect(() => {
        updateStrutture();
    }, [paginationQuery, filters, userView]);

    useEffect(() => {
        (async () => {
            if (!userView) {
                setViali(await vialeService.GetUnpaginatedViali());
                setZone(await zonaService.GetUnpaginatedZone());
            }
        })()
    }, []);

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    const intl = useIntl();

    return (
        <>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <FormControl
                        fullWidth
                        size='small'
                    >
                        <InputLabel shrink={isNotNullOrUndefined(filters.structureType)}> <FormattedMessage id="structureType" /> </InputLabel>
                        <Select
                            name="structureType"
                            label={<FormattedMessage id="structureType" />}
                            onChange={handleFiltersChange}
                            sx={{ color: 'white' }}
                            value={filters.structureType}
                        >
                            <MenuItem value={''}> <FormattedMessage id="selectStructureType" /> </MenuItem>
                            {
                                tipiStruttura
                                    .slice()
                                    .map((tipoStruttura) => (
                                        <MenuItem key={tipoStruttura.id} value={tipoStruttura.id}>
                                            {tipoStruttura.name}
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="denomination" />}
                        name="denominazione"
                        onChange={handleFiltersChange}
                        value={filters.denominazione}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="number" />}
                        name="numero"
                        onChange={handleFiltersChange}
                        value={filters.numero}
                    />
                </Grid>
                {
                    !userView && <>
                        <Grid
                            item
                            lg={3}
                            md={3}
                            xs={12}
                        >
                            <FormControl fullWidth size='small'>
                                <InputLabel> <FormattedMessage id="zone" /> </InputLabel>
                                <Select
                                    name="zonaId"
                                    label={<FormattedMessage id="zone" />}
                                    onChange={handleFiltersChange}
                                    defaultValue={''}
                                    value={filters.zonaId}
                                    sx={{ color: 'white' }}
                                >
                                    <MenuItem value={''}> <FormattedMessage id="selectZone" /> </MenuItem>
                                    {
                                        zone && zone
                                            .slice()
                                            .map((zona) => (
                                                <MenuItem key={zona.id} value={zona.id}>
                                                    {zona.descrizione}
                                                </MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            md={3}
                            xs={12}
                        >
                            <FormControl fullWidth size='small'>
                                <InputLabel> <FormattedMessage id="block" /> </InputLabel>
                                <Select
                                    name="vialeId"
                                    label={<FormattedMessage id="block" />}
                                    onChange={handleFiltersChange}
                                    defaultValue={''}
                                    value={filters.vialeId}
                                    sx={{ color: 'white' }}
                                >
                                    <MenuItem value={''}> <FormattedMessage id="selectBlock" /> </MenuItem>
                                    {
                                        viali && viali
                                            .slice()
                                            .map((viale) => (
                                                <MenuItem key={viale.id} value={viale.id}>
                                                    {viale.descrizione}
                                                </MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button
                                component={RouterLink}
                                to="create"
                                color="primary"
                                sx={{ m: 2, textAlign: 'center' }}
                                variant="contained"
                                startIcon={<AddIcon />}
                            >
                                <FormattedMessage id="addStructure" />
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
            <Card>
                <CardHeader title={<FormattedMessage id="structuresList" />} />
                <Divider />
                {
                    mobileDevice ?
                        <StrutturePageMobile
                            strutture={strutture}
                            isPageLoading={isPageLoading}

                            userView={userView}
                        /> :
                        <StrutturePage
                            strutture={strutture}
                            setSortOptions={setSortOptions}
                            sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                            isPageLoading={isPageLoading}

                            userView={userView}
                        />
                }
                <TablePagination
                    component="div"
                    count={strutture?.totalCount}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                    }}
                    onPageChange={(e, page) => {
                        setPageNumber(page);
                    }}
                    page={paginationQuery.pageNumber}
                    rowsPerPage={paginationQuery.pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                />
            </Card>
        </>
    );
};

export default StruttureList;