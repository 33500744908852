import { Agenzia } from "./agenzia";
import { Comune } from "./comune";
import { StatoDecuius } from "./enums/StatoDecuius";
import { Posto } from "./posto";
import { Utente } from "./utente";

export interface Decuius {
    id?: string;
    postoId?: string;
    nome: string;
    cognome: string;
    nomeCompleto?: string;
    protocollo?: string;
    note?: string;
    dataNascita?: string;
    natoInItalia?: boolean;
    provinciaNascita?: string;
    comuneNascita?: string;
    statoNascita?: string;
    dataMorte?: string;
    mortoInItalia?: boolean;
    comuneMorte?: string;
    provinciaMorte?: string;
    statoMorte?: string;
    stato?: StatoDecuius;
    agenziaId?: string;
    agenzia?: Agenzia;

    nazioneResidenza?: string;
    provinciaResidenza?: string;
    comuneResidenza?: string;
    indirizzoResidenza?: string;
    civicoResidenza?: string;
    capResidenza?: string;

    sesso?: number;
    codiceFiscale?: string;

    posto?: Posto;

    utente?: Utente;
    tenantId?: string;
    dateOfInsert?: string;
    isValid?: boolean;

    regioneNascitaId?: string;
    regioneMorteId?: string;
    regioneResidenzaId?: string;
}

export const defaultDecuius: Decuius = {
    nome: '',
    cognome: '',
    natoInItalia: true,
    mortoInItalia: true,
    statoNascita: "Italia",
    statoMorte: "Italia",
    nazioneResidenza: "Italia",
    dataMorte: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()
}