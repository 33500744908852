import { Grid, IconButton, Table, TableHead, TableBody, TableCell, TableRow, Tooltip, TableContainer } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { PaginationResult } from "types/paginationResult";
import AlternateTableRow from "ui-component/AlternateTableRow";
import { Link as RouterLink } from 'react-router-dom';
import { IconFiles } from '@tabler/icons';
import { PaymentDone } from "types/paymentDone";
import { TipoPagamento } from "types/enums/TipoPagamento";
import { PaymentStatus } from "types/enums/PaymentStatus";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { getCurrencyByNumber, getShortDate } from "utils/common";

interface PaymentsDoneProps {
    paymentsDone: PaginationResult<PaymentDone>;
    updateUserPayment: (paymentId: string, paymentResult: boolean) => void;
}

const PaymentsDonePage: FC<PaymentsDoneProps> = (props) => {
    const { paymentsDone, updateUserPayment } = props;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><FormattedMessage id="dossierHeader" /></TableCell>
                        <TableCell><FormattedMessage id="placeNumber" /></TableCell>
                        <TableCell><FormattedMessage id="code" /></TableCell>
                        <TableCell><FormattedMessage id="description" /></TableCell>
                        <TableCell><FormattedMessage id="date" /></TableCell>
                        <TableCell><FormattedMessage id="serviceType" /></TableCell>
                        <TableCell><FormattedMessage id="year" /></TableCell>
                        <TableCell align='right'><FormattedMessage id="payed" /></TableCell>
                        <TableCell align='right'><FormattedMessage id="totalAmount" /></TableCell>
                        <TableCell><FormattedMessage id="paymentType" /></TableCell>
                        <TableCell><FormattedMessage id="paymentStatus" /></TableCell>
                        <TableCell><FormattedMessage id="actions" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        paymentsDone.items.map((paymentDone: PaymentDone, index: number) => (
                            <AlternateTableRow key={index}>
                                <TableCell>
                                    <Grid item> {paymentDone.payerFullName} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {paymentDone.placeNumber} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {paymentDone.paymentCode} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {paymentDone.placeServiceDescription} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {getShortDate(paymentDone.paymentDate)} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> <FormattedMessage id={paymentDone.annual ? 'annual' : 'unaTantum'} /> </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {paymentDone.year} </Grid>
                                </TableCell>
                                <TableCell align='right'>
                                    <Grid item> {getCurrencyByNumber(paymentDone.amountPayed)} </Grid>
                                </TableCell>
                                <TableCell align='right'>
                                    <Grid item> {getCurrencyByNumber(paymentDone.totalAmount)} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {TipoPagamento[paymentDone.paymentType]} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid item> {PaymentStatus[paymentDone.paymentStatus]} </Grid>
                                </TableCell>
                                <TableCell>
                                    <Grid container spacing={1}>
                                        <Grid item lg={3} md={3} xs={12}>
                                            <Tooltip title={<FormattedMessage id="goToDossier" />} aria-label="Dossier">
                                                <IconButton component={RouterLink} to={`/dossiers/edit?dossierId=${paymentDone.dossierId}`}>
                                                    <IconFiles fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                paymentDone.paymentStatus != PaymentStatus.Completed &&
                                                <>
                                                    <Tooltip title={<FormattedMessage id="rejectPayment" />} aria-label="rejectPayment">
                                                        <IconButton onClick={() => updateUserPayment(paymentDone.paymentId, false)}>
                                                            <CloseIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={<FormattedMessage id="confirmPayment" />} aria-label="confirmPayment">
                                                        <IconButton onClick={() => updateUserPayment(paymentDone.paymentId, true)}>
                                                            <CheckIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </AlternateTableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PaymentsDonePage;