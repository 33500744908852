import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SteccaService } from "services/SteccaServices";
import { Stecca } from "types/stecca";
import SteccaUpsert from "./SteccaUpsert";
import { Box, Button, Divider, Grid, Paper, Skeleton, Stack, Typography, darken } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { PostoSteccaView } from "types/postoSteccaView";
import MapIcon from '@mui/icons-material/Map';
import { composeNotistackMessage, handleDownloadFile } from "utils/common";
import BackdropLoader from "ui-component/BackdropLoader";
import DeleteIcon from '@mui/icons-material/Delete';
import DialogDeleteElement from "ui-component/DialogDeleteElement";

interface SteccaHandleComponentProps {
    stecca: Stecca | undefined;
    updateStecche: () => void;
    updateSteccaToHandle: (stecca: Stecca) => Promise<void>;
    isLoading: boolean;
    deleteStecca: (steccaId: string) => void;
}

const SteccaHandleComponent: FC<SteccaHandleComponentProps> = (props) => {
    const { stecca, updateStecche, updateSteccaToHandle, isLoading, deleteStecca } = props;

    const intl = useIntl();

    const steccaService = new SteccaService();

    const [posti, setPosti] = useState<PostoSteccaView[]>([]);

    const [rows, setRows] = useState<number>(0);
    const [cols, setCols] = useState<number>(0);

    const navigate = useNavigate();

    const [deleteSteccaDialogOpen, setDeleteSteccaDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (stecca?.id) {
                let retrievedPosti = await steccaService.GetUnpaginatedPostiBySteccaId(stecca.id);
                setPosti(retrievedPosti);
                setRows(Number(stecca?.numRighe));
                setCols(Number(stecca?.numColonne));
            }
        })()
    }, [stecca])

    interface PostoPaperProps {
        posto: PostoSteccaView;
    }

    const PostoPaper: FC<PostoPaperProps> = (props) => {
        const { posto } = props;

        const statoPosto = posto.statoPosto;

        let paperBackgroundColor: string = '#FFFFFF';

        if (posto.visibile === true) {
            switch (statoPosto) {
                //Occupato
                case 0:
                    paperBackgroundColor = '#FF4242';
                    break;
                //Libero
                case 1:
                    paperBackgroundColor = '#5fb062';
                    break;
                //Concessionario
                case 2:
                    paperBackgroundColor = '#FFAE35';
                    break;
                //AventeDiritto
                case 3:
                    paperBackgroundColor = '#FFFF53';
                    break;
                //Referente
                case 4:
                    paperBackgroundColor = '#69B4FF';
                    break;
            }
        }

        return (
            <Paper
                elevation={3}
                sx={{
                    '&:hover': {
                        backgroundColor: darken(paperBackgroundColor, 0.2),
                        cursor: 'pointer'
                    },
                    transition: '0.2s',
                    backgroundColor: paperBackgroundColor,
                    width: '120px',
                    position: 'relative',
                    height: '135px',
                    p: '10px',
                    m: '10px'
                }}
                onClick={() => navigate(`/posto/edit?postoId=${posto.id}`)}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {
                        posto.visibile ?
                            <Stack alignItems="center" sx={{ height: '100px' }}>
                                <Typography variant="subtitle1"
                                    sx={{
                                        mt: 1,
                                        textAlign: 'center',
                                        maxHeight: '100%',
                                        lineHeight: '1.5em',
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 4,
                                    }}
                                >
                                    {posto.proprietarioNome} {posto.proprietarioCognome}
                                </Typography>
                            </Stack>
                            :
                            <Box sx={{
                                padding: '8px',
                                borderRadius: '5px',
                                backgroundColor: '#f73636',
                            }}>
                                <Typography variant="subtitle1" color="white">
                                    <FormattedMessage id="notVisible" />
                                </Typography>
                            </Box>
                    }
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        padding: '8px 10px 2px 2px'
                    }}
                >
                    <Typography variant="subtitle1" color="black">
                        {posto.numero}
                    </Typography>
                </Box>
            </Paper>
        );
    }

    const [isLoadingSlatPrint, setIsLoadingSlatPrint] = useState<boolean>(false);

    const handleSlatPrint = async () => {
        setIsLoadingSlatPrint(true);

        try {
            const pdfBase64: string = await steccaService.PrintSlat(stecca!.id!);

            handleDownloadFile(pdfBase64, `${stecca?.denominazione}.pdf`)
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsLoadingSlatPrint(false);
        }
    }

    return (
        <>
            <DialogDeleteElement
                open={deleteSteccaDialogOpen}
                onClose={() => setDeleteSteccaDialogOpen(false)}
                onCancel={() => setDeleteSteccaDialogOpen(false)}
                onConfirm={() => {
                    setDeleteSteccaDialogOpen(false)
                    deleteStecca(stecca!.id!)
                }}
                entityToDeleteInfo={stecca?.denominazione ?? ''}
                customMessageIntlId="confirmDeletion"
                key="removeSlat"
            />

            <BackdropLoader open={isLoadingSlatPrint} />

            <Divider />
            <Grid container direction="row" sx={{ mt: 2 }}>
                <Grid item
                    xs={6}
                    md={6}
                    lg={6}
                >
                    <Button variant="contained" startIcon={<MapIcon />} onClick={handleSlatPrint}>
                        <FormattedMessage id="print" />
                    </Button>
                </Grid>
                <Grid item
                    xs={6}
                    md={6}
                    lg={6}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setDeleteSteccaDialogOpen(true)}>
                        <FormattedMessage id="delete" />
                    </Button>
                </Grid>
            </Grid >
            <Typography variant="h3" sx={{ mt: 2 }}>
                <FormattedMessage id="denomination" />: {stecca?.denominazione}
            </Typography>
            {stecca && <SteccaUpsert isAdd={false} steccaToUpdate={stecca} updateStecche={updateStecche} updateSteccaToHandle={updateSteccaToHandle} />}
            <Divider />
            <Box sx={{ overflowX: 'scroll' }}>
                {
                    isLoading ?
                        <Grid container direction="row" columnSpacing={2}>
                            <Grid item>
                                <Skeleton variant="rounded" width={120} height={135} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rounded" width={120} height={135} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="rounded" width={120} height={135} />
                            </Grid>
                        </Grid> :
                        [...Array(rows)].map((row, rowI) => (
                            <Box
                                sx={{ my: '2px', display: 'flex', justifyContent: 'flex-start' }}
                                key={rowI}
                            >
                                {
                                    [...Array(cols)].map((row, colI) => (
                                        <Box key={colI}>
                                            {
                                                (stecca && posti) && (
                                                    posti[rowI * stecca!.numColonne + colI] && (<PostoPaper posto={posti[rowI * stecca!.numColonne + colI]} />)
                                                )
                                            }
                                        </Box>
                                    ))
                                }
                            </Box >
                        ))
                }
            </Box>
        </>
    )
};

export default SteccaHandleComponent;
