/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { Zona } from '../types/zona';
import { PaginationQuery } from 'types/paginationQuery';
import { PaginationResult } from 'types/paginationResult';

export class ZonaService {
    async GetZone(paginationQuery: PaginationQuery): Promise<PaginationResult<Zona>> {
        const result = await axios.post<PaginationResult<Zona>>(`${serviceConfig.appServiceBaseUrl}/GetZone`, paginationQuery);
        return result.data;
    }

    async GetUnpaginatedZone(): Promise<Zona[]> {
        const result = await axios.get<Zona[]>(`${serviceConfig.appServiceBaseUrl}/GetAllZone`);
        return result.data;
    }

    async DeleteZona(zonaId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteZona`, zonaId);
        return result.data;
    }

    async GetZonaById(zonaId: string): Promise<Zona> {
        const result = await axios.post<Zona>(`${serviceConfig.appServiceBaseUrl}/GetZona`, zonaId);
        return result.data;
    }

    async UpsertZona(zona: Zona): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpsertZona`, zona);
        return result.data;
    }
}