import { createSlice } from '@reduxjs/toolkit';

export const showTenantChangeSlice = createSlice({
    name: 'showTenantChangeSlice',
    initialState: {
        showTenantChange: false
    },
    reducers: {
        setShowTenantChange(state, action) {
            state.showTenantChange = action.payload;
        }
    }
});

export const { setShowTenantChange } = showTenantChangeSlice.actions;

export default showTenantChangeSlice.reducer;