import { Card, CardHeader, Divider, CardContent, Grid, TextField, Button, Typography, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { FatturaService } from "services/FatturaServices";
import { PraticaService } from "services/PraticaServices";
import { ServizioPostoService } from "services/ServizioPostoServices";
import { useDispatch, useSelector } from 'store';
import { Fattura, defaultFattura } from "types/fattura";
import { Pratica } from "types/pratica";
import { ServizioPosto } from "types/servizioPosto";
import { Utente } from "types/utente";
import AlternateTableRow from "ui-component/AlternateTableRow";
import CardContentLoader from "ui-component/CardContentLoader";
import PurpleStyledTableCell from "ui-component/PurpleStyledTableCell";
import { composeNotistackMessage, getCurrencyByNumber, getFormValue, getShortDate, handleDownloadFile, isNotNullOrUndefined } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { date, object, string } from "yup";
import { setInvoicesServicesIds } from "store/slices/invoicesServicesIdsSlice";
import { PagamentoService } from "services/PagamentoServices";
import { Pagamento } from "types/pagamento";
import { TipoPagamento } from "types/enums/TipoPagamento";
import BackdropLoader from "ui-component/BackdropLoader";
import { TenantService } from "services/TenantServices";
import { SerieNumeratore } from "types/serieNumeratore";
import dayjs from "dayjs";

const FatturaInsert = () => {
    const intl = useIntl();

    const dispatch = useDispatch();
    const { listInvoicesServicesIds } = useSelector((state) => state.invoicesServicesIds);
    const { listInvoicesPaymentsIds } = useSelector((state) => state.invoicesPaymentsIds);

    const [fattura, setFattura] = useState<Fattura>(defaultFattura);
    const [praticaNumero, setPraticaNumero] = useState<string>('');
    const [serviziPosto, setServiziPosto] = useState<Array<ServizioPosto>>([]);
    const [pagamenti, setPagamenti] = useState<Array<Pagamento>>([]);
    const [praticaId, setPraticaId] = useState<string>('');

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    const [isLoadingInvoiceSave, setIsLoadingInvoiceSave] = useState<boolean>(false);

    const fatturaService = new FatturaService();

    const navigate = useNavigate();

    const [serieNumeratori, setSerieNumeratori] = useState<Array<SerieNumeratore>>([]);

    let fatturaValidationSchema = object({
        numero: string().required('numberRequired'),
        data: date().typeError('dateMustBeDate'),
        cognome: string().required('surnameRequired'),
        nome: string().required('nameRequired'),
        codiceFiscale: string().required('fiscalCodeRequired'),
        comune: string().required('municipalityRequired'),
        provincia: string().required('provinceRequired'),
        indirizzo: string().required('addressRequired'),
        numeroCivico: string().required('civicRequired'),
        cap: string().required('capRequired'),
    });

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFattura((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const handleDateChange = (fieldName: string, date: any) => {
        let newValue: string | null;

        newValue = dayjs(date).format('YYYY-MM-DD');

        newValue = newValue == 'Invalid Date' ? null : newValue;

        setFattura((currentFormData: any) => ({ ...currentFormData, [fieldName]: newValue }));
    }

    const [selectedSerieId, setSelectedSerieId] = useState<string>('');

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            const praticaService = new PraticaService();
            const tenantService = new TenantService();

            const praticaId: string = window.location.href.split('dossierId=')[1] ?? '';
            setPraticaId(praticaId);

            const pratica: Pratica = await praticaService.GetPraticaById(praticaId);
            setPraticaNumero(pratica?.numero ?? '');
            const intestatarioPratica: Utente = await praticaService.GetPraticaHolder(praticaId);

            const getTenantDefaultCausal: string = await tenantService.GetTenantDefaultCausal();

            const retrievedSerieNumeratori: SerieNumeratore[] = await fatturaService.GetUnpaginatedFatturaSerieNumeratori();
            setSerieNumeratori(retrievedSerieNumeratori);

            const todayYear = (new Date()).getFullYear();
            const defaultSerie = retrievedSerieNumeratori.find((serieNumeratore: SerieNumeratore) => serieNumeratore.anno == todayYear && serieNumeratore.default == true);
            setSelectedSerieId(defaultSerie?.id ?? '');

            setFattura((prevData) => ({
                ...prevData,
                nome: intestatarioPratica?.nome ?? '',
                cognome: intestatarioPratica?.cognome ?? '',
                codiceFiscale: intestatarioPratica?.codiceFiscale ?? '',
                indirizzo: intestatarioPratica?.indirizzo ?? '',
                numeroCivico: intestatarioPratica?.civico ?? '',
                comune: intestatarioPratica?.comune ?? '',
                provincia: intestatarioPratica?.provincia ?? '',
                cap: intestatarioPratica?.cap ?? '',
                causale: getTenantDefaultCausal,
                praticaAssociataId: praticaId,
                serie: defaultSerie?.nome ?? '',
                numero: isNotNullOrUndefined(defaultSerie) ? String(defaultSerie!.numero + 1) : '1',
                numeratoreId: defaultSerie?.id ?? ''
            }))

            setIsPageLoading(false);
        })()
    }, [])

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            const servizioPostoService = new ServizioPostoService();
            setServiziPosto(await servizioPostoService.GetServiziPostoByIds(listInvoicesServicesIds));

            setIsPageLoading(false);
        })()
    }, [listInvoicesServicesIds])

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            const pagamentoService = new PagamentoService();
            setPagamenti(await pagamentoService.GetPagamentiByIds(listInvoicesPaymentsIds));

            setIsPageLoading(false);
        })()
    }, [listInvoicesPaymentsIds])

    const fatturaInsert = async () => {
        setIsLoadingInvoiceSave(true);

        try {
            await fatturaValidationSchema.validate(fattura, { abortEarly: false });

            const insertFatturaBody = {
                ...fattura,
                serviziPostoIds: listInvoicesServicesIds,
                pagamentiIds: listInvoicesPaymentsIds
            };

            const invoicePdfBase64: string = await fatturaService.InsertFattura(insertFatturaBody);

            dispatch(setInvoicesServicesIds([]));

            handleDownloadFile(invoicePdfBase64, `invoice-${fattura.nome}_${fattura.cognome}.pdf`);

            composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');

            navigate(`/dossiers/edit?dossierId=${praticaId}`);
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        } finally {
            setIsLoadingInvoiceSave(false);
        }
    }

    return (
        <Card>
            <BackdropLoader open={isLoadingInvoiceSave} />

            <CardHeader title={<Typography variant='h3'><FormattedMessage id="invoice" /> - <FormattedMessage id="dossier" />: {praticaNumero}</Typography>} />
            <Divider />
            {
                isPageLoading ?
                    <CardContentLoader /> :
                    (
                        <CardContent>
                            {/* TODO: Spostare ogni panel in un component a parte o capire come fare meglio. Sicuramente da sistemare */}
                            <Grid container spacing={1}>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <FormControl fullWidth required size='small'>
                                        <InputLabel> <FormattedMessage id="series" /> </InputLabel>
                                        <Select
                                            name="serie"
                                            label={<FormattedMessage id="series" />}
                                            onChange={(e) => {
                                                const serieSelectValue = e.target.value;

                                                if (isNotNullOrUndefined(serieSelectValue)) {
                                                    setSelectedSerieId(serieSelectValue);

                                                    const serie: SerieNumeratore = serieNumeratori.find((serieNumeratore: SerieNumeratore) => serieNumeratore.id == serieSelectValue)!;

                                                    setFattura((currentFormData: any) => ({ ...currentFormData, numero: serie.numero + 1, serie: serie.nome, numeratoreId: serie!.id }));
                                                }
                                            }}
                                            sx={{ color: 'white' }}
                                            value={selectedSerieId}
                                        >
                                            {
                                                serieNumeratori.length > 0 && serieNumeratori
                                                    .slice()
                                                    .map((serie: SerieNumeratore) => (
                                                        <MenuItem key={serie.id} value={serie.id}>
                                                            {serie.nome} - {serie.numero}
                                                        </MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="number" />}
                                        name="numero"
                                        required
                                        onChange={handleChange}
                                        value={fattura?.numero}
                                        InputLabelProps={{
                                            shrink: isNotNullOrUndefined(fattura.numero)
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <DatePicker
                                        inputFormat="DD/MM/YYYY"
                                        label={<FormattedMessage id="date" />}
                                        onChange={(date: any) => {
                                            handleDateChange('data', date);
                                        }}
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} error={false} required size='small' />
                                        )}
                                        value={fattura.data ?? ''}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="surname" />}
                                        name="cognome"
                                        onChange={handleChange}
                                        value={fattura.cognome}
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="name" />}
                                        name="nome"
                                        onChange={handleChange}
                                        value={fattura.nome}
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="fiscalCode" />}
                                        name="codiceFiscale"
                                        onChange={handleChange}
                                        value={fattura.codiceFiscale}
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="municipality" />}
                                        name="comune"
                                        onChange={handleChange}
                                        value={fattura.comune}
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={1}
                                    md={1}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="province" />}
                                        name="provincia"
                                        onChange={handleChange}
                                        value={fattura.provincia}
                                        inputProps={{ maxLength: 2 }}
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="address" />}
                                        name="indirizzo"
                                        onChange={handleChange}
                                        value={fattura.indirizzo}
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={2}
                                    md={2}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="civic" />}
                                        name="numeroCivico"
                                        onChange={handleChange}
                                        value={fattura.numeroCivico}
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={2}
                                    md={2}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="cap" />}
                                        name="cap"
                                        onChange={handleChange}
                                        value={fattura.cap}
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="causal" />}
                                        name="causale"
                                        InputLabelProps={{
                                            style: {
                                                height: 50,
                                            },
                                            shrink: isNotNullOrUndefined(fattura.causale)
                                        }}
                                        onChange={handleChange}
                                        value={fattura?.causale}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="notes" />}
                                        name="note"
                                        inputProps={{
                                            style: {
                                                height: 50
                                            }
                                        }}
                                        onChange={handleChange}
                                        value={fattura?.note}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ mt: 1, pt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    onClick={fatturaInsert}
                                    disabled={listInvoicesServicesIds.length == 0 && listInvoicesPaymentsIds.length == 0}
                                >
                                    <FormattedMessage id="save" />
                                </Button>
                            </Box>
                            <Typography variant='h4'><FormattedMessage id="services" /></Typography>
                            <TableContainer component={Paper} sx={{ mt: 2, mb: 4 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <PurpleStyledTableCell> <FormattedMessage id="description" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell> <FormattedMessage id="quantity" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell align="right"> <FormattedMessage id="amount" /> (€) </PurpleStyledTableCell>
                                            <PurpleStyledTableCell align="right"> <FormattedMessage id="rate" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell align="right"> <FormattedMessage id="totalAmount" /> (€) </PurpleStyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            serviziPosto.map((servizioPosto: ServizioPosto) => (
                                                <AlternateTableRow>
                                                    <TableCell> {servizioPosto.descrizione} </TableCell>
                                                    <TableCell> {servizioPosto.quantita} </TableCell>
                                                    <TableCell align="right"> {getCurrencyByNumber(servizioPosto.importo)} </TableCell>
                                                    <TableCell align="right"> {servizioPosto.aliquota} </TableCell>
                                                    <TableCell align="right"> {getCurrencyByNumber(servizioPosto.importo * servizioPosto.quantita)} </TableCell>
                                                </AlternateTableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography variant='h4'><FormattedMessage id="payments" /></Typography>
                            <TableContainer component={Paper} sx={{ mt: 2 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <PurpleStyledTableCell> <FormattedMessage id="date" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell> <FormattedMessage id="description" /> </PurpleStyledTableCell>
                                            <PurpleStyledTableCell align="right"> <FormattedMessage id="amount" /> (€) </PurpleStyledTableCell>
                                            <PurpleStyledTableCell align="right"> <FormattedMessage id="paymentMethod" /> </PurpleStyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            pagamenti.length != 0 &&
                                            pagamenti.map((pagamento: Pagamento) => (
                                                <AlternateTableRow key={pagamento.id}>
                                                    <TableCell> {getShortDate(pagamento.data)} </TableCell>
                                                    <TableCell> {pagamento.descrizione} </TableCell>
                                                    <TableCell align="right"> {getCurrencyByNumber(pagamento.importo)} </TableCell>
                                                    <TableCell align="right"> {TipoPagamento[pagamento.mezzo]} </TableCell>
                                                </AlternateTableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    )
            }
        </Card>
    )
}

export default FatturaInsert;