/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { Utente } from 'types/utente';
import { PaginationQuery } from 'types/paginationQuery';
import { PaginationResult } from 'types/paginationResult';
import { LoggedUser } from 'types/loggedUser';
import { Pratica } from 'types/pratica';
import { TenantPrivacy } from 'types/tenantPrivacy';
import { CommunicationType } from 'types/enums/CommunicationType';

export class UserService {
    async GetUsers(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Utente>> {
        const result = await axios.post<PaginationResult<Utente>>(`${serviceConfig.appServiceBaseUrl}/GetUtenti`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetUserById(userId: string): Promise<Utente> {
        const result = await axios.post<Utente>(`${serviceConfig.appServiceBaseUrl}/GetUtente`, userId);
        return result.data;
    }

    async UpsertUtente(user: Utente, tenantsIds: Array<string>): Promise<string> {
        user.tenantsIds = tenantsIds;
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/UpsertUtente`, user);
        return result.data;
    }

    async DeleteUtenteById(userId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteUtente`, userId);
        return result.data;
    }

    async ValidateFiscalCode(userData: any): Promise<boolean> {
        const result = await axios.post<boolean>(`${serviceConfig.appServiceBaseUrl}/ValidateFiscalCode`, userData);
        return result.data;
    }

    async UpdateUserInformation(user: Utente): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpdateUserInformation`, user);
        return result.data;
    }

    async MakeUserDecuius(userId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/MakeUserDecuius`, userId);
        return result.data;
    }

    async GetUser(): Promise<Utente> {
        const result = await axios.get<Utente>(`${serviceConfig.appServiceBaseUrl}/GetUser`);
        return result.data;
    }

    async ChangeCurrentTenant(tenantId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/ChangeCurrentTenant`, tenantId);
        return result.data;
    }

    async GetDossiersByUserId(paginationQuery: PaginationQuery, userId: string): Promise<PaginationResult<Pratica>> {
        const result = await axios.post<PaginationResult<Pratica>>(`${serviceConfig.appServiceBaseUrl}/GetDossiersByUserId`, {
            pagination: paginationQuery,
            userId: userId
        });
        return result.data;
    }

    async GetUserTenantsIds(userId: string): Promise<Array<string>> {
        const result = await axios.post<Array<string>>(`${serviceConfig.appServiceBaseUrl}/GetUserTenants`, userId);
        return result.data;
    }

    async UpdateUserPassword(updateUserPasswordObject: { userId: string, communicationType: CommunicationType }): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpdateUserPassword`, updateUserPasswordObject);
        return result.data;
    }

    async GenerateCredentials(generateCredentialsData: object): Promise<void> {
        const result = await axios.post<void>(
            `${serviceConfig.appServiceBaseUrl}/GenerateCredentials`,
            generateCredentialsData,
            {
                headers: {
                    "x-functions-key": serviceConfig.apiKey
                }
            }
        );
        return result.data;
    }

    async GetTenantPrivacyToAccept(userId: string): Promise<Array<TenantPrivacy>> {
        const result = await axios.post<Array<TenantPrivacy>>(`${serviceConfig.appServiceBaseUrl}/GetTenantPrivacyToAccept`, userId);
        return result.data;
    }

    async AcceptPrivacyPolicy(tenantId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/AcceptPrivacyPolicy`, tenantId);
        return result.data;
    }

    async PasswordRecovery(fiscalCode: string): Promise<void> {
        const result = await axios.post<void>(
            `${serviceConfig.appServiceBaseUrl}/PasswordRecovery`,
            fiscalCode,
            {
                headers: {
                    "x-functions-key": serviceConfig.apiKey
                }
            }
        );
        return result.data;
    }

    async ChangePassword(password: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/ChangePassword`, password);
        return result.data;
    }

    async GenerateCommunicationWithQrCode(qrCodeCommuncationObject: object): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/GenerateCommunicationWithQrCode`, qrCodeCommuncationObject);
        return result.data;
    }

    async CheckUserGenerateCredentials(checkUserGenerateCredentialsObject: object): Promise<void> {
        const result = await axios.post<void>(
            `${serviceConfig.appServiceBaseUrl}/CheckUserGenerateCredentials`,
            checkUserGenerateCredentialsObject,
            {
                headers: {
                    "x-functions-key": serviceConfig.apiKey
                }
            }
        );
        return result.data;
    }

    async UpdateMailPhoneNumberByUser(updateMailPhoneNumberByUserObject: object): Promise<void> {
        const result = await axios.post<void>(
            `${serviceConfig.appServiceBaseUrl}/UpdateMailPhoneNumberByUser`,
            updateMailPhoneNumberByUserObject,
            {
                headers: {
                    "x-functions-key": serviceConfig.apiKey
                }
            }
        );
        return result.data;
    }

    async ScanItIdentityDocument(scanItIdentityDocumentObject: object): Promise<Utente> {
        const result = await axios.post<Utente>(`${serviceConfig.appServiceBaseUrl}/ScanItIdentityDocument`, scanItIdentityDocumentObject);
        return result.data;
    }

    async GetQrCodeCommunicationsPdf(fileId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/GetQrCodeCommunicationsPdf`, fileId);
        return result.data;
    }

    async GenerateCommunicationWithQrCodeByUsersIds(qrCodeCommuncationObject: object): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/GeneratePaymentsToDoCommunicationWithQrCode`, qrCodeCommuncationObject);
        return result.data;
    }
}