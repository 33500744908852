import { FC } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Decuius } from 'types/decuius';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { PaginationResult } from 'types/paginationResult';
import ISortOptions from 'interfaces/ISortOptions';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { getShortDate } from 'utils/common';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

interface DepositPageProps {
    allDecuiusToAssign: PaginationResult<Decuius>;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
    isPageLoading: boolean;
}

const DepositPage: FC<DepositPageProps> = (props) => {
    const { allDecuiusToAssign, setSortOptions, sortOptions, isPageLoading } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="protocollo"
                        title={<FormattedMessage id="protocol" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="cognome"
                        title={<FormattedMessage id="surname" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="nome"
                        title={<FormattedMessage id="name" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="dataNascita"
                        title={<FormattedMessage id="birthDate" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="dataMorte"
                        title={<FormattedMessage id="deathDate" />}
                    />
                    <TableCell> <FormattedMessage id="actions" /> </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    isPageLoading ?
                        (<TableRowsLoader rowsNum={10} colsNum={6} />) :
                        (<>
                            {allDecuiusToAssign && allDecuiusToAssign.items?.map((decuius) => (
                                <AlternateTableRow key={decuius.id}>
                                    <TableCell>
                                        <Grid item> {decuius.protocollo} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {decuius.cognome} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {decuius.nome} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {getShortDate(decuius.dataNascita)} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {getShortDate(decuius.dataMorte)} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                            <IconButton component={RouterLink} to={`/decuius/edit?decuiusId=${decuius.id}`}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </AlternateTableRow>
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
};

export default DepositPage;
