//TODO: Capire esattamente quali campi sono opzionali e quali no. Da fare dopo aver gestito le pratiche

import { Pratica } from "./pratica";
import { Servizio } from "./servizio";

export interface Fattura {
    id?: string;

    serie?: string;
    numero?: string;
    data: string;
    note?: string;
    causale?: string;
    cognome: string;
    nome: string;
    indirizzo: string;
    comune: string;
    provincia: string;
    cap: string;
    codiceFiscale: string;
    praticaAssociataId: string;

    praticaAssociata?: Pratica;
    servizi?: Servizio;

    numeroCivico: string;
    tenantId?: string;
    dateOfInsert?: string;
    isValid?: boolean;

    importoTotale?: number;
    numeratoreId?: string;
}

export const defaultFattura: Fattura = {
    data: new Date().toDateString(),
    cognome: '',
    nome: '',
    codiceFiscale: '',
    indirizzo: '',
    numeroCivico: '',
    comune: '',
    provincia: '',
    cap: '',
    praticaAssociataId: ''
}