import { Card, CardContent, Typography, TextField, CardActionArea, Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useKeycloak } from "keycloak-react-web";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from "react-router-dom";

const KeycloakLoginRedirect = () => {
    const { keycloak } = useKeycloak();

    const navigate = useNavigate();

    return <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '10px'
        }}
    >
        <Card sx={{ minWidth: 400 }}>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item
                        xl={12}
                        md={12}
                        xs={12}
                    >
                        <Typography variant="h2" sx={{ textAlign: 'center' }}>
                            <FormattedMessage id="welcomeToOsiride" />
                        </Typography>
                    </Grid>
                    <Grid item
                        xl={12}
                        md={12}
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Button variant="contained" endIcon={<LoginIcon />} onClick={() => keycloak.login()}>
                            <FormattedMessage id="proceedWithLogin" />
                        </Button>
                    </Grid>
                    <Grid item
                        xl={12}
                        md={12}
                        xs={12}
                    >
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>
                            <FormattedMessage id="or" />
                        </Typography>
                    </Grid>
                    <Grid item
                        xl={12}
                        md={12}
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Button variant="contained" endIcon={<VpnKeyIcon />} color="error" onClick={() => navigate('/passwordRecovery')}>
                            <FormattedMessage id="passwordRecovery" />
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Box>;
}

export default KeycloakLoginRedirect;