import { Grid, TextField, IconButton, FormControl, InputLabel, MenuItem, Select, Button, Card, TablePagination, CardHeader, Dialog, DialogTitle, DialogActions, DialogContent, CardContent, useTheme, useMediaQuery } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { composeNotistackMessage, getFormValue, handleDownloadFile, isNotNullOrUndefined } from "utils/common";
import ClearIcon from '@mui/icons-material/Clear';
import { UserTypeToGetPayments } from "types/enums/UserTypeToGetPayments";
import { PagamentoService } from "services/PagamentoServices";
import { PaymentToDo } from "types/paymentToDo";
import { handleUpsertErrors } from "utils/validation";
import BackdropLoader from "ui-component/BackdropLoader";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { date, object } from "yup";
import { CommunicationType } from "types/enums/CommunicationType";
import { TenantDocument } from "types/tenantDocument";
import { TenantService } from "services/TenantServices";
import { ServizioService } from "services/ServizioServices";
import PaymentsToDoPage from "./PaymentsToDoPage";
import PaymentsToDoPageMobile from "./PaymentsToDoPageMobile";
import SearchIcon from '@mui/icons-material/Search';
import { setQrCodeCommunicationsFileId } from "store/slices/qrCodeCommunicationsFileIdSlice";
import { dispatch } from "store";
import { UserService } from "services/UserServices";

interface PaymentsToDoFilters {
    startPeriod: string | null;
    endPeriod: string | null;
    userType: UserTypeToGetPayments;
    name: string;
    surname: string;
}

interface PaymentToSendCommunication {
    placeServiceId: string;
    year: number;
}

const PaymentsToDo = () => {
    const intl = useIntl();

    const pagamentoService = new PagamentoService();
    const tenantService = new TenantService();
    const servizioService = new ServizioService();
    const userService = new UserService();

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    let paymentsToDoFiltersValidationSchema = object({
        startPeriod: date().required('startPeriodRequired').typeError('startPeriodRequired'),
        endPeriod: date().required('endPeriodRequired').typeError('endPeriodRequired'),
    });

    const [filters, setFilters] = useState<PaymentsToDoFilters>({
        startPeriod: null,
        endPeriod: null,
        userType: UserTypeToGetPayments.all,
        name: '',
        surname: ''
    });

    const [paymentsToDo, setPaymentsToDo] = useState<PaginationResult<PaymentToDo>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }));
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }));
    }

    const [communicationDocuments, setCommunicationDocuments] = useState<Array<TenantDocument>>([]);

    useEffect(() => {
        const todayDate = new Date();
        const lastMonthDate = new Date();
        lastMonthDate.setMonth(todayDate.getMonth() - 1)

        setFilters((currentFormData: any) => ({
            ...currentFormData, ...{
                startPeriod: lastMonthDate.toISOString(),
                endPeriod: todayDate.toISOString()
            }
        }));

        (async () => {
            const retrievedCommunicationDocuments: Array<TenantDocument> = await tenantService.GetTenantCommunicationDocuments();
            setCommunicationDocuments(retrievedCommunicationDocuments);
            setChosenCommunicationDocumentId(retrievedCommunicationDocuments[0]?.id ?? '')
        })();
    }, [])

    useEffect(() => {
        if (isNotNullOrUndefined(filters.startPeriod) && isNotNullOrUndefined(filters.endPeriod)) retrievePaymentsToDo();
    }, [paginationQuery]);

    const handleDateFiltersChange = (e: any, fieldName: string) => {
        let newDateValue = e;

        if (isNotNullOrUndefined(e)) {
            newDateValue = new Date(e.format());
            newDateValue.setDate(15);
        }

        setFilters((currentFormData: any) => ({ ...currentFormData, [fieldName]: newDateValue }));
    }

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const retrievePaymentsToDo = async () => {
        setCheckedPaymentsToDo([]);
        setIsPageLoading(true);

        try {
            await paymentsToDoFiltersValidationSchema.validate(filters, { abortEarly: false });

            const retrievedPaymentsToDo: PaginationResult<PaymentToDo> = await pagamentoService.GetPaymentsToDo(filters, paginationQuery);

            setPaymentsToDo(retrievedPaymentsToDo);
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        } finally {
            setIsPageLoading(false);
        }
    }

    const [sendSingleCommunicationDialogOpen, setSendSingleCommunicationDialogOpen] = useState<boolean>(false);
    const [sendMassiveCommunicationDialogOpen, setSendMassiveCommunicationDialogOpen] = useState<boolean>(false);

    const [entityToSendCommunication, setEntityToSendCommunication] = useState<{
        placeServiceId: string,
        year: number,
        fullName: string,
        communicationType: CommunicationType
    } | undefined>(undefined);

    const [massiveCommunicationType, setMassiveCommunicationType] = useState<CommunicationType>(CommunicationType.Email);

    const updateEntityToSendCommunication = (paymentToDo: PaymentToDo, communicationType: CommunicationType) => {
        setEntityToSendCommunication({
            placeServiceId: paymentToDo.placeServiceId,
            year: paymentToDo.year,
            fullName: paymentToDo.payerFullName,
            communicationType: communicationType,
        });

        setSendSingleCommunicationDialogOpen(true);
    }

    const [downloadSingleBulletinDialogOpen, setDownloadSingleBulletinDialogOpen] = useState<boolean>(false);
    const [downloadMassiveBulletinDialogOpen, setDownloadMassiveBulletinDialogOpen] = useState<boolean>(false);

    const [entityToDownloadBulletin, setEntityToDownloadBulletin] = useState<{ placeServiceId: string, year: number, fullName: string } | undefined>(undefined);

    const updateEntityToDownloadBulletin = (paymentToDo: PaymentToDo) => {
        setEntityToDownloadBulletin({
            placeServiceId: paymentToDo.placeServiceId,
            year: paymentToDo.year,
            fullName: paymentToDo.payerFullName
        });

        setDownloadSingleBulletinDialogOpen(true);
    }

    const singleDownloadBulletin = async () => {
        setDownloadSingleBulletinDialogOpen(false);
        await handleDownloadBulletins([{ placeServiceId: entityToDownloadBulletin!.placeServiceId, year: entityToDownloadBulletin!.year }]);
    }

    const massiveDownloadBulletins = async () => {
        setDownloadMassiveBulletinDialogOpen(false);
        await handleDownloadBulletins(checkedPaymentsToDo);
    }

    const handleDownloadBulletins = async (paymentsToSendCommunication: Array<PaymentToSendCommunication>) => {
        setIsPageLoading(true);

        try {
            const reportExcelBase64: string = await servizioService.GetBollettiniExcelByPlacesServices({
                placeServicesToCommunicate: paymentsToSendCommunication,
            });

            handleDownloadFile(reportExcelBase64, "Bollettini.xlsx");

            const newPaymentsToDo = paymentsToDo.items.filter((paymentToDo: PaymentToDo) =>
                !paymentsToSendCommunication.some((paymentToSendCommunication: PaymentToSendCommunication) =>
                    paymentToSendCommunication.placeServiceId === paymentToDo.placeServiceId &&
                    paymentToSendCommunication.year === paymentToDo.year
                )
            );

            setPaymentsToDo((currentFormData: any) => ({ ...currentFormData, items: newPaymentsToDo }));
            setCheckedPaymentsToDo([]);
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsPageLoading(false);
        }
    }

    const updateMassiveCommunicationType = (communicationType: CommunicationType) => {
        setMassiveCommunicationType(communicationType);
        setSendMassiveCommunicationDialogOpen(true);
    }

    const massiveSendCommunications = async () => {
        setSendMassiveCommunicationDialogOpen(false);
        await handleSendCommunication(checkedPaymentsToDo, massiveCommunicationType);
    }

    const singleSendCommunication = async () => {
        setSendSingleCommunicationDialogOpen(false);
        await handleSendCommunication([{
            placeServiceId: entityToSendCommunication!.placeServiceId,
            year: entityToSendCommunication!.year
        }], entityToSendCommunication!.communicationType);
    }

    const handleSendCommunication = async (paymentsToSendCommunication: Array<PaymentToSendCommunication>, communicationType: CommunicationType) => {
        setIsPageLoading(true);

        try {
            const paymentCommunicationsResult: boolean = await pagamentoService.SendPaymentCommunications({
                placeServicesToCommunicate: paymentsToSendCommunication,
                templateId: chosenCommunicationDocumentId,
                communicationType: communicationType
            });

            if (paymentCommunicationsResult) {
                composeNotistackMessage(intl.formatMessage({ id: 'communicationsSentSuccessfully' }), 'success');

                const newPaymentsToDo = paymentsToDo.items.filter((paymentToDo: PaymentToDo) =>
                    !paymentsToSendCommunication.some((paymentToSendCommunication: PaymentToSendCommunication) =>
                        paymentToSendCommunication.placeServiceId === paymentToDo.placeServiceId &&
                        paymentToSendCommunication.year === paymentToDo.year
                    )
                );

                setPaymentsToDo((currentFormData: any) => ({ ...currentFormData, items: newPaymentsToDo }));
                setCheckedPaymentsToDo([]);
            } else {
                composeNotistackMessage(intl.formatMessage({ id: 'anErrorOccurredWhileSendingEmail' }), 'error');
            }
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsPageLoading(false);
        }
    }

    const [chosenCommunicationDocumentId, setChosenCommunicationDocumentId] = useState<string>('');

    const SelectCommunicationTemplate = () => {
        return (
            <FormControl fullWidth size='small' required sx={{ mt: 2 }}>
                <InputLabel> <FormattedMessage id="templateCommunication" /> </InputLabel>
                <Select
                    name="communicationTemplate"
                    label={<FormattedMessage id="templateCommunication" />}
                    sx={{ color: 'white' }}
                    defaultValue={communicationDocuments[0]?.id ?? undefined}
                    onChange={(e: any) => setChosenCommunicationDocumentId(e.target.value)}
                    value={chosenCommunicationDocumentId}
                >
                    {
                        communicationDocuments.map((document, index) => (
                            <MenuItem key={index} value={document.id}>
                                {document.fileName}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        )
    }

    const [checkedPaymentsToDo, setCheckedPaymentsToDo] = useState<Array<PaymentToSendCommunication>>([]);

    const checkAllPaymentsToDo = (e: any) => {
        let newCheckedPaymentsToDo: Array<PaymentToSendCommunication> = [];
        if (e.target.checked) {
            newCheckedPaymentsToDo = paymentsToDo.items.map((paymentToDo: PaymentToDo) => { return { placeServiceId: paymentToDo.placeServiceId, year: paymentToDo.year } })
        }
        setCheckedPaymentsToDo(newCheckedPaymentsToDo);
    }

    const checkPaymentToDo = (e: any, paymentToDo: PaymentToDo) => {
        let newCheckedPaymentsToDo: Array<PaymentToSendCommunication> = [];

        if (e.target.checked) {
            newCheckedPaymentsToDo = [...checkedPaymentsToDo, ...[{ placeServiceId: paymentToDo.placeServiceId, year: paymentToDo.year }]];
        } else {
            newCheckedPaymentsToDo = checkedPaymentsToDo.filter((paymentToSendCommunication: PaymentToSendCommunication) =>
                !(paymentToSendCommunication.placeServiceId === paymentToDo.placeServiceId && paymentToSendCommunication.year === paymentToDo.year))
        }

        setCheckedPaymentsToDo(newCheckedPaymentsToDo);
    }

    const isPaymentToDoChecked = (paymentToDo: PaymentToDo): boolean => {
        return checkedPaymentsToDo.find((paymentToSendCommunication: PaymentToSendCommunication) => {
            return paymentToSendCommunication.placeServiceId === paymentToDo.placeServiceId && paymentToSendCommunication.year === paymentToDo.year
        }) !== undefined;
    }

    const [generateQrCodeCommunicationsDialogOpen, setGenerateQrCodeCommunicationsDialogOpen] = useState<boolean>(false);

    const handleGenerateQrCodeCommunicationsDialogClose = () => {
        setGenerateQrCodeCommunicationsDialogOpen(false);
        dispatch(setQrCodeCommunicationsFileId(''));
    }

    const generateQrCodeCommunications = async () => {
        setIsPageLoading(true);

        try {
            const qrCodeCommunicationObject: object = {
                usersIds: Array.from(new Set(paymentsToDo.items.filter((paymentToDo: PaymentToDo) => paymentToDo.couldReceiveQrCodeCommunication).map((paymentToDo: PaymentToDo) => paymentToDo.payerId))),
                fileId: generateAndSaveFileUUID(),
                templateId: chosenCommunicationDocumentId
            };

            setGenerateQrCodeCommunicationsDialogOpen(false);

            await userService.GenerateCommunicationWithQrCodeByUsersIds(qrCodeCommunicationObject);

            composeNotistackMessage(intl.formatMessage({ id: 'qrCodeCommunicationsGenerationStartedSuccessfully' }), 'success');
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
            dispatch(setQrCodeCommunicationsFileId(''));
        } finally {
            setIsPageLoading(false);
        }
    }

    const generateAndSaveFileUUID = (): string => {
        const uuid: string = crypto.randomUUID();

        dispatch(setQrCodeCommunicationsFileId(uuid));

        return uuid;
    }

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    return (<>
        <Dialog
            open={sendSingleCommunicationDialogOpen}
            onClose={() => setSendSingleCommunicationDialogOpen(false)}
            aria-labelledby="alert-send-communication"
            aria-describedby="alert-send-communication"
        >
            <DialogTitle id="send-communication-title">
                <FormattedMessage id="confirmSendCommunication" />: <b>{entityToSendCommunication?.fullName}</b>?
            </DialogTitle>
            <DialogContent>
                <SelectCommunicationTemplate />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSendSingleCommunicationDialogOpen(false)} variant="outlined" color="error"> <FormattedMessage id="cancel" /> </Button>
                <Button onClick={singleSendCommunication} variant="contained" color="success"><FormattedMessage id="send" /></Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={sendMassiveCommunicationDialogOpen}
            onClose={() => setSendMassiveCommunicationDialogOpen(false)}
            aria-labelledby="alert-send-massive-communication"
            aria-describedby="alert-send-massive-communication"
        >
            <DialogTitle id="send-massive-communication-title">
                <FormattedMessage id="confirmSendMassiveCommunication" />?
            </DialogTitle>
            <DialogContent>
                <SelectCommunicationTemplate />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSendMassiveCommunicationDialogOpen(false)} variant="outlined" color="error"> <FormattedMessage id="cancel" /> </Button>
                <Button onClick={massiveSendCommunications} variant="contained" color="success"><FormattedMessage id="send" /></Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={downloadMassiveBulletinDialogOpen}
            onClose={() => setDownloadMassiveBulletinDialogOpen(false)}
            aria-labelledby="alert-download-massive-bulletins"
            aria-describedby="alert-download-massive-bulletins"
        >
            <DialogTitle id="download-massive-bulletins-title">
                <FormattedMessage id="confirmDownloadMassiveBulletins" />?
            </DialogTitle>
            <DialogActions>
                <Button onClick={() => setDownloadMassiveBulletinDialogOpen(false)} variant="outlined" color="error"> <FormattedMessage id="cancel" /> </Button>
                <Button onClick={massiveDownloadBulletins} variant="contained" color="success"><FormattedMessage id="download" /></Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={downloadSingleBulletinDialogOpen}
            onClose={() => setDownloadSingleBulletinDialogOpen(false)}
            aria-labelledby="alert-download-bulletin"
            aria-describedby="alert-download-bulletin"
        >
            <DialogTitle id="download-bulletin-title">
                <FormattedMessage id="confirmDownloadBulletin" />: <b>{entityToDownloadBulletin?.fullName}</b>?
            </DialogTitle>
            <DialogActions>
                <Button onClick={() => setDownloadSingleBulletinDialogOpen(false)} variant="outlined" color="error"> <FormattedMessage id="cancel" /> </Button>
                <Button onClick={singleDownloadBulletin} variant="contained" color="success"><FormattedMessage id="download" /></Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={generateQrCodeCommunicationsDialogOpen}
            onClose={handleGenerateQrCodeCommunicationsDialogClose}
            aria-labelledby="alert-send-communication"
            aria-describedby="alert-send-communication"
        >
            <DialogTitle id="send-communication-title">
                <FormattedMessage id="confirmGenerationQrCodeCommunication" />?
            </DialogTitle>
            <DialogContent>
                <SelectCommunicationTemplate />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleGenerateQrCodeCommunicationsDialogClose} variant="outlined" color="error"> <FormattedMessage id="cancel" /> </Button>
                <Button onClick={generateQrCodeCommunications} variant="contained" color="success"><FormattedMessage id="generate" /></Button>
            </DialogActions>
        </Dialog>

        <BackdropLoader open={isPageLoading} />

        <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <MobileDatePicker
                    views={['year', 'month']}
                    label={<FormattedMessage id="startPeriod" />}
                    onChange={(e: any) => handleDateFiltersChange(e, 'startPeriod')}
                    renderInput={(params) =>
                        <TextField {...params}
                            fullWidth
                            size="small"
                            name="startPeriod"
                            required
                            InputProps={{
                                endAdornment: (
                                    filters.startPeriod !== null &&
                                    <IconButton onClick={() => handleDateFiltersChange(null, 'startPeriod')} edge="end" size="small">
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />}
                    value={filters.startPeriod}
                    inputFormat="MM/YYYY"
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <MobileDatePicker
                    views={['year', 'month']}
                    label={<FormattedMessage id="endPeriod" />}
                    onChange={(e: any) => handleDateFiltersChange(e, 'endPeriod')}
                    renderInput={(params) =>
                        <TextField {...params}
                            fullWidth
                            size="small"
                            name="endPeriod"
                            required
                            InputProps={{
                                endAdornment: (
                                    filters.endPeriod !== null &&
                                    <IconButton onClick={() => handleDateFiltersChange(null, 'endPeriod')} edge="end" size="small">
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />}
                    value={filters.endPeriod}
                    inputFormat="MM/YYYY"
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <TextField
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="surname" />}
                    name="surname"
                    onChange={handleFiltersChange}
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <TextField
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="name" />}
                    name="name"
                    onChange={handleFiltersChange}
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <FormControl fullWidth size='small' required>
                    <InputLabel> <FormattedMessage id="usersType" /> </InputLabel>
                    <Select
                        name="userType"
                        label={<FormattedMessage id="usersType" />}
                        onChange={handleFiltersChange}
                        defaultValue={filters.userType}
                        sx={{ color: 'white' }}
                    >
                        {
                            Object.values(UserTypeToGetPayments).filter(key => isNaN(Number(key)))
                                .map((userTypeToGetPayments, index) => (
                                    <MenuItem key={index} value={index}>
                                        <FormattedMessage id={UserTypeToGetPayments[index]} />
                                    </MenuItem>
                                ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <Button variant="contained" onClick={retrievePaymentsToDo} startIcon={<SearchIcon />}> <FormattedMessage id="search" /> </Button>
            </Grid>
        </Grid>
        {
            paymentsToDo.items.length != 0 &&
            <>
                <Button sx={{ m: 2 }} variant="contained" onClick={() => setDownloadMassiveBulletinDialogOpen(true)} disabled={checkedPaymentsToDo.length < 1}> <FormattedMessage id="massiveDownloadBulletins" /> </Button>
                {
                    (filters.userType == UserTypeToGetPayments.all || filters.userType == UserTypeToGetPayments.onlyEmail) &&
                    <Button sx={{ m: 2 }} variant="contained" onClick={() => updateMassiveCommunicationType(CommunicationType.Email)} disabled={checkedPaymentsToDo.length < 1}> <FormattedMessage id="sendMassiveEmail" /> </Button>
                }
                {
                    (filters.userType == UserTypeToGetPayments.all || filters.userType == UserTypeToGetPayments.onlyPhone) &&
                    <Button sx={{ m: 2 }} variant="contained" onClick={() => updateMassiveCommunicationType(CommunicationType.Whatsapp)} disabled={checkedPaymentsToDo.length < 1}> <FormattedMessage id="sendMassiveWhatsapp" /> </Button>
                }
                <Button sx={{ m: 2 }} variant="contained" onClick={() => setGenerateQrCodeCommunicationsDialogOpen(true)} disabled={!paymentsToDo.items.some((paymentToDo: PaymentToDo) => paymentToDo.couldReceiveQrCodeCommunication)}> <FormattedMessage id="generateQrCodeCommunications" /> </Button>

                <Card>
                    <CardHeader title={<FormattedMessage id="paymentsToDo" />} />
                    <CardContent>
                        {
                            mobileDevice ?
                                <PaymentsToDoPageMobile
                                    paymentsToDo={paymentsToDo}
                                    checkAllPaymentsToDo={checkAllPaymentsToDo}
                                    isPaymentToDoChecked={isPaymentToDoChecked}
                                    checkPaymentToDo={checkPaymentToDo}
                                    updateEntityToSendCommunication={updateEntityToSendCommunication}
                                    updateEntityToDownloadBulletin={updateEntityToDownloadBulletin}
                                />
                                :
                                <PaymentsToDoPage
                                    paymentsToDo={paymentsToDo}
                                    checkAllPaymentsToDo={checkAllPaymentsToDo}
                                    isPaymentToDoChecked={isPaymentToDoChecked}
                                    checkPaymentToDo={checkPaymentToDo}
                                    updateEntityToSendCommunication={updateEntityToSendCommunication}
                                    updateEntityToDownloadBulletin={updateEntityToDownloadBulletin}
                                />
                        }
                        <TablePagination
                            component="div"
                            count={paymentsToDo?.totalCount}
                            onRowsPerPageChange={(e) => {
                                setPageSize(parseInt(e.target.value, 10));
                            }}
                            onPageChange={(e, page) => {
                                setPageNumber(page);
                            }}
                            page={paginationQuery.pageNumber}
                            rowsPerPage={paginationQuery.pageSize}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                        />
                    </CardContent>
                </Card>
            </>
        }
    </>);
}

export default PaymentsToDo;