/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { Struttura } from '../types/struttura';
import { PaginationResult } from 'types/paginationResult';
import { PaginationQuery } from 'types/paginationQuery';
import { Utente } from 'types/utente';
import { Allegato } from 'types/allegato';
import { StructureShortInfo } from 'types/structureShortInfo';

export class StrutturaService {
    async GetStrutture(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Struttura>> {
        const result = await axios.post<PaginationResult<Struttura>>(`${serviceConfig.appServiceBaseUrl}/GetStrutture`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetStrutturaById(strutturaId: string): Promise<Struttura> {
        const result = await axios.post<Struttura>(`${serviceConfig.appServiceBaseUrl}/GetStruttura`, strutturaId);
        return result.data;
    }

    async DeleteStruttura(strutturaId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteStruttura`, strutturaId);
        return result.data;
    }

    async UpsertStruttura(struttura: any): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/UpsertStruttura`, struttura);
        return result.data;
    }

    async GetStrutturaConcessionari(strutturaId: string): Promise<Array<Utente>> {
        const result = await axios.post<Array<Utente>>(`${serviceConfig.appServiceBaseUrl}/GetStrutturaConcessionari`, strutturaId);
        return result.data;
    }

    async AssignConcessionarioToStruttura(strutturaConcessionario: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/AssignConcessionarioToStruttura`, strutturaConcessionario);
        return result.data;
    }

    async RemoveConcessionarioFromStruttura(strutturaConcessionario: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/RemoveConcessionarioFromStruttura`, strutturaConcessionario);
        return result.data;
    }

    async InsertAllegatoToStruttura(insertStrutturaAllegato: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/InsertStrutturaAllegato`, insertStrutturaAllegato);
        return result.data;
    }

    async DeleteAllegatoFromStruttura(allegatoId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteStrutturaAllegato`, allegatoId);
        return result.data;
    }

    async GetStrutturaAllegati(strutturaId: string): Promise<Array<Allegato>> {
        const result = await axios.post<Array<Allegato>>(`${serviceConfig.appServiceBaseUrl}/GetStrutturaAllegati`, strutturaId);
        return result.data;
    }

    async AssignUserToStruttura(assignUserStruttura: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/AssignUserToStruttura`, assignUserStruttura);
        return result.data;
    }

    async GetStrutturaAssignedUsers(strutturaId: string): Promise<Array<Utente>> {
        const result = await axios.post<Array<Utente>>(`${serviceConfig.appServiceBaseUrl}/GetStrutturaAssignedUsers`, strutturaId);
        return result.data;
    }

    async DeleteUserFromStruttura(userStruttura: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteUserFromStruttura`, userStruttura);
        return result.data;
    }

    async GetUserStructures(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Struttura>> {
        const result = await axios.post<PaginationResult<Struttura>>(`${serviceConfig.appServiceBaseUrl}/GetUserStructures`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetUserStructuresNumber(): Promise<number> {
        const result = await axios.get<number>(`${serviceConfig.appServiceBaseUrl}/GetUserStructuresNumber`);
        return result.data;
    }

    async DownloadStrutturaAllegato(documentId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/DownloadStrutturaAllegato`, documentId);
        return result.data;
    }

    async RenameAllegatoStruttura(renameAllegatoObject: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/RenameAllegatoStruttura`, renameAllegatoObject);
        return result.data;
    }

    async GetStructuresShortInfo(structureNameFilter: string): Promise<StructureShortInfo[]> {
        const result = await axios.post<StructureShortInfo[]>(`${serviceConfig.appServiceBaseUrl}/GetAllStructuresShortInfo`, structureNameFilter);
        return result.data;
    }
}