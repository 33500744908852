import { Editor, Transforms, Element as SlateElement, Text } from 'slate'
import Link from '../Elements/Link/Link'
import Image from '../Elements/Embed/Image'
import Signature from '../Elements/Signature/Signature'
import Equation from '../Elements/Equation/Equation'
import Table from '../Elements/Table/Table'
import escapeHtml from 'escape-html'
import { jsx } from 'slate-hyperscript'
import AdminSignature from '../Elements/AdminSignature/AdminSignature'

const alignment = ['alignLeft', 'alignRight', 'alignCenter', 'alignJustify']
const list_types = ['orderedList', 'unorderedList']

export const sizeMap = {
    small: '0.75em',
    normal: '1em',
    medium: '1.75em',
    huge: '2.5em'
}

export const reverseSizeMap = {
    '0.75em': 'small',
    '1em': 'normal',
    '1.75em': 'medium',
    '2.5em': 'huge'
}

export const fontFamilyMap = {
    sans: 'Helvetica,Arial, sans serif',
    serif: 'Georgia, Times New Roaman,serif',
    monospace: 'Monaco, Courier New,monospace'
}

export const reverseFontFamilyMap = {
    "Helvetica, \"Arial\", sans serif": 'sans',
    "Georgia, \"Times New Roaman\", serif": 'serif',
    "Monaco, \"Courier New\", monospace": 'monospace'
}

export const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = list_types.includes(format)
    const isIndent = alignment.includes(format)
    const isAligned = alignment.some(alignmentType => isBlockActive(editor, alignmentType))


    /*If the node is already aligned and change in indent is called we should unwrap it first and split the node to prevent
    messy, nested DOM structure and bugs due to that.*/
    if (isAligned && isIndent) {
        Transforms.unwrapNodes(editor, {
            match: n => alignment.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
            split: true
        })
    }


    /* Wraping the nodes for alignment, to allow it to co-exist with other block level operations*/
    if (isIndent) {
        Transforms.wrapNodes(editor, {
            type: format,
            children: []
        })
        return
    }
    Transforms.unwrapNodes(editor, {
        match: n => list_types.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
        split: true
    })



    Transforms.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    })


    if (isList && !isActive) {
        Transforms.wrapNodes(editor, {
            type: format,
            children: []
        })
    }




}

export const addMarkData = (editor, data) => {
    Editor.addMark(editor, data.format, data.value);
}

export const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)

    return marks ? marks[format] === true : false
}

export const isBlockActive = (editor, format) => {

    const [match] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format
    })


    return !!match;
}

export const activeMark = (editor, format) => {
    const defaultMarkData = {
        color: 'black',
        bgColor: 'black',
        fontSize: 'normal',
        fontFamily: 'sans'
    }
    const marks = Editor.marks(editor);
    const defaultValue = defaultMarkData[format];
    return marks?.[format] ?? defaultValue;
}

export const getMarked = (leaf, children) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }
    if (leaf.strikethrough) {
        children = <span style={{ textDecoration: 'line-through' }}>{children}</span>
    }
    if (leaf.underline) {
        children = <u>{children}</u>
    }
    if (leaf.superscript) {
        children = <sup>{children}</sup>
    }
    if (leaf.subscript) {
        children = <sub>{children}</sub>
    }
    if (leaf.color) {
        children = <span style={{ color: leaf.color }}>{children}</span>
    }
    if (leaf.bgColor) {
        children = <span style={{ backgroundColor: leaf.bgColor }}>{children}</span>
    }
    if (leaf.fontSize) {
        const size = sizeMap[leaf.fontSize]
        children = <span style={{ fontSize: size }}>{children}</span>
    }
    if (leaf.fontFamily) {
        const family = fontFamilyMap[leaf.fontFamily]
        children = <span style={{ fontFamily: family }}>{children}</span>
    }
    if (leaf.lineHeight) {
        const lineHeight = leaf.lineHeight;
        children = <span style={{ lineHeight: lineHeight }}>{children}</span>
    }
    return children;
}

const checkType = (node, targetType) => {
    if (Array.isArray(node)) {
        return node.some(child => checkType(child, targetType));
    } else {
        if (targetType.includes(node.type)) {
            return true;
        }
        if (node.children) {
            return node.children.some(child => checkType(child, targetType));
        }
    }
    return false;
}

export const getBlock = (props) => {
    const { element, children } = props;
    const attributes = props.attributes ?? {};

    let style;

    switch (element.type) {
        case 'headingOne':
            return <h1 {...attributes} {...element.attr}>{children}</h1>
        case 'headingTwo':
            return <h2 {...attributes} {...element.attr}>{children}</h2>
        case 'headingThree':
            return <h3 {...attributes} {...element.attr}>{children}</h3>
        case 'blockquote':
            return <blockquote {...attributes} {...element.attr}>{children}</blockquote>
        case 'alignLeft':
            return <div style={{ textAlign: 'left', listStylePosition: 'inside' }} {...attributes} {...element.attr}>{children}</div>
        case 'alignCenter':
            style = { textAlign: 'center', listStylePosition: 'inside' };
            if (checkType(element, ['signature', 'adminSignature'])) {
                style = { ...style, ...{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' } }
            }
            return <div style={style} {...attributes} {...element.attr}>{children}</div>
        case 'alignRight':
            style = { textAlign: 'right', listStylePosition: 'inside' };
            if (checkType(element, ['signature', 'adminSignature'])) {
                style = { ...style, ...{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end' } }
            }
            return <div style={style} {...attributes} {...element.attr}>{children}</div>
        case 'alignJustify':
            return <div style={{ textAlign: 'justify', listStylePosition: 'inside' }} {...attributes} {...element.attr}>{children}</div>
        case 'list-item':
            return <li {...attributes} {...element.attr}>{children}</li>
        case 'orderedList':
            return <ol type='1' {...attributes}>{children}</ol>
        case 'unorderedList':
            return <ul {...attributes}>{children}</ul>
        case 'link':
            return <Link {...props} />
        case 'table':
            return <Table {...props} />
        case 'table-row':
            return <tr {...attributes}>{children}</tr>
        case 'table-cell':
            return <td {...element.attr} {...attributes}>{children}</td>
        case 'image':
            return <Image {...props} />
        case 'signature':
            return <Signature {...props} />
        case 'adminSignature':
            return <AdminSignature {...props} />
        // case 'video':
        // return <Video {...props}/>
        case 'equation':
            return <Equation {...props} />
        case 'htmlCode':
        // return <HtmlCode {...props}/>
        default:
            return <div {...element.attr} {...attributes}>{children}</div>
    }
}

export const serialize = node => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        if (node.bold) {
            string = `<strong>${string}</strong>`
        }
        if (node.italic) {
            string = `<em>${string}</em>`;
        }
        if (node.underline) {
            string = `<u>${string}</u>`;
        }
        if (node.strikethrough) {
            string = `<del>${string}</del>`;
        }

        let colorStyle = '';

        if (node.color) {
            colorStyle = `color:${node.color};`;
        }
        if (node.bgColor) {
            colorStyle += ` background-color:${node.bgColor}`;
        }
        if (colorStyle !== '') {
            string = `<span style="${colorStyle}">${string}</span>`;
        }

        if (node.fontFamily) {
            string = `<span style="font-family:${fontFamilyMap[node.fontFamily]};">${string}</span>`;
        }
        if (node.fontSize) {
            string = `<span style="font-size:${sizeMap[node.fontSize]}">${string}</span>`
        }

        if (node.lineHeight) {
            string = `<span style="line-height: ${node.lineHeight};">${string}</span>`;
        }

        return string
    }

    var children;

    if (!!node.children) {
        children = node.children.map(n => serialize(n)).join('')
    } else {
        children = node.map(n => serialize(n)).join('')
    }

    switch (node.type) {
        case 'quote':
            return `<blockquote><p>${children}</p></blockquote>`;
        case 'paragraph':
            return `<p style="white-space: pre-wrap;">${children}</p>`;
        case "placeholder":
            return `<p>${children}</p>`;
        case 'link':
            return `<a href="${escapeHtml(node.url)}">${children}</a>`;
        case 'headingOne':
            return `<h1>${children}</h1>`;
        case 'headingTwo':
            return `<h2>${children}</h2>`;
        case 'headingThree':
            return `<h3>${children}</h3>`;
        case 'unorderedList':
            return `<ul>${children}</ul>`;
        case 'orderedList':
            return `<ol>${children}</ol>`;
        case 'list-item':
            return `<li>${children}</li>`;
        case 'alignCenter':
            return `<div style="text-align: center;">${children}</div>`;
        case 'alignLeft':
            return `<div style="text-align: left;">${children}</div>`;
        case 'alignRight':
            return `<div style="text-align: right;">${children}</div>`;
        case 'alignJustify':
            return `<div style="text-align: justify;">${children}</div>`;
        case 'signature':
            return `<img width="200px" height="50px" class="signature" src=${node.url} />`;
        case 'adminSignature':
            return `<img width="200px" height="50px" class="adminSignature" src=${node.url} />`;
        default:
            return children
    }
}

export const deserialize = (el, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
        return jsx('text', markAttributes, el.textContent);
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null;
    }

    const nodeAttributes = { ...markAttributes };

    switch (el.nodeName) {
        case 'STRONG':
            nodeAttributes.bold = true;
            break;
        case 'EM':
            nodeAttributes.italic = true;
            break;
        case 'DEL':
            nodeAttributes.strikethrough = true;
            break;
        case 'U':
            nodeAttributes.underline = true;
            break;
    }

    if (el.nodeName === 'SPAN') {
        const styles = el.style;

        if (styles.color) {
            nodeAttributes.color = styles.color;
        }

        if (styles.backgroundColor) {
            nodeAttributes.bgColor = styles.backgroundColor;
        }

        if (styles.fontFamily) {
            nodeAttributes.fontFamily = reverseFontFamilyMap[styles.fontFamily];
        }

        if (styles.fontSize) {
            nodeAttributes.fontSize = reverseSizeMap[styles.fontSize];
        }

        if (styles.lineHeight) {
            nodeAttributes.lineHeight = styles.lineHeight;
        }
    }

    const children = Array.from(el.childNodes)
        .map(node => deserialize(node, nodeAttributes))
        .flat();

    if (children.length === 0) {
        children.push(jsx('text', nodeAttributes, ''));
    }

    switch (el.nodeName) {
        case 'BODY':
            return jsx('fragment', {}, children);
        case 'BR':
            return '\n';
        case 'BLOCKQUOTE':
            return jsx('element', { type: 'quote' }, children);
        case 'P':
            return jsx('element', { type: 'paragraph' }, children);
        case 'A':
            return jsx(
                'element',
                { type: 'link', url: el.getAttribute('href') },
                children
            );
        case 'H1':
            return jsx('element', { type: 'headingOne' }, children);
        case 'H2':
            return jsx('element', { type: 'headingTwo' }, children);
        case 'H3':
            return jsx('element', { type: 'headingThree' }, children);
        case 'UL':
            return jsx('element', { type: 'unorderedList' }, children);
        case 'OL':
            return jsx('element', { type: 'orderedList' }, children);
        case 'LI':
            return jsx('element', { type: 'list-item' }, children);
        case 'DIV':
            const textAlign = el.style.textAlign;
            if (textAlign === 'center') {
                return jsx('element', { type: 'alignCenter' }, children);
            } else if (textAlign === 'right') {
                return jsx('element', { type: 'alignRight' }, children);
            } else if (textAlign === 'justify') {
                return jsx('element', { type: 'alignJustify' }, children);
            } else if (textAlign === 'alignLeft') {
                return jsx('element', { type: 'alignLeft' }, children);
            } else {
                return jsx('element', children);
            }
        case 'IMG':
            let type;

            switch(el.className) {
                case 'signature':
                    type = 'signature';
                    break;
                case 'adminSignature':
                    type = 'adminSignature';
                    break;
                default:
                    type = 'image';
                    break;
            }

            return jsx('element', {
                type: type,
                url: el.src,
            }, children);
        default:
            return children;
    }
};