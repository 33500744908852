import { Grid, TextField, IconButton, Button, Card, TablePagination, CardHeader, CardContent, useTheme, useMediaQuery, Autocomplete, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ClearIcon from '@mui/icons-material/Clear';
import { PagamentoService } from "services/PagamentoServices";
import { handleUpsertErrors } from "utils/validation";
import BackdropLoader from "ui-component/BackdropLoader";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { date, object } from "yup";
import { PaymentDone } from "types/paymentDone";
import PaymentsDonePageMobile from "./PaymentsDonePageMobile";
import PaymentsDonePage from "./PaymentsDonePage";
import { ServizioService } from "services/ServizioServices";
import IAutocompleteOptions from "interfaces/IAutocompleteOptions";
import { Servizio } from "types/servizio";
import { composeNotistackMessage, getFormValue, isNotNullOrUndefined } from "utils/common";
import { TipoPagamento } from "types/enums/TipoPagamento";
import SearchIcon from '@mui/icons-material/Search';

interface IPaymentsDoneFilters {
    startPeriod: string | null;
    endPeriod: string | null;
    paymentCode: string;
}

const PaymentsDone = () => {
    const intl = useIntl();

    const pagamentoService = new PagamentoService();
    const servizioService = new ServizioService();

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    let paymentsDoneFiltersValidationSchema = object({
        startPeriod: date().required('startPeriodRequired').typeError('startPeriodRequired'),
        endPeriod: date().required('endPeriodRequired').typeError('endPeriodRequired'),
    });

    const [filters, setFilters] = useState<IPaymentsDoneFilters>({ startPeriod: null, endPeriod: null, paymentCode: '' });

    const [chosenService, setChosenService] = useState<IAutocompleteOptions | null>(null);
    const [paymentType, setPaymentType] = useState<TipoPagamento | null>(null);

    const [paymentsDone, setPaymentsDone] = useState<PaginationResult<PaymentDone>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }));
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }));
    }

    const handleFiltersPeriodChange = (e: any, fieldName: string) => {
        let newDateValue = e;

        if (isNotNullOrUndefined(e)) {
            newDateValue = new Date(e.format());
            newDateValue.setDate(15);
        }

        setFilters((currentFormData: any) => ({ ...currentFormData, [fieldName]: newDateValue }));
    }

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const handleChange = (e: any) => {
        setPaymentType(isNotNullOrUndefined(e.target.value) ? e.target.value : null);
    }

    const [servicesOptions, setServicesOptions] = useState<Array<IAutocompleteOptions>>([]);

    useEffect(() => {
        const todayDate = new Date();
        const lastMonthDate = new Date();
        lastMonthDate.setMonth(todayDate.getMonth() - 1)

        setFilters({
            startPeriod: lastMonthDate.toISOString(),
            endPeriod: todayDate.toISOString(),
            paymentCode: ''
        });

        (async () => {
            const retrievedServices = await servizioService.GetUnpaginatedServizi();
            setServicesOptions(retrievedServices.map((service: Servizio) => ({ id: service.id!, label: service.descrizione })));
        })()
    }, [])

    useEffect(() => {
        if (isNotNullOrUndefined(filters.startPeriod) && isNotNullOrUndefined(filters.endPeriod)) retrievePaymentsDone();
    }, [paginationQuery]);

    const retrievePaymentsDone = async () => {
        setIsPageLoading(true);

        try {
            await paymentsDoneFiltersValidationSchema.validate(filters, { abortEarly: false });

            const paymentsDone: PaginationResult<PaymentDone> = await pagamentoService.GetPaymentsDone({
                ...filters,
                serviceId: chosenService?.id,
                paymentType: paymentType
            }, paginationQuery);

            setPaymentsDone(paymentsDone);
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        } finally {
            setIsPageLoading(false);
        }
    }

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);

    const handleAutocompleteChange = (value: IAutocompleteOptions | null) => {
        setChosenService(value);
    }

    const updateUserPayment = async (paymentId: string, paymentResult: boolean) => {
        setIsPageLoading(true);

        try {
            await pagamentoService.UpdateUserPaymentByAdmin({
                paymentId: paymentId,
                paymentResult: paymentResult
            });

            await retrievePaymentsDone();
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsPageLoading(false);
        }
    }

    return (<>
        <BackdropLoader open={isPageLoading} />

        <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <MobileDatePicker
                    views={['year', 'month']}
                    label={<FormattedMessage id="startPeriod" />}
                    onChange={(e: any) => handleFiltersPeriodChange(e, 'startPeriod')}
                    renderInput={(params) =>
                        <TextField {...params}
                            fullWidth
                            size="small"
                            name="startPeriod"
                            required
                            InputProps={{
                                endAdornment: (
                                    filters.startPeriod !== null &&
                                    <IconButton onClick={() => handleFiltersPeriodChange(null, 'startPeriod')} edge="end" size="small">
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />}
                    value={filters.startPeriod}
                    inputFormat="MM/YYYY"
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <MobileDatePicker
                    views={['year', 'month']}
                    label={<FormattedMessage id="endPeriod" />}
                    onChange={(e: any) => handleFiltersPeriodChange(e, 'endPeriod')}
                    renderInput={(params) =>
                        <TextField {...params}
                            fullWidth
                            size="small"
                            name="endPeriod"
                            required
                            InputProps={{
                                endAdornment: (
                                    filters.endPeriod !== null &&
                                    <IconButton onClick={() => handleFiltersPeriodChange(null, 'endPeriod')} edge="end" size="small">
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />}
                    value={filters.endPeriod}
                    inputFormat="MM/YYYY"
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}>
                <Autocomplete
                    id="service"
                    options={servicesOptions}
                    renderInput={(params) =>
                        <TextField {...params} name="service"
                            fullWidth label={<FormattedMessage id="service" />} size='small'
                        />}
                    onChange={(e: any, value: IAutocompleteOptions | null) => {
                        handleAutocompleteChange(value);
                    }}
                    onOpen={() => { setAutocompleteOpen(true) }}
                    onClose={() => { setAutocompleteOpen(false) }}
                    open={autocompleteOpen}
                    value={chosenService}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}>
                <TextField
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="paymentCode" />}
                    name="paymentCode"
                    onChange={handleFiltersChange}
                    value={filters.paymentCode}
                />
            </Grid>
            <Grid
                item
                lg={3}
                md={3}
                xs={12}>
                <FormControl fullWidth size='small'>
                    <InputLabel> <FormattedMessage id="paymentMethod" /> </InputLabel>
                    <Select
                        name="paymentType"
                        label={<FormattedMessage id="paymentMethod" />}
                        onChange={handleChange}
                        sx={{ color: 'white' }}
                        value={paymentType}
                    >
                        <MenuItem value=''>
                            <i><FormattedMessage id="none" /></i>
                        </MenuItem>
                        {
                            Object.values(TipoPagamento).filter(key => isNaN(Number(key)))
                                .map((tipoPagamento, index) => (
                                    <MenuItem key={index} value={index}>
                                        {TipoPagamento[index]}
                                    </MenuItem>
                                ))
                        }
                    </Select>
                </FormControl>
            </Grid>

            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <Button variant="contained" onClick={retrievePaymentsDone} startIcon={<SearchIcon />}> <FormattedMessage id="search" /> </Button>
            </Grid>
        </Grid>
        {
            paymentsDone.items.length != 0 &&
            <Card>
                <CardHeader title={<FormattedMessage id="paymentsDone" />} />
                <CardContent>
                    {
                        mobileDevice ?
                            <PaymentsDonePageMobile
                                paymentsDone={paymentsDone}
                                updateUserPayment={updateUserPayment}
                            />
                            :
                            <PaymentsDonePage
                                paymentsDone={paymentsDone}
                                updateUserPayment={updateUserPayment}
                            />
                    }
                    <TablePagination
                        component="div"
                        count={paymentsDone?.totalCount}
                        onRowsPerPageChange={(e) => {
                            setPageSize(parseInt(e.target.value, 10));
                        }}
                        onPageChange={(e, page) => {
                            setPageNumber(page);
                        }}
                        page={paginationQuery.pageNumber}
                        rowsPerPage={paginationQuery.pageSize}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                    />
                </CardContent>
            </Card>
        }
    </>);
}

export default PaymentsDone;