import { composeNotistackMessage } from "./common";

export const handleValidationErrors = (errorsArray: Array<any> | undefined, intl: any) => {
    errorsArray?.forEach((error: any) => {
        let messageText: string = intl.formatMessage({ id: error.message });
        composeNotistackMessage(messageText, 'error');
    });
}

export const handleUpsertErrors = (errorsArray: Array<any> | undefined, intl: any) => {
    if (errorsArray) {
        handleValidationErrors(errorsArray, intl);
    } else {
        composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
    }
}