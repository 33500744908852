import { Table, TableHead, TableRow, TableCell, TableBody, } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { PostoPraticaData } from "types/postoPraticaData";
import AlternateTableRow from "ui-component/AlternateTableRow";

interface PlacesListProps {
    dossierPlaces: PostoPraticaData[];
}

const PlacesList: FC<PlacesListProps> = (props) => {
    const { dossierPlaces } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell align="left"> <FormattedMessage id="structure" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="placeNumber" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="ownerSurname" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="ownerName" /></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    dossierPlaces &&
                    dossierPlaces.length > 0 &&
                    dossierPlaces.map((dossierPlace: PostoPraticaData) => (
                        <AlternateTableRow>
                            <TableCell align="left">{dossierPlace.strutturaDenominazione}</TableCell>
                            <TableCell align="left">{dossierPlace.postoNumero}</TableCell>
                            <TableCell align="left">{dossierPlace.postoProprietarioCognome}</TableCell>
                            <TableCell align="left">{dossierPlace.postoProprietarioNome}</TableCell>
                        </AlternateTableRow>
                    ))
                }
            </TableBody>
        </Table>
    );
}

export default PlacesList;