import { useKeycloak } from "keycloak-react-web";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";

const RedirectToLogin = () => {
    const { loggedUser } = useSelector((state) => state.loggedUser);
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();

    useEffect(() => {
        if (loggedUser && loggedUser != null) {
            navigate('/');
        } else {
            keycloak.login();
        }
    }, [loggedUser]);

    return (
        <></>
    );
}

export default RedirectToLogin;