import { Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { PaginationResult } from "types/paginationResult";
import { ServiceReport } from "types/serviceReport";
import TableRowsLoader from "ui-component/TableRowsLoader";
import { getCurrencyByNumber } from "utils/common";

interface ServiziReportPageMobileProps {
    serviziReport: PaginationResult<ServiceReport>;

    isPageLoading: boolean;
}

const ServiziReportPageMobile: FC<ServiziReportPageMobileProps> = (props) => {
    const { serviziReport, isPageLoading } = props;

    return (
        <Table>
            <TableBody>
                {
                    isPageLoading ? (
                        <TableRowsLoader rowsNum={3} colsNum={1} />
                    ) :
                        (<>
                            {serviziReport && serviziReport.items?.map((servizioReport) => (
                                <TableRow key={servizioReport.id}>
                                    <TableCell>
                                        <Grid container direction="column">
                                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <Typography variant="h6" align="center"> <FormattedMessage id="description" /> </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" align="center"> {servizioReport.description} </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <Typography variant="h6" align="center"> <FormattedMessage id="servicesNumberOfUses" /> </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" align="center"> {servizioReport.numberOfUses} </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <Typography variant="h6" align="center"> <FormattedMessage id="totalAmount" /> </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" align="center"> {getCurrencyByNumber(servizioReport.totalAmount)} </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <Typography variant="h6" align="center"> <FormattedMessage id="totalPayed" /> </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" align="center"> {getCurrencyByNumber(servizioReport.totalPayed)} </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <Typography variant="h6" align="center"> <FormattedMessage id="balance" /> </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" align="center"> {getCurrencyByNumber(servizioReport.totalAmount - servizioReport.totalPayed)} </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
}

export default ServiziReportPageMobile;