import { createSlice } from '@reduxjs/toolkit';

interface LoggedUserState {
    loggedUser: any;
}

export const loggedUserSlice = createSlice({
    name: 'loggedUserSlice',
    initialState: {
        loggedUser: undefined
    } as LoggedUserState,
    reducers: {
        setLoggedUser(state, action) {
            state.loggedUser = action.payload;
        }
    }
});

export const { setLoggedUser } = loggedUserSlice.actions;

export default loggedUserSlice.reducer;