import { FC, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { PaginationResult } from 'types/paginationResult';
import { MoreVert } from '@mui/icons-material';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { Posto } from 'types/posto';
import AddIcon from '@mui/icons-material/Add';
import { getShortDate } from 'utils/common';

interface PostoWithMenuProps {
    posto: Posto;

    alreadyAssignedPostiIds?: Array<string>;
    assignPosto?: (postoId: string) => void;

    checkedPlaces: Array<string>;
    checkPlace: (e: any, postoId: string) => void;
}

const PostoWithMenu: FC<PostoWithMenuProps> = (props) => {
    const { posto, alreadyAssignedPostiIds, assignPosto, checkedPlaces, checkPlace } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={posto.id}>
            <TableCell>
                <Grid container direction="row" sx={{
                    alignItems: 'center',
                }}>
                    <Grid item
                        lg={3}
                        md={3}
                        xs={3}
                    >
                        <Checkbox key={`check-service-${posto.id}`}
                            checked={checkedPlaces.includes(posto.id)}
                            onChange={(e: any) => checkPlace(e, posto.id)}
                            disabled={alreadyAssignedPostiIds?.includes(posto.id)}
                        />
                    </Grid>
                    <Grid item
                        lg={6}
                        md={6}
                        xs={6}
                    >
                        <Grid container direction="column">
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="structure" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {posto.steccaProvenienza?.struttura?.denominazione} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="structureNumber" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {posto.steccaProvenienza?.struttura?.numero} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="number" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {posto.numero} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="placeHeader" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center">
                                        {posto.proprietarioNome} {posto.proprietarioCognome}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="birthDate" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center">
                                        {getShortDate(posto.proprietarioDataNascita)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`posto-actions-menu-${posto.id}`}
                    MenuListProps={{
                        'aria-labelledby': `posto-action-button-${posto.id}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem key={'assign'} onClick={handleClose} disabled={alreadyAssignedPostiIds?.includes(posto.id!)}>
                        <IconButton onClick={() => { assignPosto!(posto.id!) }}>
                            <AddIcon fontSize="small" sx={{ mr: '3px' }} />
                            <Typography variant="subtitle1"> <FormattedMessage id="assignPlace" /> </Typography>
                        </IconButton>
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow >
    );
};

interface PostiPageMobileProps {
    posti: PaginationResult<Posto>;

    alreadyAssignedPostiIds?: Array<string>;
    assignPosto?: (postoId: string) => void;

    isPageLoading: boolean;

    checkAllPlaces: (e: any, posti: Array<Posto>) => void;
    checkedPlaces: Array<string>;
    checkPlace: (e: any, postoId: string) => void;
}

const PostiPageMobile: FC<PostiPageMobileProps> = (props) => {
    const { posti, alreadyAssignedPostiIds, assignPosto, isPageLoading, checkAllPlaces, checkedPlaces, checkPlace } = props;

    return (
        <>
            <Table>
                <TableBody>
                    {
                        isPageLoading ? (
                            <TableRowsLoader rowsNum={3} colsNum={1} />
                        ) :
                            (<>
                                <TableRow>
                                    <TableCell>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={<b><FormattedMessage id="all" /></b>}
                                            labelPlacement="end"
                                            onChange={(e: any) => checkAllPlaces(e, posti.items)}
                                        />
                                    </TableCell>
                                </TableRow>
                                {posti && posti.items?.map((posto) => (
                                    <PostoWithMenu
                                        key={posto.id}
                                        posto={posto}
                                        alreadyAssignedPostiIds={alreadyAssignedPostiIds}
                                        assignPosto={assignPosto}

                                        checkedPlaces={checkedPlaces}
                                        checkPlace={checkPlace}
                                    />
                                ))}
                            </>)
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default PostiPageMobile;
