import type { FC } from 'react';
import type { ApexOptions } from 'apexcharts';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';

interface RadialBarChartProps {
    percentage: number;
    label: string;
}

export const RadialBarChart: FC<RadialBarChartProps> = (props) => {
    const { percentage, label } = props;

    const theme = useTheme();

    const chartOptions: ApexOptions = {
        chart: {
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: false
            }
        },
        colors: ['#5e35b1'],
        fill: {
            opacity: 1
        },
        labels: [label],
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        color: theme.palette.text.primary,
                        fontFamily: theme.typography.fontFamily
                    },
                    value: {
                        color: '#666666'
                    }
                },
                hollow: {
                    size: '60%',
                },
                track: {
                    background: theme.palette.background.default
                }
            }
        }
    };

    return (
        <ReactApexChart
            height={250}
            options={chartOptions}
            series={[percentage]}
            type="radialBar"
        />
    );
};
