import { Box, Card, CardContent, CardHeader, keyframes, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PraticaService } from "services/PraticaServices";
import { UserPaymentsReport, defaultUserPaymentsReport } from "types/userPaymentsReport";
import PaymentsPage from "./PaymentsPage";
import CardContentLoader from "ui-component/CardContentLoader";
import ReportIcon from '@mui/icons-material/Report';
import PaymentsPageMobile from "./PaymentsPageMobile";
import { handleDownloadFile } from "utils/common";
import { FatturaService } from "services/FatturaServices";

const PaymentsList = () => {
    const praticaService = new PraticaService();
    const fatturaService = new FatturaService();

    const [placeServicesToPay, setPlaceServicesToPay] = useState<UserPaymentsReport>(defaultUserPaymentsReport)

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            const retrievedPlaceServicesToPay = await praticaService.GetPlaceServicesPaymentsReport();
            setPlaceServicesToPay(retrievedPlaceServicesToPay);

            setIsPageLoading(false);
        })()
    }, [])

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    const blinkingBackgroundColor = keyframes`
        0% {
        background-color: #fa6b6b;
        }
        50% {
        background-color: #f54838;
        }
        100% {
        background-color: #fa6b6b;
        }
    `;

    const downloadFattura = async (fatturaId: string, fatturaName: string) => {
        const fatturaBase64: string = await fatturaService.DownloadFatturaById(fatturaId);
        handleDownloadFile(fatturaBase64, fatturaName);
    }
    return (
        <Card>
            <CardHeader title={<FormattedMessage id="paymentsList" />} />
            {
                isPageLoading ?
                    <CardContentLoader /> :
                    <CardContent>
                        {
                            placeServicesToPay.toPay.length > 0 &&
                            <>
                                <Box sx={{
                                    borderRadius: "7px",
                                    padding: "7px",
                                    color: "black",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    animation: `${blinkingBackgroundColor} 2s infinite`,
                                }}>
                                    <ReportIcon fontSize="small" sx={{ mr: "5px" }} />
                                    <FormattedMessage id="paymentsToDoWarningText" />
                                </Box>
                            </>
                        }
                        {
                            mobileDevice ?
                                <PaymentsPageMobile
                                    placeServices={placeServicesToPay}
                                    downloadFattura={downloadFattura}
                                /> :
                                <PaymentsPage
                                    placeServices={placeServicesToPay}
                                    downloadFattura={downloadFattura}
                                />
                        }
                    </CardContent>
            }
        </Card >
    )
}

export default PaymentsList;