import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Chip,
    ClickAwayListener,
    Dialog,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import { IconSettings } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import { useKeycloak } from 'keycloak-react-web';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ChangeTenantDialog from '../ChangeTenantDialog';
import { FormattedMessage } from 'react-intl';
import { toFirstUpper } from 'utils/common';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { Tenant } from 'types/tenant';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const ProfileSection = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const { keycloak, initialized } = useKeycloak();
    const [moreTenants, setMoreTentants] = useState<boolean>(false);
    const [changeTenantModalOpen, setChangeTenantModalOpen] = useState<boolean>(false);

    const handleChangeTenantModal = (open: boolean) => {
        setChangeTenantModalOpen(open);
    }

    const { loggedUser } = useSelector((state) => state.loggedUser);
    const { loggedUserIsAdmin } = useSelector((state) => state.loggedUserIsAdmin);
    const { showTenantChange } = useSelector((state) => state.showTenantChange);

    const navigate = useNavigate();

    return (
        <>
            <Dialog
                fullWidth maxWidth="sm"
                open={changeTenantModalOpen}
                onClose={() => handleChangeTenantModal(false)}
                aria-labelledby="change-tenant-modal-title"
                aria-describedby="change-tenant-modal-description"
            >
                <ChangeTenantDialog handleChangeTenantModal={handleChangeTenantModal} />
            </Dialog>

            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.secondary[200]}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        {
                                            loggedUser !== undefined && (
                                                <>
                                                    <Box sx={{ p: 2, pb: 0 }}>
                                                        <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mb: 1 }}>
                                                            <Typography variant="h4">{toFirstUpper(loggedUser?.firstName ?? loggedUser?.nome)} {toFirstUpper(loggedUser?.lastName ?? loggedUser?.cognome)}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </>)
                                        }
                                        <Box>
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {loggedUser === undefined && (
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 4}
                                                        onClick={() => keycloak.login()}
                                                    >
                                                        <ListItemIcon>
                                                            <LoginIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={
                                                            <Typography variant="body2"> <FormattedMessage id="login" /> </Typography>
                                                        } />
                                                    </ListItemButton>
                                                )}
                                                {loggedUser !== undefined && (
                                                    <>
                                                        {
                                                            showTenantChange &&
                                                            <>
                                                                {
                                                                    loggedUser.tenantsList.length == 1 ?
                                                                        (
                                                                            <ListItem>
                                                                                <ListItemIcon>
                                                                                    <CorporateFareIcon fontSize="small" />
                                                                                </ListItemIcon>
                                                                                <ListItemText primary={<Typography variant="body2">{loggedUser.tenantsList[0]?.name}</Typography>} />
                                                                            </ListItem>
                                                                        ) :
                                                                        (
                                                                            <ListItemButton onClick={() => handleChangeTenantModal(true)}>
                                                                                <ListItemIcon>
                                                                                    <CorporateFareIcon fontSize="small" />
                                                                                </ListItemIcon>
                                                                                <ListItemText primary={<Typography variant="body2">{loggedUser.tenantsList.find((tenant: Tenant) => tenant.id == loggedUser.currentTenantId)?.name}</Typography>} />
                                                                                <PlayArrowIcon sx={{ mr: '1px' }} />
                                                                            </ListItemButton>
                                                                        )
                                                                }
                                                                {
                                                                    loggedUserIsAdmin &&
                                                                    <ListItemButton
                                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                                        onClick={() => {
                                                                            navigate(`/tenant`);
                                                                        }}
                                                                    >
                                                                        <ListItemIcon>
                                                                            <RoomPreferencesIcon fontSize="small" />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={<Typography variant="body2"> <FormattedMessage id="tenantSettings" /> </Typography>} />
                                                                    </ListItemButton>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            !loggedUserIsAdmin &&
                                                            <ListItemButton
                                                                sx={{ borderRadius: `${borderRadius}px` }}
                                                                onClick={() => {
                                                                    navigate(`/yourInfo`);
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <AccountCircleIcon fontSize="small" />
                                                                </ListItemIcon>
                                                                <ListItemText primary={<Typography variant="body2"> <FormattedMessage id="yourInfo" /> </Typography>} />
                                                            </ListItemButton>
                                                        }
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            onClick={() => {
                                                                navigate(`/changePassword`);
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <VpnKeyIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            <ListItemText primary={<Typography variant="body2"> <FormattedMessage id="changePassword" /> </Typography>} />
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            selected={selectedIndex === 4}
                                                            onClick={() => {
                                                                keycloak.logout();
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <LogoutIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            <ListItemText primary={<Typography variant="body2"> <FormattedMessage id="logout" /> </Typography>} />
                                                        </ListItemButton>
                                                    </>
                                                )}
                                            </List>
                                        </Box>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
