import { FC } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { PaginationResult } from 'types/paginationResult';
import { getShortDate } from 'utils/common';
import { Pratica } from 'types/pratica';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ISortOptions from 'interfaces/ISortOptions';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import SearchIcon from '@mui/icons-material/Search';

interface PratichePageProps {
    pratiche: PaginationResult<Pratica>;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
    isPageLoading: boolean;

    userView?: boolean;
}

const PratichePage: FC<PratichePageProps> = (props) => {
    const { pratiche, setSortOptions, sortOptions, isPageLoading, userView } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="data"
                        title={<FormattedMessage id="date" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="numero"
                        title={<FormattedMessage id="number" />}
                    />
                    {
                        !userView &&
                        <>
                            <TableCellWithSorting
                                setSortOptions={setSortOptions}
                                sortOptions={sortOptions}
                                name="serie"
                                title={<FormattedMessage id="series" />}
                            />
                            <TableCellWithSorting
                                setSortOptions={setSortOptions}
                                sortOptions={sortOptions}
                                name="intestatarioCognome"
                                title={<FormattedMessage id="surname" />}
                            />
                            <TableCellWithSorting
                                setSortOptions={setSortOptions}
                                sortOptions={sortOptions}
                                name="intestatarioNome"
                                title={<FormattedMessage id="name" />}
                            />
                            <TableCellWithSorting
                                setSortOptions={setSortOptions}
                                sortOptions={sortOptions}
                                name="intestatarioComuneNascita"
                                title={<FormattedMessage id="birthPlace" />}
                            />
                            <TableCellWithSorting
                                setSortOptions={setSortOptions}
                                sortOptions={sortOptions}
                                name="intestatarioDataNascita"
                                title={<FormattedMessage id="birthDate" />}
                            />
                        </>
                    }
                    <TableCell> <FormattedMessage id="actions" /> </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    isPageLoading ?
                        (<TableRowsLoader rowsNum={10} colsNum={userView ? 3 : 8} />) :
                        (<>
                            {pratiche && pratiche.items?.map((pratica) => (
                                <AlternateTableRow key={pratica.id}>
                                    <TableCell>
                                        <Grid item> {getShortDate(pratica.data)} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {pratica.numero} </Grid>
                                    </TableCell>
                                    {
                                        !userView &&
                                        <>
                                            <TableCell>
                                                <Grid item> {pratica.numeratore?.nome} </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid item> {pratica.intestatario?.cognome} </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid item> {pratica.intestatario?.nome} </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid item> {pratica.intestatario?.luogoNascita} </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid item> {getShortDate(pratica.intestatario?.dataNascita)} </Grid>
                                            </TableCell>
                                        </>
                                    }
                                    <TableCell>
                                        {
                                            userView ?
                                                <Tooltip title={<FormattedMessage id="dossierInfo" />} aria-label="Dossier info">
                                                    <IconButton component={RouterLink} to={`/yourDossiers/info?dossierId=${pratica.id}`}>
                                                        <SearchIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip> :
                                                <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                                    <IconButton component={RouterLink} to={`/dossiers/edit?dossierId=${pratica.id}`}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                    </TableCell>
                                </AlternateTableRow>
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
};

export default PratichePage;
