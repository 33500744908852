import { FC, useEffect } from 'react';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage } from 'react-intl';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { PaginationResult } from 'types/paginationResult';
import { Servizio } from 'types/servizio';
import ISortOptions from 'interfaces/ISortOptions';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { getCurrencyByNumber } from 'utils/common';

interface ServiziPageProps {
    servizi: PaginationResult<Servizio>;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
    isPageLoading: boolean;

    fromExternal: boolean;
    assignServiceToEntity?: (servizioId: string) => void;
    assignedServicesIds?: Array<string>;
}

const ServiziPage: FC<ServiziPageProps> = (props) => {
    const { servizi, setSortOptions, sortOptions, isPageLoading, fromExternal, assignServiceToEntity, assignedServicesIds } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="codice"
                        title={<FormattedMessage id="code" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="descrizione"
                        title={<FormattedMessage id="description" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="prezzo"
                        title={<><FormattedMessage id="price" /> (€)</>}
                        align='right'
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="aliquota"
                        title={<FormattedMessage id="rate" />}
                        align='right'
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="importo"
                        title={<><FormattedMessage id="amount" /> (€)</>}
                        align='right'
                    />
                    <TableCell align='right'> <FormattedMessage id="actions" /> </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    isPageLoading ?
                        (<TableRowsLoader rowsNum={10} colsNum={6} />) :
                        (<>
                            {servizi && servizi.items?.map((servizio) => (
                                <AlternateTableRow key={servizio.id}>
                                    <TableCell>
                                        <Grid item> {servizio.codice} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {servizio.descrizione} </Grid>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Grid item> {getCurrencyByNumber(servizio.prezzo)} </Grid>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Grid item> {servizio.aliquota} </Grid>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Grid item> {getCurrencyByNumber(servizio.importo)} </Grid>
                                    </TableCell>
                                    <TableCell align='right'>
                                        {
                                            fromExternal ?
                                                <Button
                                                    onClick={() => assignServiceToEntity!(servizio.id!)}
                                                    variant="outlined"
                                                    disabled={assignedServicesIds?.includes(servizio.id!)}>
                                                    <FormattedMessage id="assignService" />
                                                </Button> :
                                                <>
                                                    <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                                        <IconButton component={RouterLink} to={`edit?serviceId=${servizio.id}`}>
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                        }
                                    </TableCell>
                                </AlternateTableRow>
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
};

export default ServiziPage;
