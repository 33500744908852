import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createEditor, Editor } from 'slate';
import { withHistory } from "slate-history";
import { Slate, Editable, withReact, } from 'slate-react';
import Toolbar from './Toolbar/Toolbar'
import { getMarked, getBlock } from './utils/SlateUtilityFunctions.js'
import withLinks from './plugins/withLinks.js'
import withTables from './plugins/withTable.js'
import withEmbeds from './plugins/withEmbeds.js'
import withEquation from './plugins/withEquation.js'
import './Editor.css'

const Element = (props) => {
    return getBlock(props);
}

const Leaf = ({ attributes, children, leaf }) => {
    children = getMarked(leaf, children);
    return <span {...attributes}>{children}</span>
}

const SlateEditor = (props) => {
    const { initialValue, onChange, readOnly } = props;

    const [value, setValue] = useState(initialValue || defaultValue);

    const editor = useMemo(() => withEquation(withHistory(withEmbeds(withTables(withLinks(withReact(createEditor())))))), []);

    const renderElement = useCallback(props => <Element {...props} />, [])

    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />
    }, [])

    const handleEditorChange = (newValue) => {
        setValue(newValue)
        onChange(newValue)
    }

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue)
        }
    }, [initialValue])

    const defaultValue = [
        {
            type: 'paragraph',
            children: [{ text: '' }],
        },
    ]

    return (
        <Slate className="editor" editor={editor} initialValue={value} onChange={handleEditorChange} >
            <Toolbar />
            <div className="editor-wrapper" style={{ border: '1px solid lightgray', padding: '0 10px', width: '100%' }}>
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    style={{ border: 'none', outline: 'none', padding: '30px' }}
                    readOnly={readOnly}
                />
            </div>
        </Slate>

    )
}

export default SlateEditor