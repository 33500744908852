import { useSelector } from "store";
import AdminDashboard from "./AdminDashboard";
import UserDashboard from "./UserDashboard";
import PrivateRoute from "helpers/PrivateRoute";
import Breadcrumbs from "layout/Breadcrumbs/Breadcrumbs";
import { PagePermission } from "types/enums/PagePermission";

const DashboardHandler = () => {
    const { loggedUserIsAdmin } = useSelector((state) => state.loggedUserIsAdmin);

    return (<>
        {
            loggedUserIsAdmin == true &&
            <PrivateRoute pagePermission={PagePermission.Admin}>
                <Breadcrumbs />
                <AdminDashboard />
            </PrivateRoute>
        }
        {

            loggedUserIsAdmin == false &&
            <PrivateRoute pagePermission={PagePermission.Customer}>
                <Breadcrumbs />
                <UserDashboard />
            </PrivateRoute>

        }
    </>);
}

export default DashboardHandler;