import { FC, useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage, useIntl } from 'react-intl';
import { Decuius } from 'types/decuius';
import { PaginationResult } from 'types/paginationResult';
import { MoreVert } from '@mui/icons-material';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { getShortDate } from 'utils/common';
import PlaceIcon from '@mui/icons-material/Place';
import { IconFiles } from '@tabler/icons';

interface DecuiusWithMenuProps {
    decuius: Decuius;

    fromExternal: boolean;
    assignDecuius?: (decuiusId: string) => void;
    alreadyAssignedDecuiusIds?: Array<string>;
}

const DecuiusWithMenu: FC<DecuiusWithMenuProps> = (props) => {
    const { decuius, fromExternal, assignDecuius, alreadyAssignedDecuiusIds } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={decuius.id}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="surname" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {decuius.cognome} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="name" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {decuius.nome} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="birthDate" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getShortDate(decuius.dataNascita)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="deathDate" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getShortDate(decuius.dataMorte)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="comuneNascita" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {decuius.comuneNascita} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="comuneMorte" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {decuius.comuneMorte} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`decuius-actions-menu-${decuius.id}`}
                    MenuListProps={{
                        'aria-labelledby': `decuius-action-button-${decuius.id}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    {fromExternal ? (
                        <MenuItem key={'assign'} onClick={handleClose} disabled={alreadyAssignedDecuiusIds?.includes(decuius.id!)}>
                            <IconButton onClick={() => { assignDecuius!(decuius.id!) }}>
                                <PersonAddIcon fontSize="small" sx={{ mr: '3px' }} />
                                <Typography variant="subtitle1"> <FormattedMessage id='assignDecuius' /> </Typography>
                            </IconButton>
                        </MenuItem>
                    ) : (
                        <>
                            <MenuItem key={'place'} onClick={handleClose}>
                                <IconButton
                                    component={RouterLink}
                                    to={`/posto/edit?postoId=${decuius.postoId}`}
                                >
                                    <PlaceIcon fontSize="small" sx={{ mr: '3px' }} />
                                    <Typography variant="subtitle1"> <FormattedMessage id="goToPlace" /> </Typography>
                                </IconButton>
                            </MenuItem>
                            <MenuItem key={'edit'} onClick={handleClose}>
                                <IconButton
                                    component={RouterLink}
                                    to={`edit?decuiusId=${decuius.id}`}
                                >
                                    <EditIcon fontSize="small" sx={{ mr: '3px' }} />
                                    <Typography variant="subtitle1"> <FormattedMessage id="edit" /> </Typography>
                                </IconButton>
                            </MenuItem>
                            {
                                decuius.posto?.postoPratiche !== undefined &&
                                decuius.posto?.postoPratiche !== null &&
                                decuius.posto?.postoPratiche?.length !== 0 &&
                                <MenuItem key={'goToDossiers'} onClick={handleClose}>
                                    <IconButton
                                        component={RouterLink}
                                        to={
                                            decuius.posto.postoPratiche.length == 1 ?
                                                `/dossiers/edit?dossierId=${decuius.posto.postoPratiche[0].praticaAssociataId}` :
                                                `/decuius/dossiersList?decuiusId=${decuius.id}`
                                        }
                                    >
                                        <IconFiles fontSize="small" style={{ marginRight: '3px' }} />
                                        <Typography variant="subtitle1"> <FormattedMessage id={decuius.posto.postoPratiche.length == 1 ? "goToDossier" : "goToDossiers"} /> </Typography>
                                    </IconButton>
                                </MenuItem>
                            }
                        </>
                    )}
                </Menu>
            </TableCell>
        </TableRow>
    );
};

interface DecuiusPageMobileProps {
    allDecuius: PaginationResult<Decuius>;

    isPageLoading: boolean;

    fromExternal: boolean;
    assignDecuius?: (decuiusId: string) => void;
    alreadyAssignedDecuiusIds?: Array<string>;
};

const DecuiusPageMobile: FC<DecuiusPageMobileProps> = (props) => {
    const { allDecuius, isPageLoading, fromExternal, assignDecuius, alreadyAssignedDecuiusIds } = props;

    return (
        <>
            <Table>
                <TableBody>
                    {
                        isPageLoading ? (
                            <TableRowsLoader rowsNum={3} colsNum={1} />
                        ) :
                            (<>
                                {allDecuius && allDecuius.items?.map((decuius) => (
                                    <DecuiusWithMenu
                                        key={decuius.id}
                                        decuius={decuius}

                                        fromExternal={fromExternal}
                                        assignDecuius={assignDecuius}
                                        alreadyAssignedDecuiusIds={alreadyAssignedDecuiusIds}
                                    />
                                ))}
                            </>)
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default DecuiusPageMobile;
