import { Button, Card, CardActionArea, CardContent, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UserService } from "services/UserServices";
import BackdropLoader from "ui-component/BackdropLoader";
import { composeNotistackMessage, getFormValue, isNotNullOrUndefined } from "utils/common";

interface IChangePassword {
    password: string;
    repeatedPassword: string;
}

const ChangePassword = () => {
    const intl = useIntl();
    const userService = new UserService();

    const defaultChangePasswordObject: IChangePassword = {
        password: '',
        repeatedPassword: ''
    };

    const [changePasswordObject, setChangePasswordObject] = useState<IChangePassword>(defaultChangePasswordObject);

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    const handleChange = (e: any) => {
		let { name, value } = getFormValue(e);
		setChangePasswordObject((currentFormData: any) => ({ ...currentFormData, [name]: value }));
	}

    const changePassword = async () => {
        setIsPageLoading(true);

        try {
            await userService.ChangePassword(changePasswordObject.password);

            composeNotistackMessage(intl.formatMessage({ id: 'changePasswordSuccessfully' }), 'success');

            setChangePasswordObject(defaultChangePasswordObject);
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsPageLoading(false);
        }
    }

    return (
        <>
            <BackdropLoader open={isPageLoading} />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: '10px'
                }}
            >
                <Card sx={{ minWidth: 450 }}>
                    <CardContent>
                        <Typography variant="h4" sx={{ mb: 2 }}>
                            <FormattedMessage id="changePassword" />
                        </Typography>
                        <Grid container direction="column" spacing={2}>
                            <Grid item
                                xs={12}
                                md={12}
                                xl={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={<FormattedMessage id="newPassword" />}
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    value={changePasswordObject.password}
                                />
                            </Grid>
                            <Grid item
                                xs={12}
                                md={12}
                                xl={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={<FormattedMessage id="repeatNewPassword" />}
                                    type="password"
                                    name="repeatedPassword"
                                    onChange={handleChange}
                                    value={changePasswordObject.repeatedPassword}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActionArea>
                        <Button variant="contained" sx={{ width: '100%' }} onClick={changePassword} disabled={changePasswordObject.password !== changePasswordObject.repeatedPassword || !isNotNullOrUndefined(changePasswordObject.password)}>
                            <FormattedMessage id="updatePassword" />
                        </Button>
                    </CardActionArea>
                </Card>
            </Box>
        </>
    );
}

export default ChangePassword;