import { PaginationResult, voidPagination } from "types/paginationResult";
import { Stecca } from "./stecca";

export interface SteccheInfo {
    stecche: PaginationResult<Stecca>;
    totGuadagnato: number;
    totGuadagnabile: number;
    totNumPostiLiberi: number;
    totNumPostiAventiDiritto: number;
    totNumPostiConcessionari: number;
    totNumPostiSalme: number;
    totNumPostiReferenti: number;
}

export const defaultSteccheInfo: SteccheInfo = {
    stecche: voidPagination,
    totGuadagnato: 0,
    totGuadagnabile: 0,
    totNumPostiLiberi: 0,
    totNumPostiAventiDiritto: 0,
    totNumPostiConcessionari: 0,
    totNumPostiSalme: 0,
    totNumPostiReferenti: 0
}