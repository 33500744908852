export interface Zona {
    dateOfInsert?: string;
    descrizione: string;
    id?: string;
    isValid?: boolean;
    tenantId?: string;
}

export const defaultZona: Zona = {
    descrizione: '',
}