import { Agenzia } from "./agenzia";
import { Comune } from "./comune";
import { PaymentStatus } from "./enums/PaymentStatus";
import { StatoDecuius } from "./enums/StatoDecuius";
import { TipoPagamento } from "./enums/TipoPagamento";
import { Posto } from "./posto";
import { ServizioPosto } from "./servizioPosto";
import { Utente } from "./utente";

export interface Pagamento {
    id?: string;
    data?: string;
    descrizione?: string;
    importo: number | null;
    servizioRiferimentoId: string;
    servizioRiferimento?: ServizioPosto;
    note?: string;
    mezzo: TipoPagamento;

    invoiceNumber?: string;

    dateOfInsert?: string;
    isValid?: boolean;

    status?: PaymentStatus;
    code?: string;
}

export const defaultPagamento: Pagamento = {
    importo: 0,
    code: '',
    mezzo: TipoPagamento.Assegno,
    servizioRiferimentoId: '',
    data: new Date().toDateString(),
    descrizione: ''
}