import { FC, useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage } from 'react-intl';
import { PaginationResult } from 'types/paginationResult';
import { Servizio } from 'types/servizio';
import { MoreVert } from '@mui/icons-material';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import AddIcon from '@mui/icons-material/Add';
import { getCurrencyByNumber } from 'utils/common';

interface ServizioWithMenuProps {
    servizio: Servizio;

    fromExternal: boolean;
    assignServiceToEntity?: (servizioId: string) => void;
    assignedServicesIds?: Array<string>;
}

const ServizioWithMenu: FC<ServizioWithMenuProps> = (props) => {
    const { servizio, fromExternal, assignServiceToEntity, assignedServicesIds } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={servizio.id}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="code" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {servizio.codice} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="description" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {servizio.descrizione} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="price" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getCurrencyByNumber(servizio.prezzo)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="rate" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {servizio.aliquota} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="amount" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getCurrencyByNumber(servizio.importo)} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`servizio-actions-menu-${servizio.id}`}
                    MenuListProps={{
                        'aria-labelledby': `servizio-action-button-${servizio.id}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    {fromExternal ? (
                        <MenuItem key={'assign'} onClick={handleClose} disabled={assignedServicesIds?.includes(servizio.id!)}>
                            <IconButton onClick={() => { assignServiceToEntity!(servizio.id!) }}>
                                <AddIcon fontSize="small" sx={{ mr: '3px' }} />
                                <Typography variant="subtitle1"> <FormattedMessage id='assignService' /> </Typography>
                            </IconButton>
                        </MenuItem>
                    ) : (
                        <>
                            <MenuItem key={'edit'} onClick={handleClose} >
                                <IconButton
                                    component={RouterLink}
                                    to={`edit?serviceId=${servizio.id}`}
                                >
                                    <EditIcon fontSize="small" sx={{ mr: '3px' }} />
                                    <Typography variant="subtitle1"> <FormattedMessage id="edit" /> </Typography>
                                </IconButton>
                            </MenuItem>
                        </>
                    )}
                </Menu>
            </TableCell>
        </TableRow>
    );
}

interface ServiziPageMobileProps {
    servizi: PaginationResult<Servizio>;
    isPageLoading: boolean;

    fromExternal: boolean;
    assignServiceToEntity?: (servizioId: string) => void;
    assignedServicesIds?: Array<string>;
}

const ServiziPageMobile: FC<ServiziPageMobileProps> = (props) => {
    const { servizi, isPageLoading, fromExternal, assignServiceToEntity, assignedServicesIds } = props;

    return (
        <Table>
            <TableBody>
                {
                    isPageLoading ? (
                        <TableRowsLoader rowsNum={3} colsNum={1} />
                    ) :
                        (<>
                            {servizi && servizi.items?.map((servizio) => (
                                <ServizioWithMenu
                                    key={servizio.id}
                                    servizio={servizio}
                                    fromExternal={fromExternal}
                                    assignServiceToEntity={assignServiceToEntity}
                                    assignedServicesIds={assignedServicesIds}
                                />
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
};

export default ServiziPageMobile;
