import { Button, Card, CardActionArea, CardContent, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useKeycloak } from "keycloak-react-web";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { UserService } from "services/UserServices";
import { useSelector } from "store";
import BackdropLoader from "ui-component/BackdropLoader";
import { composeNotistackMessage, getFormValue } from "utils/common";

const PasswordRecovery = () => {
    const intl = useIntl();
    const { loggedUser } = useSelector((state) => state.loggedUser);
    const navigate = useNavigate();
    const userService = new UserService();
    const { keycloak } = useKeycloak();

    const [fiscalCode, setFiscalCode] = useState<string>('');

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    const handleChange = (e: any) => {
        let { value } = getFormValue(e);
        setFiscalCode(value);
    }

    useEffect(() => {
        if (loggedUser && loggedUser != null) {
            navigate('/');
        }
    }, [loggedUser]);

    const recoveryPassword = async () => {
        setIsPageLoading(true);

        try {
            await userService.PasswordRecovery(fiscalCode);

            composeNotistackMessage(intl.formatMessage({ id: 'passwordRecoverySuccessfully' }), 'success');

            setTimeout(() => {
                keycloak.login();
            }, 1500);
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsPageLoading(false);
        }
    }

    return (
        <>
            <BackdropLoader open={isPageLoading} />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: '10px'
                }}
            >
                <Card sx={{ minWidth: 500 }}>
                    <CardContent>
                        <Typography variant="h4" sx={{ mb: 2 }}>
                            <FormattedMessage id="passwordRecovery" />
                        </Typography>
                        <TextField
                            fullWidth
                            size='small'
                            label={<FormattedMessage id="fiscalCode" />}
                            name="fiscalCode"
                            onChange={handleChange}
                            value={fiscalCode}
                        />
                    </CardContent>
                    <CardActionArea>
                        <Button variant="contained" sx={{ width: '100%' }} onClick={recoveryPassword}>
                            <FormattedMessage id="request" />
                        </Button>
                    </CardActionArea>
                </Card>
            </Box>
        </>
    );
}

export default PasswordRecovery;