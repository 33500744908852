import { useKeycloak } from 'keycloak-react-web';
import Loading from '../pages/LoadingPage';
import KeycloakLoginRedirect from './KeycloakLoginRedirect';
import { useSelector } from 'store';
import UnauthorizedPage from 'pages/UnauthorizedPage';
import { FC } from 'react';
import { PagePermission } from 'types/enums/PagePermission';

interface PrivateRouteProps {
    children: any;
    pagePermission?: PagePermission;
}

const PrivateRoute: FC<PrivateRouteProps> = (props) => {
    const { children, pagePermission } = props;

    const { keycloak, initialized } = useKeycloak();

    const isLoggedIn = keycloak.authenticated;

    const loadingPage = <Loading />;

    const { loggedUserIsAdmin } = useSelector((state) => state.loggedUserIsAdmin);
    const { loggedUserIsSuperAdmin } = useSelector((state) => state.loggedUserIsSuperAdmin);

    var returnPage = loadingPage;

    if (initialized) {
        if (isLoggedIn) {
            if (loggedUserIsAdmin !== undefined) {
                switch(pagePermission) {
                    case PagePermission.Customer:
                        returnPage = (loggedUserIsAdmin || loggedUserIsSuperAdmin) ? <UnauthorizedPage /> : children;
                        break;
                    case PagePermission.Admin:
                        returnPage = loggedUserIsAdmin ? children : <UnauthorizedPage />;
                        break;
                    case PagePermission.SuperAdmin:
                        returnPage = loggedUserIsSuperAdmin ? children : <UnauthorizedPage />;
                        break;
                    default:
                        returnPage = children;
                        break;
                }
            }
        } else {
            returnPage = <KeycloakLoginRedirect />;
        }
    }

    return returnPage;
};

export default PrivateRoute;