import { alpha, Autocomplete, Box, Button, Card, CardContent, CardHeader, Checkbox, Chip, Dialog, DialogActions, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Menu, MenuItem, MenuProps, Radio, RadioGroup, Select, styled, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { UserService } from "services/UserServices";
import { Utente, defaultUtente } from "types/utente";
import { composeNotistackMessage, getFiscalCode, getFormValue, getShortDate, isNotNullOrUndefined, toFirstUpper } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { object, string } from "yup";
import CardContentLoader from "ui-component/CardContentLoader";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import { Regione } from "types/regione";
import { GeoService } from "services/GeoServices";
import { Provincia } from "types/provincia";
import { Comune } from "types/comune";
import { useDispatch, useSelector } from "store";
import { setUserIdToAssign } from "store/slices/newUserIdToAssignSlice";
import { Nazione } from "types/nazione";
import { IconFiles } from '@tabler/icons';
import IAutocompleteOptions from "interfaces/IAutocompleteOptions";
import { Tenant } from "types/tenant";
import { TenantService } from "services/TenantServices";
import dayjs, { Dayjs } from "dayjs";
import KeyIcon from '@mui/icons-material/Key';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import WhiteCoffin from "ui-component/icons/WhiteCoffin";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import UploadIdentityDocument from "ui-component/UploadIdentityDocument";
import CloseIcon from '@mui/icons-material/Close';
import { CommunicationType } from "types/enums/CommunicationType";

interface UserUpsertProps {
	isAdd: boolean;
	userView?: boolean;
}

const UserUpsert: FC<UserUpsertProps> = (props) => {
	const { isAdd, userView } = props;

	const intl = useIntl();

	const userService = new UserService();
	const geoService = new GeoService();
	const tenantService = new TenantService();

	const [user, setUser] = useState<Utente>(defaultUtente);

	const [strutturaId, setStrutturaId] = useState<string | undefined>(undefined);
	const [postoId, setPostoId] = useState<string | undefined>(undefined);
	const [dossierId, setDossierId] = useState<string | undefined>(undefined);
	const [isFromDossier, setIsFromDossier] = useState<boolean>(false);

	const [regions, setRegions] = useState<Regione[]>([]);

	const [birthProvinces, setBirthProvinces] = useState<Provincia[]>([]);
	const [birthCities, setBirthCities] = useState<Comune[]>([]);

	const [residenceProvinces, setResidenceProvinces] = useState<Provincia[]>([]);
	const [residenceCities, setResidenceCities] = useState<Comune[]>([]);

	let userValidationSchema = object({
		cognome: string().required('surnameRequired'),
		nome: string().required('nameRequired'),
		cellulare: string().matches(/^$|^\+\d+$/, 'phoneNumberInvalid')
	});

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

	const [entityUpdatedInfo, setEntityUpdatedInfo] = useState<{ userOfUpdate: string, dateOfUpdate: string }>({
		userOfUpdate: '',
		dateOfUpdate: ''
	});

	const [chosenTenants, setChosenTenants] = useState<Array<IAutocompleteOptions>>([]);
	const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);
	const [tenantsOptions, setTenantsOptions] = useState<Array<IAutocompleteOptions>>([]);

	const handleAutocompleteChange = (value: Array<IAutocompleteOptions>) => {
		setChosenTenants(value);
	}

	const [communicationTypeMenuAnchorEl, setCommunicationTypeMenuAnchorEl] = useState<null | HTMLElement>(null);
	const communicationTypeMenuOpen = Boolean(communicationTypeMenuAnchorEl);

	const handleCommunicationTypeMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setCommunicationTypeMenuAnchorEl(event.currentTarget);
	}

	const handleCommunicationTypeMenuClose = () => {
		setCommunicationTypeMenuAnchorEl(null);
	}

	const CommunicationTypesMenu = styled((props: MenuProps) => (
		<Menu
			elevation={0}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			{...props}
		/>
	))(({ theme }) => ({
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 180,
			color:
				theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
			boxShadow:
				'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
			'& .MuiMenu-list': {
				padding: '4px 0',
			},
			'& .MuiMenuItem-root': {
				'& .MuiSvgIcon-root': {
					fontSize: 18,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(1.5),
				},
				'&:active': {
					backgroundColor: alpha(
						theme.palette.primary.main,
						theme.palette.action.selectedOpacity,
					),
				},
			},
		},
	}));

	useEffect(() => {
		setIsPageLoading(true);

		(async () => {
			setRegions(await geoService.GetRegioni());
			setCountryOptions(await geoService.GetNazioni());

			const retrievedTenants = await tenantService.GetTenants();
			setTenantsOptions(retrievedTenants.map((tenant: Tenant) => ({ id: tenant.id!, label: tenant.name })));

			if (!isAdd) {
				await retrieveUser();
			} else {
				let strutturaId: string = window.location.href.split('strutturaId=')[1] ?? undefined;
				setStrutturaId(strutturaId);
				setUser((currentFormData: any) => ({ ...currentFormData, strutturaId: strutturaId }));

				let postoId: string = window.location.href.split('postoId=')[1] ?? undefined;
				setPostoId(postoId);

				let isFromDossier: string = window.location.href.split('fromDossier=')[1] ?? undefined;
				setIsFromDossier(isFromDossier !== undefined);

				let dossierId: string = window.location.href.split('dossierId=')[1] ?? undefined;
				setDossierId(dossierId);
			}
			setIsPageLoading(false);
		})();
	}, [])

	const retrieveUser = async () => {
		let retrievedUser: Utente;

		if (userView == true) {
			retrievedUser = await userService.GetUser();
		} else {
			let userId: string = window.location.href.split('userId=')[1] ?? '';
			retrievedUser = await userService.GetUserById(userId);

			const userTenantsIds: Array<string> = await userService.GetUserTenantsIds(userId);

			//TODO: Rimuovere ripetizione
			const retrievedTenants = await tenantService.GetTenants();

			const chosenUserTenants = userTenantsIds.map((tenantId: string) => {
				return {
					id: tenantId,
					label: retrievedTenants.find((retrievedTenant) => retrievedTenant.id == tenantId)!.name
				}
			})

			setChosenTenants(chosenUserTenants);
		}
		setUserHasUsername(isNotNullOrUndefined(retrievedUser.username));
		setUser(retrievedUser);

		setEntityUpdatedInfo({
			userOfUpdate: retrievedUser.userOfUpdate ?? '',
			dateOfUpdate: retrievedUser.dateOfUpdate ?? ''
		});

		setSelectedBirthRegion(retrievedUser.regioneNascitaId ?? '');

		setSelectedResidenceRegion(retrievedUser.regioneId ?? '');
	}

	const handleChange = (e: any) => {
		let { name, value } = getFormValue(e);
		setUser((currentFormData: any) => ({ ...currentFormData, [name]: value }));
	}

	const handleDateChange = (fieldName: string, date: any) => {
		let newValue: string | null;

		newValue = dayjs(date).format('YYYY-MM-DD');

		newValue = newValue == 'Invalid Date' ? null : newValue;

		setUser((currentFormData: any) => ({ ...currentFormData, [fieldName]: newValue }));
	}

	const userUpsert = async () => {
		try {
			let redirectPath: string = '';

			await userValidationSchema.validate(user, { abortEarly: false });

			if (userView === true) {
				await userService.UpdateUserInformation(user);
			} else {
				let userId: string = await userService.UpsertUtente(user, chosenTenants.map((chosenTenant) => chosenTenant.id));

				if (strutturaId !== undefined) {
					redirectPath = `/structures/edit?strutturaId=${strutturaId}`;
				} else if (postoId !== undefined) {
					redirectPath = `/posto/edit?postoId=${postoId}&utenteId=${userId}`;
				} else if (isFromDossier) {
					dispatch(setUserIdToAssign(userId));
					redirectPath = `/dossiers/create`;
				} else if (dossierId !== undefined) {
					redirectPath = `/dossiers/edit?dossierId=${dossierId}&userId=${userId}`;
				} else if (isAdd) {
					redirectPath = `/users`;
				}
			}

			composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');

			if (!isAdd) await retrieveUser();

			if (redirectPath !== '') navigate(redirectPath);
		} catch (validationErrors: any) {
			handleUpsertErrors(validationErrors.inner, intl);
		}
	}

	useEffect(() => {
		if (
			isNotNullOrUndefined(user.nome) &&
			isNotNullOrUndefined(user.cognome) &&
			isNotNullOrUndefined(user.dataNascita) &&
			(isNotNullOrUndefined(user.sesso) && user.sesso != 0) &&
			isNotNullOrUndefined(user.statoNascita)
		) {
			try {
				const fiscalCode: string = getFiscalCode(user.nome, user.cognome, user.dataNascita!, user.sesso!, user.statoNascita!, user.provinciaNascita!, user.luogoNascita!);

				setUser((currentFormData: any) => ({ ...currentFormData, codiceFiscale: fiscalCode }));
			}
			catch (e: any) { }
		}
	}, [user.nome, user.cognome, user.dataNascita, user.sesso, user.statoNascita, user.provinciaNascita, user.luogoNascita])

	const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState<boolean>(false);
	const [makeDecuiusDialogOpen, setMakeDecuiusDialogOpen] = useState<boolean>(false);

	const deleteUser = async () => {
		try {
			await userService.DeleteUtenteById(user.id!);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullyDelete' }), 'success');

			navigate('/users');
		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		}
	};

	const [countryOptions, setCountryOptions] = useState<Nazione[]>([]);

	const [selectedBirthRegion, setSelectedBirthRegion] = useState<string>('');
	const [selectedBirthProvince, setSelectedBirthProvince] = useState<string>('');
	const [selectedBirthCity, setSelectedBirthCity] = useState<string>('');
	const [selectedResidenceRegion, setSelectedResidenceRegion] = useState<string>('');
	const [selectedResidenceProvince, setSelectedResidenceProvince] = useState<string>('');
	const [selectedResidenceCity, setSelectedResidenceCity] = useState<string>('');

	const [enableBirthAdditionalFields, setEnableBirthAdditionalFields] = useState<boolean>(true);
	const [enableResidenceAdditionalFields, setEnableResidenceAdditionalFields] = useState<boolean>(true);

	const { loggedUserIsSuperAdmin } = useSelector((state) => state.loggedUserIsSuperAdmin);

	const [userHasUsername, setUserHasUsername] = useState<boolean>(false);

	useEffect(() => {
		setEnableBirthAdditionalFields(toFirstUpper(user.statoNascita) == "Italia");
	}, [user.statoNascita])

	useEffect(() => {
		setEnableResidenceAdditionalFields(toFirstUpper(user.stato) == "Italia");
	}, [user.stato])

	useEffect(() => {
		if (isNotNullOrUndefined(selectedBirthRegion)) {
			(async () => {
				const retrievedBirthProvinces = await geoService.GetProvinceByRegioneId(selectedBirthRegion);
				setBirthProvinces(retrievedBirthProvinces);

				setSelectedBirthProvince(retrievedBirthProvinces.find((province: Provincia) => province.sigla == user.provinciaNascita?.toUpperCase())?.id!);
			})()
		}
	}, [selectedBirthRegion])

	useEffect(() => {
		if (isNotNullOrUndefined(selectedResidenceRegion)) {
			(async () => {
				const retrievedResidenceProvinces = await geoService.GetProvinceByRegioneId(selectedResidenceRegion);
				setResidenceProvinces(retrievedResidenceProvinces);

				setSelectedResidenceProvince(retrievedResidenceProvinces.find((province: Provincia) => province.sigla == user.provincia?.toUpperCase())?.id!);
			})()
		}
	}, [selectedResidenceRegion])

	useEffect(() => {
		if (isNotNullOrUndefined(selectedBirthProvince)) {
			(async () => {
				const retrievedBirthCities = await geoService.GetComuniByProvinciaId(selectedBirthProvince);
				setBirthCities(retrievedBirthCities);

				setSelectedBirthCity(retrievedBirthCities.find((city: Comune) => city.nome.toUpperCase() == user?.luogoNascita?.toUpperCase())?.id!);
			})()
		}
	}, [selectedBirthProvince])

	useEffect(() => {
		if (isNotNullOrUndefined(selectedResidenceProvince)) {
			(async () => {
				const retrievedResidenceCities = await geoService.GetComuniByProvinciaId(selectedResidenceProvince);
				setResidenceCities(retrievedResidenceCities);

				setSelectedResidenceCity(retrievedResidenceCities.find((city: Comune) => city.nome.toUpperCase() == user?.comune?.toUpperCase())?.id!);
			})()
		}
	}, [selectedResidenceProvince])

	const makeDecuius = async () => {
		try {
			const newDecuiusId: string = await userService.MakeUserDecuius(user.id!);

			composeNotistackMessage(intl.formatMessage({ id: 'decuiusFromUserSuccessfullySaved' }), 'success');

			navigate(`/decuius/edit?decuiusId=${newDecuiusId}`);
		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		}
	}

	const generatePassword = async (communicationType: CommunicationType) => {
		try {
			await userService.UpdateUserPassword({
				userId: user.id!,
				communicationType: communicationType
			});

			composeNotistackMessage(intl.formatMessage({ id: 'passwordGeneratedSuccessfully' }), 'success');
		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		}
	}

	const [scanIdentityDocumentDialogOpen, setScanIdentityDocumentDialogOpen] = useState<boolean>(false);

	const setUserByIdCard = (userData: Utente) => {
		userData.id = undefined;

		setUser(userData);
		setSelectedBirthRegion(userData.regioneNascitaId ?? '');
		setSelectedResidenceRegion(userData.regioneId ?? '');

		setScanIdentityDocumentDialogOpen(false);
	}

	return (
		<Card>
			<DialogDeleteElement
				open={deleteUserDialogOpen}
				onClose={() => setDeleteUserDialogOpen(false)}
				onCancel={() => setDeleteUserDialogOpen(false)}
				onConfirm={() => {
					setDeleteUserDialogOpen(false)
					deleteUser();
				}}
				entityToDeleteInfo={`${user.nome} ${user.cognome}` ?? ''}
			/>
			<Dialog
				open={makeDecuiusDialogOpen}
				onClose={() => setMakeDecuiusDialogOpen(false)}
				aria-labelledby="alert-make-decuius-title"
				aria-describedby="alert-make-decuius-description"
			>
				<DialogTitle id="make-decuius-title">
					<FormattedMessage id="dialogMakeDecuiusFromUser" />: <b>{`${user.nome} ${user.cognome}`}</b>?
				</DialogTitle>
				<DialogActions>
					<Button onClick={() => setMakeDecuiusDialogOpen(false)} variant="outlined" color="error"> <FormattedMessage id="cancel" /> </Button>
					<Button onClick={makeDecuius} variant="contained" color="error"><FormattedMessage id="makeDecuius" /></Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={scanIdentityDocumentDialogOpen}
				onClose={() => setScanIdentityDocumentDialogOpen(false)}
				aria-labelledby="dialog-scan-identity-card-title"
				aria-describedby="dialog-scan-identity-card-description"
				maxWidth="lg"
			>
				<DialogTitle id="upload-identity-document">
					<FormattedMessage id="uploadIdentityCard" />
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={() => setScanIdentityDocumentDialogOpen(false)}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<UploadIdentityDocument setUserByIdCard={setUserByIdCard} />
			</Dialog>

			<CardHeader title={<FormattedMessage id="registry" />} />
			<Divider />
			{
				isPageLoading ?
					<CardContentLoader /> :
					(
						<CardContent>
							<Grid container direction="row" spacing={1}>
								{
									loggedUserIsSuperAdmin &&
									<>
										<Grid
											item
											lg={4}
											md={4}
											xs={12}
										>
											<TextField
												fullWidth
												size='small'
												label={<FormattedMessage id="username" />}
												name="username"
												onChange={handleChange}
												value={user.username}
											/>
										</Grid>
										<Grid
											item
											lg={4}
											md={4}
											xs={12}>
											<Autocomplete
												id="services"
												options={tenantsOptions}
												renderInput={(params) =>
													<TextField {...params} name="tenants"
														fullWidth label={<FormattedMessage id="tenants" />} size='small'
													/>}
												onChange={(e: any, value: IAutocompleteOptions[]) => {
													handleAutocompleteChange(value);
												}}
												onOpen={() => { setAutocompleteOpen(true) }}
												onClose={() => { setAutocompleteOpen(false) }}
												open={autocompleteOpen}
												value={chosenTenants}
												multiple={true}
												isOptionEqualToValue={(option, value) => option.id === value.id}
											/>
										</Grid>
										<Grid
											item
											lg={2}
											md={2}
											xs={6}>
											<FormControlLabel
												control={<Checkbox checked={user.isSuperAdmin} />}
												label={<FormattedMessage id="isSuperAdmin" />}
												name="isSuperAdmin"
												labelPlacement="end"
												onChange={handleChange}
											/>
										</Grid>
										<Grid
											item
											lg={2}
											md={2}
											xs={6}>
											<FormControlLabel
												control={<Checkbox checked={user.isCurrentTenantAdmin} />}
												label={<FormattedMessage id="TenantAdmin" />}
												name="isCurrentTenantAdmin"
												labelPlacement="end"
												onChange={handleChange}
											/>
										</Grid>
									</>
								}
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="surname" />}
										name="cognome"
										required
										onChange={handleChange}
										value={user.cognome}
										disabled={userView}
									/>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="name" />}
										name="nome"
										required
										onChange={handleChange}
										value={user.nome}
										disabled={userView}
									/>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<DatePicker
										inputFormat="DD/MM/YYYY"
										label={<FormattedMessage id="birthDate" />}
										onChange={(date: any) => {
											handleDateChange('dataNascita', date);
										}}
										renderInput={(params) => (
											<TextField fullWidth {...params} error={false} size='small' />
										)}
										value={user.dataNascita ?? ''}
										disabled={userView}
									/>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl disabled={userView}>
										<RadioGroup
											row
											aria-labelledby="gender-radio-buttons"
											name="sesso"
											onChange={handleChange}
											value={user.sesso}
										>
											<FormControlLabel value={2} control={<Radio />} label={<FormattedMessage id="female" />} />
											<FormControlLabel value={1} control={<Radio />} label={<FormattedMessage id="male" />} />
										</RadioGroup>
									</FormControl>
								</Grid>

								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl fullWidth size='small' disabled={userView}>
										<InputLabel> <FormattedMessage id="birthCountry" /> </InputLabel>
										<Select
											name="statoNascita"
											label={<FormattedMessage id="birthCountry" />}
											onChange={handleChange}
											value={toFirstUpper(user.statoNascita)}
											defaultValue="Italia"
											sx={{ color: 'white' }}
										>
											{
												countryOptions
													.slice()
													.map((countryOption) => (
														<MenuItem key={countryOption.name} value={countryOption.name}>
															{countryOption.name}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableBirthAdditionalFields || userView || !isNotNullOrUndefined(user.statoNascita)}
									>
										<InputLabel> <FormattedMessage id="birthRegion" /> </InputLabel>
										<Select
											label={<FormattedMessage id="birthRegion" />}
											onChange={(e: any) => setSelectedBirthRegion(e.target.value)}
											sx={{ color: 'white' }}
											value={selectedBirthRegion}
										>
											{
												regions && regions
													.slice()
													.map((regione) => (
														<MenuItem key={regione.id} value={regione.id}>
															{regione.nome}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableBirthAdditionalFields || selectedBirthRegion === "" || userView}
									>
										<InputLabel> <FormattedMessage id="birthProvince" /> </InputLabel>
										<Select
											label={<FormattedMessage id="birthProvince" />}
											onChange={(e: any) => {
												setUser((currentFormData: any) => ({ ...currentFormData, provinciaNascita: birthProvinces.find((province) => province.id == e.target.value)?.sigla ?? '' }));
												setSelectedBirthProvince(e.target.value);
											}}
											sx={{ color: 'white' }}
											value={selectedBirthProvince}
										>
											{
												birthProvinces && birthProvinces
													.slice()
													.map((provincia: Provincia) => (
														<MenuItem key={provincia.id} value={provincia.id}>
															{provincia.sigla}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableBirthAdditionalFields || selectedBirthProvince === "" || userView}
									>
										<InputLabel> <FormattedMessage id="birthCity" /> </InputLabel>
										<Select
											name="luogoNascita"
											label={<FormattedMessage id="birthCity" />}
											onChange={(e: any) => {
												setUser((currentFormData: any) => ({ ...currentFormData, luogoNascita: birthCities.find((city) => city.id == e.target.value)?.nome ?? '' }))
												setSelectedBirthCity(e.target.value);
											}}
											sx={{ color: 'white' }}
											value={selectedBirthCity}
										>
											{
												birthCities && birthCities
													.slice()
													.map((city) => (
														<MenuItem key={city.id} value={city.id}>
															{city.nome}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>

								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl fullWidth size='small' disabled={userView}>
										<InputLabel> <FormattedMessage id="residenceCountry" /> </InputLabel>
										<Select
											name="stato"
											label={<FormattedMessage id="residenceCountry" />}
											onChange={handleChange}
											value={toFirstUpper(user.stato)}
											defaultValue="Italia"
											sx={{ color: 'white' }}
										>
											{
												countryOptions
													.slice()
													.map((countryOption) => (
														<MenuItem key={countryOption.name} value={countryOption.name}>
															{countryOption.name}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableResidenceAdditionalFields || userView || !isNotNullOrUndefined(user.stato)}
									>
										<InputLabel> <FormattedMessage id="residenceRegion" /> </InputLabel>
										<Select
											label={<FormattedMessage id="residenceRegion" />}
											onChange={(e: any) => setSelectedResidenceRegion(e.target.value)}
											sx={{ color: 'white' }}
											value={selectedResidenceRegion}
										>
											{
												regions && regions
													.slice()
													.map((regione) => (
														<MenuItem key={regione.id} value={regione.id}>
															{regione.nome}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableResidenceAdditionalFields || selectedResidenceRegion === "" || userView}
									>
										<InputLabel> <FormattedMessage id="residenceProvince" /> </InputLabel>
										<Select
											label={<FormattedMessage id="residenceProvince" />}
											onChange={(e: any) => {
												setUser((currentFormData: any) => ({ ...currentFormData, provincia: residenceProvinces.find((province) => province.id == e.target.value)?.sigla ?? '' }));
												setSelectedResidenceProvince(e.target.value);
											}}
											sx={{ color: 'white' }}
											value={selectedResidenceProvince}
										>
											{
												residenceProvinces && residenceProvinces
													.slice()
													.map((provincia: Provincia) => (
														<MenuItem key={provincia.id} value={provincia.id}>
															{provincia.sigla}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl
										fullWidth
										size='small'
										disabled={!enableResidenceAdditionalFields || selectedResidenceProvince === "" || userView}
									>
										<InputLabel> <FormattedMessage id="residenceMunicipality" /> </InputLabel>
										<Select
											name="comune"
											label={<FormattedMessage id="residenceMunicipality" />}
											onChange={(e: any) => {
												const city = residenceCities.find((city) => city.id == e.target.value);
												setUser((currentFormData: any) => ({ ...currentFormData, comune: city?.nome ?? '', cap: city?.cap ?? '' }))
												setSelectedResidenceCity(e.target.value);
											}}
											sx={{ color: 'white' }}
											value={selectedResidenceCity}
										>
											{
												residenceCities && residenceCities
													.slice()
													.map((city) => (
														<MenuItem key={city.id} value={city.id}>
															{city.nome}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>

								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="fiscalCode" />}
										name="codiceFiscale"
										onChange={handleChange}
										value={user.codiceFiscale}
										InputLabelProps={{ shrink: isNotNullOrUndefined(user.codiceFiscale) }}
									/>
								</Grid>

								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="address" />}
										name="indirizzo"
										onChange={handleChange}
										value={user.indirizzo}
										disabled={userView}
										InputLabelProps={{ shrink: isNotNullOrUndefined(user.indirizzo) }}
									/>
								</Grid>
								<Grid
									item
									lg={2}
									md={2}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="civic" />}
										name="civico"
										onChange={handleChange}
										value={user.civico}
										disabled={userView}
										InputLabelProps={{ shrink: isNotNullOrUndefined(user.civico) }}
									/>
								</Grid>
								<Grid
									item
									lg={2}
									md={2}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="cap" />}
										name="cap"
										onChange={handleChange}
										value={user.cap}
										disabled={userView}
										InputLabelProps={{
											shrink: isNotNullOrUndefined(user.cap)
										}}
									/>
								</Grid>

								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="mail" />}
										name="mail"
										onChange={handleChange}
										value={user.mail}
									/>
								</Grid>
								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="phone" />}
										name="telefono"
										onChange={handleChange}
										value={user.telefono}
									/>
								</Grid>
								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="mobilePhone" />}
										name="cellulare"
										onChange={handleChange}
										value={user.cellulare}
									/>
								</Grid>
								{
									!userView &&
									<Grid
										item
										lg={12}
										md={12}
										xs={12}
									>
										<TextField
											fullWidth
											size='small'
											label={<FormattedMessage id="notes" />}
											name="note"
											inputProps={{
												style: {
													height: 50
												}
											}}
											onChange={handleChange}
											value={user.note}
										/>
									</Grid>
								}
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									{
										!userView &&
										<Box sx={{ pt: 1, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
											{isAdd ?
												<Button
													color="warning"
													type="button"
													variant="contained"
													onClick={() => setScanIdentityDocumentDialogOpen(true)}
													startIcon={<DocumentScannerIcon />}
												>
													<FormattedMessage id="scanIdentityCard" />
												</Button> :
												<>

													<Button
														color="error"
														type="button"
														variant="contained"
														onClick={() => setDeleteUserDialogOpen(true)}
														startIcon={<DeleteIcon />}
													>
														<FormattedMessage id="delete" />
													</Button>
													{
														isNotNullOrUndefined(user.decuiusId) ? (
															<Button
																color="secondary"
																type="button"
																variant="contained"
																onClick={() => navigate(`/decuius/edit?decuiusId=${user.decuiusId}`)}
																startIcon={<WhiteCoffin />}
															>
																<FormattedMessage id="goToDecuius" />
															</Button>
														) : (
															<Button
																color="warning"
																type="button"
																variant="contained"
																onClick={() => setMakeDecuiusDialogOpen(true)}
																startIcon={<WhiteCoffin />}
															>
																<FormattedMessage id="makeDecuius" />
															</Button>
														)
													}
													<Button
														variant="contained"
														startIcon={<IconFiles />}
														component={RouterLink}
														to={`/users/dossiersList?userId=${user.id}`}
													>
														<FormattedMessage id="goToDossiers" />
													</Button>
													<Button
														color="secondary"
														variant="contained"
														startIcon={<KeyIcon />}
														onClick={handleCommunicationTypeMenuOpen}
														disabled={!userHasUsername}
													>
														<FormattedMessage id="generatePassword" />
													</Button>
													<CommunicationTypesMenu
														id="new-communicationType-menu"
														MenuListProps={{
															'aria-labelledby': 'new-communicationType-button',
														}}
														anchorEl={communicationTypeMenuAnchorEl}
														open={communicationTypeMenuOpen}
														onClose={handleCommunicationTypeMenuClose}
													>
														{
															Object.values(CommunicationType).filter(key => isNaN(Number(key)))
																.map((comunicationType, index) => (
																	<MenuItem key={index} value={index} onClick={() => {
																		generatePassword(index)
																		handleCommunicationTypeMenuClose();
																	}}>
																		{CommunicationType[index]}
																	</MenuItem>
																))
														}
													</CommunicationTypesMenu>
												</>
											}
										</Box>
									}
								</Grid>
								<Grid item xs={12} md={6}>
									<Box sx={{ pt: 1, display: 'flex', justifyContent: 'flex-end' }}>
										<Button
											color="primary"
											type="button"
											variant="contained"
											onClick={userUpsert}
											startIcon={<SaveIcon />}
										>
											<FormattedMessage id={strutturaId || postoId ? "add" : "save"} />
										</Button>
									</Box>
								</Grid>
							</Grid>
						</CardContent>
					)
			}
			{
				isNotNullOrUndefined(entityUpdatedInfo.userOfUpdate) && isNotNullOrUndefined(entityUpdatedInfo.dateOfUpdate) &&
				<Divider sx={{ mb: 2 }}>
					<Chip size="small" label={
						<>
							<FormattedMessage id="updatedBy" /> {entityUpdatedInfo.userOfUpdate} <FormattedMessage id="at" /> {getShortDate(entityUpdatedInfo.dateOfUpdate)}
						</>
					} />
				</Divider>
			}
		</Card >
	)
};

export default UserUpsert;
