/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';
import serviceConfig from '../ServiceConfig';
import { Tenant } from 'types/tenant';
import { TenantDocument } from 'types/tenantDocument';
import { CrossTenantDocument } from 'types/crossTenantDocument';
import { TenantDashboardData } from 'types/tenantDashbaordData';

export class TenantService {
    async GetTenant(): Promise<Tenant> {
        const result = await axios.post<Tenant>(`${serviceConfig.appServiceBaseUrl}/GetTenant`);
        return result.data;
    }

    async UpdateTenant(tenant: Tenant): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpdateTenant`, tenant);
        return result.data;
    }

    async GetTenantDocument(documentId: string): Promise<TenantDocument> {
        const result = await axios.post<TenantDocument>(`${serviceConfig.appServiceBaseUrl}/GetTenantDocument`, documentId);
        return result.data;
    }

    async GetCrossTenantDocument(documentId: string): Promise<TenantDocument> {
        const result = await axios.post<TenantDocument>(`${serviceConfig.appServiceBaseUrl}/GetCrossTenantDocument`, documentId);
        return result.data;
    }

    async UpsertTenantDocument(tenantDocument: TenantDocument): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpsertTenantDocument`, tenantDocument);
        return result.data;
    }

    async UpsertCrossTenantDocument(crossTenantDocument: CrossTenantDocument): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpsertCrossTenantDocument`, crossTenantDocument);
        return result.data;
    }

    async GetTenantDocuments(): Promise<Array<TenantDocument>> {
        const result = await axios.post<Array<TenantDocument>>(`${serviceConfig.appServiceBaseUrl}/GetTenantDocuments`);
        return result.data;
    }

    async GetCrossTenantDocuments(): Promise<Array<CrossTenantDocument>> {
        const result = await axios.post<Array<CrossTenantDocument>>(`${serviceConfig.appServiceBaseUrl}/GetCrossTenantDocuments`);
        return result.data;
    }

    async DownloadTenantDocument(documentId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/DownloadTenantDocument`, documentId);
        return result.data;
    }

    async DownloadCrossTenantDocument(documentId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/DownloadCrossTenantDocument`, documentId);
        return result.data;
    }

    async DeleteTenantDocument(documentId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteTenantDocument`, documentId);
        return result.data;
    }

    async DeleteCrossTenantDocument(documentId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteCrossTenantDocument`, documentId);
        return result.data;
    }

    async GetTenantDossierDocuments(): Promise<Array<TenantDocument>> {
        const result = await axios.post<Array<TenantDocument>>(`${serviceConfig.appServiceBaseUrl}/GetTenantDossierDocuments`);
        return result.data;
    }

    async GetTenants(): Promise<Array<Tenant>> {
        const result = await axios.get<Array<Tenant>>(`${serviceConfig.appServiceBaseUrl}/GetTenants`);
        return result.data;
    }

    async GetTenantDefaultCausal(): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/GetTenantDefaultCausal`);
        return result.data;
    }

    async GetTenantCommunicationDocuments(): Promise<Array<TenantDocument>> {
        const result = await axios.post<Array<TenantDocument>>(`${serviceConfig.appServiceBaseUrl}/GetTenantCommunicationDocuments`);
        return result.data;
    }

    async GetTenantDashboardData(): Promise<TenantDashboardData> {
        const result = await axios.post<TenantDashboardData>(`${serviceConfig.appServiceBaseUrl}/GetTenantDashboardData`);
        return result.data;
    }
}