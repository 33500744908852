import { Box, TableCell } from "@mui/material";
import { FC, ReactElement } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { FormattedMessage } from "react-intl";
import ISortOptions from "interfaces/ISortOptions";

interface TableCellWithSortingProps {
    title: ReactElement;
    name: string;
    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
    align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
}

const TableCellWithSorting: FC<TableCellWithSortingProps> = (props) => {
    const { title, name, setSortOptions, sortOptions, align = 'left' } = props;

    return (
        <TableCell
            align={align}
            onClick={() => setSortOptions(name)}
            sx={{
                '&:hover': {
                    backgroundColor: 'lightgray',
                    cursor: 'pointer'
                },
                backgroundColor: sortOptions.sortColumn == name ? '#e3e3e3' : 'white',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: align === 'right' ? 'flex-end' : 'flex-start' }}>
                {title}
                {
                    sortOptions.sortColumn == name &&
                    (sortOptions.ascending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)
                }
            </Box>
        </TableCell>
    );
}

export default TableCellWithSorting;