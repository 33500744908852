/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { Viale } from '../types/viale';
import { PaginationQuery } from 'types/paginationQuery';
import { PaginationResult } from 'types/paginationResult';

export class VialeService {
    async GetViali(paginationQuery: PaginationQuery): Promise<PaginationResult<Viale>> {
        const result = await axios.post<PaginationResult<Viale>>(`${serviceConfig.appServiceBaseUrl}/GetViali`, paginationQuery);
        return result.data;
    }

    async GetUnpaginatedViali(): Promise<Viale[]> {
        const result = await axios.get<Viale[]>(`${serviceConfig.appServiceBaseUrl}/GetAllViali`);
        return result.data;
    }

    async DeleteViale(vialeId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteViale`, vialeId);
        return result.data;
    }

    async GetVialeById(vialeId: string): Promise<Viale> {
        const result = await axios.post<Viale>(`${serviceConfig.appServiceBaseUrl}/GetViale`, vialeId);
        return result.data;
    }

    async UpsertViale(viale: Viale): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpsertViale`, viale);
        return result.data;
    }
}