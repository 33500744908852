import { TableRow, TableCell, Skeleton } from "@mui/material";
import { FC } from "react";

interface TableRowsLoaderProps {
	rowsNum: number;
	colsNum: number;
}

const TableRowsLoader: FC<TableRowsLoaderProps> = (props) => {
	const { rowsNum, colsNum } = props;

	return <>
		{
			[...Array(rowsNum)].map((row, index) => (
				<TableRow key={index}>
					{
						[...Array(colsNum)].map((row, index) => (
							<TableCell scope="row" key={index}>
								<Skeleton animation="wave" variant="text" />
							</TableCell>
						))
					}
				</TableRow>
			))
		}
	</>
};

export default TableRowsLoader;