import { Button, Card, CardContent, CardHeader, Grid, IconButton, Table, TableBody, TableCell, TablePagination, TableRow, TextField, Typography, debounce, useMediaQuery, useTheme } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PraticaService } from "services/PraticaServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import ClearIcon from '@mui/icons-material/Clear';
import { getCurrencyByNumber, getFormValue, handleDownloadFile, isNotNullOrUndefined, retrieveCookieByKey, saveFiltersPaginationCookie } from "utils/common";
import PraticheReportPageMobile from "./PraticheReportPageMobile";
import PraticheReportPage from "./PraticheReportPage";
import { DossiersCompleteReport, defaultDossierCompleteReport } from "types/dossiersCompleteReport";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useSelector } from "store";

interface PraticheReportFilters {
    startDate: string | null,
    endDate: string | null,
    serie: string;
    numero: string;
    cognome: string;
    nome: string;
    note: string;
}

const PraticheReport = () => {
    const intl = useIntl();

    const praticaService = new PraticaService();

    const { loggedUser } = useSelector((state) => state.loggedUser);

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);
    const [filters, setFilters] = useState<PraticheReportFilters>({
        startDate: null,
        endDate: null,
        serie: "",
        numero: "",
        cognome: "",
        nome: "",
        note: ""
    });

    const [dossiersCompleteReport, setDossiersCompleteReport] = useState<DossiersCompleteReport>(defaultDossierCompleteReport);

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    useEffect(() => {
        const filtersPaginationCookie = retrieveCookieByKey(loggedUser.currentTenantId, 'reportDossiersListFilters');
        if (isNotNullOrUndefined(filtersPaginationCookie)) {
            setFilters(filtersPaginationCookie.filters);
            setPaginationQuery(filtersPaginationCookie.paginationQuery);
        }
    }, [])

    const updatePraticheReport = () => {
        setIsPageLoading(true);
        getPraticheReport(paginationQuery, filters);

        saveFiltersPaginationCookie(loggedUser.currentTenantId, 'reportDossiersListFilters', {
            filters: filters,
            paginationQuery: paginationQuery
        }, 1);
    }

    const getPraticheReport = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: PraticheReportFilters) => {
        const retrievedPratiche = await praticaService.GetPraticheReport(paginationQuery, filters);
        setDossiersCompleteReport(retrievedPratiche);

        setIsPageLoading(false);
    }, 700), []);

    useEffect(() => {
        updatePraticheReport();
    }, [paginationQuery, filters]);

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const handleDateFiltersChange = (e: any, fieldName: string) => {
        setFilters((currentFormData: any) => ({ ...currentFormData, [fieldName]: e == null ? e : e.format() }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    const getPraticheReportExcel = async () => {
        const reportExcelBase64: string = await praticaService.GetPraticheReportExcel(paginationQuery, filters);
        handleDownloadFile(reportExcelBase64, "Report Pratiche.xlsx")
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    return (
        <>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <MobileDatePicker
                        label={<FormattedMessage id="startDate" />}
                        onChange={(e: any) => handleDateFiltersChange(e, 'startDate')}
                        renderInput={(params) => <TextField {...params} fullWidth size="small" name="startDate" />}
                        InputProps={{
                            endAdornment: (
                                filters.startDate !== null &&
                                <IconButton onClick={() => handleDateFiltersChange(null, 'startDate')} edge="end" size="small">
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        value={filters.startDate}
                        inputFormat="DD/MM/YYYY"
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <MobileDatePicker
                        label={<FormattedMessage id="endDate" />}
                        onChange={(e: any) => handleDateFiltersChange(e, 'endDate')}
                        renderInput={(params) =>
                            <TextField {...params}
                                fullWidth
                                size="small"
                                name="endDate"
                                InputProps={{
                                    endAdornment: (
                                        filters.endDate !== null &&
                                        <IconButton onClick={() => handleDateFiltersChange(null, 'endDate')} edge="end" size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    ),
                                }}
                            />}
                        value={filters.endDate}
                        inputFormat="DD/MM/YYYY"
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="series" />}
                        name="serie"
                        onChange={handleFiltersChange}
                        value={filters.serie}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="number" />}
                        name="numero"
                        onChange={handleFiltersChange}
                        value={filters.numero}
                    />
                </Grid>

                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="surname" />}
                        name="cognome"
                        onChange={handleFiltersChange}
                        value={filters.cognome}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="name" />}
                        name="nome"
                        onChange={handleFiltersChange}
                        value={filters.nome}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="notes" />}
                        name="note"
                        onChange={handleFiltersChange}
                        value={filters.note}
                    />
                </Grid>
            </Grid>
            <Card>
                <CardHeader title={
                    <Grid container direction="row" sx={{ alignItems: 'center' }}>
                        <Grid item
                            lg={6}
                            md={6}
                            xs={6}
                        >
                            <FormattedMessage id="dossiersReport" />
                        </Grid>
                        <Grid item
                            lg={6}
                            md={6}
                            xs={6}
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button variant="outlined" startIcon={<InsertDriveFileIcon />} onClick={getPraticheReportExcel}>
                                <FormattedMessage id="exportExcel" />
                            </Button>
                        </Grid>
                    </Grid>
                } />
                <CardContent>
                    {
                        mobileDevice ?
                            <PraticheReportPageMobile
                                praticheReport={dossiersCompleteReport.pagedDossiersReportsDto}
                                isPageLoading={isPageLoading}
                            /> :
                            <PraticheReportPage
                                praticheReport={dossiersCompleteReport.pagedDossiersReportsDto}
                                isPageLoading={isPageLoading}

                                setSortOptions={setSortOptions}
                                sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                            />
                    }

                    <Table sx={{ mt: 2 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="h5"
                                    >
                                        <FormattedMessage id="total" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {getCurrencyByNumber(dossiersCompleteReport.totalToPay)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="h5"
                                    >
                                        <FormattedMessage id="totalPayed" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {getCurrencyByNumber(dossiersCompleteReport.totalPayed)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <TablePagination
                        component="div"
                        count={dossiersCompleteReport.pagedDossiersReportsDto?.totalCount}
                        onRowsPerPageChange={(e) => {
                            setPageSize(parseInt(e.target.value, 10));
                        }}
                        onPageChange={(e, page) => {
                            setPageNumber(page);
                        }}
                        page={paginationQuery.pageNumber}
                        rowsPerPage={paginationQuery.pageSize}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                    />
                </CardContent>
            </Card>
        </>
    );
}

export default PraticheReport;