import { Card, CardHeader, Divider, TablePagination, debounce, useMediaQuery, useTheme } from "@mui/material";
import PratichePage from "pages/Pratiche/PratichePage";
import PratichePageMobile from "pages/Pratiche/PratichePageMobile";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DecuiusService } from "services/DecuiusServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { Pratica } from "types/pratica";

const DecuiusDossiersList = () => {
    const intl = useIntl();

    const [dossiers, setDossiers] = useState<PaginationResult<Pratica>>(voidPagination);
    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    const [decuiusId, setDecuiusId] = useState<string>('');

    const decuiusService = new DecuiusService();

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            let decuiusId: string = window.location.href.split('decuiusId=')[1] ?? '';
            setDecuiusId(decuiusId);

            await getDossiers(paginationQuery, decuiusId);

            setIsPageLoading(false);
        })()
    }, [])

    useEffect(() => {
        if (decuiusId !== '') {
            updateDossiers();
        }
    }, [paginationQuery, decuiusId]);

    const updateDossiers = () => {
        setIsPageLoading(true);
        getDossiers(paginationQuery, decuiusId);
    }

    const getDossiers = useCallback(debounce(async (paginationQuery: PaginationQuery, decuiusId: string) => {
        const retrievedDossiers: PaginationResult<Pratica> = await decuiusService.GetDossiersByDecuiusId(paginationQuery, decuiusId);
        setDossiers(retrievedDossiers);

        setIsPageLoading(false);
    }, 700), [decuiusId]);

    return (
        <Card>
            <CardHeader title={<FormattedMessage id="decuiusDossiersList" />} />
            <Divider />
            {
                mobileDevice ?
                    <PratichePageMobile
                        pratiche={dossiers}
                        isPageLoading={isPageLoading}
                    /> :
                    <PratichePage
                        pratiche={dossiers}

                        setSortOptions={setSortOptions}
                        sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                        isPageLoading={isPageLoading}
                    />
            }
            <TablePagination
                component="div"
                count={dossiers?.totalCount}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10));
                }}
                onPageChange={(e, page) => {
                    setPageNumber(page);
                }}
                page={paginationQuery.pageNumber}
                rowsPerPage={paginationQuery.pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
            />
        </Card>
    );
}

export default DecuiusDossiersList;