import PrivateRoute from "helpers/PrivateRoute";
import Breadcrumbs from "layout/Breadcrumbs/Breadcrumbs";
import AgenziaUpsert from "pages/Agenzie/AgenziaUpsert";
import AgenzieList from "pages/Agenzie/AgenzieList";
import GenerateBulletins from "pages/Bollettini/GenerateBulletins";
import RecordPayments from "pages/Bollettini/RecordPayments";
import DashboardHandler from "pages/Dashboard/DashboardHandler";
import DecuiusDossiersList from "pages/Decuius/DecuiusDossiersList";
import DecuiusList from "pages/Decuius/DecuiusList";
import DecuiusUpsert from "pages/Decuius/DecuiusUpsert";
import DepositList from "pages/Deposito/DepositList";
import FatturaInsert from "pages/Fatture/FatturaInsert";
import FattureList from "pages/Fatture/FattureList";
import SerieNumeratoreUpsert from "pages/Numeratori/SerieNumeratoreUpsert";
import SerieNumeratoriList from "pages/Numeratori/SerieNumeratoriList";
import PostoUpdate from "pages/Posti/PostoUpdate";
import ServizioPostoUpdate from "pages/PostiPratica/ServizioPostoUpdate";
import PraticaInsert from "pages/Pratiche/PraticaInsert";
import PraticaUpdate from "pages/Pratiche/PraticaUpdate";
import PraticheList from "pages/Pratiche/PraticheList";
import PraticheReport from "pages/Pratiche/PraticheReport";
import ServiziList from "pages/Servizi/ServiziList";
import ServiziReport from "pages/Servizi/ServiziReport";
import ServizioUpsert from "pages/Servizi/ServizioUpsert";
import StrutturaUpsert from "pages/Strutture/StrutturaUpsert";
import StruttureList from "pages/Strutture/StruttureList";
import TenantDocumentEditor from "pages/TenantDocumentEditor";
import TenantUpdate from "pages/Tenants/TenantUpdate";
import TransferRegisterList from "pages/TransferRegister/TransferRegisterList";
import DossierInfo from "pages/UserArea/Dossiers/DossierInfo";
import PaymentsList from "pages/UserArea/Payments/PaymentsList";
import UserUpsert from "pages/Utenti/UserUpsert";
import UserDossiersList from "pages/Utenti/UserDossiersList";
import UsersList from "pages/Utenti/UsersList";
import VialeUpsert from "pages/Viali/VialeUpsert";
import VialiPage from "pages/Viali/VialiPage";
import ZonaUpsert from "pages/Zone/ZonaUpsert";
import ZonePage from "pages/Zone/ZonePage";
import { Routes, Route } from "react-router";
import PaymentsToDo from "pages/Pagamenti/PaymentsToDo";
import GenerateCredentials from "pages/GenerateCredentials/GenerateCredentials";
import PasswordRecovery from "pages/Utenti/PasswordRecovery";
import PaymentsDone from "pages/Pagamenti/PaymentsDone";
import ChangePassword from "pages/Utenti/ChangePassword";
import InsertEmailAndPhone from "pages/InsertEmailAndPhone/InsertEmailAndPhone";
import { PagePermission } from "types/enums/PagePermission";
import SuperAdminPage from "pages/SuperAdminArea/SuperAdminPage";
import SuperAdminDocumentEditor from "pages/SuperAdminArea/SuperAdminDocumentEditor";
import RedirectToLogin from "pages/Utenti/RedirectToLogin";

const AllRoutes = () => (
    <Routes>
        <Route
            path="/zones"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <ZonePage />
                </PrivateRoute>
            }
        />
        <Route
            path="/zones/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <ZonaUpsert isAdd={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/zones/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <ZonaUpsert isAdd={true} />
                </PrivateRoute>
            }
        />
        <Route
            path="/blocks"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <VialiPage />
                </PrivateRoute>
            }
        />
        <Route
            path="/blocks/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <VialeUpsert isAdd={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/blocks/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <VialeUpsert isAdd={true} />
                </PrivateRoute>
            }
        />
        <Route
            path="/structures"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <StruttureList />
                </PrivateRoute>
            }
        />
        <Route
            path="/structures/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <StrutturaUpsert isAdd={true} />
                </PrivateRoute>
            }
        />
        <Route
            path="/structures/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <StrutturaUpsert isAdd={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/decuius"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <DecuiusList fromExternal={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/decuius/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <DecuiusUpsert isAdd={true} />
                </PrivateRoute>
            }
        />
        <Route
            path="/decuius/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <DecuiusUpsert isAdd={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/decuius/dossiersList"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <DecuiusDossiersList />
                </PrivateRoute>
            }
        />
        <Route
            path="/agencies"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <AgenzieList />
                </PrivateRoute>
            }
        />
        <Route
            path="/agencies/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <AgenziaUpsert isAdd={true} />
                </PrivateRoute>
            }
        />
        <Route
            path="/agencies/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <AgenziaUpsert isAdd={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/deposit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <DepositList />
                </PrivateRoute>
            }
        />
        <Route
            path="/users"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <UsersList fromExternal={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/users/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <UserUpsert isAdd={true} />
                </PrivateRoute>
            }
        />
        <Route
            path="/users/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <UserUpsert isAdd={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/users/dossiersList"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <UserDossiersList />
                </PrivateRoute>
            }
        />
        <Route
            path="/services"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <ServiziList fromExternal={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/services/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <ServizioUpsert isAdd={true} />
                </PrivateRoute>
            }
        />
        <Route
            path="/services/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <ServizioUpsert isAdd={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/invoices"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <FattureList />
                </PrivateRoute>
            }
        />
        <Route
            path="/dossiers"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <PraticheList />
                </PrivateRoute>
            }
        />
        <Route
            path="/dossiers/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <PraticaInsert />
                </PrivateRoute>
            }
        />
        <Route
            path="/dossiers/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <PraticaUpdate />
                </PrivateRoute>
            }
        />
        <Route
            path="/posto/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <PostoUpdate />
                </PrivateRoute>
            }
        />
        <Route
            path="/serieNumerators"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <SerieNumeratoriList />
                </PrivateRoute>
            }
        />
        <Route
            path="/serieNumerators/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <SerieNumeratoreUpsert isAdd={true} />
                </PrivateRoute>
            }
        />
        <Route
            path="/serieNumerators/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <SerieNumeratoreUpsert isAdd={false} />
                </PrivateRoute>
            }
        />
        <Route
            path="/placeServices/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <ServizioPostoUpdate />
                </PrivateRoute>
            }
        />
        <Route
            path="/invoices/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <FatturaInsert />
                </PrivateRoute>
            }
        />
        <Route
            path="/tenant"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <TenantUpdate />
                </PrivateRoute>
            }
        />
        <Route
            path="/tenant/documents"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <TenantUpdate />
                </PrivateRoute>
            }
        />
        <Route
            path="/tenant/documents/create"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <TenantDocumentEditor />
                </PrivateRoute>
            }
        />
        <Route
            path="/tenant/documents/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <TenantDocumentEditor />
                </PrivateRoute>
            }
        />
        <Route
            path="/yourStructures"
            element={
                <PrivateRoute pagePermission={PagePermission.Customer}>
                    <Breadcrumbs />
                    <StruttureList userView />
                </PrivateRoute>
            }
        />
        <Route
            path="/yourDossiers"
            element={
                <PrivateRoute pagePermission={PagePermission.Customer}>
                    <Breadcrumbs />
                    <PraticheList userView />
                </PrivateRoute>
            }
        />
        <Route
            path="/yourDossiers/info"
            element={
                <PrivateRoute pagePermission={PagePermission.Customer}>
                    <Breadcrumbs />
                    <DossierInfo />
                </PrivateRoute>
            }
        />
        <Route
            path="/yourPayments"
            element={
                <PrivateRoute pagePermission={PagePermission.Customer}>
                    <Breadcrumbs />
                    <PaymentsList />
                </PrivateRoute>
            }
        />
        <Route
            path="/yourInfo"
            element={
                <PrivateRoute pagePermission={PagePermission.Customer}>
                    <Breadcrumbs />
                    <UserUpsert isAdd={false} userView={true} />
                </PrivateRoute>
            }
        />
        <Route
            path="/servicesReport"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <ServiziReport />
                </PrivateRoute>
            }
        />
        <Route
            path="/dossiersReport"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <PraticheReport />
                </PrivateRoute>
            }
        />
        <Route
            path="/transferRegister"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <TransferRegisterList />
                </PrivateRoute>
            }
        />
        <Route
            path="/generateBulletins"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <GenerateBulletins />
                </PrivateRoute>
            }
        />
        <Route
            path="/recordPayments"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <RecordPayments />
                </PrivateRoute>
            }
        />
        <Route
            path="/paymentsToDo"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <PaymentsToDo />
                </PrivateRoute>
            }
        />
        <Route
            path="/paymentsDone"
            element={
                <PrivateRoute pagePermission={PagePermission.Admin}>
                    <Breadcrumbs />
                    <PaymentsDone />
                </PrivateRoute>
            }
        />
        <Route
            path="/changePassword"
            element={
                <PrivateRoute>
                    <Breadcrumbs />
                    <ChangePassword />
                </PrivateRoute>
            }
        />
        <Route
            path="/superAdminSettings"
            element={
                <PrivateRoute pagePermission={PagePermission.SuperAdmin}>
                    <Breadcrumbs />
                    <SuperAdminPage />
                </PrivateRoute>
            }
        />
        <Route
            path="/superAdminSettings/documents"
            element={
                <PrivateRoute pagePermission={PagePermission.SuperAdmin}>
                    <Breadcrumbs />
                    <TenantUpdate />
                </PrivateRoute>
            }
        />
        <Route
            path="/superAdminSettings/documents/create"
            element={
                <PrivateRoute pagePermission={PagePermission.SuperAdmin}>
                    <Breadcrumbs />
                    <SuperAdminDocumentEditor />
                </PrivateRoute>
            }
        />
        <Route
            path="/superAdminSettings/documents/edit"
            element={
                <PrivateRoute pagePermission={PagePermission.SuperAdmin}>
                    <Breadcrumbs />
                    <SuperAdminDocumentEditor />
                </PrivateRoute>
            }
        />
        <Route
            path="/login"
            element={
                <RedirectToLogin />
            }
        />
        <Route
            path="/passwordRecovery"
            element={
                <PasswordRecovery />
            }
        />
        <Route
            path="/9gtoxGFR8xVs4boa1HTf"
            element={
                <GenerateCredentials />
            }
        />
        <Route
            path="/fruhlad8qlm210a"
            element={
                <InsertEmailAndPhone />
            }
        />
        <Route
            path="*"
            element={
                <PrivateRoute>
                    <DashboardHandler />
                </PrivateRoute>
            }
        />
    </Routes>
);

export default AllRoutes;