export interface TenantDashboardData {
    numberOfUsers: number;
    numberOfStructures: number;
    numberOfDossiers: number;
    numberOfInvoices: number;
    numberOfServices: number;
    numberOfAgencies: number;
    numberOfDecuiusWithPlace: number;
    numberOfDecuiusWithoutPlace: number;
    percentageFreePlaces: number;
    percentageOccupiedPlaces: number;
    freePlacesEarnable: number;
    occupiedPlacesEarned: number;
}

export const defaultTenantDashboardData: TenantDashboardData = {
    numberOfUsers: 0,
    numberOfStructures: 0,
    numberOfDossiers: 0,
    numberOfInvoices: 0,
    numberOfServices: 0,
    numberOfAgencies: 0,
    numberOfDecuiusWithPlace: 0,
    numberOfDecuiusWithoutPlace: 0,
    percentageFreePlaces: 0,
    percentageOccupiedPlaces: 0,
    freePlacesEarnable: 0,
    occupiedPlacesEarned: 0,
}