import { TipoNumeratore } from "./enums/TipoNumeratore";

export interface SerieNumeratore {
    id?: string;
    nome: string;
    anno: number;
    numero: number;
    tipo: TipoNumeratore;
    tipoNome?: string;
    default: boolean;

    dateOfInsert?: string;
    isValid?: boolean;
    tenantId?: string;
}

export const defaultSerieNumeratore: SerieNumeratore = {
    nome: '',
    anno: 1920,
    numero: 0,
    tipo: TipoNumeratore.Pratica,
    default: false
}