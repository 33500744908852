import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import { IconBell } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { PagamentoService } from 'services/PagamentoServices';
import { UnviewedPayment } from 'types/unviewedPayment';

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const pagamentoService = new PagamentoService();

    const [open, setOpen] = useState(false);

    const anchorRef = useRef<any>(null);

    const location = useLocation();
    const navigate = useNavigate();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);

    const [unviewedPayments, setUnviewedPayments] = useState<Array<UnviewedPayment>>([]);

    useEffect(() => {
        retrieveUnviewedPayments();

        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        retrieveUnviewedPayments();
    }, [])

    useEffect(() => {
        retrieveUnviewedPayments();
    }, [location])

    const retrieveUnviewedPayments = async () => {
        const retrievedUnviewedPayments = await pagamentoService.GetUnviewedPayments();
        setUnviewedPayments(retrievedUnviewedPayments);
    }

    const handleViewPayment = async (paymentId: string, dossierId: string): Promise<void> => {
        //TODO: Brutto ma attualmente funzionante, da aggiornare
        navigate('/dossiers');
        setTimeout(() => navigate(`/dossiers/edit?dossierId=${dossierId}`), 1);

        await pagamentoService.SetPaymentsViewed([paymentId]);
        await retrieveUnviewedPayments();
        setOpen(false);
    }

    const viewAllPayments = async () => {
        const paymentsIds: Array<string> = unviewedPayments.map((unviewedPayment: UnviewedPayment) => unviewedPayment.paymentId);
        await pagamentoService.SetPaymentsViewed(paymentsIds);
        await retrieveUnviewedPayments();
    }

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: unviewedPayments.length > 0 ? 'lightgreen' : theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary[200],
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <IconBell stroke={1.5} size="1.3rem" />
                </Avatar>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{ minWidth: '280px' }}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography variant="subtitle1"><FormattedMessage id="notifications" /></Typography>
                                                            <Chip
                                                                size="small"
                                                                label={unviewedPayments.length}
                                                                sx={{
                                                                    color: theme.palette.background.default,
                                                                    bgcolor: theme.palette.warning.dark
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography onClick={viewAllPayments} variant="subtitle2" color="primary"
                                                            sx={{
                                                                '&:hover': {
                                                                    cursor: 'pointer'
                                                                }
                                                            }}
                                                        >
                                                            <FormattedMessage id="markAllRead" />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                                >
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider sx={{ my: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    <NotificationList unviewedPayments={unviewedPayments} handleViewPayment={handleViewPayment} />
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
