import { Editor, Transforms, Path, Range, Element } from 'slate';

export const createPlaceholderNode = (placeholder) => (
    {
        type: 'placeholder',
        inline: true,
        children: [{ text: `{{${placeholder}}}` }]
    }
)

export const insertPlaceholder = (editor, placeholder) => {
    if (!placeholder) return;

    const { selection } = editor;
    const placeholderNode = createPlaceholderNode(placeholder);

    if (!!selection) {
        const [parent, parentPath] = Editor.parent(editor, selection.focus.path);

        // if(parent.type === 'placeholder'){
        //     removePlaceholder(editor);
        // }

        if (editor.isVoid(parent)) {
            Transforms.insertNodes(editor,
                { type: 'paragraph', children: [placeholderNode] },
                {
                    at: Path.next(parentPath),
                    select: true
                }

            )
        }
        else if (Range.isCollapsed(selection)) {
            Transforms.insertNodes(editor, placeholderNode, { select: true });
        }
        else {
            Transforms.wrapNodes(editor, placeholderNode,
                { split: true }
            )

        }
    }
    else {
        Transforms.insertNodes(editor, { type: 'paragraph', children: [placeholderNode] })
    }
};

export const removePlaceholder = (editor) => {
    Transforms.unwrapNodes(editor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'placeholder'
    })
};

export const placeholderList = [
    "NOME_TENANT",
    "GIORNO",
    "MESE",
    "ANNO",
    "DATA",
    "PARTITA_IVA",

    "INTESTATARIO_NOME",
    "INTESTATARIO_COGNOME",
    "INTESTATARIO_EMAIL",
    "INTESTATARIO_COD_FISCALE",
    "INTESTATARIO_INDIRIZZO",
    "INTESTATARIO_PROVINCIA",
    "INTESTATARIO_LUOGO_NASCITA",
    "INTESTATARIO_NUM_TEL",
    "INTESTATARIO_DATA_NASCITA",
    "INTESTATARIO_COMUNE_RESIDENZA",
    
    "INTESTATARIO_POSTO_NOME",
    "INTESTATARIO_POSTO_COGNOME",
    "INTESTATARIO_POSTO_DATA_NASCITA",
    "INTESTATARIO_POSTO_DATA_MORTE",
    "INTESTATARIO_POSTO_PROVINCIA_NASCITA",
    "INTESTATARIO_POSTO_LUOGO_NASCITA",
    "INTESTATARIO_POSTO_PROVINCIA_MORTE",
    "INTESTATARIO_POSTO_LUOGO_MORTE",
    "INTESTATARIO_POSTO_COD_FISCALE",
    "INTESTATARIO_POSTO_INDIRIZZO",
    "INTESTATARIO_POSTO_NAZIONE_RESIDENZA",
    "INTESTATARIO_POSTO_PROVINCIA_RESIDENZA",
    "INTESTATARIO_POSTO_COMUNE_RESIDENZA",

    "NUMERO_LOCULO",
    "NUMERO_STECCA",
    "BLOCCO_STRUTTURA",
    "NUMERO_STRUTTURA",
    "PREZZO_LOCULO_CON_IVA",
    "PREZZO_LOCULO_SENZA_IVA",

    "NOME_COMPLETO_PAGANTE",
    "DESCRIZIONE_SERVIZIO",
    "TOTALE_DA_PAGARE",
    "TOTALE",
    "LINK_PAGAMENTO",
    "ANNO_SERVIZIO",
    "CODICE_PAGAMENTO_SERVIZIO",

    "INIZIO_LISTA_POSTI_CAPPELLA",
    "FINE_LISTA_POSTI_CAPPELLA",

    "PASSWORD",

    "QR_CODE_REGISTRAZIONE",
    "UTENTE_NOME",
    "UTENTE_COGNOME",
    "UTENTE_CODICE_FISCALE",
]