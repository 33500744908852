
import { useRef } from 'react'
import Button from '../../common/Button.jsx'
import Icon from '../../common/Icon.jsx'
import usePopup from '../../utils/customHooks/usePopup'
import { Editor, Transforms, Text } from 'slate'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider } from "@mui/material";

const LineHeightButton = (props) => {
    const { editor } = props

    const linkInputRef = useRef(null);
    const [showInput, setShowInput] = usePopup(linkInputRef)

    const intl = useIntl();

    const setLineHeight = (lineHeight) => {
        const { selection } = editor;

        if (selection) {
            Transforms.setNodes(
                editor,
                { lineHeight },
                { at: selection, match: n => Editor.isBlock(editor, n) }
            );
        }
    };

    const handleSetLineHeight = (e) => {
        const newLineHeight = e.target.value;
        setLineHeight(newLineHeight);
        setShowInput(prev => !prev);
    }

    const toggleLineHeightOptions = () => {
        setShowInput(prev => !prev);
    }

    const lineHeightOptions = [1, 1.5, 2, 2.5, 3, 3.5];

    return (
        <div ref={linkInputRef} className='popup-wrapper'>
            <Button disabled={!editor.selection} className={showInput ? 'clicked' : ''} format={'lineHeight'} onClick={toggleLineHeightOptions}>
                <Icon icon='lineHeight' />
            </Button>
            {showInput &&
                <div className='popup' style={{ cursor: 'context-menu' }}>
                    <span style={{ fontSize: '1em', marginBottom: '3px' }}> <b><FormattedMessage id="lineHeight" /></b> </span>
                    <Divider />
                    <div style={{ margin: '5px 0px 0px 5px' }}>
                        <select onChange={handleSetLineHeight}>
                            <option value="select">{intl.formatMessage({ id: 'choose' })}</option>
                            {lineHeightOptions.map(lineHeight =>
                                <option value={lineHeight}>{lineHeight}</option>
                            )}
                        </select>
                    </div>
                </div>
            }
        </div>
    )
}


export default LineHeightButton;