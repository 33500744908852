import { Card, CardContent, Link, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { FormattedMessage } from "react-intl";

const Breadcrumbs = () => {
    const location = useLocation();

    const [breadcrumbsElements, setBreadcrumbsElements] = useState<Array<ReactElement>>([]);

    const pagePathTitle: { [path: string]: ReactElement } = {
        '/tenant': <FormattedMessage id="infoTenant" />,
        '/tenant/documents/create': <FormattedMessage id="addDocument" />,
        '/tenant/documents/edit': <FormattedMessage id="editDocument" />,

        '/structures': <FormattedMessage id="structures" />,
        '/structures/create': <FormattedMessage id="addStructure" />,
        '/structures/edit': <FormattedMessage id="editStructure" />,

        '/posto/edit': <FormattedMessage id="editPlace" />,

        '/users': <FormattedMessage id="users" />,
        '/users/create': <FormattedMessage id="addUser" />,
        '/users/edit': <FormattedMessage id="editUser" />,
        '/users/dossiersList': <FormattedMessage id="userDossiersList" />,

        '/decuius': <FormattedMessage id="decuius" />,
        '/decuius/create': <FormattedMessage id="addDecuius" />,
        '/decuius/edit': <FormattedMessage id="editDecuius" />,
        '/decuius/dossiersList': <FormattedMessage id="decuiusDossiersList" />,

        '/agencies': <FormattedMessage id="agencies" />,
        '/agencies/create': <FormattedMessage id="addAgency" />,
        '/agencies/edit': <FormattedMessage id="editAgency" />,

        '/dossiers': <FormattedMessage id="dossiers" />,
        '/dossiers/create': <FormattedMessage id="addDossier" />,
        '/dossiers/edit': <FormattedMessage id="editDossier" />,
        '/dossiersReport': <FormattedMessage id="dossiersReport" />,
        '/paymentsToDo': <FormattedMessage id="paymentsToDo" />,
        '/paymentsDone': <FormattedMessage id="paymentsDone" />,

        '/deposit': <FormattedMessage id="deposit" />,
        '/transferRegister': <FormattedMessage id="transferRegister" />,
        '/generateBulletins': <FormattedMessage id="generateBulletins" />,
        '/recordPayments': <FormattedMessage id="recordPayments" />,

        '/invoices': <FormattedMessage id="invoices" />,
        '/invoices/create': <FormattedMessage id="addInvoice" />,

        '/zones': <FormattedMessage id="zones" />,
        '/zones/create': <FormattedMessage id="addZone" />,
        '/zones/edit': <FormattedMessage id="editZone" />,

        '/blocks': <FormattedMessage id="blocks" />,
        '/blocks/create': <FormattedMessage id="addBlock" />,
        '/blocks/edit': <FormattedMessage id="editBlock" />,

        '/services': <FormattedMessage id="services" />,
        '/services/create': <FormattedMessage id="addService" />,
        '/services/edit': <FormattedMessage id="editService" />,
        '/servicesReport': <FormattedMessage id="servicesReport" />,

        '/serieNumerators': <FormattedMessage id="numerators" />,
        '/serieNumerators/create': <FormattedMessage id="addSerieNumerator" />,
        '/serieNumerators/edit': <FormattedMessage id="editSerieNumerator" />,

        '/yourStructures': <FormattedMessage id="yourStructures" />,

        '/yourDossiers': <FormattedMessage id="yourDossiers" />,
        '/yourDossiers/info': <FormattedMessage id="dossierInfo" />,

        '/yourPayments': <FormattedMessage id="yourPayments" />,

        '/yourInfo': <FormattedMessage id="yourInfo" />,
        '/changePassword': <FormattedMessage id="changePassword" />,

        '/superAdminSettings': <FormattedMessage id="superAdminSettings" />,
        '/superAdminSettings/documents/create': <FormattedMessage id="addDocument" />,
        '/superAdminSettings/documents/edit': <FormattedMessage id="editDocument" />,
    };

    const pathToNotShow: Array<string> = [
        '/posto',
        '/tenant/documents',
        '/superAdminSettings/documents'
    ];

    useEffect(() => {
        setBreadcrumbs();
    }, [location])

    const createBreadcrumbItem = (url: string, formattedMessage: ReactElement) => {
        return <Typography key={url} color='inherit' component={RouterLink} to={url}
            sx={{
                textDecoration: 'none',
                transition: '0.2s',
                '&:hover': {
                    color: 'grey'
                }
            }}
        >
            {formattedMessage}
        </Typography>
    }

    const setBreadcrumbs = () => {
        const allPaths: Array<string> = location.pathname.split('/').filter(part => part !== '');

        let breadcrumbsArray: ReactElement[] = [
            createBreadcrumbItem('/', <FormattedMessage id="home" />)
        ];

        let pagePath: string = '';
        allPaths.map((path: string, index: number) => {
            pagePath += `/${path}`;
            if (!pathToNotShow.includes(pagePath)) {

                let pageTitle: ReactElement | null = pagePathTitle[pagePath];

                pagePath += (allPaths.length == index + 1) ? location.search : '';

                breadcrumbsArray.push(
                    createBreadcrumbItem(pagePath, pageTitle)
                );
            }
        });

        setBreadcrumbsElements(breadcrumbsArray);
    }

    return (
        <Card
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '18px'
            }}
        >
            <CardContent>
                <MuiBreadcrumbs separator="›" aria-label="breadcrumb">
                    {breadcrumbsElements}
                </MuiBreadcrumbs>
            </CardContent>
        </Card>
    )
}

export default Breadcrumbs;