import { FC, useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage } from 'react-intl';
import { Struttura } from 'types/struttura';
import { PaginationResult } from 'types/paginationResult';
import { MoreVert } from '@mui/icons-material';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import SearchIcon from '@mui/icons-material/Search';

interface StrutturaWithMenuProps {
    struttura: Struttura;

    userView?: boolean;
}

const StrutturaWithMenu: FC<StrutturaWithMenuProps> = (props) => {
    const { struttura, userView } = props;

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow hover key={struttura.id}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="denomination" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {struttura.denominazione} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="number" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {struttura.numero} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="zone" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {struttura.zona?.descrizione} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="block" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {struttura.viale?.descrizione} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            {
                !userView &&
                <TableCell
                    sx={{ verticalAlign: 'center', textAlign: 'right' }}
                >
                    <IconButton onClick={handleOpenMenu}>
                        <MoreVert fontSize="small" />
                    </IconButton>
                    <Menu
                        id={`structure-${struttura.id}-actions-menu`}
                        MenuListProps={{
                            'aria-labelledby': `structure-action-button`,
                        }}
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem key={'edit'} onClick={handleCloseMenu} >
                            <IconButton
                                component={RouterLink}
                                to={`edit?strutturaId=${struttura.id}`}
                            >
                                <EditIcon fontSize="small" sx={{ mr: '3px' }} />
                                <Typography variant="subtitle1"> <FormattedMessage id="edit" /> </Typography>
                            </IconButton>
                        </MenuItem>
                    </Menu>
                </TableCell>
            }
        </TableRow>
    );
};

interface StrutturePageMobileProps {
    strutture: PaginationResult<Struttura>;
    isPageLoading: boolean;

    userView?: boolean;
}

const StrutturePageMobile: FC<StrutturePageMobileProps> = (props) => {
    const { strutture, isPageLoading, userView } = props;

    return (
        <Table>
            <TableBody>
                {
                    isPageLoading ? (
                        <TableRowsLoader rowsNum={3} colsNum={1} />
                    ) :
                        (<>
                            {strutture && strutture.items?.map((struttura) => (
                                <StrutturaWithMenu key={struttura.id} struttura={struttura} userView={userView} />
                            ))}
                        </>)
                }

            </TableBody>
        </Table>
    );
};

export default StrutturePageMobile;