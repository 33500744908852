import { FC } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage } from 'react-intl';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { Agenzia } from 'types/agenzia';
import { PaginationResult } from 'types/paginationResult';
import ISortOptions from 'interfaces/ISortOptions';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { SerieNumeratore } from 'types/serieNumeratore';

interface SerieNumeratoriPageProps {
    serieNumeratori: PaginationResult<SerieNumeratore>;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
    isPageLoading: boolean;
}

const SerieNumeratorePage: FC<SerieNumeratoriPageProps> = (props) => {
    const { serieNumeratori: serieNumeratori, setSortOptions, sortOptions, isPageLoading } = props;

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="nome"
                            title={<FormattedMessage id="name" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="anno"
                            title={<FormattedMessage id="year" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="numero"
                            title={<FormattedMessage id="number" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="tipo"
                            title={<FormattedMessage id="type" />}
                        />
                        <TableCell> <FormattedMessage id="actions" /> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isPageLoading ?
                            (<TableRowsLoader rowsNum={10} colsNum={5} />) :
                            (<>
                                {serieNumeratori && serieNumeratori.items?.map((serieNumeratore) => (
                                    <AlternateTableRow key={serieNumeratore.id}>
                                        <TableCell>
                                            <Grid item> {serieNumeratore.nome} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {serieNumeratore.anno} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {serieNumeratore.numero} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {serieNumeratore.tipoNome} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                                <IconButton component={RouterLink} to={`edit?serieNumeratoreId=${serieNumeratore.id}`}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </AlternateTableRow>
                                ))}
                            </>)
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default SerieNumeratorePage;
