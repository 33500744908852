/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { Posto } from 'types/posto';
import { Allegato } from 'types/allegato';
import { Utente } from 'types/utente';
import { Decuius } from 'types/decuius';
import { PaginationQuery } from 'types/paginationQuery';
import { PaginationResult } from 'types/paginationResult';

export class PostoService {
    async GetPosti(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Posto>> {
        const result = await axios.post<PaginationResult<Posto>>(`${serviceConfig.appServiceBaseUrl}/GetPosti`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetPostoById(postoId: string): Promise<Posto> {
        const result = await axios.post<Posto>(`${serviceConfig.appServiceBaseUrl}/GetPosto`, postoId);
        return result.data;
    }

    async UpsertPosto(posto: Posto): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpsertPosto`, posto);
        return result.data;
    }

    async InsertAllegatoToPosto(insertPostoAllegato: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/InsertPostoAllegato`, insertPostoAllegato);
        return result.data;
    }

    async DeleteAllegatoFromPosto(allegatoId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeletePostoAllegato`, allegatoId);
        return result.data;
    }

    async GetPostoAllegati(postoId: string): Promise<Array<Allegato>> {
        const result = await axios.post<Array<Allegato>>(`${serviceConfig.appServiceBaseUrl}/GetPostoAllegati`, postoId);
        return result.data;
    }

    async AssignUserToPosto(postoUtente: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/AssignUserToPosto`, postoUtente);
        return result.data;
    }

    async GetPostoAssignedUtente(postoId: string): Promise<Utente | null> {
        const result = await axios.post<Utente | null>(`${serviceConfig.appServiceBaseUrl}/GetPostoAssignedUtente`, postoId);
        return result.data;
    }

    async RemoveUtenteFromPosto(postoId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/RemoveUtenteFromPosto`, postoId);
        return result.data;
    }

    async AssignDecuiusToPosto(postoDecuius: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/AssignDecuiusToPosto`, postoDecuius);
        return result.data;
    }

    async GetPostoAssignedDecuius(postoId: string): Promise<Decuius | null> {
        const result = await axios.post<Decuius | null>(`${serviceConfig.appServiceBaseUrl}/GetPostoAssignedDecuius`, postoId);
        return result.data;
    }

    async RemoveDecuiusFromPosto(decuiusId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/RemoveDecuiusFromPosto`, decuiusId);
        return result.data;
    }

    async GetPostoAssignedDecuiusBoxes(postoId: string): Promise<Array<Decuius>> {
        const result = await axios.post<Array<Decuius>>(`${serviceConfig.appServiceBaseUrl}/GetPostoAssignedDecuiusBoxes`, postoId);
        return result.data;
    }

    async ChangeDecuiusStatus(decuiusStatus: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/ChangeDecuiusStatus`, decuiusStatus);
        return result.data;
    }

    async ChangePlaceVisibility(placeId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/ChangePlaceVisibility`, placeId);
        return result.data;
    }

    async DownloadPostoAllegato(documentId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/DownloadPostoAllegato`, documentId);
        return result.data;
    }

    async RenameAllegatoPosto(renameAllegatoObject: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/RenameAllegatoPosto`, renameAllegatoObject);
        return result.data;
    }
}