export interface Tenant {
    id?: string;
    name: string;
    companyName: string;
    vatNumber: string;
    fiscalCode: string;
    address: string;
    civic: string;
    cap: string;
    municipality: string;
    province: string;
    country: string;
    bankName: string;
    iban: string;
    invoiceNotes?: string;
    invoicesDefaultCausal?: string;
    urlDocEasyApi?: string;
    docEasyApiKey?: string;
    docEasyApiSecret?: string;
    docEasyClientCode?: string;
    docEasyFileName?: string;

    nexiAlias?: string;
    nexiSecretKey?: string;

    whatsappApiUrl?: string;
    whatsappBearerToken?: string;

    tenantLogoBase64?: string | null;
    tenantAdminSignatureBase64?: string | null;

    qrCommunicationWithHeaderFooter: boolean;
    automaticInvoice: boolean;

    dateOfInsert?: string;
    isValid?: boolean;
}

export const defaultTenant: Tenant = {
    name: '',
    companyName: '',
    vatNumber: '',
    fiscalCode: '',
    address: '',
    civic: '',
    cap: '',
    municipality: '',
    province: '',
    country: '',
    bankName: '',
    iban: '',
    qrCommunicationWithHeaderFooter: false,
    automaticInvoice: false
}