import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, Skeleton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TenantService } from "services/TenantServices";
import { Tenant, defaultTenant } from "types/tenant";
import CardContentLoader from "ui-component/CardContentLoader";
import { composeNotistackMessage, getFormValue, handleDownloadFile } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { object, string } from "yup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from "react-router-dom";
import { TemplateType } from "types/enums/TemplateType";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { TenantDocument } from "types/tenantDocument";
import BoxAddElement from "ui-component/BoxAddElement";
import DocumentCard from "ui-component/DocumentCard";
import ImageDropzone from "ui-component/ImageDropzone";

const TenantUpdate = () => {
    const intl = useIntl();

    const tenantService = new TenantService();

    const navigate = useNavigate();

    const [tenant, setTenant] = useState<Tenant>(defaultTenant);
    const [tenantLogoBase64, setTenantLogoBase64] = useState<string>('');
    const [tenantAdminSignature, setTenantAdminSignature] = useState<string>('');
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    const [privacyDocument, setPrivacyDocument] = useState<TenantDocument | undefined>(undefined);
    const [preliminaryDocuments, setPreliminaryDocuments] = useState<Array<TenantDocument>>([]);
    const [otherDocuments, setOtherDocuments] = useState<Array<TenantDocument>>([]);
    const [communicationsDocuments, setCommunicationsDocuments] = useState<Array<TenantDocument>>([]);

    let tenantValidationSchema = object({
        name: string().required('nameRequired'),
        companyName: string().required('companyNameRequired'),
        vatNumber: string().required('vatNumberRequired'),
        fiscalCode: string().required('fiscalCodeRequired'),
        address: string().required('addressRequired'),
        civic: string().required('civicRequired'),
        cap: string().required('capRequired'),
        municipality: string().required('municipalityRequired'),
        province: string().required('provinceRequired'),
        country: string().required('countryRequired'),
        bankName: string().required('bankNameRequired'),
        iban: string().required('ibanRequired'),
    });

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setTenant((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    useEffect(() => {
        setIsPageLoading(true);
        (async () => {
            let retrievedTenant: Tenant = await tenantService.GetTenant();

            setTenantLogoBase64(retrievedTenant.tenantLogoBase64!);
            setTenantAdminSignature(retrievedTenant.tenantAdminSignatureBase64!);

            setTenant({ ...retrievedTenant, tenantLogoBase64: null });
            setTenant({ ...retrievedTenant, tenantAdminSignatureBase64: null });

            // Documents retrieve
            await updateDocuments();

            setIsPageLoading(false);
        })();
    }, [])

    const tenantUpdate = async () => {
        try {
            await tenantValidationSchema.validate(tenant, { abortEarly: false });

            composeNotistackMessage(intl.formatMessage({ id: 'tenantUpdatedSuccessfully' }), 'success');
            await tenantService.UpdateTenant(tenant);
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        }
    }

    const downloadDocument = async (documentId: string, documentName: string) => {
        const tenantDocumentPdfBase64: string = await tenantService.DownloadTenantDocument(documentId);
        handleDownloadFile(tenantDocumentPdfBase64, documentName);
    }

    const deleteDocument = async (documentId: string) => {
        await tenantService.DeleteTenantDocument(documentId);
        updateDocuments();
    }

    const updateDocuments = async () => {
        setIsPageLoading(true);

        (async () => {
            const allTenantDocuments: Array<TenantDocument> = await tenantService.GetTenantDocuments();
            setPrivacyDocument(allTenantDocuments.find((document: TenantDocument) => document.templateType == TemplateType.Privacy));
            setPreliminaryDocuments(allTenantDocuments.filter((document: TenantDocument) => document.templateType == TemplateType.Preliminare));
            setCommunicationsDocuments(allTenantDocuments.filter((document: TenantDocument) => document.templateType == TemplateType.Comunicazione));
            setOtherDocuments(allTenantDocuments.filter((document: TenantDocument) => document.templateType == TemplateType.Altro));

            setIsPageLoading(false);
        })();
    }

    const handleUpdateLogo = (base64: string) => {
        setTenantLogoBase64(base64);
        setTenant((prevData) => ({ ...prevData, tenantLogoBase64: base64 }));
    }

    const handleUpdateAdminSignature = (base64: string) => {
        setTenantAdminSignature(base64);
        setTenant((prevData) => ({ ...prevData, tenantAdminSignatureBase64: base64 }));
    }

    const checkAdminSignatureImage = async (imageBase64: string): Promise<boolean> => {
        return new Promise((resolve) => {
            const image = new Image();

            image.src = imageBase64;

            image.onload = () => {
                const validImage = Math.trunc(image.height * 4) == image.width;
                resolve(validImage);
            }
        })
    }

    return (
        <Card>
            <CardHeader title={<FormattedMessage id="tenantData" />} />
            <Divider />
            {
                isPageLoading ?
                    <CardContentLoader /> :
                    (
                        <CardContent>
                            <Box sx={{ marginBottom: '15px' }}>
                                <ImageDropzone
                                    imageBase64={tenantLogoBase64}
                                    handleUpdateImage={handleUpdateLogo}
                                />
                            </Box>
                            <Grid container direction="row" spacing={1}>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="name" />}
                                        name="name"
                                        required
                                        onChange={handleChange}
                                        value={tenant.name}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="companyName" />}
                                        name="companyName"
                                        required
                                        onChange={handleChange}
                                        value={tenant.companyName}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="vatNumber" />}
                                        name="vatNumber"
                                        required
                                        onChange={handleChange}
                                        value={tenant.vatNumber}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="fiscalCode" />}
                                        name="fiscalCode"
                                        required
                                        onChange={handleChange}
                                        value={tenant.fiscalCode}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="address" />}
                                        name="address"
                                        required
                                        onChange={handleChange}
                                        value={tenant.address}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="civic" />}
                                        name="civic"
                                        required
                                        onChange={handleChange}
                                        value={tenant.civic}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="cap" />}
                                        name="cap"
                                        required
                                        onChange={handleChange}
                                        value={tenant.cap}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="municipality" />}
                                        name="municipality"
                                        required
                                        onChange={handleChange}
                                        value={tenant.municipality}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="province" />}
                                        name="province"
                                        required
                                        onChange={handleChange}
                                        value={tenant.province}
                                        inputProps={{ maxLength: 2 }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="country" />}
                                        name="country"
                                        required
                                        onChange={handleChange}
                                        value={tenant.country}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="bankName" />}
                                        name="bankName"
                                        required
                                        onChange={handleChange}
                                        value={tenant.bankName}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="iban" />}
                                        name="iban"
                                        required
                                        onChange={handleChange}
                                        value={tenant.iban}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="invoiceNotes" />}
                                        name="invoiceNotes"
                                        onChange={handleChange}
                                        value={tenant.invoiceNotes}
                                        inputProps={{
                                            style: {
                                                height: 50
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={<FormattedMessage id="invoicesDefaultCausal" />}
                                        name="invoicesDefaultCausal"
                                        onChange={handleChange}
                                        value={tenant.invoicesDefaultCausal}
                                        inputProps={{
                                            style: {
                                                height: 50
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    md={6}
                                    xs={12}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={tenant.qrCommunicationWithHeaderFooter}
                                            />
                                        }
                                        label={<FormattedMessage id="qrCommunicationWithHeaderFooter" />}
                                        name="qrCommunicationWithHeaderFooter"
                                        labelPlacement="start"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    md={6}
                                    xs={12}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={tenant.automaticInvoice}
                                            />
                                        }
                                        label={<FormattedMessage id="automaticInvoicePostPayment" />}
                                        name="automaticInvoice"
                                        labelPlacement="start"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    xs={12}
                                >
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="tenantAdvancedSettings"
                                            id="tenantAdvancedSettings"
                                        >
                                            <Typography> <FormattedMessage id="showTenantAdvancedSettings" /> </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container direction="row" spacing={1}>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label={<FormattedMessage id="urlDocEasyApi" />}
                                                        name="urlDocEasyApi"
                                                        onChange={handleChange}
                                                        value={tenant.urlDocEasyApi}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label={<FormattedMessage id="docEasyApiKey" />}
                                                        name="docEasyApiKey"
                                                        onChange={handleChange}
                                                        value={tenant.docEasyApiKey}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label={<FormattedMessage id="docEasyApiSecret" />}
                                                        name="docEasyApiSecret"
                                                        onChange={handleChange}
                                                        value={tenant.docEasyApiSecret}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label={<FormattedMessage id="docEasyClientCode" />}
                                                        name="docEasyClientCode"
                                                        onChange={handleChange}
                                                        value={tenant.docEasyClientCode}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label={<FormattedMessage id="docEasyFileName" />}
                                                        name="docEasyFileName"
                                                        onChange={handleChange}
                                                        value={tenant.docEasyFileName}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label={<FormattedMessage id="nexiAlias" />}
                                                        name="nexiAlias"
                                                        onChange={handleChange}
                                                        value={tenant.nexiAlias}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label={<FormattedMessage id="nexiSecretKey" />}
                                                        name="nexiSecretKey"
                                                        onChange={handleChange}
                                                        value={tenant.nexiSecretKey}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label={<FormattedMessage id="whatsappApiUrl" />}
                                                        name="whatsappApiUrl"
                                                        onChange={handleChange}
                                                        value={tenant.whatsappApiUrl}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label={<FormattedMessage id="whatsappBearerToken" />}
                                                        name="whatsappBearerToken"
                                                        onChange={handleChange}
                                                        value={tenant.whatsappBearerToken}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                            <Box sx={{ marginTop: '15px', textAlign: 'center' }}>
                                <Typography variant="h4" sx={{ marginBottom: '15px' }}> <FormattedMessage id="adminSignature" /> </Typography>
                                <ImageDropzone
                                    imageBase64={tenantAdminSignature}
                                    handleUpdateImage={handleUpdateAdminSignature}
                                    checkImageValidity={checkAdminSignatureImage}
                                    imageValidityErrorMessage={intl.formatMessage({ id: 'signatureAdminSizeError' })}
                                />
                            </Box>
                        </CardContent>
                    )
            }

            <Accordion sx={{ mb: 1 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="tenantPrivacyDocument"
                    id="tenantPrivacyDocument"
                >
                    <Typography> <FormattedMessage id="showTenantPrivacyDocument" /> </Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Grid container direction="row" spacing={2}>
                        <Grid item
                            lg={2}
                            md={2}
                            xs={12}
                        >
                            {
                                isPageLoading ?
                                    <Grid item
                                        lg={2}
                                        md={2}
                                        xs={12}
                                    >
                                        <Skeleton
                                            variant="rectangular"
                                            width={180}
                                            height={200}
                                            sx={{ bgcolor: 'grey.300', borderRadius: '10px' }}
                                        />
                                    </Grid> :
                                    privacyDocument === undefined ?
                                        <BoxAddElement onClick={() => navigate(`/tenant/documents/create?templateType=privacy`)} /> :
                                        <DocumentCard
                                            navigatePath={"/tenant/documents/edit"}
                                            documentId={privacyDocument.id!}
                                            documentName={privacyDocument.fileName}
                                            downloadDocument={downloadDocument}
                                            deleteDocument={deleteDocument}
                                            icon={<PrivacyTipIcon sx={{ fontSize: 48 }} />}
                                        />
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ mb: 1 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="tenantPreliminaryDocumentsSummary"
                    id="tenantPreliminaryDocumentsSummary"
                >
                    <Typography> <FormattedMessage id="showPreliminaryDocuments" /> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row" spacing={2}>
                        {
                            isPageLoading ?
                                <Grid item
                                    lg={2}
                                    md={2}
                                    xs={12}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={180}
                                        height={200}
                                        sx={{ bgcolor: 'grey.300', borderRadius: '10px' }}
                                    />
                                </Grid> :
                                <>
                                    {
                                        preliminaryDocuments.map((preliminaryDocument: TenantDocument) => (
                                            <Grid item
                                                lg={2}
                                                md={2}
                                                xs={12}
                                            >
                                                <DocumentCard
                                                    navigatePath={"/tenant/documents/edit"}
                                                    documentId={preliminaryDocument.id!}
                                                    documentName={preliminaryDocument.fileName}
                                                    downloadDocument={downloadDocument}
                                                    deleteDocument={deleteDocument}
                                                    icon={<DescriptionIcon sx={{ fontSize: 48 }} />}
                                                />
                                            </Grid>
                                        ))
                                    }
                                    <Grid item
                                        lg={2}
                                        md={2}
                                        xs={12}
                                    >
                                        <BoxAddElement onClick={() => navigate(`/tenant/documents/create?templateType=preliminare`)} />
                                    </Grid>
                                </>

                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ mb: 1 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="tenantCommunicationsSummary"
                    id="tenantCommunicationsSummary"
                >
                    <Typography> <FormattedMessage id="showCommunicationsDocuments" /> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row" spacing={2}>
                        {
                            isPageLoading ?
                                <Grid item
                                    lg={2}
                                    md={2}
                                    xs={12}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={180}
                                        height={200}
                                        sx={{ bgcolor: 'grey.300', borderRadius: '10px' }}
                                    />
                                </Grid> :
                                <>
                                    {
                                        communicationsDocuments.map((communicationDocument: TenantDocument) => (
                                            <Grid item
                                                lg={2}
                                                md={2}
                                                xs={12}
                                            >
                                                <DocumentCard
                                                    navigatePath={"/tenant/documents/edit"}
                                                    documentId={communicationDocument.id!}
                                                    documentName={communicationDocument.fileName}
                                                    downloadDocument={downloadDocument}
                                                    deleteDocument={deleteDocument}
                                                    icon={<DescriptionIcon sx={{ fontSize: 48 }} />}
                                                />
                                            </Grid>
                                        ))
                                    }
                                    <Grid item
                                        lg={2}
                                        md={2}
                                        xs={12}
                                    >
                                        <BoxAddElement onClick={() => navigate(`/tenant/documents/create?templateType=comunicazione`)} />
                                    </Grid>
                                </>
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ mb: 1 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="tenantDocumentsSummary"
                    id="tenantDocumentsSummary"
                >
                    <Typography> <FormattedMessage id="showOtherDocuments" /> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row" spacing={2}>
                        {
                            isPageLoading ?
                                <Grid item
                                    lg={2}
                                    md={2}
                                    xs={12}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={180}
                                        height={200}
                                        sx={{ bgcolor: 'grey.300', borderRadius: '10px' }}
                                    />
                                </Grid> :
                                <>
                                    {
                                        otherDocuments.map((otherDocument: TenantDocument) => (
                                            <Grid item
                                                lg={2}
                                                md={2}
                                                xs={12}
                                            >
                                                <DocumentCard
                                                    navigatePath={"/tenant/documents/edit"}
                                                    documentId={otherDocument.id!}
                                                    documentName={otherDocument.fileName}
                                                    downloadDocument={downloadDocument}
                                                    deleteDocument={deleteDocument}
                                                    icon={<DescriptionIcon sx={{ fontSize: 48 }} />}
                                                />
                                            </Grid>
                                        ))
                                    }
                                    <Grid item
                                        lg={2}
                                        md={2}
                                        xs={12}
                                    >
                                        <BoxAddElement onClick={() => navigate(`/tenant/documents/create?templateType=altro`)} />
                                    </Grid>
                                </>
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={tenantUpdate}
                >
                    <FormattedMessage id="save" />
                </Button>
            </Box>
        </Card>
    );
}

export default TenantUpdate;