import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { VialeService } from "services/VialeServices";
import { Viale, defaultViale } from "types/viale";
import CardContentLoader from "ui-component/CardContentLoader";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import { composeNotistackMessage, getFormValue } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { object, string } from "yup";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

interface VialeUpsertProps {
	isAdd: boolean;
}

const VialeUpsert: FC<VialeUpsertProps> = (props) => {
	const { isAdd } = props;

	const intl = useIntl();

	const vialeService = new VialeService();
	const [viale, setViale] = useState<Viale>(defaultViale);

	let vialeValidationSchema = object({
		descrizione: string().required('descriptionRequired')
	});

	const navigate = useNavigate();

	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsPageLoading(true);

		(async () => {
			if (!isAdd) {
				let vialeId: string = window.location.href.split('vialeId=')[1] ?? '';
				let retrievedViale: Viale = await vialeService.GetVialeById(vialeId);

				setViale(retrievedViale);
			}
			setIsPageLoading(false);
		})();
	}, [])

	const handleChange = (e: any) => {
		let { name, value } = getFormValue(e);
		setViale((currentFormData: any) => ({ ...currentFormData, [name]: value }));
	}

	const vialeUpsert = async () => {
		try {
			await vialeValidationSchema.validate(viale, { abortEarly: false });

			await vialeService.UpsertViale(viale);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');

			if (isAdd) navigate('/blocks');
		} catch (validationErrors: any) {
			handleUpsertErrors(validationErrors.inner, intl);
		}
	}

	const [deleteVialeDialogOpen, setDeleteVialeDialogOpen] = useState<boolean>(false);

	const deleteViale = async () => {
		try {
			await vialeService.DeleteViale(viale.id!);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullyDelete' }), 'success');

			navigate('/blocks');
		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		}
	};

	return (
		<Card>
			<DialogDeleteElement
				open={deleteVialeDialogOpen}
				onClose={() => setDeleteVialeDialogOpen(false)}
				onCancel={() => setDeleteVialeDialogOpen(false)}
				onConfirm={() => {
					setDeleteVialeDialogOpen(false)
					deleteViale();
				}}
				entityToDeleteInfo={viale.descrizione}
			/>
			<CardHeader title={<FormattedMessage id="blockData" />} />
			<Divider />
			{
				isPageLoading ?
					<CardContentLoader /> :
					(
						<CardContent>
							<Grid container>
								<Grid
									item
									lg={12}
									md={12}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="description" />}
										name="descrizione"
										required
										onChange={handleChange}
										value={viale.descrizione}
									/>
								</Grid>
							</Grid>
						</CardContent>
					)
			}
			<Divider />
			<Grid container>
				<Grid item
					xs={6}
					md={6}
					lg={6}
				>
					{!isAdd && <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-start' }}>
						<Button
							color="error"
							type="submit"
							variant="contained"
							onClick={() => setDeleteVialeDialogOpen(true)}
							startIcon={<DeleteIcon />}
						>
							<FormattedMessage id="delete" />
						</Button>
					</Box>}
				</Grid>
				<Grid item
					xs={6}
					md={6}
					lg={6}
				>
					<Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							color="primary"
							type="submit"
							variant="contained"
							onClick={vialeUpsert}
							startIcon={<SaveIcon />}
						>
							<FormattedMessage id="save" />
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Card>
	)
};

export default VialeUpsert;
