import { createSvgIcon } from "@mui/material";

const Coffin = createSvgIcon(
	<svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512">
		<g>
			<g>
				<path d="M469.145,155.111L377.766,7.891C374.721,2.984,369.356,0,363.582,0H148.417c-5.774,0-11.139,2.984-14.186,7.891
			l-91.376,147.22c-2.483,4-3.161,8.864-1.868,13.391l94.683,331.389c2.048,7.168,8.599,12.109,16.053,12.109h208.552
			c7.454,0,14.005-4.942,16.053-12.109l94.683-331.389C472.306,163.975,471.628,159.112,469.145,155.111z M347.684,478.609H164.315
			l-89.19-312.174l82.581-133.043h196.587l82.581,133.043L347.684,478.609z"/>
			</g>
		</g>
		<g>
			<g>
				<path d="M322.782,166.957h-50.087V116.87c0-9.22-7.475-16.696-16.696-16.696s-16.696,7.475-16.696,16.696v50.087h-50.087
			c-9.22,0-16.696,7.475-16.696,16.696c0,9.22,7.475,16.696,16.696,16.696h50.087v150.261c0,9.22,7.475,16.696,16.696,16.696
			s16.696-7.475,16.696-16.696V200.348h50.087c9.22,0,16.696-7.475,16.696-16.696C339.478,174.432,332.002,166.957,322.782,166.957z
			"/>
			</g>
		</g>
	</svg>, 'Coffin'

);

export default Coffin;