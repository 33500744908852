import { useTheme, styled } from '@mui/material/styles';
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@mui/material';
import { UnviewedPayment } from 'types/unviewedPayment';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

interface NotificationListProps {
    unviewedPayments: Array<UnviewedPayment>;
    handleViewPayment: (paymentId: string, dossierId: string) => void;
}

const NotificationList: FC<NotificationListProps> = (props) => {
    const theme = useTheme();

    const intl = useIntl();

    const { unviewedPayments, handleViewPayment } = props;

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {
                unviewedPayments && unviewedPayments.length > 0 &&
                unviewedPayments.map((unviewedPayment: UnviewedPayment) =>
                    <>
                        <ListItemWrapper onClick={() => handleViewPayment(unviewedPayment.paymentId, unviewedPayment.dossierId)}>
                            <ListItem alignItems="center">
                                <ListItemText primary={`${intl.formatMessage({ id: 'dossier' })}: ${unviewedPayment.dossierNumber}`} />
                                <ListItemSecondaryAction>
                                    <Grid
                                        container
                                        xl={12}
                                        md={12}
                                        xs={12}
                                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                    >
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {unviewedPayment.paymentDate}
                                        </Typography>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Grid item xs={12} sx={{ pb: 2, mt: 1 }}>
                                <Typography variant="subtitle2">
                                    <FormattedMessage id="aPaymentHasBeenDone" />: <b>€{unviewedPayment.paymentAmount}</b>. <br /><FormattedMessage id="service" />: <b>{unviewedPayment.placeServiceDescription}</b>
                                </Typography>
                            </Grid>
                        </ListItemWrapper>
                        <Divider />
                    </>
                )
            }
        </List>
    );
};

export default NotificationList;
