import { FC } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { PaginationResult } from 'types/paginationResult';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import ISortOptions from 'interfaces/ISortOptions';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { Posto } from 'types/posto';
import { getShortDate } from 'utils/common';
import AddIcon from '@mui/icons-material/Add';

interface PostiPageProps {
    posti: PaginationResult<Posto>

    alreadyAssignedPostiIds?: Array<string>;
    assignPosto?: (postoId: string) => void;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;

    isPageLoading: boolean;

    checkAllPlaces: (e: any, posti: Array<Posto>) => void;
    checkedPlaces: Array<string>;
    checkPlace: (e: any, postoId: string) => void;
}

const PostiPage: FC<PostiPageProps> = (props) => {
    const { posti, alreadyAssignedPostiIds, assignPosto, setSortOptions, sortOptions, isPageLoading, checkAllPlaces, checkedPlaces, checkPlace } = props;

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={<b><FormattedMessage id="all" /></b>}
                                labelPlacement="end"
                                onChange={(e: any) => {
                                    checkAllPlaces(e, posti.items);
                                }}
                            />
                        </TableCell>
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="struttura"
                            title={<FormattedMessage id="structure" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="structureNumber"
                            title={<FormattedMessage id="structureNumber" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="numero"
                            title={<FormattedMessage id="placeNumber" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="intestazione"
                            title={<FormattedMessage id="placeHeader" />}
                        />
                        <TableCell> <FormattedMessage id="birthDate" /> </TableCell>
                        <TableCell> <FormattedMessage id="actions" /> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isPageLoading ?
                            (<TableRowsLoader rowsNum={10} colsNum={6} />) :
                            (<>
                                {posti && posti.items?.map((posto) => (
                                    <AlternateTableRow key={posto.id}>
                                        <TableCell>
                                            <Checkbox key={`check-service-${posto.id}`}
                                                checked={checkedPlaces.includes(posto.id)}
                                                onChange={(e: any) => checkPlace(e, posto.id)}
                                                disabled={alreadyAssignedPostiIds?.includes(posto.id)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {posto.steccaProvenienza?.struttura?.denominazione} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {posto.steccaProvenienza?.struttura?.numero} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {posto.numero} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item>
                                                {posto.proprietarioNome} {posto.proprietarioCognome}
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item>
                                                {getShortDate(posto.proprietarioDataNascita)}
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() => { assignPosto!(posto.id!) }}
                                                variant="outlined"
                                                disabled={alreadyAssignedPostiIds?.includes(posto.id!)}
                                                startIcon={<AddIcon />}
                                            >
                                                <FormattedMessage id="assignPlace" />
                                            </Button>
                                        </TableCell>
                                    </AlternateTableRow>
                                ))}
                            </>)
                    }

                </TableBody>
            </Table >
        </>
    );
};

export default PostiPage;
