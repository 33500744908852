/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';
import serviceConfig from '../ServiceConfig';
import { ServizioPosto } from 'types/servizioPosto';

export class ServizioPostoService {
    async GetServizioPostoById(servizioPostoId: string): Promise<ServizioPosto> {
        const result = await axios.post<ServizioPosto>(`${serviceConfig.appServiceBaseUrl}/GetServizioPosto`, servizioPostoId);
        return result.data;
    }

    async UpdateServizioPosto(servizioPosto: ServizioPosto): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpdateServizioPosto`, servizioPosto);
        return result.data;
    }

    async GetServiziPostoByIds(serviziPostoIds: string[]): Promise<Array<ServizioPosto>> {
        const result = await axios.post<Array<ServizioPosto>>(`${serviceConfig.appServiceBaseUrl}/GetServiziPosto`, serviziPostoIds);
        return result.data;
    }
}