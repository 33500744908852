import { FC } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage, useIntl } from 'react-intl';
import { Struttura } from 'types/struttura';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { PaginationResult } from 'types/paginationResult';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import ISortOptions from 'interfaces/ISortOptions';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import SearchIcon from '@mui/icons-material/Search';

interface StrutturePageProps {
    strutture: PaginationResult<Struttura>;
    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
    isPageLoading: boolean;

    userView?: boolean;
}

const StrutturePage: FC<StrutturePageProps> = (props) => {
    const { strutture, setSortOptions, sortOptions, isPageLoading, userView } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="denominazione"
                        title={<FormattedMessage id="denomination" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="numero"
                        title={<FormattedMessage id="number" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="zona"
                        title={<FormattedMessage id="zone" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="viale"
                        title={<FormattedMessage id="block" />}
                    />
                    {!userView && <TableCell> <FormattedMessage id="actions" /> </TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    isPageLoading ?
                        (<TableRowsLoader rowsNum={10} colsNum={5} />) :
                        (<>
                            {strutture && strutture.items?.map((struttura) => (
                                <AlternateTableRow key={struttura.id}>
                                    <TableCell>
                                        <Grid item> {struttura.denominazione} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {struttura.numero} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {struttura.zona?.descrizione} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {struttura.viale?.descrizione} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            !userView &&
                                            <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                                <IconButton component={RouterLink} to={`edit?strutturaId=${struttura.id}`}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </TableCell>
                                </AlternateTableRow>
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
};

export default StrutturePage;
