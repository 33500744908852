import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

const Loading = () => (
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
    >
        <CircularProgress sx={{ color: "#5e35b1" }} />
    </Box>
);

export default Loading;
