import { Box, Button, Card, CardContent, Grid, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SteccaService } from "services/SteccaServices";
import { Stecca, defaultStecca } from "types/stecca";
import BackdropLoader from "ui-component/BackdropLoader";
import { composeNotistackMessage, getFormValue } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { number, object, string } from "yup";

interface SteccaUpsertProps {
	isAdd: boolean;
	strutturaId?: string;
	steccaToUpdate?: Stecca;
	updateStecche?: () => void;
	updateSteccaToHandle?: (stecca: Stecca) => Promise<void>;
}

const SteccaUpsert: FC<SteccaUpsertProps> = (props) => {
	const { isAdd, strutturaId, steccaToUpdate, updateStecche, updateSteccaToHandle } = props;

	const intl = useIntl()

	const steccaService = new SteccaService();
	const [stecca, setStecca] = useState<Stecca>(steccaToUpdate ?? defaultStecca);

	let steccaValidationSchema = object({
		codice: string().required('codeRequired'),
		denominazione: string().required('denominationRequired'),
		numRighe: number().min(1, 'rowsNumberMinOne').typeError('rowsNumberMustBeNumber'),
		numColonne: number().min(1, 'colsNumberMinOne').typeError('colsNumberMustBeNumber'),
		numeroIniziale: number().typeError('initialNumberMustBeNumber').nullable(true).default(null)
	});

	useEffect(() => {
		(async () => {
			if (isAdd) {
				setStecca((currentFormData: any) => ({ ...currentFormData, strutturaId: strutturaId }));
			}
		}
		)();
	}, [])

	const handleChange = (e: any) => {
		let { name, value } = getFormValue(e);
		setStecca((currentFormData: any) => ({ ...currentFormData, [name]: value }));
	}

	const [isLoadingSteccaAdd, setIsLoadingSteccaAdd] = useState<boolean>(false);

	const steccaUpsert = async () => {
		setIsLoadingSteccaAdd(true);

		try {
			await steccaValidationSchema.validate(stecca, { abortEarly: false });
			await steccaService.UpsertStecca(stecca);

			if (isAdd) resetStecca();
			else updateSteccaToHandle!(stecca);

			updateStecche!();

			composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');

			setIsLoadingSteccaAdd(false);
		} catch (validationErrors: any) {
			handleUpsertErrors(validationErrors.inner, intl);
		} finally {
			setIsLoadingSteccaAdd(false);
		}
	}

	const resetStecca = () => {
		setStecca({ ...defaultStecca, strutturaId: strutturaId });
	}

	return (
		<Card>
			<BackdropLoader open={isLoadingSteccaAdd} />

			<CardContent>
				<Grid container spacing={1}>
					<Grid
						item
						lg={2}
						md={2}
						xs={12}
					>
						<TextField
							fullWidth
							size='small'
							label={<FormattedMessage id="code" />}
							name="codice"
							required
							onChange={handleChange}
							value={stecca.codice}
							disabled={!isAdd}
						/>
					</Grid>
					<Grid
						item
						lg={4}
						md={4}
						xs={12}
					>
						<TextField
							fullWidth
							size='small'
							label={<FormattedMessage id="denomination" />}
							name="denominazione"
							required
							onChange={handleChange}
							value={stecca.denominazione}
							disabled={!isAdd}
						/>
					</Grid>
					<Grid
						item
						lg={2}
						md={2}
						xs={6}
					>
						<TextField
							fullWidth
							size='small'
							label={<FormattedMessage id="rowsNumber" />}
							name="numRighe"
							required
							onChange={handleChange}
							value={stecca.numRighe}
							type="number"
							disabled={!isAdd}
						/>
					</Grid>
					<Grid
						item
						lg={2}
						md={2}
						xs={6}
					>
						<TextField
							fullWidth
							size='small'
							label={<FormattedMessage id="columnsNumber" />}
							name="numColonne"
							required
							onChange={handleChange}
							value={stecca.numColonne}
							type="number"
							disabled={!isAdd}
						/>
					</Grid>
					{
						isAdd && <Grid
							item
							lg={2}
							md={2}
							xs={6}
						>
							<TextField
								fullWidth
								size='small'
								label={<FormattedMessage id="startingNumber" />}
								name="numeroIniziale"
								onChange={handleChange}
								value={stecca.numeroIniziale}
								type="number"
								InputProps={{ inputProps: { min: 0 } }}
							/>
						</Grid>
					}
				</Grid>
			</CardContent>
			{
				isAdd &&
				<Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						color="primary"
						type="submit"
						variant="contained"
						onClick={steccaUpsert}
					>
						<FormattedMessage id="add" />
					</Button>
				</Box>
			}
		</Card>
	)
};

export default SteccaUpsert;
