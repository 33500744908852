import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import AlternateTableRow from "ui-component/AlternateTableRow";
import { getCurrencyByNumber, getShortDate } from "utils/common";
import DownloadIcon from '@mui/icons-material/Download';
import { Fattura } from "types/fattura";

interface InvoicesListProps {
    fatture: Fattura[];
    downloadFattura: (fatturaId: string, fatturaName: string) => void;
}

const InvoicesList: FC<InvoicesListProps> = (props) => {
    const { fatture, downloadFattura } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell align="left"> <FormattedMessage id="invoiceNumber" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="amount" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="creationDate" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="actions" /></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    fatture.length > 0 &&
                    fatture &&
                    fatture.map((fattura: Fattura) => (
                        <AlternateTableRow>
                            <TableCell align="left">{fattura.numero}</TableCell>
                            <TableCell align="left">{getCurrencyByNumber(fattura.importoTotale)}</TableCell>
                            <TableCell align="left">{getShortDate(fattura.data)}</TableCell>
                            <TableCell align="left">
                                <Tooltip title={<FormattedMessage id="download" />} aria-label="Download">
                                    <IconButton onClick={() => downloadFattura(fattura.id!, `invoice-${fattura.numero}.pdf`)}>
                                        <DownloadIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </AlternateTableRow>
                    ))
                }
            </TableBody>
        </Table>
    );
}

export default InvoicesList;