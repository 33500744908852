import { Card, CardContent, CardHeader, Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, debounce } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PraticaService } from "services/PraticaServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { DossierReport } from "types/dossierReport";
import AlternateTableRow from "ui-component/AlternateTableRow";
import TableRowsLoader from "ui-component/TableRowsLoader";
import ClearIcon from '@mui/icons-material/Clear';
import { getCurrencyByNumber, getFormValue, getShortDate } from "utils/common";
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';
import { MoreVert } from "@mui/icons-material";

interface PraticaReportWithMenuProps {
    praticaReport: DossierReport,
}

const PraticaReportWithMenu: FC<PraticaReportWithMenuProps> = (props) => {
    const { praticaReport } = props;

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={praticaReport.id}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="date" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getShortDate(praticaReport.date)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="series" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {praticaReport.series} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="number" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {praticaReport.number} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="owner" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {praticaReport.owner} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="totalToPay" /> (€) </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getCurrencyByNumber(praticaReport.totalToPay)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="totalPayed" /> (€) </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getCurrencyByNumber(praticaReport.totalPayed)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="balance" /> (€) </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getCurrencyByNumber(praticaReport.balance)} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`praticaReport-actions-menu-${praticaReport.id}`}
                    MenuListProps={{
                        'aria-labelledby': `praticaReport-action-button-${praticaReport.id}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem key={'edit'} onClick={handleClose}>
                        <IconButton
                            component={RouterLink}
                            to={`/dossiers/edit?dossierId=${praticaReport.id}`}
                        >
                            <EditIcon fontSize="small" sx={{ mr: '3px' }} />
                            <Typography variant="subtitle1"> <FormattedMessage id="edit" /> </Typography>
                        </IconButton>
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
}

interface PraticheReportPageMobileProps {
    praticheReport: PaginationResult<DossierReport>;

    isPageLoading: boolean;
}

const PraticheReportPageMobile: FC<PraticheReportPageMobileProps> = (props) => {
    const { praticheReport, isPageLoading } = props;

    return (
        <Table>
            <TableBody>
                {
                    isPageLoading ? (
                        <TableRowsLoader rowsNum={3} colsNum={1} />
                    ) :
                        (<>
                            {praticheReport && praticheReport.items?.map((praticaReport) => (
                                <PraticaReportWithMenu
                                    key={praticaReport.id}
                                    praticaReport={praticaReport}
                                />
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
}

export default PraticheReportPageMobile;