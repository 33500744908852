export interface Servizio {
    id?: string;
    codice?: string;
    descrizione: string;
    prezzo: number;
    aliquota: number;
    annuale: boolean;
    ricorrente: boolean;
    importo?: string;

    tenantId?: string;
    dateOfInsert?: string;
    isValid?: boolean;
}

export const defaultServizio: Servizio = {
    descrizione: '',
    prezzo: 0,
    aliquota: 0,
    annuale: false,
    ricorrente: false,
}