declare global {
  interface Window {
    odevConfig: any;
  }
}
const config = window.odevConfig;

interface ServiceConfig {
  appAuthDomain: string;
  appClientId: string;
  appAuthRealm: string;
  environmentName: string;
  appServiceBaseUrl: string;
  aiInstrumentationKey: string;
  appTokenHint: string;
  appPostLogoutRedirectUri: string;
  apiKey: string;
}

const appServiceBaseUrl: string = config?.AppServiceBaseUrl || process.env.REACT_APP_SERVICE_BASE_URL || "";
const appAuthDomain: string = config?.AppAuthDomain || process.env.REACT_APP_AUTH_DOMAIN || "";
const appAuthRealm: string = config?.AppAuthRealm || process.env.REACT_APP_AUTH_REALM || "";
const appClientId: string = config?.AppClientId || process.env.REACT_APP_CLIENT_ID || "";
const environmentName: string = config?.EnvironmentName || process.env.REACT_APP_ENVIRONMENT_NAME || "";
const aiInstrumentationKey: string = config?.AiInstrumentationKey || process.env.AI_INSTRUMENTATION_KEY || "";
const appTokenHint: string = config?.AppTokenHint || process.env.REACT_APP_TOKEN_HINT || "";
const appPostLogoutRedirectUri: string = config?.AppPostLogoutRedirectUri || process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI || "";
const apiKey: string = config?.ApiKey || process.env.REACT_APP_API_KEY || "";

if (!appServiceBaseUrl) throw new Error("Missing env REACT_APP_SERVICE_BASE_URL or config value 'appServiceBaseUrl'");

const serviceConfig: ServiceConfig = {
  appServiceBaseUrl: `${appServiceBaseUrl}/api`,
  appAuthDomain: appAuthDomain,
  appAuthRealm: appAuthRealm,
  appClientId: appClientId,
  environmentName: environmentName,
  aiInstrumentationKey: aiInstrumentationKey,
  appTokenHint: appTokenHint,
  appPostLogoutRedirectUri: appPostLogoutRedirectUri,
  apiKey: apiKey,
};

export default serviceConfig;
