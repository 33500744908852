import { Table, TableRow, TableCell, TableBody, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { PostoPraticaData } from "types/postoPraticaData";

interface PlacesListMobileProps {
    dossierPlaces: PostoPraticaData[];
}


const PlacesListMobile: FC<PlacesListMobileProps> = (props) => {
    const { dossierPlaces } = props;

    return (
        <Table size="small">
            <TableBody>
                {
                    dossierPlaces &&
                    dossierPlaces.length > 0 &&
                    dossierPlaces.map((dossierPlace: PostoPraticaData) => (
                        <TableRow>
                            <TableCell>
                                <Grid container direction="column">
                                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                        <Grid item>
                                            <Typography variant="h6" align="center"> <FormattedMessage id="structure" /> </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" align="center"> {dossierPlace?.strutturaDenominazione} </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                        <Grid item>
                                            <Typography variant="h6" align="center"> <FormattedMessage id="placeNumber" /> </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" align="center"> {dossierPlace?.postoNumero} </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                        <Grid item>
                                            <Typography variant="h6" align="center"> <FormattedMessage id="ownerSurname" /> </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" align="center"> {dossierPlace.postoProprietarioCognome} </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                        <Grid item>
                                            <Typography variant="h6" align="center"> <FormattedMessage id="ownerName" /> </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" align="center"> {dossierPlace.postoProprietarioNome} </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    )
                    )}
            </TableBody>
        </Table>
    )
}

export default PlacesListMobile;