import { FC, useEffect, useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage } from 'react-intl';
import { Utente } from 'types/utente';
import { PaginationResult } from 'types/paginationResult';
import { MoreVert } from '@mui/icons-material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { isNotNullOrUndefined } from 'utils/common';

interface UserWithMenuProps {
    user: Utente;

    fromExternal: boolean;
    assignUserButtonText?: string;
    alreadyAssignedUsersIds?: Array<string>;
    assignUser?: (userId: string) => void;

    setupQrCodeCommunicationGeneration: (userId: string) => void;
}

const UserWithMenu: FC<UserWithMenuProps> = (props) => {
    const { user, fromExternal, assignUserButtonText, alreadyAssignedUsersIds, assignUser, setupQrCodeCommunicationGeneration } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={user.id}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="surname" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {user.cognome} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="name" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {user.nome} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="municipality" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {user.comune} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="address" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {user.indirizzo} {user.civico} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="fiscalCode" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {user.codiceFiscale} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`user-actions-menu-${user.id}`}
                    MenuListProps={{
                        'aria-labelledby': `user-action-button-${user.id}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    {fromExternal ? (
                        <MenuItem key={'assign'} onClick={handleClose} disabled={alreadyAssignedUsersIds?.includes(user.id!)}>
                            <IconButton onClick={() => { assignUser!(user.id!) }}>
                                <PersonAddIcon fontSize="small" sx={{ mr: '3px' }} />
                                <Typography variant="subtitle1"> <FormattedMessage id={assignUserButtonText} /> </Typography>
                            </IconButton>
                        </MenuItem>
                    ) : (
                        <>
                            <MenuItem key={'edit'} onClick={handleClose}>
                                <IconButton
                                    component={RouterLink}
                                    to={`edit?userId=${user.id}`}
                                >
                                    <EditIcon fontSize="small" sx={{ mr: '3px' }} />
                                    <Typography variant="subtitle1"> <FormattedMessage id="edit" /> </Typography>
                                </IconButton>
                            </MenuItem>
                            {
                                !isNotNullOrUndefined(user.mail) && !isNotNullOrUndefined(user.cellulare) && user.showQrCodeCommunicationButton === true &&
                                <MenuItem key={'generateQrCodeCommunication'} onClick={handleClose}>
                                    <IconButton onClick={() => setupQrCodeCommunicationGeneration(user.id!)}>
                                        <QrCode2Icon fontSize="small" sx={{ mr: '3px' }} />
                                        <Typography variant="subtitle1"> <FormattedMessage id="generateQrCodeCommunication" /> </Typography>
                                    </IconButton>
                                </MenuItem>
                            }
                        </>
                    )}
                </Menu>
            </TableCell>
        </TableRow>
    );
};

interface UsersPageMobileProps {
    users: PaginationResult<Utente>

    fromExternal: boolean;
    assignUserButtonText?: string;
    alreadyAssignedUsersIds?: Array<string>;
    assignUser?: (userId: string) => void;

    isPageLoading: boolean;

    setupQrCodeCommunicationGeneration: (userId: string) => void;
}

const UsersPageMobile: FC<UsersPageMobileProps> = (props) => {
    const { users, fromExternal, assignUserButtonText, alreadyAssignedUsersIds, assignUser, isPageLoading, setupQrCodeCommunicationGeneration } = props;

    return (
        <>
            <Table>
                <TableBody>
                    {
                        isPageLoading ? (
                            <TableRowsLoader rowsNum={3} colsNum={1} />
                        ) :
                            (<>
                                {users && users.items?.map((user) => (
                                    <UserWithMenu
                                        key={user.id}
                                        user={user}
                                        fromExternal={fromExternal}
                                        assignUserButtonText={assignUserButtonText}
                                        alreadyAssignedUsersIds={alreadyAssignedUsersIds}
                                        assignUser={assignUser}
                                        setupQrCodeCommunicationGeneration={setupQrCodeCommunicationGeneration}
                                    />
                                ))}
                            </>)
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default UsersPageMobile;
