import { Box, Button, Card, CardContent, CardHeader, Grid, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Utente } from "types/utente";
import { composeNotistackMessage, getFormValue, isNotNullOrUndefined } from "utils/common";
import InitialDataStep from "./StepperContents/InitialDataStep";
import HeaderStep from "./StepperContents/HeaderStep";
import UploadFileStep from "./StepperContents/UploadFileStep";
import { Pratica, defaultPratica } from "types/pratica";
import PlacesStep from "./StepperContents/PlacesStep";
import InvoicesStep from "./StepperContents/InvoicesStep";
import { PraticaService } from "services/PraticaServices";
import CardContentLoader from "ui-component/CardContentLoader";
import { Allegato } from "types/allegato";
import { handleUpsertErrors } from "utils/validation";
import { object, string } from "yup";
import { pdfjs } from 'react-pdf';
import { TenantDocument } from "types/tenantDocument";
import { TenantService } from "services/TenantServices";
import { useNavigate } from "react-router-dom";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import BackdropLoader from "ui-component/BackdropLoader";
import dayjs from "dayjs";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

const PraticaUpdate = () => {
    const intl = useIntl();

    const navigate = useNavigate();

    pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

    const tabs = ['initialData', 'header', 'attachments', 'placesAndServices', 'invoices'];
    const [tabIndex, setTabIndex] = useState<number>(0);
    const a11yProps = (index: number) => {
        return {
            id: `dossier-tab-${index}`,
            'aria-controls': `dossier-tabpanel-${index}`,
        };
    }

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    const praticaService = new PraticaService();
    const tenantService = new TenantService();

    const [pratica, setPratica] = useState<Pratica>(defaultPratica);
    const [assignedUser, setAssignedUser] = useState<Utente | undefined>(undefined);
    const [files, setFiles] = useState<Array<Allegato>>([]);

    const [tenantPreliminari, setTenantPreliminari] = useState<Array<TenantDocument>>([]);

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            let praticaId: string = window.location.href.split('dossierId=')[1] ?? undefined;
            if (praticaId !== undefined) {
                praticaId = praticaId.split('&')[0] ?? ''
            }

            const retrievedPratica: Pratica = await praticaService.GetPraticaById(praticaId);
            setPratica(retrievedPratica);

            const retrievedPraticaHolder: Utente = await praticaService.GetPraticaHolder(praticaId);
            setAssignedUser(retrievedPraticaHolder);

            const retrievedAllegati: Array<Allegato> = await praticaService.GetPraticaAllegati(praticaId);
            setFiles(retrievedAllegati);

            const tenantPreliminari: Array<TenantDocument> = await tenantService.GetTenantDossierDocuments();
            setTenantPreliminari(tenantPreliminari);

            let userId: string = window.location.href.split('userId=')[1] ?? undefined;
            if (userId !== undefined) {
                userId = userId.split('&')[0] ?? '';
            }

            if (isNotNullOrUndefined(userId)) {
                (async () => {
                    await assignUser(userId, praticaId);
                })()
            }

            setIsPageLoading(false);
        })();
    }, [])

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setPratica((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const handleDateChange = (fieldName: string, date: any) => {
        let newValue: string | null;

        newValue = dayjs(date).format('YYYY-MM-DD');

        newValue = newValue == 'Invalid Date' ? null : newValue;

        setPratica((currentFormData: any) => ({ ...currentFormData, [fieldName]: newValue }));
    }

    const assignUser = async (userId: string, praticaId?: string) => {
        const praticaIdToUse: string | undefined = isNotNullOrUndefined(praticaId) ? praticaId : pratica.id;
        await praticaService.AssignUserToPratica({ praticaId: praticaIdToUse, userId: userId });
        let retrievedPraticaHolder: Utente = await praticaService.GetPraticaHolder(praticaIdToUse!);
        setAssignedUser(retrievedPraticaHolder);
    }

    const addDocument = async (newFileName: string, newFileBase64: string) => {
        setIsLoadingFileUpload(true);

        try {
            await praticaService.InsertAllegatoToPratica({
                praticaId: pratica.id,
                nomeFile: newFileName,
                allegatoBase64: newFileBase64
            });

            let retrievedAllegati: Array<Allegato> = await praticaService.GetPraticaAllegati(pratica.id!);
            setFiles(retrievedAllegati);
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsLoadingFileUpload(false);
        }
    }

    const handleUploadFile = async (e: any) => {
        var fileReader: FileReader = new FileReader();
        let newFileName = e.target.files[0].name;
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = async () => {
            let newFileBase64: string = fileReader.result as string;
            await addDocument(newFileName, newFileBase64);
        }
    }

    const handleDeleteFile = async (allegatoId: string) => {
        await praticaService.DeleteAllegatoFromPratica(allegatoId);
        setFiles(files.filter((file) => file.id !== allegatoId));
    }

    let praticaValidationSchema = object({
        numeratoreId: string().required('serieRequired'),
        numero: string().required('numberRequired'),
    });

    const updatePratica = async () => {
        try {
            await praticaValidationSchema.validate(pratica, { abortEarly: false });

            await praticaService.UpdatePratica(pratica);

            composeNotistackMessage(intl.formatMessage({ id: 'dossierUpdatedSuccessfully' }), 'success');
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        }
    }

    const deletePratica = async () => {
        try {
            await praticaService.DeletePraticaById(pratica.id!);

            composeNotistackMessage(intl.formatMessage({ id: 'successfullyDelete' }), 'success');

            navigate('/dossiers');
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        }
    };

    const getTabContent = () => {
        switch (tabIndex) {
            case 0:
                return (<>
                    <InitialDataStep pratica={pratica} handleChangePratica={handleChange} handleDateChangePratica={handleDateChange} />
                    <Grid container>
                        <Grid item
                            xs={6}
                            md={6}
                            lg={6}
                        >
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                                <Button
                                    color="error"
                                    type="submit"
                                    variant="contained"
                                    onClick={() => setDeletePraticaDialogOpen(true)}
                                    startIcon={<DeleteIcon />}
                                >
                                    <FormattedMessage id="delete" />
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item
                            xs={6}
                            md={6}
                            lg={6}
                        >
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    onClick={updatePratica}
                                    startIcon={<SaveIcon />}
                                >
                                    <FormattedMessage id="save" />
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </>);
            case 1:
                return <HeaderStep assignedUser={assignedUser} assignUserToEntity={assignUser} isAdd={false} dossierId={pratica.id} />;
            case 2:
                return <UploadFileStep
                    files={files}
                    handleUploadFile={handleUploadFile}
                    handleDeleteFile={handleDeleteFile}
                    fileInEditId={fileInEditId}
                    newFileName={newFileName}
                    handleCloseEditName={handleCloseEditName}
                    handleConfirmEditName={handleConfirmEditName}
                    handleUpdateFileInEditId={handleUpdateFileInEditId}
                    handleUpdateNewFileName={handleUpdateNewFileName}
                />;
            case 3:
                return <PlacesStep pratica={pratica} assignedUser={assignedUser} tenantDocuments={tenantPreliminari} addDocumentToDossier={addDocument} />;
            case 4:
                return <InvoicesStep praticaId={pratica.id!} />;
        }
    }

    const [deletePraticaDialogOpen, setDeletePraticaDialogOpen] = useState<boolean>(false);

    const [isLoadingFileUpload, setIsLoadingFileUpload] = useState<boolean>(false);

    const [fileInEditId, setFileInEditId] = useState<string>('');

    const [newFileName, setNewFileName] = useState<string>('');

    const handleCloseEditName = () => {
        setFileInEditId('');
        setNewFileName('');
    }

    const handleUpdateFileInEditId = (fileId: string) => {
        setFileInEditId(fileId);
    }

    const handleUpdateNewFileName = (newFileName: string) => {
        setNewFileName(newFileName);
    }

    const handleConfirmEditName = async () => {
        setIsPageLoading(true);

        try {
            await praticaService.RenameAllegatoPratica({
                allegatoId: fileInEditId,
                newAllegatoName: newFileName
            });

            composeNotistackMessage(intl.formatMessage({ id: 'fileRenamedSuccessfully' }), 'success');
            handleCloseEditName();

            const retrievedAllegati: Array<Allegato> = await praticaService.GetPraticaAllegati(pratica.id!);
            setFiles(retrievedAllegati);

        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsPageLoading(false);
        }
    }

    return (
        <Card>
            <BackdropLoader open={isLoadingFileUpload} />

            <DialogDeleteElement
                open={deletePraticaDialogOpen}
                onClose={() => setDeletePraticaDialogOpen(false)}
                onCancel={() => setDeletePraticaDialogOpen(false)}
                onConfirm={() => {
                    setDeletePraticaDialogOpen(false)
                    deletePratica();
                }}
                entityToDeleteInfo={pratica?.numero ?? ''}
            />
            <CardHeader title={`${intl.formatMessage({ id: 'dossier' })}: ${pratica?.numero ?? ''}`} />
            <Tabs value={tabIndex} onChange={(event: any, newTabIndex: number) => { setTabIndex(newTabIndex) }} aria-label="Dossier tabs" variant="scrollable" scrollButtons="auto">
                {
                    tabs.map((tab, index) => (
                        <Tab
                            label={<FormattedMessage id={tab} />}
                            {...a11yProps(index)}
                        />
                    ))
                }
            </Tabs>
            {
                isPageLoading ?
                    <CardContentLoader /> :
                    <CardContent>
                        {
                            getTabContent()
                        }
                    </CardContent >
            }
        </Card >
    );
}

export default PraticaUpdate;