import { FC, useEffect, useRef } from "react";
import { PaymentRedirectData } from "types/paymentRedirectData";

interface PaymentRedirectPageProps {
    paymentRedirectData: PaymentRedirectData;
    resetPaymentRedirectData: () => void;
}

const PaymentRedirectPage: FC<PaymentRedirectPageProps> = (props) => {
    const { paymentRedirectData, resetPaymentRedirectData } = props;
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.submit();
            resetPaymentRedirectData();
        }
    }, [formRef])

    return (
        <form ref={formRef} method="post" action={paymentRedirectData.formActionValue}>
            <input type="hidden" name="codTrans" value={paymentRedirectData.codTrans} />
            <input type="hidden" name="url_back" value={paymentRedirectData.urlBack} />
            <input type="hidden" name="divisa" value={paymentRedirectData.divisa} />
            <input type="hidden" name="importo" value={paymentRedirectData.importo} />
            <input type="hidden" name="Referer" value="" />
            <input type="hidden" name="alias" value={paymentRedirectData.alias} />
            <input type="hidden" name="mac" value={paymentRedirectData.mac} />
            <input type="hidden" name="url" value={paymentRedirectData.url} />
            <input type="hidden" name="merchantnumber" value={paymentRedirectData.merchantNumber} />
            <input type="hidden" name="pathGpo" value={paymentRedirectData.pathGpo} />
            <input type="hidden" name="mioReferer" value="" />
            <input type="hidden" name="dispatchingSequence" value={paymentRedirectData.dispatchingSequence} />
        </form>
    )
}

export default PaymentRedirectPage;