// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import invoicesServicesIdsReducer from './slices/invoicesServicesIdsSlice';
import invoicesPaymentsIdsReducer from './slices/invoicesPaymentsIdsSlice';
import loggedUserReducer from './slices/loggedUserSlice';
import loggedUserIsAdminReducer from './slices/loggedUserIsAdminSlice';
import loggedUserIsSuperAdminReducer from './slices/loggedUserIsSuperAdminSlice';
import showTenantChangeReducer from './slices/showTenantChangeSlice';
import splitDossierPlacesDossierReducer from './slices/splitDossierPlacesDossiersSlice';
import newUserIdToAssignReducer from './slices/newUserIdToAssignSlice';
import recordedPaymentsReducer from './slices/recordedPaymentsSlice';
import qrCodeCommunicationsFileIdReducer from './slices/qrCodeCommunicationsFileIdSlice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    invoicesServicesIds: invoicesServicesIdsReducer,
    invoicesPaymentsIds: invoicesPaymentsIdsReducer,
    loggedUser: loggedUserReducer,
    loggedUserIsAdmin: loggedUserIsAdminReducer,
    loggedUserIsSuperAdmin: loggedUserIsSuperAdminReducer,
    splittedDossierPlaceDossier: splitDossierPlacesDossierReducer,
    newUserIdToAssign: newUserIdToAssignReducer,
    showTenantChange: showTenantChangeReducer,
    recordedPayments: recordedPaymentsReducer,
    qrCodeCommunicationsFileId: qrCodeCommunicationsFileIdReducer,
});

export default reducer;
