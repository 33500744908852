import { createSlice } from '@reduxjs/toolkit';

interface LoggedUserIsSuperAdminState {
    loggedUserIsSuperAdmin: boolean | undefined;
}

export const loggedUserIsSuperAdminSlice = createSlice({
    name: 'loggedUserIsSuperAdminSlice',
    initialState: {
        loggedUserIsSuperAdmin: undefined
    } as LoggedUserIsSuperAdminState,
    reducers: {
        setLoggedUserIsSuperAdmin(state, action) {
            state.loggedUserIsSuperAdmin = action.payload;
        }
    }
});

export const { setLoggedUserIsSuperAdmin } = loggedUserIsSuperAdminSlice.actions;

export default loggedUserIsSuperAdminSlice.reducer;