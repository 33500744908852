import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { PostoPraticaData } from "types/postoPraticaData";
import { UserPaymentToDo } from "types/userPaymentToDo";
import AlternateTableRow from "ui-component/AlternateTableRow";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { getWithTwoDecimal } from "utils/common";

interface PaymentsToDoListProps {
    servicesToPay: UserPaymentToDo[];
    dossierPlaces: PostoPraticaData[];
    executePayment: (amountToPay: string, placeServiceId: string) => void;
}

const PaymentsToDoList: FC<PaymentsToDoListProps> = (props) => {
    const { servicesToPay, dossierPlaces, executePayment } = props;

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell align="left"> <FormattedMessage id="structure" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="placeNumber" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="description" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="date" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="year" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="totalAmount" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="payed" /></TableCell>
                    <TableCell align="left"> <FormattedMessage id="actions" /></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    servicesToPay &&
                    servicesToPay.length > 0 &&
                    servicesToPay.map((userPaymentToDo: UserPaymentToDo) => {
                        const dossierPlace = dossierPlaces.find((dossierPlace: PostoPraticaData) => dossierPlace.postoPraticaId === userPaymentToDo.postoPraticaAssociatoId);
                        return (
                            <AlternateTableRow>
                                <TableCell align="left">
                                    {dossierPlace?.strutturaDenominazione}
                                </TableCell>
                                <TableCell align="left">
                                    {dossierPlace?.postoNumero}
                                </TableCell>
                                <TableCell align="left">{userPaymentToDo.descrizione}</TableCell>
                                <TableCell align="left">{userPaymentToDo.data}</TableCell>
                                <TableCell align="left">{userPaymentToDo.year}</TableCell>
                                <TableCell align="left">{userPaymentToDo.importo * userPaymentToDo.quantita}</TableCell>
                                <TableCell align="left">{userPaymentToDo.pagato}</TableCell>
                                <TableCell align="left">
                                    <Tooltip title={<FormattedMessage id="pay" />} aria-label="Pay">
                                        <IconButton onClick={() => {
                                            executePayment(getWithTwoDecimal(userPaymentToDo.importo - userPaymentToDo.pagato!), userPaymentToDo.placeServiceId);
                                        }}>
                                            <AttachMoneyIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </AlternateTableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    );
}

export default PaymentsToDoList;