/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { PaginationQuery } from 'types/paginationQuery';
import { PaginationResult } from 'types/paginationResult';
import { SerieNumeratore } from 'types/serieNumeratore';

export class SerieNumeratoreService {
    async GetSerieNumeratori(paginationQuery: PaginationQuery): Promise<PaginationResult<SerieNumeratore>> {
        const result = await axios.post<PaginationResult<SerieNumeratore>>(`${serviceConfig.appServiceBaseUrl}/GetSerieNumeratori`, paginationQuery);
        return result.data;
    }

    async DeleteSerieNumeratore(serieNumeratoreId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteSerieNumeratore`, serieNumeratoreId);
        return result.data;
    }

    async GetSerieNumeratoreById(serieNumeratoreId: string): Promise<SerieNumeratore> {
        const result = await axios.post<SerieNumeratore>(`${serviceConfig.appServiceBaseUrl}/GetSerieNumeratore`, serieNumeratoreId);
        return result.data;
    }

    async UpsertSerieNumeratore(serieNumeratore: SerieNumeratore): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpsertSerieNumeratore`, serieNumeratore);
        return result.data;
    }
}