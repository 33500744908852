import { createSlice } from '@reduxjs/toolkit';

export const qrCodeCommunicationsFileIdSlice = createSlice({
    name: 'qrCodeCommunicationsFileIdSlice',
    initialState: {
        qrCodeCommunicationsFileId: ''
    } ,
    reducers: {
        setQrCodeCommunicationsFileId(state, action) {
            state.qrCodeCommunicationsFileId = action.payload;
        }
    }
});

export const { setQrCodeCommunicationsFileId } = qrCodeCommunicationsFileIdSlice.actions;

export default qrCodeCommunicationsFileIdSlice.reducer;