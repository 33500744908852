import { FC, useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage } from 'react-intl';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { PaginationResult } from 'types/paginationResult';
import { Fattura } from 'types/fattura';
import { MoreVert } from '@mui/icons-material';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { getCurrencyByNumber, getShortDate } from 'utils/common';
import { IconFiles } from '@tabler/icons';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';

interface FatturaWithMenuProps {
    fattura: Fattura;
    downloadFattura: (fatturaId: string, fatturaName: string) => void;
    handleEInvoiceToSend: (invoiceId: string, invoiceNumber: string) => void;

    updateEntityToRemoveData: (dataToRemove: { id: string, name: string }) => void;
}

const FatturaWithMenu: FC<FatturaWithMenuProps> = (props) => {
    const { fattura, downloadFattura, handleEInvoiceToSend, updateEntityToRemoveData } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={fattura.id}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="series" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {fattura.serie} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="number" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {fattura.numero} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="date" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getShortDate(fattura.data)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="surname" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {fattura.cognome} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="name" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {fattura.nome} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="municipality" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {fattura.comune} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="fiscalCode" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {fattura.codiceFiscale} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="totalAmount" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getCurrencyByNumber(fattura.importoTotale)} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`fattura-actions-menu-${fattura.id}`}
                    MenuListProps={{
                        'aria-labelledby': `fattura-action-button-${fattura.id}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem key={'open-dossier'} onClick={handleClose}>
                        <IconButton onClick={() => navigate(`/dossiers/edit?dossierId=${fattura.praticaAssociataId}`)}>
                            <IconFiles fontSize="small" />
                            <Typography variant="subtitle1"> <FormattedMessage id="dossier" /> </Typography>
                        </IconButton>
                    </MenuItem>
                    <MenuItem key={'download'} onClick={handleClose}>
                        <IconButton onClick={() => downloadFattura(fattura.id!, `invoice-${fattura.nome}_${fattura.cognome}.pdf`)}>
                            <DownloadIcon fontSize="small" />
                            <Typography variant="subtitle1"> <FormattedMessage id="download" /> </Typography>
                        </IconButton>
                    </MenuItem>
                    <MenuItem key={'delete'} onClick={() => {
                        handleClose();
                        updateEntityToRemoveData({
                            id: fattura.id!,
                            name: fattura.numero!
                        })
                    }}>
                        <IconButton>
                            <DeleteTwoTone fontSize="small" sx={{ mr: '3px' }} />
                            <Typography variant="subtitle1"> <FormattedMessage id="delete" /> </Typography>
                        </IconButton>
                    </MenuItem>
                    <MenuItem key={'send'} onClick={handleClose}>
                        <IconButton onClick={() => handleEInvoiceToSend(fattura.id!, fattura?.numero ?? '')}>
                            <SendIcon fontSize="small" sx={{ mr: '3px' }} />
                            <Typography variant="subtitle1"> <FormattedMessage id="sendElectronicInvoice" /> </Typography>
                        </IconButton>
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
}

interface FatturePageMobileProps {
    fatture: PaginationResult<Fattura>;
    downloadFattura: (fatturaId: string, fatturaName: string) => void;
    handleEInvoiceToSend: (invoiceId: string, invoiceNumber: string) => void;

    isPageLoading: boolean;
    updateEntityToRemoveData: (dataToRemove: { id: string, name: string }) => void;
}

const FatturePageMobile: FC<FatturePageMobileProps> = (props) => {
    const { fatture, downloadFattura, handleEInvoiceToSend, isPageLoading, updateEntityToRemoveData } = props;

    return (<>
        <Table>
            <TableBody>
                {
                    isPageLoading ? (
                        <TableRowsLoader rowsNum={3} colsNum={1} />
                    ) :
                        (<>
                            {fatture && fatture.items?.map((fattura) => (
                                <FatturaWithMenu
                                    key={fattura.id}
                                    fattura={fattura}
                                    downloadFattura={downloadFattura}
                                    handleEInvoiceToSend={handleEInvoiceToSend}

                                    updateEntityToRemoveData={updateEntityToRemoveData}
                                />
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    </>);
};

export default FatturePageMobile;
