import { FC } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage } from 'react-intl';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { PaginationResult } from 'types/paginationResult';
import { Fattura } from 'types/fattura';
import ISortOptions from 'interfaces/ISortOptions';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { getCurrencyByNumber, getShortDate } from 'utils/common';
import { IconFiles } from '@tabler/icons';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';

interface FatturePageProps {
    fatture: PaginationResult<Fattura>;
    downloadFattura: (fatturaId: string, fatturaName: string) => void;
    handleEInvoiceToSend: (invoiceId: string, invoiceNumber: string) => void;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
    isPageLoading: boolean;

    updateEntityToRemoveData: (dataToRemove: { id: string, name: string }) => void;
}

const FatturePage: FC<FatturePageProps> = (props) => {
    const { fatture, downloadFattura, handleEInvoiceToSend, setSortOptions, sortOptions, isPageLoading, updateEntityToRemoveData } = props;

    const navigate = useNavigate();

    return (<>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        title={<FormattedMessage id="series" />}
                        name="serie"
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        title={<FormattedMessage id="number" />}
                        name="numero"
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        title={<FormattedMessage id="date" />}
                        name="data"
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        title={<FormattedMessage id="surname" />}
                        name="cognome"
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        title={<FormattedMessage id="name" />}
                        name="nome"
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        title={<FormattedMessage id="municipality" />}
                        name="comune"
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        title={<FormattedMessage id="fiscalCode" />}
                        name="codiceFiscale"
                    />
                    <TableCell align='right'> <FormattedMessage id="totalAmount" /> (€) </TableCell>
                    <TableCell> <FormattedMessage id="actions" /> </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    isPageLoading ?
                        (<TableRowsLoader rowsNum={10} colsNum={9} />) :
                        (<>
                            {fatture && fatture.items?.map((fattura) => (
                                <AlternateTableRow key={fattura.id}>
                                    <TableCell>
                                        <Grid item> {fattura.serie} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {fattura.numero} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {getShortDate(fattura.data)} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {fattura.cognome} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {fattura.nome} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {fattura.comune} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {fattura.codiceFiscale} </Grid>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Grid item> {getCurrencyByNumber(fattura.importoTotale)} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={<FormattedMessage id="dossier" />} aria-label="Dossier">
                                            <IconButton onClick={() => navigate(`/dossiers/edit?dossierId=${fattura.praticaAssociataId}`)}>
                                                <IconFiles fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={<FormattedMessage id="download" />} aria-label="Download">
                                            <IconButton onClick={() => downloadFattura(fattura.id!, `invoice-${fattura.nome}_${fattura.cognome}.pdf`)}>
                                                <DownloadIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete">
                                            <IconButton onClick={() => updateEntityToRemoveData({
                                                id: fattura.id!,
                                                name: fattura.numero!
                                            })}>
                                                <DeleteTwoTone fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={<FormattedMessage id="sendElectronicInvoice" />} aria-label="send">
                                            <IconButton onClick={() => handleEInvoiceToSend(fattura.id!, fattura?.numero ?? '')}>
                                                <SendIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </AlternateTableRow>
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    </>
    );
};

export default FatturePage;
