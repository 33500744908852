import axios from "axios";
import { useKeycloak } from "keycloak-react-web";
import { FC, useEffect } from "react";
import { dispatch } from "store";
import { setLoggedUser } from "store/slices/loggedUserSlice";
import { setLoggedUserIsAdmin } from "store/slices/loggedUserIsAdminSlice";
import { UserService } from "services/UserServices";
import { setShowTenantChange } from "store/slices/showTenantChangeSlice";
import { setLoggedUserIsSuperAdmin } from "store/slices/loggedUserIsSuperAdminSlice";

interface SetupAuthProps {
    children: any;
}

const SetupAuth: FC<SetupAuthProps> = (props) => {
    const { children } = props;

    const { keycloak } = useKeycloak();

    const userService = new UserService();

    useEffect(() => {
        (async () => {
            if (keycloak.token !== undefined) {
                axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`;

                var loggedUserIsAdmin: boolean = false;

                let retrievedUser: any = await userService.GetUser();
                if (retrievedUser != null) {
                    loggedUserIsAdmin = retrievedUser?.isSuperAdmin == true || retrievedUser?.isCurrentTenantAdmin == true;
                    dispatch(setShowTenantChange(retrievedUser.isSomeTenantAdmin));
                } else {
                    keycloak.logout();
                }

                dispatch(setLoggedUser(retrievedUser));
                dispatch(setLoggedUserIsAdmin(loggedUserIsAdmin));
                dispatch(setLoggedUserIsSuperAdmin(retrievedUser?.isSuperAdmin == true));
            }
        })()
    }, [keycloak.token])

    return children;
}

export default SetupAuth;