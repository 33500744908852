import { useSelected, useFocused } from "slate-react";

const Signature = ({ attributes, element, children }) => {
  const { url, alt } = element;
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div
      {...attributes}
      className='embed'
      style={{ display: 'flex', boxShadow: selected && focused && '0 0 3px 3px lightgray' }}
      {...element.attr}
    >
      <div contentEditable={false} style={{ width: `200px`, height: `50px` }} >

        <img alt={alt} src={url} />
      </div>
      {children}
    </div>
  );
};
export default Signature;