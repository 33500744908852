import { Autocomplete, Button, Card, CardContent, CardHeader, Grid, IconButton, TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ClearIcon from '@mui/icons-material/Clear';
import IAutocompleteOptions from "interfaces/IAutocompleteOptions";
import { ServizioService } from "services/ServizioServices";
import { Servizio } from "types/servizio";
import { handleDownloadFile } from "utils/common";
import { array, date, object } from "yup";
import { handleUpsertErrors } from "utils/validation";
import BackdropLoader from "ui-component/BackdropLoader";
import ReceiptIcon from '@mui/icons-material/Receipt';

interface GenerateBulletinsFilters {
    startDate: string | null;
    endDate: string | null;
}

const GenerateBulletins = () => {
    const intl = useIntl();

    const servizioService = new ServizioService();

    let generateBulletinsValidationSchema = object({
        servicesIds: array().min(1, 'servicesIdsRequired'),
        startDate: date().required('startDateRequired').typeError('startDateRequired'),
        endDate: date().required('endDateRequired').typeError('endDateRequired')
    });

    const [filters, setFilters] = useState<GenerateBulletinsFilters>({
        startDate: null,
        endDate: null
    });

    const handleDateFiltersChange = (e: any, fieldName: string) => {
        setFilters((currentFormData: any) => ({ ...currentFormData, [fieldName]: e == null ? e : e.format() }));
    }

    const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);
    const [servicesOptions, setServicesOptions] = useState<Array<IAutocompleteOptions>>([]);

    const [chosenServices, setChosenServices] = useState<Array<IAutocompleteOptions>>([]);

    const [isLoadingGenerateBulletins, setIsLoadingGenerateBulletins] = useState<boolean>(false);

    const handleAutocompleteChange = (value: Array<IAutocompleteOptions>) => {
        setChosenServices(value);
    }

    useEffect(() => {
        (async () => {
            const retrievedServices = await servizioService.GetUnpaginatedServizi();
            setServicesOptions(retrievedServices.map((service: Servizio) => ({ id: service.id!, label: service.descrizione })));
        })();
    }, []);

    const generateBulletins = async () => {
        setIsLoadingGenerateBulletins(true);

        try {
            const servicesIds = chosenServices.map((chosenService) => chosenService.id);

            const getBollettiniObject = {
                ...filters,
                ...{ servicesIds: servicesIds }
            };

            await generateBulletinsValidationSchema.validate(getBollettiniObject, { abortEarly: false });

            const reportExcelBase64: string = await servizioService.GetBollettiniExcel(getBollettiniObject);

            handleDownloadFile(reportExcelBase64, "Bollettini.xlsx")
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        } finally {
            setIsLoadingGenerateBulletins(false);
        }
    }

    return (
        <Card>
            <BackdropLoader open={isLoadingGenerateBulletins}/>

            <CardHeader title={<FormattedMessage id="generateBulletins" />} />
            <CardContent>
                <Grid container spacing={1} sx={{ mb: 2 }}>
                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <Autocomplete
                            id="services"
                            options={servicesOptions}
                            renderInput={(params) =>
                                <TextField {...params} required name="services"
                                    fullWidth label={<FormattedMessage id="services" />} size='small'
                                />}
                            onChange={(e: any, value: IAutocompleteOptions[]) => {
                                handleAutocompleteChange(value);
                            }}
                            onOpen={() => { setAutocompleteOpen(true) }}
                            onClose={() => { setAutocompleteOpen(false) }}
                            open={autocompleteOpen}
                            value={chosenServices}
                            multiple={true}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                        md={3}
                        xs={12}
                    >
                        <MobileDatePicker
                            label={<FormattedMessage id="startDate" />}
                            onChange={(e: any) => handleDateFiltersChange(e, 'startDate')}
                            renderInput={(params) => <TextField {...params} fullWidth required size="small" name="startDate" />}
                            InputProps={{
                                endAdornment: (
                                    filters.startDate !== null &&
                                    <IconButton onClick={() => handleDateFiltersChange(null, 'startDate')} edge="end" size="small">
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                            value={filters.startDate}
                            inputFormat="DD/MM/YYYY"
                        />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                        md={3}
                        xs={12}
                    >
                        <MobileDatePicker
                            label={<FormattedMessage id="endDate" />}
                            onChange={(e: any) => handleDateFiltersChange(e, 'endDate')}
                            renderInput={(params) =>
                                <TextField {...params}
                                    fullWidth
                                    required
                                    size="small"
                                    name="endDate"
                                    InputProps={{
                                        endAdornment: (
                                            filters.endDate !== null &&
                                            <IconButton onClick={() => handleDateFiltersChange(null, 'endDate')} edge="end" size="small">
                                                <ClearIcon />
                                            </IconButton>
                                        ),
                                    }}
                                />}
                            value={filters.endDate}
                            inputFormat="DD/MM/YYYY"
                        />
                    </Grid>
                    <Grid
                        item
                        lg={2}
                        md={2}
                        xs={12}
                    >
                        <Button variant="contained" onClick={generateBulletins} startIcon={<ReceiptIcon />}>
                            <FormattedMessage id="generateBulletins" />
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default GenerateBulletins;