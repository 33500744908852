import { Box, Button, Card, CardContent, CardHeader, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UserService } from "services/UserServices";
import { Utente } from "types/utente";
import { composeNotistackMessage, getCurrencyByNumber, getFormValue, getShortDate, isNotNullOrUndefined } from "utils/common";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import InitialDataStep from "./StepperContents/InitialDataStep";
import HeaderStep from "./StepperContents/HeaderStep";
import UploadFileStep from "./StepperContents/UploadFileStep";
import { Pratica, defaultPratica } from "types/pratica";
import { PraticaService } from "services/PraticaServices";
import { useNavigate } from "react-router-dom";
import { handleUpsertErrors } from "utils/validation";
import { object, string } from "yup";
import { Allegato } from "types/allegato";
import { useDispatch, useSelector } from "store";
import { setSplittedDossierPlacesDossiers } from "store/slices/splitDossierPlacesDossiersSlice";
import { PostoPraticaData } from "types/postoPraticaData";
import AlternateTableRow from "ui-component/AlternateTableRow";
import PurpleStyledTableCell from "ui-component/PurpleStyledTableCell";
import { setUserIdToAssign } from "store/slices/newUserIdToAssignSlice";
import dayjs from "dayjs";
import SaveIcon from '@mui/icons-material/Save';

const PraticaInsert = () => {
    const intl = useIntl();

    const { listSplittedDossierPlacesDossiers } = useSelector((state) => state.splittedDossierPlaceDossier);
    const { userIdToAssign } = useSelector((state) => state.newUserIdToAssign);

    const [dossierPlacesToAssign, setDossierPlacesToAssign] = useState<Array<PostoPraticaData>>([]);

    const dispatch = useDispatch();

    const steps = ['initialData', 'header', 'attachments'];
    const [activeStep, setActiveStep] = useState(0);

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handlePreviousStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const getStepContent = () => {
        switch (activeStep) {
            case 0:
                return <InitialDataStep pratica={pratica} handleChangePratica={handleChange} handleDateChangePratica={handleDateChange} updateDossierNumber={updateDossierNumber} />;
            case 1:
                return <HeaderStep assignedUser={assignedUser} assignUserToEntity={assignUser} isAdd />;
            case 2:
                return <UploadFileStep
                    files={files}
                    handleUploadFile={handleUploadFile}
                    handleDeleteFile={handleDeleteFile}
                    fileInEditId={fileInEditId}
                    newFileName={newFileName}
                    handleCloseEditName={handleCloseEditName}
                    handleConfirmEditName={handleConfirmEditName}
                    handleUpdateFileInEditId={handleUpdateFileInEditId}
                    handleUpdateNewFileName={handleUpdateNewFileName}
                />;
        }
    }

    const praticaService = new PraticaService();
    const userService = new UserService();

    const [pratica, setPratica] = useState<Pratica>(defaultPratica);
    const [assignedUser, setAssignedUser] = useState<Utente | undefined>(undefined);
    const [files, setFiles] = useState<Array<Allegato>>([]);

    const navigate = useNavigate();

    let praticaValidationSchema = object({
        numeratoreId: string().required('serieRequired'),
        numero: string().required('numberRequired'),
        intestatarioId: string().required('holderRequired')
    });

    useEffect(() => {
        if (isNotNullOrUndefined(listSplittedDossierPlacesDossiers) && listSplittedDossierPlacesDossiers.length !== 0) {
            setDossierPlacesToAssign(listSplittedDossierPlacesDossiers);

            dispatch(setSplittedDossierPlacesDossiers([]));
        }
    }, [listSplittedDossierPlacesDossiers]);

    useEffect(() => {
        if (isNotNullOrUndefined(userIdToAssign)) {
            assignUser(userIdToAssign);
            dispatch(setUserIdToAssign(''));
        }
    }, [userIdToAssign])

    const updateDossierNumber = (number: string) => {
        setPratica((currentFormData: any) => ({ ...currentFormData, numero: number }));
    }

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setPratica((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const handleDateChange = (fieldName: string, date: any) => {
        let newValue: string | null;

        newValue = dayjs(date).format('YYYY-MM-DD');

        newValue = newValue == 'Invalid Date' ? null : newValue;

        setPratica((currentFormData: any) => ({ ...currentFormData, [fieldName]: newValue }));
    }

    const assignUser = async (userId: string) => {
        const retrievedUser: Utente = await userService.GetUserById(userId);
        setAssignedUser(retrievedUser);
        setPratica((prevStatus) => ({ ...prevStatus, intestatarioId: userId }));
    }

    const handleUploadFile = async (e: any) => {
        var fileReader: FileReader = new FileReader();
        let newFileName = e.target.files[0].name;
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = async () => {
            let newFileBase64: string = fileReader.result as string;

            let newFileData: Allegato = {
                id: (files.length).toString(),
                nomeFile: newFileName,
                allegatoBase64: newFileBase64
            };

            let newFiles = [...files, newFileData];

            setFiles(newFiles);
        }
    }

    const handleDeleteFile = async (fileId: string) => {
        setFiles(files.filter((file) => file.id !== fileId));
    }

    const praticaInsert = async () => {
        try {
            const praticaInsertBody = {
                pratica: pratica,
                allegati: files,
                postiPraticaIds: dossierPlacesToAssign.map((dossierPlace: PostoPraticaData) => dossierPlace.postoPraticaId)
            };

            await praticaValidationSchema.validate(pratica, { abortEarly: false });

            let praticaId: string = await praticaService.InsertPratica(praticaInsertBody);

            composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');

            navigate(`/dossiers/edit?dossierId=${praticaId}`);
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
            setActiveStep(0);
        }
    }

    const [fileInEditId, setFileInEditId] = useState<string>('');

    const [newFileName, setNewFileName] = useState<string>('');

    const handleCloseEditName = () => {
        setFileInEditId('');
        setNewFileName('');
    }

    const handleUpdateFileInEditId = (fileId: string) => {
        setFileInEditId(fileId);
    }

    const handleUpdateNewFileName = (newFileName: string) => {
        setNewFileName(newFileName);
    }

    const handleConfirmEditName = async () => {
        try {
            const newFiles: Array<Allegato> = files;

            newFiles.map((file: Allegato) => {
                if (file.id == fileInEditId) {
                    const getExtension = (str: string) => str.slice(str.lastIndexOf("."));

                    const oldFileExt: string = getExtension(file.nomeFile);
                    const newFileExt: string = getExtension(newFileName);

                    file.nomeFile = newFileName + (oldFileExt == newFileExt ? '' : oldFileExt);
                }
            })

            setFiles(newFiles);

            composeNotistackMessage(intl.formatMessage({ id: 'fileRenamedSuccessfully' }), 'success');
            handleCloseEditName();
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        }
    }

    return (
        <Card>
            <CardHeader title={`${intl.formatMessage({ id: 'dossier' })}: ${pratica.numero ?? ''}`} />
            <Divider />
            <CardContent>
                <Box sx={{ width: '100%', mb: 2 }}>
                    <Stepper nonLinear activeStep={activeStep}>
                        {
                            steps.map((label) =>
                            (
                                <Step key={label}>
                                    <StepLabel> <FormattedMessage id={label} /> </StepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                    <Box sx={{ margin: 2 }}>
                        {
                            getStepContent()
                        }
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            variant="outlined"
                            disabled={activeStep === 0}
                            onClick={handlePreviousStep}
                            color="secondary"
                        >
                            <FormattedMessage id="previous" />
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {
                            activeStep === steps.length - 1 ?
                                <Button
                                    variant="contained"
                                    onClick={praticaInsert}
                                    startIcon={<SaveIcon />}
                                >
                                    <FormattedMessage id="save" />
                                </Button> :
                                <Button
                                    variant="contained"
                                    onClick={handleNextStep}
                                    color="secondary"
                                >
                                    <FormattedMessage id="next" />
                                </Button>
                        }
                    </Box>
                </Box>
                {
                    dossierPlacesToAssign.length != 0 &&
                    <>
                        <h3> <FormattedMessage id="placesDossierToAssign" /> </h3>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <PurpleStyledTableCell> <FormattedMessage id="structure" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="placeNumber" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="surname" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="name" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="birthDate" /> </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="totalAmount" /> (€) </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="totalPayed" /> (€) </PurpleStyledTableCell>
                                        <PurpleStyledTableCell> <FormattedMessage id="totalToPay" /> (€) </PurpleStyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dossierPlacesToAssign.map((postoPraticaData: PostoPraticaData) => (
                                            <AlternateTableRow key={postoPraticaData.postoPraticaId}>
                                                <TableCell> {postoPraticaData.strutturaDenominazione} </TableCell>
                                                <TableCell>
                                                    <Box sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer'
                                                        }
                                                    }}
                                                        onClick={() => { navigate(`/posto/edit?postoId=${postoPraticaData.postoId}`) }}
                                                    >
                                                        {postoPraticaData.postoNumero}
                                                    </Box>
                                                </TableCell>
                                                <TableCell> {postoPraticaData.postoProprietarioCognome} </TableCell>
                                                <TableCell> {postoPraticaData.postoProprietarioNome} </TableCell>
                                                <TableCell> {getShortDate(postoPraticaData.postoProprietarioDataNascita)} </TableCell>
                                                <TableCell> {getCurrencyByNumber(postoPraticaData.importoTotale)} </TableCell>
                                                <TableCell> {getCurrencyByNumber(postoPraticaData.pagatoTotale)} </TableCell>
                                                <TableCell> {getCurrencyByNumber(postoPraticaData.importoTotale! - postoPraticaData.pagatoTotale!)} </TableCell>
                                            </AlternateTableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                }
            </CardContent>
        </Card>
    );
}

export default PraticaInsert;