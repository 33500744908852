import { FC } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage } from 'react-intl';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { Agenzia } from 'types/agenzia';
import { PaginationResult } from 'types/paginationResult';
import ISortOptions from 'interfaces/ISortOptions';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import TableRowsLoader from 'ui-component/TableRowsLoader';

interface AgenziePageProps {
    agenzie: PaginationResult<Agenzia>;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
    isPageLoading: boolean;
}

const AgenziePage: FC<AgenziePageProps> = (props) => {
    const { agenzie, setSortOptions, sortOptions, isPageLoading } = props;

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="denominazione"
                            title={<FormattedMessage id="denomination" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="comune"
                            title={<FormattedMessage id="municipality" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="rappresentante"
                            title={<FormattedMessage id="representative" />}
                        />
                        <TableCell> <FormattedMessage id="actions" /> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isPageLoading ?
                            (<TableRowsLoader rowsNum={10} colsNum={4} />) :
                            (<>
                                {agenzie && agenzie.items?.map((agenzia) => (
                                    <AlternateTableRow key={agenzia.id}>
                                        <TableCell>
                                            <Grid item> {agenzia.denominazione} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {agenzia.comune} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {agenzia.rappresentante} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                                <IconButton component={RouterLink} to={`edit?agenziaId=${agenzia.id}`}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </AlternateTableRow>
                                ))}
                            </>)
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default AgenziePage;
