import { Card, CardHeader, Divider, Button, CardContent, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ServizioPostoService } from "services/ServizioPostoServices";
import { ServizioPosto } from "types/servizioPosto";
import CardContentLoader from "ui-component/CardContentLoader";
import { getCurrencyByNumber, getFormValue } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { date, number, object } from "yup";

const ServizioPostoUpdate = () => {
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [servizioPosto, setServizioPosto] = useState<ServizioPosto | undefined>(undefined);

    const [totalAmount, setTotalAmount] = useState<number>(0);

    const servizioPostoService = new ServizioPostoService();

    const intl = useIntl()

    const [praticaId, setPraticaId] = useState<string>('');

    const navigate = useNavigate();

    let servizioPostoValidationSchema = object({
        data: date().typeError('dateMustBeDate'),
        prezzo: number().required('priceRequired').min(0, 'priceMinZero').typeError('priceMustBeNumber'),
        aliquota: number().required().min(0, 'rateMinZero').typeError('rateMustBeNumber'),
        quantita: number().min(1, 'quantityMinOne').typeError('quantityMustBeNumber'),
    });

    useEffect(() => {
        setIsPageLoading(true);
        (async () => {
            let servizioPostoId: string = window.location.href.split('placeServiceId=')[1] ?? '';
            if (servizioPostoId !== '') {
                servizioPostoId = servizioPostoId.split('&')[0];
            }
            let retrievedServizioPosto: ServizioPosto = await servizioPostoService.GetServizioPostoById(servizioPostoId);

            let praticaIdQueryParam: string = window.location.href.split('dossierId=')[1] ?? '';
            setPraticaId(praticaIdQueryParam);

            setServizioPosto(retrievedServizioPosto);
            setIsPageLoading(false);
        })();
    }, [])

    useEffect(() => {
        if (servizioPosto !== undefined) {
            let amount: number = servizioPosto!.quantita * (servizioPosto!.prezzo * (1 + (servizioPosto!.aliquota / 100)));
            setTotalAmount(Number.isNaN(amount) ? 0 : amount);
        }
    }, [servizioPosto?.quantita, servizioPosto?.prezzo, servizioPosto?.aliquota])

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setServizioPosto((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const handleDateChange = (fieldName: string, date: any) => {
		let newValue: string | null;

		newValue = dayjs(date).format('YYYY-MM-DD');

		newValue = newValue == 'Invalid Date' ? null : newValue;

        setServizioPosto((currentFormData: any) => ({ ...currentFormData, [fieldName]: newValue }));
    }


    const servizioPostoUpdate = async () => {
        try {
            await servizioPostoValidationSchema.validate(servizioPosto, { abortEarly: false });
            await servizioPostoService.UpdateServizioPosto(servizioPosto!);

            navigate(`/dossiers/edit?dossierId=${praticaId}`);
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        }
    }

    return (
        <Card>
            <CardHeader title={<FormattedMessage id="serviceDetail" />} />
            <Divider />
            {
                isPageLoading ?
                    <CardContentLoader /> :
                    (<CardContent>
                        <Grid container direction="row" spacing={1}>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={<FormattedMessage id="description" />}
                                    name="descrizione"
                                    onChange={handleChange}
                                    value={servizioPosto?.descrizione}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    label={<FormattedMessage id="date" />}
                                    onChange={(date: any) => {
                                        handleDateChange('data', date);
                                    }}
                                    renderInput={(params) => (
                                        <TextField fullWidth {...params} error={false} size='small' />
                                    )}
                                    value={servizioPosto?.data ?? ''}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    required
                                    size='small'
                                    label={<FormattedMessage id="price" />}
                                    name="prezzo"
                                    onChange={handleChange}
                                    value={servizioPosto?.prezzo}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    required
                                    size='small'
                                    label={<FormattedMessage id="rate" />}
                                    name="aliquota"
                                    onChange={handleChange}
                                    value={servizioPosto?.aliquota}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    required
                                    size='small'
                                    label={<FormattedMessage id="quantity" />}
                                    name="quantita"
                                    onChange={handleChange}
                                    value={servizioPosto?.quantita}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    disabled
                                    size='small'
                                    label={<FormattedMessage id="totalAmount" />}
                                    onChange={handleChange}
                                    value={getCurrencyByNumber(totalAmount)}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>)
            }
            <Divider />
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={servizioPostoUpdate}
                >
                    <FormattedMessage id="save" />
                </Button>
            </Box>
        </Card>
    );
}

export default ServizioPostoUpdate;