/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { PaginationQuery } from 'types/paginationQuery';
import { PaginationResult } from 'types/paginationResult';
import { Fattura } from 'types/fattura';
import { SerieNumeratore } from 'types/serieNumeratore';

export class FatturaService {
    async GetFatture(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Fattura>> {
        const result = await axios.post<PaginationResult<Fattura>>(`${serviceConfig.appServiceBaseUrl}/GetFatture`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetFatturaById(fatturaId: string): Promise<Fattura> {
        const result = await axios.post<Fattura>(`${serviceConfig.appServiceBaseUrl}/GetFattura`, fatturaId);
        return result.data;
    }

    async InsertFattura(insertFatturaBody: object): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/InsertFattura`, insertFatturaBody);
        return result.data;
    }

    async DeleteFatturaById(fatturaId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteFattura`, fatturaId);
        return result.data;
    }

    async DownloadFatturaById(fatturaId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/DownloadFattura`, fatturaId);
        return result.data;
    }

    async SendElectronicInvoice(fatturaId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/SendElectronicInvoice`, fatturaId);
        return result.data;
    }

    async GetUnpaginatedFatturaSerieNumeratori(): Promise<SerieNumeratore[]> {
        const result = await axios.get<SerieNumeratore[]>(`${serviceConfig.appServiceBaseUrl}/GetAllFatturaSerieNumeratori`);
        return result.data;
    }
}