import { DossierReport } from "./dossierReport";
import { PaginationResult, voidPagination } from "./paginationResult";

export interface DossiersCompleteReport {
    totalToPay: number;
    totalPayed: number;
    pagedDossiersReportsDto: PaginationResult<DossierReport>;
}

export const defaultDossierCompleteReport: DossiersCompleteReport = {
    totalToPay: 0,
    totalPayed: 0,
    pagedDossiersReportsDto: voidPagination
};