import React, { ReactNode, useEffect, useMemo, useState } from 'react';

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

// assets
import { useKeycloak } from 'keycloak-react-web';
import { Tenant } from 'types/tenant';
import Loading from 'pages/LoadingPage';
import Breadcrumbs from 'layout/Breadcrumbs/Breadcrumbs';
import { isNotNullOrUndefined } from 'utils/common';

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: '20px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

type MainLayoutProps = {
    children: ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const { keycloak, initialized } = useKeycloak();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
    const [userCurrentTenantName, setUserCurrentTenantName] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { loggedUser } = useSelector((state) => state.loggedUser);
    const { loggedUserIsAdmin } = useSelector((state) => state.loggedUserIsAdmin);

    useEffect(() => {
        (async () => {
            if (loggedUser !== undefined) {
                setIsUserLoggedIn(loggedUser !== null);
                if (isNotNullOrUndefined(loggedUser.tenantsList) && loggedUser.tenantsList.length != 0) {
                    const currentTenant: Tenant | undefined = loggedUser.tenantsList.find((tenant: Tenant) => tenant.id == loggedUser.currentTenantId);
                    setUserCurrentTenantName(currentTenant?.name ?? '');
                }
            }
        })()
    }, [loggedUser]);

    useEffect(() => {
        setIsLoading(!initialized);
    }, [initialized])

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        isLoading ? <Loading /> :
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* header */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={3}
                    sx={{
                        bgcolor: theme.palette.primary.dark,
                        transition: drawerOpen ? theme.transitions.create('width') : 'none'
                    }}
                >
                    {header}
                </AppBar>

                {/* drawer */}
                {isUserLoggedIn && (<>
                    <Sidebar userCurrentTenantName={userCurrentTenantName} loggedUserIsAdmin={loggedUserIsAdmin} />
                </>)}

                {/* main content */}
                <Main theme={theme} open={drawerOpen}>
                    {children}
                </Main>
            </Box>
    );
};

export default MainLayout;
