import { Button, Card, CardHeader, Divider, Grid, TablePagination, TextField, debounce, useMediaQuery, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DecuiusService } from "services/DecuiusServices";
import { Decuius } from "types/decuius";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { getFormValue, isNotNullOrUndefined, retrieveCookieByKey, saveFiltersPaginationCookie } from "utils/common";
import { Link as RouterLink } from 'react-router-dom';
import DecuiusPage from "./DecuiusPage";
import DecuiusPageMobile from "./DecuiusPageMobile";
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from "store";
import { StatoDecuius } from "types/enums/StatoDecuius";

interface DecuiusFilters {
    cognome: string;
    nome: string;
    strutturaNumero: string;
    strutturaDenominazione: string;
}

interface DecuiusListProps {
    fromExternal: boolean;
    assignDecuiusToEntity?: (decuiusId: string) => void;
    alreadyAssignedDecuiusIds?: Array<string>;
    customAddMethod?: () => void;
    decuiusType?: StatoDecuius;
    onlyWithPlace?: boolean;
}

const DecuiusList: FC<DecuiusListProps> = (props) => {
    const { fromExternal, assignDecuiusToEntity, alreadyAssignedDecuiusIds, customAddMethod, decuiusType, onlyWithPlace = true } = props;

    const { loggedUser } = useSelector((state) => state.loggedUser);

    const intl = useIntl();

    const decuiusService = new DecuiusService();

    const [allDecuius, setAllDecuius] = useState<PaginationResult<Decuius>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);

    const [filters, setFilters] = useState<DecuiusFilters>({
        cognome: "",
        nome: "",
        strutturaNumero: "",
        strutturaDenominazione: ""
    });

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    useEffect(() => {
        const filtersPaginationCookie = retrieveCookieByKey(loggedUser.currentTenantId, 'decuiusListFilters');
        if (isNotNullOrUndefined(filtersPaginationCookie)) {
            setFilters(filtersPaginationCookie.filters);
            setPaginationQuery(filtersPaginationCookie.paginationQuery);
        }
    }, [])

    const updateAllDecuius = () => {
        setIsPageLoading(true);
        getAllDecuius(paginationQuery, filters);

        saveFiltersPaginationCookie(loggedUser.currentTenantId, 'decuiusListFilters', {
            filters: filters,
            paginationQuery: paginationQuery
        }, 1);
    }

    const getAllDecuius = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: DecuiusFilters) => {
        const retrievedAllDecuius = await decuiusService.GetAllDecuius(paginationQuery, { ...filters, onlyWithPlace: onlyWithPlace });
        setAllDecuius(retrievedAllDecuius);

        setIsPageLoading(false);
    }, 700), []);

    useEffect(() => {
        updateAllDecuius();
    }, [paginationQuery, filters]);

    const assignDecuius = (decuiusId: string) => {
        if (assignDecuiusToEntity !== undefined) assignDecuiusToEntity(decuiusId);
    }

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    return (
        <>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="surname" />}
                        name="cognome"
                        onChange={handleFiltersChange}
                        value={filters.cognome}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="name" />}
                        name="nome"
                        onChange={handleFiltersChange}
                        value={filters.nome}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="structureNumber" />}
                        name="strutturaNumero"
                        onChange={handleFiltersChange}
                        value={filters.strutturaNumero}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="structureDenomination" />}
                        name="strutturaDenominazione"
                        onChange={handleFiltersChange}
                        value={filters.strutturaDenominazione}
                    />
                </Grid>

                {
                    !fromExternal ? <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Button
                            component={RouterLink}
                            to="create"
                            color="primary"
                            sx={{ m: 2, textAlign: 'center' }}
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            <FormattedMessage id="addDecuius" />
                        </Button>
                    </Grid> :
                        <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'flex-start' }}
                        >
                            <Button
                                onClick={customAddMethod}
                                color="success"
                                sx={{ m: 2, textAlign: 'center' }}
                                variant="contained"
                                startIcon={<AddIcon />}
                            >
                                <FormattedMessage id={decuiusType == StatoDecuius.Salma ? "addAndAssignDecuius" : "addAndAssignBox"} />
                            </Button>
                        </Grid>
                }
            </Grid>
            <Card>
                {!fromExternal && <CardHeader title={<FormattedMessage id="decuiusList" />} />}
                <Divider />
                {
                    mobileDevice ?
                        <DecuiusPageMobile
                            allDecuius={allDecuius}
                            isPageLoading={isPageLoading}

                            fromExternal={fromExternal}
                            assignDecuius={assignDecuius}
                            alreadyAssignedDecuiusIds={alreadyAssignedDecuiusIds}
                        /> :
                        <DecuiusPage
                            allDecuius={allDecuius}

                            setSortOptions={setSortOptions}
                            sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                            isPageLoading={isPageLoading}

                            fromExternal={fromExternal}
                            assignDecuius={assignDecuius}
                            alreadyAssignedDecuiusIds={alreadyAssignedDecuiusIds}
                        />
                }
                <TablePagination
                    component="div"
                    count={allDecuius?.totalCount}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                    }}
                    onPageChange={(e, page) => {
                        setPageNumber(page);
                    }}
                    page={paginationQuery.pageNumber}
                    rowsPerPage={paginationQuery.pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                />
            </Card>
        </>
    );
}

export default DecuiusList;