import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { IconFiles } from "@tabler/icons";
import ISortOptions from "interfaces/ISortOptions";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CommunicationType } from "types/enums/CommunicationType";
import { PaginationResult } from "types/paginationResult";
import { Transfer } from "types/transfer";
import AlternateTableRow from "ui-component/AlternateTableRow";
import TableCellWithSorting from "ui-component/TableCellWithSorting";
import TableRowsLoader from "ui-component/TableRowsLoader";
import { getShortDate, isNotNullOrUndefined } from "utils/common";
import { Link as RouterLink } from "react-router-dom";
import Coffin from "ui-component/icons/Coffin";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';

interface TransferRegisterPageProps {
    transfers: PaginationResult<Transfer>;
    isPageLoading: boolean;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
}

const TransferRegisterPage: FC<TransferRegisterPageProps> = (props) => {
    const { transfers, isPageLoading, setSortOptions, sortOptions } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="data"
                        title={<FormattedMessage id="date" />}
                    />

                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="operatorName"
                        title={<FormattedMessage id="operator" />}
                    />

                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="sourceStructure"
                        title={<FormattedMessage id="sourceStructure" />}
                    />

                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="sourcePlace"
                        title={<FormattedMessage id="sourcePlace" />}
                    />

                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="destinationStructure"
                        title={<FormattedMessage id="destinationStructure" />}
                    />

                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="destinationPlace"
                        title={<FormattedMessage id="destinationPlace" />}
                    />

                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="decuiusName"
                        title={<FormattedMessage id="decuiusName" />}
                    />

                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="causale"
                        title={<FormattedMessage id="causal" />}
                    />

                    <TableCell> <FormattedMessage id="actions" /> </TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {
                    isPageLoading ?
                        (<TableRowsLoader rowsNum={10} colsNum={9} />) :
                        (<>
                            {transfers && transfers.items?.map((transfer, index) => (
                                <AlternateTableRow key={index}>
                                    <TableCell>
                                        <Grid item> {getShortDate(transfer.date)} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {transfer.operatorName.substring(0, 1)} {transfer.operatorSurname.substring(0, 1)} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {transfer.sourceStructure} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {transfer.sourcePlace} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {transfer.destinationStructure} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {transfer.destinationPlace} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {transfer.decuiusName} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {transfer.causal} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container spacing={1}>
                                            <Grid item lg={4} md={4} xs={12}>
                                                {
                                                    isNotNullOrUndefined(transfer.sourceStructureId) &&
                                                    <Tooltip title={<FormattedMessage id="goToSourceStructure" />} aria-label="Go to source structure">
                                                        <IconButton component={RouterLink} to={`/structures/edit?strutturaId=${transfer.sourceStructureId}`}>
                                                            <UpgradeIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                            <Grid item lg={4} md={4} xs={12}>
                                                {
                                                    isNotNullOrUndefined(transfer.destinationStructureId) &&
                                                    <Tooltip title={<FormattedMessage id="goToDestinationStructure" />} aria-label="Dossier">
                                                        <IconButton component={RouterLink} to={`/structures/edit?strutturaId=${transfer.destinationStructureId}`}>
                                                            <VerticalAlignBottomIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                            <Grid item lg={4} md={4} xs={12}>
                                                <Tooltip title={<FormattedMessage id="goToDecuius" />} aria-label="Dossier">
                                                    <IconButton component={RouterLink} to={`/decuius/edit?decuiusId=${transfer.decuiusId}`}>
                                                        <Coffin sx={{ width: 20 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </AlternateTableRow>
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
}

export default TransferRegisterPage;