import { Card, CardContent, Grid, TextField, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PagamentoService } from "services/PagamentoServices";
import { TipoPagamento } from "types/enums/TipoPagamento";
import { Pagamento, defaultPagamento } from "types/pagamento";
import { composeNotistackMessage, getFormValue } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { date, number, object } from "yup";
import SaveIcon from '@mui/icons-material/Save';

interface PagamentoUpsertProps {
    isAdd: boolean;
    servizioPostoId?: string;
    pagamentoToUpdate?: Pagamento;
    updateServizioPostoPagamenti: () => void;
}

const PagamentoUpsert: FC<PagamentoUpsertProps> = (props) => {
    const { isAdd, servizioPostoId, pagamentoToUpdate, updateServizioPostoPagamenti } = props;

    const [pagamento, setPagamento] = useState<Pagamento>(pagamentoToUpdate ?? defaultPagamento);

    const pagamentoService = new PagamentoService();

    const intl = useIntl()

    useEffect(() => {
        (async () => {
            if (isAdd) {
                setPagamento((currentFormData: any) => ({ ...currentFormData, servizioRiferimentoId: servizioPostoId }));
            }
        }
        )();
    }, [])

    let pagamentoValidationSchema = object({
        data: date().typeError('dateMustBeDate'),
        importo: number().required('amountRequired').min(0, 'amountMinZero').typeError('amountMustBeNumber'),
        mezzo: number().required('paymentMethodRequired'),
    });

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setPagamento((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const handleDateChange = (fieldName: string, date: any) => {
        let newValue: string | null;

        newValue = dayjs(date).format('YYYY-MM-DD');

        newValue = newValue == 'Invalid Date' ? null : newValue;

        setPagamento((currentFormData: any) => ({ ...currentFormData, [fieldName]: newValue }));
    }

    const pagamentoUpsert = async () => {
        try {
            await pagamentoValidationSchema.validate(pagamento, { abortEarly: false });
            await pagamentoService.UpsertServizioPostoPagamento(pagamento);

            if (isAdd) resetPagamento();

            updateServizioPostoPagamenti();

            composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        }
    }

    const resetPagamento = () => {
        setPagamento({ ...defaultPagamento, servizioRiferimentoId: servizioPostoId! });
    }

    return (
        <Card>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid
                        item
                        lg={2}
                        md={2}
                        xs={12}
                    >
                        <DatePicker
                            inputFormat="DD/MM/YYYY"
                            label={<FormattedMessage id="date" />}
                            onChange={(date: any) => {
                                handleDateChange('data', date);
                            }}
                            renderInput={(params) => (
                                <TextField fullWidth {...params} error={false} size='small' />
                            )}
                            value={pagamento.data}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={2}
                        md={2}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={<FormattedMessage id="amount" />}
                            name="importo"
                            required
                            onChange={handleChange}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={pagamento.importo}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={2}
                        md={2}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={<FormattedMessage id="code" />}
                            name="code"
                            onChange={handleChange}
                            value={pagamento.code}
                            inputProps={{ maxLength: '8' }}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={6}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={<FormattedMessage id="description" />}
                            name="descrizione"
                            onChange={handleChange}
                            value={pagamento.descrizione}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={2}
                        md={2}
                        xs={6}
                    >
                        <FormControl fullWidth required size='small'>
                            <InputLabel> <FormattedMessage id="paymentMethod" /> </InputLabel>
                            <Select
                                name="mezzo"
                                label={<FormattedMessage id="paymentMethod" />}
                                onChange={handleChange}
                                sx={{ color: 'white' }}
                                value={pagamento.mezzo}
                            >
                                {
                                    Object.values(TipoPagamento).filter(key => isNaN(Number(key)))
                                        .map((tipoPagamento, index) => (
                                            <MenuItem key={index} value={index}>
                                                {TipoPagamento[index]}
                                            </MenuItem>
                                        ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={pagamentoUpsert}
                    startIcon={<SaveIcon />}
                >
                    <FormattedMessage id={isAdd ? "add" : "edit"} />
                </Button>
            </Box>
        </Card>
    );
}

export default PagamentoUpsert;