export enum TipoStruttura {
    Colombario,
    Cappella,
    Tomba,
    Ossario
}

export const tipiStruttura =
    [
        {
            id: 0,
            name: 'Colombario'
        },
        {
            id: 1,
            name: 'Cappella'
        },
        {
            id: 2,
            name: 'Tomba'
        },
        {
            id: 3,
            name: 'Ossario'
        },
    ]