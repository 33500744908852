import { Button, Card, CardActionArea, CardContent, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UserService } from "services/UserServices";
import BackdropLoader from "ui-component/BackdropLoader";
import LoginIcon from '@mui/icons-material/Login';
import ReplayIcon from '@mui/icons-material/Replay';
import { useKeycloak } from "keycloak-react-web";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";
import { getFormValue, isNotNullOrUndefined } from "utils/common";

const InsertEmailAndPhone = () => {
    const userService = new UserService();
    const intl = useIntl();

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [messageData, setMessageData] = useState<{ message: string, success: boolean } | null>(null);

    const { keycloak } = useKeycloak();

    const navigate = useNavigate();

    const { loggedUser } = useSelector((state) => state.loggedUser);

    useEffect(() => {
        if (loggedUser && loggedUser != null) {
            navigate('/');
        }
    }, [loggedUser]);

    const redirectToLogin = () => {
        keycloak.login();
    }

    const [isAccepted, setIsAccepted] = useState<boolean>(false);

    const reinitializePage = () => {
        setMessageData(null);
    }

    const [userData, setUserData] = useState<{ userId: string, hashedPrivateCode: string }>({
        userId: '',
        hashedPrivateCode: ''
    });

    useEffect(() => {
        (async () => {
            const url: string = window.location.href;

            let userId: string = url.split('yn7nujbb=')[1] ?? '';
            if (userId !== '') {
                userId = userId.split('&')[0];
            }

            let hashedPrivateCode: string = url.split('44fzyr3g=')[1] ?? '';
            if (hashedPrivateCode !== '') {
                hashedPrivateCode = hashedPrivateCode.split('&')[0];
            }

            if (!isNotNullOrUndefined(userId) || !isNotNullOrUndefined(hashedPrivateCode)) {
                navigate('/');
            }

            try {
                await userService.CheckUserGenerateCredentials({ userId: userId, hashedPrivateCode: hashedPrivateCode });
            } catch (e: any) {
                navigate('/');
            } finally {
                setIsPageLoading(false);
                setUserData({
                    userId: userId,
                    hashedPrivateCode: hashedPrivateCode
                });
            }
        })()
    }, [])

    const generateCredentials = async () => {
        setIsPageLoading(true);

        try {
            await userService.UpdateMailPhoneNumberByUser({ ...userData, ...{ mail: mailPhoneNumber.mail, phoneNumber: mailPhoneNumber.phoneNumber } });

            setMessageData({
                message: intl.formatMessage({ id: 'credentialsSuccessfullyGenerated' }),
                success: true
            });
        } catch (e: any) {
            setMessageData({
                message: intl.formatMessage({ id: 'errorGeneratingCredentials' }),
                success: false
            });
        } finally {
            setIsPageLoading(false);
        }
    }

    const [mailPhoneNumber, setMailPhoneNumber] = useState<{ mail: string, phoneNumber: string }>({
        mail: '',
        phoneNumber: ''
    });

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setMailPhoneNumber((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    return (
        <>
            <BackdropLoader open={isPageLoading} />

            {
                messageData === null ?
                    <Card
                        sx={{
                            borderRadius: 1,
                            boxShadow: 2,
                            padding: 2,
                        }}
                    >
                        <CardContent>
                            <Grid container direction="row" rowSpacing={3}>
                                <Grid item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    sx={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography variant="subtitle1">
                                        La presente per metterla a conoscenza, che da oggi potrà accedere al nostro portale <a href="https://osiride.orangedev.it">Osiride</a>. La username sarà il suo codice fiscale e la password verrà generata ed inviata ai recapiti indicati sotto dopo aver preso visione ed accettato il testo corrente.<br />
                                        All'interno del portale potrà visionare le pratiche abbinate al posto occupato dal vostro caro estinto, con i servizi ad esso associati e le relative scadenze.<br />
                                        Cogliamo l'occasione inoltre per metterla a conoscenza che tutte le scadenze relative ai servizi di lampada votiva annuale, non verranno più inviate a mezzo posta, ma solo in formato digitale (link Whatsapp o email).
                                        Cogliamo l'occasione per porgere cordiali Saluti.
                                    </Typography>
                                </Grid>
                                <Grid item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    sx={{
                                        textAlign: 'end'
                                    }}
                                >
                                    <Typography><b>LA DIREZIONE</b></Typography>
                                </Grid>
                            </Grid>

                            <Box
                                sx={{
                                    maxWidth: '300px',
                                    margin: '0 auto',
                                    mt: '5px'
                                }}
                            >
                                <Grid container direction="row" rowSpacing={3}>
                                    <Grid item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        sx={{
                                            textAlign: 'center'
                                        }}>
                                        <TextField
                                            required
                                            fullWidth
                                            size='small'
                                            label={<FormattedMessage id="mail" />}
                                            name="mail"
                                            onChange={handleChange}
                                            value={mailPhoneNumber.mail}
                                        />
                                    </Grid>
                                    <Grid item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        sx={{
                                            textAlign: 'center'
                                        }}>
                                        <TextField
                                            required
                                            fullWidth
                                            size='small'
                                            label={<FormattedMessage id="phone" />}
                                            name="phoneNumber"
                                            onChange={handleChange}
                                            value={mailPhoneNumber.phoneNumber}
                                        />
                                    </Grid>

                                    <Grid item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        sx={{
                                            textAlign: 'center'
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isAccepted}
                                                />
                                            }
                                            label={<FormattedMessage id="credentialsGenerationAcceptText" />}
                                            name="default"
                                            labelPlacement="start"
                                            onChange={() => setIsAccepted(!isAccepted)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                        <CardActionArea>
                            <Button sx={{ width: '100%' }} variant="contained" onClick={generateCredentials} disabled={!isAccepted || !isNotNullOrUndefined(mailPhoneNumber.mail) || !isNotNullOrUndefined(mailPhoneNumber.phoneNumber)}> <FormattedMessage id="updateDataAndGenerateCredentials" /> </Button>
                        </CardActionArea>
                    </Card> :
                    <Box
                        sx={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            borderRadius: 1,
                            boxShadow: 2,
                            padding: '30px',
                            backgroundColor: messageData.success ? '#92f890' : '#E67D72'
                        }}>
                        <Typography variant="h4">{messageData.message}</Typography>

                        <Typography
                            variant="h5"
                            sx={{
                                mt: 2,
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                            onClick={() => {
                                messageData.success === true ? redirectToLogin() : reinitializePage();
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {
                                    messageData.success === true ?
                                        <>
                                            <FormattedMessage id="login" /> <LoginIcon fontSize="small" sx={{ ml: 1 }} />
                                        </> :
                                        <>
                                            <FormattedMessage id="retry" /> <ReplayIcon fontSize="small" sx={{ ml: 1 }} />
                                        </>
                                }
                            </Box>
                        </Typography>
                    </Box>
            }
        </>
    )
}

export default InsertEmailAndPhone;