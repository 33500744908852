/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { Regione } from 'types/regione';
import { Provincia } from 'types/provincia';
import { Comune } from 'types/comune';
import { Nazione } from 'types/nazione';

export class GeoService {
    async GetRegioni(): Promise<Regione[]> {
        const result = await axios.get<Regione[]>(`${serviceConfig.appServiceBaseUrl}/GetRegioni`);
        return result.data;
    }

    async GetProvinceByRegioneId(regioneId: string): Promise<Provincia[]> {
        const result = await axios.post<Provincia[]>(`${serviceConfig.appServiceBaseUrl}/GetProvince`, regioneId);
        return result.data;
    }

    async GetComuniByProvinciaId(provinciaId: string): Promise<Comune[]> {
        const result = await axios.post<Comune[]>(`${serviceConfig.appServiceBaseUrl}/GetComuni`, provinciaId);
        return result.data;
    }

    async GetNazioni(): Promise<Nazione[]> {
        const result = await axios.get<Nazione[]>(`${serviceConfig.appServiceBaseUrl}/GetNazioni`);
        return result.data;
    }
}