import { createSlice } from '@reduxjs/toolkit';


export const invoicesServicesIdsSlice = createSlice({
    name: 'invoicesServicesIds',
    initialState: {
        listInvoicesServicesIds: []
    },
    reducers: {
        setInvoicesServicesIds(state, action) {
            state.listInvoicesServicesIds = action.payload;
        }
    }
});

export const { setInvoicesServicesIds } = invoicesServicesIdsSlice.actions;

export default invoicesServicesIdsSlice.reducer;