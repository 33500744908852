import { FC, useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { PaginationResult } from 'types/paginationResult';
import { getShortDate } from 'utils/common';
import { Pratica } from 'types/pratica';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { MoreVert } from '@mui/icons-material';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import SearchIcon from '@mui/icons-material/Search';

interface PraticaWithMenuProps {
    pratica: Pratica,

    userView?: boolean
}

const PraticaWithMenu: FC<PraticaWithMenuProps> = (props) => {
    const { pratica, userView } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={pratica.id}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="date" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getShortDate(pratica.data)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="number" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {pratica.numero} </Typography>
                        </Grid>
                    </Grid>
                    {
                        !userView &&
                        <>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="series" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {pratica.numeratore?.nome} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="surname" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {pratica.intestatario?.cognome} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="name" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {pratica.intestatario?.nome} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="birthPlace" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {pratica.intestatario?.comune} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="birthDate" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {getShortDate(pratica.intestatario?.dataNascita)} </Typography>
                                </Grid>
                            </Grid>
                        </>
                    }
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`pratica-actions-menu-${pratica.id}`}
                    MenuListProps={{
                        'aria-labelledby': `pratica-action-button-${pratica.id}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    {
                        userView ?
                            <MenuItem key={'dossierInfo'} onClick={handleClose}>
                                <IconButton
                                    component={RouterLink}
                                    to={`/yourDossiers/info?dossierId=${pratica.id}`}
                                >
                                    <SearchIcon fontSize="small" sx={{ mr: '3px' }} />
                                    <Typography variant="subtitle1"> <FormattedMessage id="dossierInfo" /> </Typography>
                                </IconButton>
                            </MenuItem>
                            :
                            <MenuItem key={'edit'} onClick={handleClose}>
                                <IconButton
                                    component={RouterLink}
                                    to={`/dossiers/edit?dossierId=${pratica.id}`}
                                >
                                    <EditIcon fontSize="small" sx={{ mr: '3px' }} />
                                    <Typography variant="subtitle1"> <FormattedMessage id="edit" /> </Typography>
                                </IconButton>
                            </MenuItem>
                    }
                </Menu>
            </TableCell>
        </TableRow>
    );
}

interface PratichePageMobileProps {
    pratiche: PaginationResult<Pratica>;
    isPageLoading: boolean;

    userView?: boolean;
}

const PratichePageMobile: FC<PratichePageMobileProps> = (props) => {
    const { pratiche, isPageLoading, userView } = props;

    return (
        <Table>
            <TableBody>
                {
                    isPageLoading ? (
                        <TableRowsLoader rowsNum={3} colsNum={1} />
                    ) :
                        (<>
                            {pratiche && pratiche.items?.map((pratica) => (
                                <PraticaWithMenu
                                    key={pratica.id}
                                    pratica={pratica}

                                    userView={userView}
                                />
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
};

export default PratichePageMobile;
