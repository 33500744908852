export interface Agenzia {
    id?: string;
    denominazione: string;
    comune?: string;
    rappresentante?: string;
    dateOfInsert?: string;
    isValid?: boolean;
    tenantId?: string;
}

export const defaultAgenzia: Agenzia = {
    denominazione: ''
}