export interface Viale {
    dateOfInsert?: string;
    descrizione: string;
    id?: string;
    isValid?: boolean;
    tenantId?: string;
}

export const defaultViale: Viale = {
    descrizione: '',
}