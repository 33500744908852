import Locales from 'ui-component/Locales';
import ThemeCustomization from 'themes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
// import Routes from "./routes/Routes";
import { HashRouter, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'contexts/ConfigContext';
import { Provider } from 'react-redux';
import { store } from 'store';
import MainLayout from 'layout/MainLayout';
import AllRoutes from 'routes/AllRoutes';
import SetupAuth from 'utils/SetupAuth';
import PrivacyPolicy from 'utils/PrivacyPolicy';
import BackgroundApiCheck from 'utils/BackgroundApiCheck';

const App = () => {
    return (
        <SetupAuth>
            <ThemeCustomization>
                <Provider store={store}>
                    <BackgroundApiCheck>
                        <HashRouter>
                            <ConfigProvider>
                                <Locales>
                                    <SnackbarProvider dense maxSnack={3}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MainLayout>
                                                <PrivacyPolicy>
                                                    <AllRoutes />
                                                </PrivacyPolicy>
                                            </MainLayout>
                                        </LocalizationProvider>
                                    </SnackbarProvider>
                                </Locales>
                            </ConfigProvider>
                        </HashRouter>
                    </BackgroundApiCheck>
                </Provider>
            </ThemeCustomization>
        </SetupAuth>
    );
};

export default App;
