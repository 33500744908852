import { Box, Grid, Paper, Skeleton, Typography, styled } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from 'react-router-dom';
import { darken } from '@mui/material/styles';
import { useCallback, useEffect, useState } from "react";
import { TenantService } from "services/TenantServices";
import { defaultTenantDashboardData, TenantDashboardData } from "types/tenantDashbaordData";
import { RadialBarChart } from "ui-component/RadialBarChart";
import { composeNotistackMessage, getCurrencyByNumber } from "utils/common";

const AdminDashboard = () => {
    const navigate = useNavigate();

    const pages = [
        {
            title: 'structures',
            path: '/structures',
            color: '#84bd00',
            tenantDashboardDataKey: 'numberOfStructures'
        },
        {
            title: 'users',
            path: '/users',
            color: '#234fbf',
            tenantDashboardDataKey: 'numberOfUsers'
        },
        {
            title: 'decuius',
            path: '/decuius',
            color: '#b7c44e',
            tenantDashboardDataKey: 'numberOfDecuiusWithPlace'
        },
        {
            title: 'dossiers',
            path: '/dossiers',
            color: '#ff97b8',
            tenantDashboardDataKey: 'numberOfDossiers'
        },
        {
            title: 'invoices',
            path: '/invoices',
            color: '#fe5000',
            tenantDashboardDataKey: 'numberOfInvoices'
        },
        {
            title: 'deposit',
            path: '/deposit',
            color: '#e03f33',
            tenantDashboardDataKey: 'numberOfDecuiusWithoutPlace'
        },
        {
            title: 'services',
            path: '/services',
            color: '#f6d039',
            tenantDashboardDataKey: 'numberOfServices'
        },
        {
            title: 'agencies',
            path: '/agencies',
            color: '#b56b82',
            tenantDashboardDataKey: 'numberOfAgencies'
        },
    ];

    const tenantService = new TenantService();

    const [tenantDashboardData, setTenantDashboardData] = useState<TenantDashboardData>(defaultTenantDashboardData);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const intl = useIntl();

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            try {
                const tenantDashbaordData: TenantDashboardData = await tenantService.GetTenantDashboardData();
                setTenantDashboardData(tenantDashbaordData);
            } catch (e: any) {
                composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
            } finally {
                setIsLoading(false);
            }
        })()
    }, [])

    const SectionPaper = styled(Paper)(({ theme, color }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        backgroundColor: color,
        height: 150,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        transition: 'opacity 0.3s ease',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: darken(color!, 0.2)
        }
    }));

    const renderSectionPaper = (page: { title: string, path: string, color: string, tenantDashboardDataKey: string }) => (
        <>
            {
                isLoading ?
                    <Skeleton variant="rectangular" sx={{ width: "100%", height: 150, borderRadius: 2 }} /> :
                    <SectionPaper color={page.color} onClick={() => navigate(page.path)} sx={{ position: 'relative' }}>
                        <Typography
                            variant="h2"
                            color="white"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                textAlign: 'center',
                                marginTop: 1
                            }}
                        >
                            <FormattedMessage id={page.title} />
                        </Typography>
                        <Typography variant="h1" color="white">
                            {/* @ts-ignore */}
                            {tenantDashboardData[page.tenantDashboardDataKey]}
                        </Typography>
                    </SectionPaper >
            }
        </>
    );

    return (
        <Box sx={{ m: 6 }}>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item
                            lg={6}
                            md={6}
                            xs={12}>
                            {
                                isLoading ?
                                    <Skeleton variant="rectangular" sx={{ width: "100%", height: "100%", borderRadius: 2 }} /> :
                                    <Paper sx={{ backgroundColor: "#b09cf7", padding: 1 }}>
                                        <Grid container direction="column" spacing={1}>
                                            <Grid item
                                                lg={12}
                                                md={12}
                                                xs={12}>
                                                <RadialBarChart percentage={tenantDashboardData.percentageOccupiedPlaces} label={intl.formatMessage({ id: 'occupiedPlaces' })} />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="h4"
                                                    color="white"
                                                >
                                                    <FormattedMessage id="earned" />: {getCurrencyByNumber(tenantDashboardData.occupiedPlacesEarned)}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="h4"
                                                    color="white"
                                                >
                                                    <FormattedMessage id="earnable" />: {getCurrencyByNumber(tenantDashboardData.freePlacesEarnable)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                            }
                        </Grid>
                        <Grid item
                            lg={6}
                            md={6}
                            xs={12}>
                            <Grid container spacing={3}>
                                {
                                    pages.slice(0, 4).map((page) => (
                                        (<Grid item
                                            lg={6}
                                            md={6}
                                            xs={12}
                                        >
                                            {
                                                renderSectionPaper(page)
                                            }
                                        </Grid>)
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        {
                            pages.slice(4).map((page) => (
                                (<Grid item
                                    lg={3}
                                    md={3}
                                    xs={12}
                                >
                                    {
                                        renderSectionPaper(page)
                                    }
                                </Grid>)
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AdminDashboard;