//TODO: Capire esattamente quali campi sono opzionali e quali no. Da fare dopo aver capire come gestire le pratiche

import { SerieNumeratore } from "./serieNumeratore";
import { Utente } from "./utente";

export interface Pratica {
    id?: string;
    serie?: string;
    numeratore?: SerieNumeratore,
    numeratoreId?: string;
    numero?: string;
    intestatario?: Utente;
    intestatarioId?: string;
    data?: string;
    note?: string;
    dataPreliminare?: string;
    statoPratica?: string;

    tenantId?: string;
    dateOfInsert?: string;
    isValid?: boolean;
}

export const defaultPratica: Pratica = {

}