import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

interface DialogDeleteElementProps {
    open: boolean;
    entityToDeleteInfo: string;
    onClose: (...args: any[]) => void;
    onCancel: (...args: any[]) => void;
    onConfirm: (...args: any[]) => void;
    customMessageIntlId?: string;
}

const DialogDeleteElement: FC<DialogDeleteElementProps> = (props) => {
    const { open, entityToDeleteInfo, onClose, onCancel, onConfirm, customMessageIntlId } = props;

    return (<Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-remove-title"
        aria-describedby="alert-remove-description"
    >
        <DialogTitle id="remove-title">
            <FormattedMessage id={customMessageIntlId ?? "confirmDeletion"} />: <b>{entityToDeleteInfo}</b>?
        </DialogTitle>
        <DialogActions>
            <Button onClick={onCancel} variant="outlined" color="error"> <FormattedMessage id="cancel" /> </Button>
            <Button onClick={onConfirm} variant="contained" color="error"><FormattedMessage id="delete" /></Button>
        </DialogActions>
    </Dialog>);
}

export default DialogDeleteElement;