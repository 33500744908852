import { Box, Button, Grid, IconButton, List, ListItem, ListItemText, TextField, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Allegato } from "types/allegato";
import { FC, useState } from "react";
import { AttachFile, DeleteTwoTone } from "@mui/icons-material";
import VisuallyHiddenInput from "ui-component/VisuallyHiddenInput";
import { handleDownloadFile, isNotNullOrUndefined } from "utils/common";
import DownloadIcon from '@mui/icons-material/Download';
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import { PraticaService } from "services/PraticaServices";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface UploadFileStepProps {
    files: Array<Allegato>;
    handleUploadFile: (e: any) => void;
    handleDeleteFile: (fileIndex: any) => void;
    fileInEditId: string;
    newFileName: string;
    handleCloseEditName: () => void;
    handleConfirmEditName: () => void;
    handleUpdateFileInEditId: (fileId: string) => void;
    handleUpdateNewFileName: (newFileName: string) => void;
}

const UploadFileStep: FC<UploadFileStepProps> = (props) => {
    const { files, handleUploadFile, handleDeleteFile, fileInEditId, newFileName, handleCloseEditName, handleConfirmEditName, handleUpdateFileInEditId, handleUpdateNewFileName } = props;

    const [removeAttachmentDialogOpen, setRemoveAttachmentDialogOpen] = useState<boolean>(false);
    const [entityToRemoveData, setEntityToRemoveData] = useState<{ id: string | number, name: string } | undefined>();

    const praticaService = new PraticaService();

    const downloadAllegato = async (file: any) => {
        if (isNotNullOrUndefined(file.allegatoBase64)) {
            handleDownloadFile(file.allegatoBase64, file.nomeFile);
        } else {
            const allegatoBase64: string = await praticaService.DownloadPraticaAllegato(file.id!);
            handleDownloadFile(allegatoBase64, file.nomeFile);
        }
    }

    return (
        <>
            <Grid
                container
                direction="row"
            >
                <Grid item lg={4}>
                    <Button component="label" variant="contained" startIcon={<AttachFileIcon />}>
                        <FormattedMessage id="uploadFile" />
                        <VisuallyHiddenInput type="file"
                            onChange={handleUploadFile}
                        />
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
            >
                <DialogDeleteElement
                    open={removeAttachmentDialogOpen}
                    onClose={() => setRemoveAttachmentDialogOpen(false)}
                    onCancel={() => setRemoveAttachmentDialogOpen(false)}
                    onConfirm={() => {
                        setRemoveAttachmentDialogOpen(false)
                        handleDeleteFile(entityToRemoveData!.id)
                    }}
                    entityToDeleteInfo={entityToRemoveData?.name ?? ''}
                    customMessageIntlId="confirmDeletion"
                    key="removeAttachment"
                />
                {files && files?.map((file) => (
                    <Grid container direction="row" sx={{ mt: 2 }} columnSpacing={2}>
                        <Grid item
                            xs={1}
                            md={1}
                            xl={1}
                        >
                            <Tooltip title={<FormattedMessage id="rename" />} aria-label="rename">
                                {
                                    fileInEditId !== file.id ?
                                        <IconButton edge="end" onClick={() => { handleUpdateFileInEditId(file.id!); handleUpdateNewFileName(file.nomeFile) }}>
                                            <EditIcon fontSize="small" />
                                        </IconButton> :
                                        <>
                                            <IconButton edge="end" onClick={handleCloseEditName} sx={{ mr: 1 }}>
                                                <CloseIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton edge="end" onClick={handleConfirmEditName}>
                                                <CheckIcon fontSize="small" />
                                            </IconButton>
                                        </>
                                }
                            </Tooltip>
                        </Grid>
                        <Grid item
                            xs={4}
                            md={4}
                            xl={4}
                        >
                            <Tooltip title={fileInEditId !== file.id ? file.nomeFile : newFileName}>
                                <TextField
                                    size="small"
                                    value={fileInEditId !== file.id ? file.nomeFile : newFileName}
                                    disabled={fileInEditId !== file.id}
                                    onChange={(e) => { handleUpdateNewFileName(e.target.value) }}
                                    fullWidth
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item
                            xs={4}
                            md={4}
                            xl={4}
                        >
                            <Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete" sx={{ mr: 1 }}>
                                <IconButton edge="end" onClick={() => {
                                    setEntityToRemoveData({
                                        id: file.id!,
                                        name: file.nomeFile
                                    });
                                    setRemoveAttachmentDialogOpen(true);
                                }}>
                                    <DeleteTwoTone />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={<FormattedMessage id="download" />} aria-label="download">
                                <IconButton edge="end" onClick={() => downloadAllegato(file)}>
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default UploadFileStep;