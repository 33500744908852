import { createSlice } from '@reduxjs/toolkit';

export const recordedPaymentsSlice = createSlice({
    name: 'recordedPayments',
    initialState: {
        listRecordedPayments: []
    },
    reducers: {
        setRecordedPayments(state, action) {
            state.listRecordedPayments = action.payload;
        }
    }
});

export const { setRecordedPayments } = recordedPaymentsSlice.actions;

export default recordedPaymentsSlice.reducer;