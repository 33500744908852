
import { useRef, useState } from 'react'
import Button from '../../common/Button.jsx'
import Icon from '../../common/Icon.jsx'
import usePopup from '../../utils/customHooks/usePopup'
import { Transforms } from 'slate'
import { insertPlaceholder, placeholderList } from '../../utils/placeholder.js'
import { FormattedMessage } from 'react-intl'

const LinkButton = (props) => {
    const { editor } = props

    const linkInputRef = useRef(null);
    const [showInput, setShowInput] = usePopup(linkInputRef)

    const [selection, setSelection] = useState()

    const handleInsertPlaceholder = (selectedPlaceholder) => {
        selection && Transforms.select(editor, selection);
        insertPlaceholder(editor, selectedPlaceholder)
        setShowInput(prev => !prev);
    }

    const togglePlaceholder = () => {
        setSelection(editor.selection);
        setShowInput(prev => !prev);
    }

    return (
        <div ref={linkInputRef} className='popup-wrapper'>
            <Button disabled={!editor.selection} className={showInput ? 'clicked' : ''} format={'placeholder'} onClick={togglePlaceholder}>
                <Icon icon='placeholder' />
            </Button>
            {showInput &&
                <div className='popup'>
                    <span style={{ fontSize: '0.8em' }}><FormattedMessage id="availablePlaceholder" /> </span>
                    <ul style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        {placeholderList.map((placeholder, index) =>
                            <li key={index} onClick={() => handleInsertPlaceholder(placeholder)}>
                                <FormattedMessage id={placeholder} />
                            </li>
                        )}
                    </ul>
                </div>
            }
        </div>
    )
}


export default LinkButton;