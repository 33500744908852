import { Card, CardHeader, Divider, Grid, TextField, Button, TablePagination, useMediaQuery, useTheme, debounce, Typography } from "@mui/material";
import { useState, useEffect, useCallback, FC } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { ServizioService } from "services/ServizioServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { Servizio } from "types/servizio";
import { composeNotistackMessage, getFormValue, isNotNullOrUndefined, retrieveCookieByKey, saveFiltersPaginationCookie } from "utils/common";
import ServiziPage from "./ServiziPage";
import ServiziPageMobile from "./ServiziPageMobile";
import { Link as RouterLink } from 'react-router-dom';
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from "store";

interface ServiziListProps {
    fromExternal: boolean;
    assignServiceToEntity?: (servizioId: string) => void;
    assignedServicesIds?: Array<string>;
}

interface ServiziFilters {
    codice: string;
    descrizione: string;
}

const ServiziList: FC<ServiziListProps> = (props) => {
    const { fromExternal, assignServiceToEntity, assignedServicesIds } = props;

    const intl = useIntl();

    const { loggedUser } = useSelector((state) => state.loggedUser);

    const servizioService = new ServizioService();

    const [servizi, setServizi] = useState<PaginationResult<Servizio>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);
    const [filters, setFilters] = useState<ServiziFilters>({
        codice: "",
        descrizione: ""
    });

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    useEffect(() => {
        const filtersPaginationCookie = retrieveCookieByKey(loggedUser.currentTenantId, 'servicesListFilters');
        if (isNotNullOrUndefined(filtersPaginationCookie)) {
            setFilters(filtersPaginationCookie.filters);
            setPaginationQuery(filtersPaginationCookie.paginationQuery);
        }
    }, [])

    const updateServizi = () => {
        setIsPageLoading(true);
        getServizi(paginationQuery, filters);

        saveFiltersPaginationCookie(loggedUser.currentTenantId, 'servicesListFilters', {
            filters: filters,
            paginationQuery: paginationQuery
        }, 1);
    }

    const getServizi = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: ServiziFilters) => {
        const retrievedServizi = await servizioService.GetServizi(paginationQuery, filters);
        setServizi(retrievedServizi);

        setIsPageLoading(false);
    }, 700), []);

    useEffect(() => {
        updateServizi();
    }, [paginationQuery, filters]);

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    return (
        <>
            {
                fromExternal && (<>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        <FormattedMessage id="servicesList" />
                    </Typography>
                </>)
            }
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="code" />}
                        name="codice"
                        onChange={handleFiltersChange}
                        value={filters.codice}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="description" />}
                        name="descrizione"
                        onChange={handleFiltersChange}
                        value={filters.descrizione}
                    />
                </Grid>

                {
                    !fromExternal &&
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Button
                            component={RouterLink}
                            to="create"
                            color="primary"
                            sx={{ m: 2, textAlign: 'center' }}
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            <FormattedMessage id="addService" />
                        </Button>
                    </Grid >
                }
            </Grid >
            <Card>
                {
                    !fromExternal && (<>
                        <CardHeader title={<FormattedMessage id="servicesList" />} />
                        <Divider />
                    </>)
                }
                {
                    mobileDevice ?
                        <ServiziPageMobile
                            servizi={servizi}
                            isPageLoading={isPageLoading}

                            fromExternal={fromExternal}
                            assignServiceToEntity={assignServiceToEntity}
                            assignedServicesIds={assignedServicesIds}
                        /> :
                        <ServiziPage
                            servizi={servizi}

                            setSortOptions={setSortOptions}
                            sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                            isPageLoading={isPageLoading}

                            fromExternal={fromExternal}
                            assignServiceToEntity={assignServiceToEntity}
                            assignedServicesIds={assignedServicesIds}
                        />
                }

                <TablePagination
                    component="div"
                    count={servizi?.totalCount}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                    }}
                    onPageChange={(e, page) => {
                        setPageNumber(page);
                    }}
                    page={paginationQuery.pageNumber}
                    rowsPerPage={paginationQuery.pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                />
            </Card>
        </>
    );
}

export default ServiziList;