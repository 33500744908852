/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { Servizio } from 'types/servizio';
import { PaginationQuery } from 'types/paginationQuery';
import { PaginationResult } from 'types/paginationResult';
import { UserPaymentsNumber } from 'types/userPaymentsNumber';
import { ServiceReport } from 'types/serviceReport';

export class ServizioService {
    async GetServizi(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Servizio>> {
        const result = await axios.post<PaginationResult<Servizio>>(`${serviceConfig.appServiceBaseUrl}/GetServizi`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetUnpaginatedServizi(): Promise<Servizio[]> {
        const result = await axios.get<Servizio[]>(`${serviceConfig.appServiceBaseUrl}/GetAllServizi`);
        return result.data;
    }

    async GetServizioById(servizioId: string): Promise<Servizio> {
        const result = await axios.post<Servizio>(`${serviceConfig.appServiceBaseUrl}/GetServizio`, servizioId);
        return result.data;
    }

    async UpsertServizio(servizio: Servizio): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpsertServizio`, servizio);
        return result.data;
    }

    async DeleteServizioById(servizioId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteServizio`, servizioId);
        return result.data;
    }

    async GetUserServicesPaymentsNumber(): Promise<UserPaymentsNumber> {
        const result = await axios.get<UserPaymentsNumber>(`${serviceConfig.appServiceBaseUrl}/GetUserServicesPaymentsNumber`);
        return result.data;
    }

    async GetServiziReport(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<ServiceReport>> {
        const result = await axios.post<PaginationResult<ServiceReport>>(`${serviceConfig.appServiceBaseUrl}/GetServiziReport`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetBollettiniExcel(filters: object): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/GetBollettiniExcel`, filters);
        return result.data;
    }

    async GetBollettiniExcelByPlacesServices(placesServicesIdsObject: object): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/GetBollettiniExcelByPlacesServices`, placesServicesIdsObject);
        return result.data;
    }
}