import { TemplateType } from "./enums/TemplateType";

export interface TenantDocument {
    id?: string;
    fileName: string;
    templateType: TemplateType;
    tenantId?: string;

    dateOfInsert?: string;
    isValid?: boolean;

    documentHtmlToString?: string;
}

export const defaultTenantDocument: TenantDocument = {
    fileName: '',
    templateType: TemplateType.Altro
}