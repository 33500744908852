export interface PaginationResult<Type> {
    items: Array<Type>;
    totalCount: number;
    totalPages: number;
    currentPages: number;
}

export const voidPagination = {
    items: [],
    totalCount: 0,
    totalPages: 0,
    currentPages: 0,
};