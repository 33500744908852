import { Card, CardContent, CardHeader, Grid, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Tooltip, debounce } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PraticaService } from "services/PraticaServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { DossierReport } from "types/dossierReport";
import AlternateTableRow from "ui-component/AlternateTableRow";
import TableRowsLoader from "ui-component/TableRowsLoader";
import ClearIcon from '@mui/icons-material/Clear';
import { getCurrencyByNumber, getFormValue, getShortDate } from "utils/common";
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';
import TableCellWithSorting from "ui-component/TableCellWithSorting";
import ISortOptions from "interfaces/ISortOptions";

interface PraticheReportPageProps {
    praticheReport: PaginationResult<DossierReport>;
    isPageLoading: boolean;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
}

const PraticheReportPage: FC<PraticheReportPageProps> = (props) => {
    const { praticheReport, isPageLoading, setSortOptions, sortOptions } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="data"
                        title={<FormattedMessage id="date" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="series"
                        title={<FormattedMessage id="series" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="numero"
                        title={<FormattedMessage id="number" />}
                    />
                    <TableCellWithSorting
                        setSortOptions={setSortOptions}
                        sortOptions={sortOptions}
                        name="ownerName"
                        title={<FormattedMessage id="owner" />}
                    />
                    <TableCell align='right'> <FormattedMessage id="totalToPay" /> (€) </TableCell>
                    <TableCell align='right'> <FormattedMessage id="totalPayed" /> (€) </TableCell>
                    <TableCell align='right'> <FormattedMessage id="balance" /> (€) </TableCell>
                    <TableCell> <FormattedMessage id="actions" /> </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    isPageLoading ?
                        (<TableRowsLoader rowsNum={10} colsNum={8} />) :
                        (<>
                            {praticheReport && praticheReport.items?.map((praticaReport) => (
                                <AlternateTableRow key={praticaReport.id}>
                                    <TableCell>
                                        <Grid item> {getShortDate(praticaReport.date)} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {praticaReport.series} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {praticaReport.number} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid item> {praticaReport.owner} </Grid>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Grid item> {getCurrencyByNumber(praticaReport.totalToPay)} </Grid>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Grid item> {getCurrencyByNumber(praticaReport.totalPayed)} </Grid>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Grid item> {getCurrencyByNumber(praticaReport.balance)} </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                            <IconButton component={RouterLink} to={`/dossiers/edit?dossierId=${praticaReport.id}`}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </AlternateTableRow>
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
}

export default PraticheReportPage;