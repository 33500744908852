import { Grid, IconButton, Table, TableBody, TableCell, TableRow, TableContainer, Typography, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PaginationResult } from "types/paginationResult";
import { Link as RouterLink } from 'react-router-dom';
import { IconFiles } from '@tabler/icons';
import { MoreVert } from "@mui/icons-material";
import { PaymentDone } from "types/paymentDone";
import { TipoPagamento } from "types/enums/TipoPagamento";
import { PaymentStatus } from "types/enums/PaymentStatus";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { getCurrencyByNumber, getShortDate } from "utils/common";

interface PaymentDoneWithMenuProps {
    paymentDone: PaymentDone;
    updateUserPayment: (paymentId: string, paymentResult: boolean) => void;
}

const PaymentDoneWithMenu: FC<PaymentDoneWithMenuProps> = (props) => {
    const { paymentDone, updateUserPayment } = props;

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={`${paymentDone.dossierId}-${paymentDone.dossierId}`}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="dossierHeader" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {paymentDone.payerFullName} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="placeNumber" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {paymentDone.placeNumber} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="code" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {paymentDone.paymentCode} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="description" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {paymentDone.placeServiceDescription} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="date" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getShortDate(paymentDone.paymentDate)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="serviceType" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> <FormattedMessage id={paymentDone.annual ? 'annual' : 'unaTantum'} /> </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="payed" /> (€) </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getCurrencyByNumber(paymentDone.amountPayed)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="totalAmount" /> (€) </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getCurrencyByNumber(paymentDone.totalAmount)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="paymentType" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {TipoPagamento[paymentDone.paymentType]} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="paymentStatus" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {PaymentStatus[paymentDone.paymentStatus]} </Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`paymentDone-actions-menu-${paymentDone.dossierId}-${paymentDone.dossierId}`}
                    MenuListProps={{
                        'aria-labelledby': `paymentDone-action-button-${paymentDone.dossierId}-${paymentDone.dossierId}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem key={'goToDossier'} onClick={handleClose}>
                        <IconButton component={RouterLink} to={`/dossiers/edit?dossierId=${paymentDone.dossierId}`}>
                            <IconFiles fontSize="small" style={{ marginRight: '3px' }} />
                            <Typography variant="subtitle1"> <FormattedMessage id="goToDossier" /> </Typography>
                        </IconButton>
                    </MenuItem>
                    {
                        paymentDone.paymentStatus != PaymentStatus.Completed &&
                        <>
                            <MenuItem key={'rejectPayment'} onClick={handleClose}>
                                <IconButton onClick={() => updateUserPayment(paymentDone.paymentId, false)}>
                                    <CloseIcon fontSize="small" style={{ marginRight: '3px' }} />
                                    <Typography variant="subtitle1"> <FormattedMessage id="rejectPayment" /> </Typography>
                                </IconButton>
                            </MenuItem>
                            <MenuItem key={'confirmPayment'} onClick={handleClose}>
                                <IconButton onClick={() => updateUserPayment(paymentDone.paymentId, true)}>
                                    <CheckIcon fontSize="small" style={{ marginRight: '3px' }} />
                                    <Typography variant="subtitle1"> <FormattedMessage id="confirmPayment" /> </Typography>
                                </IconButton>
                            </MenuItem>
                        </>
                    }
                </Menu>
            </TableCell>
        </TableRow >
    );
}

interface PaymentsDonePageMobile {
    paymentsDone: PaginationResult<PaymentDone>;
    updateUserPayment: (paymentId: string, paymentResult: boolean) => void;
}

const PaymentsDonePageMobile: FC<PaymentsDonePageMobile> = (props) => {
    const { paymentsDone, updateUserPayment } = props;

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {paymentsDone && paymentsDone.items?.map((paymentDone, index) => (
                        <PaymentDoneWithMenu
                            key={index}
                            paymentDone={paymentDone}
                            updateUserPayment={updateUserPayment}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PaymentsDonePageMobile;