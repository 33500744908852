import { Button, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import { composeNotistackMessage, getFormValue, isNotNullOrUndefined, toFirstUpper } from "utils/common";
import VisuallyHiddenInput from "./VisuallyHiddenInput";
import BackdropLoader from "./BackdropLoader";
import { UserService } from "services/UserServices";
import { Utente } from "types/utente";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Provincia } from "types/provincia";
import { Comune } from "types/comune";
import { Regione } from "types/regione";
import { Nazione } from "types/nazione";
import { GeoService } from "services/GeoServices";
import imageCompression from 'browser-image-compression';
import heic2any from "heic2any";

interface IIdentityDocumentImages {
    documentFrontBase64: string;
    documentBackBase64: string;
}

interface UploadImageBoxProps {
    fieldName: string;
    imageSrc: string;
    title: string;
}

interface UploadIdentityDocumentProps {
    setUserByIdCard: (userData: Utente) => void;
}

const UploadIdentityDocument: FC<UploadIdentityDocumentProps> = (props) => {
    const { setUserByIdCard } = props;
    const intl = useIntl();

    const userService = new UserService();
    const geoService = new GeoService();

    const [identityDocumentImages, setIdentityDocumentImages] = useState<IIdentityDocumentImages>({ documentFrontBase64: '', documentBackBase64: '' });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [user, setUser] = useState<Utente | null>(null);

    const handleUploadFile = async (e: any, fieldName: string) => {
        var fileReader: FileReader = new FileReader();
        var file = e.target.files[0];
        if (isNotNullOrUndefined(file)) {
            setIsLoading(true);

            try {
                let convertedImage: File | null = null;

                if (file.name.split('.').pop()?.toUpperCase() == 'HEIC') {
                    convertedImage = await heic2any({
                        blob: file,
                        toType: 'png'
                    }) as File;
                }

                const compressOptions = {
                    maxSizeMB: 0.7,
                    maxWidthOrHeight: 1280,
                    useWebWorker: true,
                };

                const compressedFile = await imageCompression(convertedImage ?? file, compressOptions);

                fileReader.readAsDataURL(compressedFile);
                fileReader.onload = async () => {
                    let newFileBase64: string = fileReader.result as string;
                    setIdentityDocumentImages((currentFormData: any) => ({ ...currentFormData, [fieldName]: newFileBase64 }));
                }
            } catch (e: any) {
                composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
            } finally {
                setIsLoading(false);
            }
        }
    }

    const handleRemoveFile = (fieldName: string) => {
        setIdentityDocumentImages((currentFormData: any) => ({ ...currentFormData, [fieldName]: '' }));
    }

    const UploadImageBox: FC<UploadImageBoxProps> = (props) => {
        const { fieldName, imageSrc, title } = props;

        return (
            <Box sx={{ mb: 3 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    <FormattedMessage id={title} />
                </Typography>
                {
                    !isNotNullOrUndefined(imageSrc) ?
                        <Button component="label">
                            <Box sx={{
                                transition: 'opacity 0.3s',
                                ':hover': {
                                    opacity: 0.7,
                                    cursor: 'pointer'
                                },
                                padding: '10px',
                                backgroundColor: '#dedede',
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '120px',
                                width: '120px'
                            }}>
                                <ImageIcon sx={{ fontSize: 60 }} />
                                <VisuallyHiddenInput type="file"
                                    onChange={(e) => handleUploadFile(e, fieldName)}
                                />
                            </Box>
                        </Button> :
                        <Box sx={{ position: 'relative', display: 'inline-block' }}>
                            <IconButton
                                onClick={() => handleRemoveFile(fieldName)}
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    zIndex: 1,
                                    color: 'white',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    },
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <img src={imageSrc} width="250px" />
                        </Box>
                }
            </Box>
        )
    }

    const scanIdentityCard = async () => {
        setIsLoading(true);

        try {
            const user: Utente = await userService.ScanItIdentityDocument(identityDocumentImages);

            setUser(user);
            setSelectedBirthRegion(user.regioneNascitaId ?? '');
            setSelectedResidenceRegion(user.regioneId ?? '');

            composeNotistackMessage(intl.formatMessage({ id: 'scannedSuccessfully' }), 'success');
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setUser((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const handleDateChange = (fieldName: string, date: any) => {
        let newValue: string | null;

        newValue = dayjs(date).format('YYYY-MM-DD');

        newValue = newValue == 'Invalid Date' ? null : newValue;

        setUser((currentFormData: any) => ({ ...currentFormData, [fieldName]: newValue }));
    }

    const [countryOptions, setCountryOptions] = useState<Nazione[]>([]);

    const [regions, setRegions] = useState<Regione[]>([]);

    const [birthProvinces, setBirthProvinces] = useState<Provincia[]>([]);
    const [birthCities, setBirthCities] = useState<Comune[]>([]);

    const [residenceProvinces, setResidenceProvinces] = useState<Provincia[]>([]);
    const [residenceCities, setResidenceCities] = useState<Comune[]>([]);

    const [selectedBirthRegion, setSelectedBirthRegion] = useState<string>('');
    const [selectedBirthProvince, setSelectedBirthProvince] = useState<string>('');
    const [selectedBirthCity, setSelectedBirthCity] = useState<string>('');
    const [selectedResidenceRegion, setSelectedResidenceRegion] = useState<string>('');
    const [selectedResidenceProvince, setSelectedResidenceProvince] = useState<string>('');
    const [selectedResidenceCity, setSelectedResidenceCity] = useState<string>('');

    const [enableBirthAdditionalFields, setEnableBirthAdditionalFields] = useState<boolean>(true);
    const [enableResidenceAdditionalFields, setEnableResidenceAdditionalFields] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            setRegions(await geoService.GetRegioni());
            setCountryOptions(await geoService.GetNazioni());
        })()
    }, [])

    useEffect(() => {
        setEnableBirthAdditionalFields(toFirstUpper(user?.statoNascita ?? '') == "Italia");
    }, [user?.statoNascita])

    useEffect(() => {
        setEnableResidenceAdditionalFields(toFirstUpper(user?.stato ?? '') == "Italia");
    }, [user?.stato])

    useEffect(() => {
        if (isNotNullOrUndefined(selectedBirthRegion)) {
            (async () => {
                const retrievedBirthProvinces = await geoService.GetProvinceByRegioneId(selectedBirthRegion);
                setBirthProvinces(retrievedBirthProvinces);
                setSelectedBirthProvince(retrievedBirthProvinces.find((province: Provincia) => province.sigla == user?.provinciaNascita?.toUpperCase())?.id!);
            })()
        }
    }, [selectedBirthRegion])

    useEffect(() => {
        if (isNotNullOrUndefined(selectedResidenceRegion)) {
            (async () => {
                const retrievedResidenceProvinces = await geoService.GetProvinceByRegioneId(selectedResidenceRegion);
                setResidenceProvinces(retrievedResidenceProvinces);
                setSelectedResidenceProvince(retrievedResidenceProvinces.find((province: Provincia) => province.sigla == user?.provincia?.toUpperCase())?.id!);
            })()
        }
    }, [selectedResidenceRegion])

    useEffect(() => {
        if (isNotNullOrUndefined(selectedBirthProvince)) {
            (async () => {
                const retrievedBirthCities = await geoService.GetComuniByProvinciaId(selectedBirthProvince);
                setBirthCities(retrievedBirthCities);
                setSelectedBirthCity(retrievedBirthCities.find((city: Comune) => city.nome.toUpperCase() == user?.luogoNascita?.toUpperCase())?.id!);
            })()
        }
    }, [selectedBirthProvince])

    useEffect(() => {
        if (isNotNullOrUndefined(selectedResidenceProvince)) {
            (async () => {
                const retrievedResidenceCities = await geoService.GetComuniByProvinciaId(selectedResidenceProvince);
                setResidenceCities(retrievedResidenceCities);
                setSelectedResidenceCity(retrievedResidenceCities.find((city: Comune) => city.nome.toUpperCase() == user?.comune?.toUpperCase())?.id!);
            })()
        }
    }, [selectedResidenceProvince])

    return (
        <>
            <BackdropLoader open={isLoading} />
            <Box sx={{ m: 3, minWidth: '450px' }}>
                {
                    user == null ?
                        <>
                            <UploadImageBox fieldName="documentFrontBase64" imageSrc={identityDocumentImages.documentFrontBase64} title="uploadIdentityDocumentFrontImage" />
                            <UploadImageBox fieldName="documentBackBase64" imageSrc={identityDocumentImages.documentBackBase64} title="uploadIdentityDocumentBackImage" />

                            <Button fullWidth variant="contained" onClick={scanIdentityCard} disabled={!isNotNullOrUndefined(identityDocumentImages.documentFrontBase64) || !isNotNullOrUndefined(identityDocumentImages.documentBackBase64)}>
                                <FormattedMessage id="scanDocument" />
                            </Button>
                        </>
                        :
                        <Grid container direction="row" spacing={1}>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={<FormattedMessage id="surname" />}
                                    name="cognome"
                                    required
                                    onChange={handleChange}
                                    value={user.cognome}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={<FormattedMessage id="name" />}
                                    name="nome"
                                    required
                                    onChange={handleChange}
                                    value={user.nome}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    label={<FormattedMessage id="birthDate" />}
                                    onChange={(date: any) => {
                                        handleDateChange('dataNascita', date);
                                    }}
                                    renderInput={(params) => (
                                        <TextField fullWidth {...params} error={false} size='small' />
                                    )}
                                    value={user.dataNascita ?? ''}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="gender-radio-buttons"
                                        name="sesso"
                                        onChange={handleChange}
                                        value={user.sesso}
                                    >
                                        <FormControlLabel value={2} control={<Radio />} label={<FormattedMessage id="female" />} />
                                        <FormControlLabel value={1} control={<Radio />} label={<FormattedMessage id="male" />} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <FormControl fullWidth size='small'>
                                    <InputLabel> <FormattedMessage id="birthCountry" /> </InputLabel>
                                    <Select
                                        name="statoNascita"
                                        label={<FormattedMessage id="birthCountry" />}
                                        onChange={handleChange}
                                        value={toFirstUpper(user.statoNascita)}
                                        defaultValue="Italia"
                                        sx={{ color: 'white' }}
                                    >
                                        {
                                            countryOptions
                                                .slice()
                                                .map((countryOption) => (
                                                    <MenuItem key={countryOption.name} value={countryOption.name}>
                                                        {countryOption.name}
                                                    </MenuItem>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <FormControl
                                    fullWidth
                                    size='small'
                                    disabled={!enableBirthAdditionalFields || !isNotNullOrUndefined(user.statoNascita)}
                                >
                                    <InputLabel> <FormattedMessage id="birthRegion" /> </InputLabel>
                                    <Select
                                        label={<FormattedMessage id="birthRegion" />}
                                        onChange={(e: any) => setSelectedBirthRegion(e.target.value)}
                                        sx={{ color: 'white' }}
                                        value={selectedBirthRegion}
                                    >
                                        {
                                            regions && regions
                                                .slice()
                                                .map((regione) => (
                                                    <MenuItem key={regione.id} value={regione.id}>
                                                        {regione.nome}
                                                    </MenuItem>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <FormControl
                                    fullWidth
                                    size='small'
                                    disabled={!enableBirthAdditionalFields || selectedBirthRegion === ""}
                                >
                                    <InputLabel> <FormattedMessage id="birthProvince" /> </InputLabel>
                                    <Select
                                        label={<FormattedMessage id="birthProvince" />}
                                        onChange={(e: any) => {
                                            setUser((currentFormData: any) => ({ ...currentFormData, provinciaNascita: birthProvinces.find((province) => province.id == e.target.value)?.sigla ?? '' }));
                                            setSelectedBirthProvince(e.target.value);
                                        }}
                                        sx={{ color: 'white' }}
                                        value={selectedBirthProvince}
                                    >
                                        {
                                            birthProvinces && birthProvinces
                                                .slice()
                                                .map((provincia: Provincia) => (
                                                    <MenuItem key={provincia.id} value={provincia.id}>
                                                        {provincia.sigla}
                                                    </MenuItem>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <FormControl
                                    fullWidth
                                    size='small'
                                    disabled={!enableBirthAdditionalFields || selectedBirthProvince === ""}
                                >
                                    <InputLabel> <FormattedMessage id="birthCity" /> </InputLabel>
                                    <Select
                                        name="luogoNascita"
                                        label={<FormattedMessage id="birthCity" />}
                                        onChange={(e: any) => {
                                            setUser((currentFormData: any) => ({ ...currentFormData, luogoNascita: birthCities.find((city) => city.id == e.target.value)?.nome ?? '' }))
                                            setSelectedBirthCity(e.target.value);
                                        }}
                                        sx={{ color: 'white' }}
                                        value={selectedBirthCity}
                                    >
                                        {
                                            birthCities && birthCities
                                                .slice()
                                                .map((city) => (
                                                    <MenuItem key={city.id} value={city.id}>
                                                        {city.nome}
                                                    </MenuItem>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <FormControl fullWidth size='small'>
                                    <InputLabel> <FormattedMessage id="residenceCountry" /> </InputLabel>
                                    <Select
                                        name="stato"
                                        label={<FormattedMessage id="residenceCountry" />}
                                        onChange={handleChange}
                                        value={toFirstUpper(user.stato)}
                                        defaultValue="Italia"
                                        sx={{ color: 'white' }}
                                    >
                                        {
                                            countryOptions
                                                .slice()
                                                .map((countryOption) => (
                                                    <MenuItem key={countryOption.name} value={countryOption.name}>
                                                        {countryOption.name}
                                                    </MenuItem>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <FormControl
                                    fullWidth
                                    size='small'
                                    disabled={!enableResidenceAdditionalFields || !isNotNullOrUndefined(user.stato)}
                                >
                                    <InputLabel> <FormattedMessage id="residenceRegion" /> </InputLabel>
                                    <Select
                                        label={<FormattedMessage id="residenceRegion" />}
                                        onChange={(e: any) => setSelectedResidenceRegion(e.target.value)}
                                        sx={{ color: 'white' }}
                                        value={selectedResidenceRegion}
                                    >
                                        {
                                            regions && regions
                                                .slice()
                                                .map((regione) => (
                                                    <MenuItem key={regione.id} value={regione.id}>
                                                        {regione.nome}
                                                    </MenuItem>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <FormControl
                                    fullWidth
                                    size='small'
                                    disabled={!enableResidenceAdditionalFields || selectedResidenceRegion === ""}
                                >
                                    <InputLabel> <FormattedMessage id="residenceProvince" /> </InputLabel>
                                    <Select
                                        label={<FormattedMessage id="residenceProvince" />}
                                        onChange={(e: any) => {
                                            setUser((currentFormData: any) => ({ ...currentFormData, provincia: residenceProvinces.find((province) => province.id == e.target.value)?.sigla ?? '' }));
                                            setSelectedResidenceProvince(e.target.value);
                                        }}
                                        sx={{ color: 'white' }}
                                        value={selectedResidenceProvince}
                                    >
                                        {
                                            residenceProvinces && residenceProvinces
                                                .slice()
                                                .map((provincia: Provincia) => (
                                                    <MenuItem key={provincia.id} value={provincia.id}>
                                                        {provincia.sigla}
                                                    </MenuItem>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                xs={12}
                            >
                                <FormControl
                                    fullWidth
                                    size='small'
                                    disabled={!enableResidenceAdditionalFields || selectedResidenceProvince === ""}
                                >
                                    <InputLabel> <FormattedMessage id="residenceMunicipality" /> </InputLabel>
                                    <Select
                                        name="comune"
                                        label={<FormattedMessage id="residenceMunicipality" />}
                                        onChange={(e: any) => {
                                            const city = residenceCities.find((city) => city.id == e.target.value);
                                            setUser((currentFormData: any) => ({ ...currentFormData, comune: city?.nome ?? '', cap: city?.cap ?? '' }))
                                            setSelectedResidenceCity(e.target.value);
                                        }}
                                        sx={{ color: 'white' }}
                                        value={selectedResidenceCity}
                                    >
                                        {
                                            residenceCities && residenceCities
                                                .slice()
                                                .map((city) => (
                                                    <MenuItem key={city.id} value={city.id}>
                                                        {city.nome}
                                                    </MenuItem>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={<FormattedMessage id="fiscalCode" />}
                                    name="codiceFiscale"
                                    onChange={handleChange}
                                    value={user.codiceFiscale}
                                    InputLabelProps={{ shrink: isNotNullOrUndefined(user.codiceFiscale) }}
                                />
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={<FormattedMessage id="address" />}
                                    name="indirizzo"
                                    onChange={handleChange}
                                    value={user.indirizzo}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={<FormattedMessage id="civic" />}
                                    name="civico"
                                    onChange={handleChange}
                                    value={user.civico}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button onClick={() => setUserByIdCard(user)} variant="contained">
                                    <FormattedMessage id="fillUserData" />
                                </Button>
                            </Grid>
                        </Grid>
                }
            </Box>
        </>
    )
}

export default UploadIdentityDocument;