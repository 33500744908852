
import { useRef } from 'react'
import Button from '../../common/Button.jsx'
import Icon from '../../common/Icon.jsx'
import defaultAdminSignature from 'assets/images/defaultAdminSignature.png'
import { Transforms } from 'slate'

const LinkButton = (props) => {
    const { editor } = props

    const linkInputRef = useRef(null);

    const handleClick = () => {
        const adminSignatureImageNode = {
            type: 'adminSignature',
            url: defaultAdminSignature,
            children: [{ text: '' }]
        }

        Transforms.insertNodes(editor, adminSignatureImageNode);
        Transforms.insertNodes(editor, {
            type: 'paragraph',
            children: [{ text: '' }]
        })
    };

    return (
        <div ref={linkInputRef} className='popup-wrapper'>
            <Button disabled={!editor.selection} format={'adminSignature'} onClick={handleClick}>
                <Icon icon='adminSignature' />
            </Button>
        </div>
    )
}

export default LinkButton;