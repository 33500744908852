import { Accordion, AccordionDetails, AccordionSummary, Card, CardHeader, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TenantService } from "services/TenantServices";
import { CrossTenantDocument } from "types/crossTenantDocument";
import BoxAddElement from "ui-component/BoxAddElement";
import DocumentCard from "ui-component/DocumentCard";
import DescriptionIcon from '@mui/icons-material/Description';
import { handleDownloadFile } from "utils/common";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SuperAdminPage = () => {
    const tenantService = new TenantService();

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [crossTenantDocuments, setCrossTenantDocuments] = useState<Array<CrossTenantDocument>>([]);

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            // Documents retrieve
            await updateDocuments();
        })();
    }, [])

    const updateDocuments = async () => {
        setIsPageLoading(true);

        (async () => {
            const crossTenantDocuments: Array<CrossTenantDocument> = await tenantService.GetCrossTenantDocuments();
            setCrossTenantDocuments(crossTenantDocuments);

            setIsPageLoading(false);
        })();
    }

    const downloadDocument = async (documentId: string, documentName: string) => {
        const crossTenantDocumentPdfBase64: string = await tenantService.DownloadCrossTenantDocument(documentId);
        handleDownloadFile(crossTenantDocumentPdfBase64, documentName);
    }

    const deleteDocument = async (documentId: string) => {
        await tenantService.DeleteCrossTenantDocument(documentId);
        updateDocuments();
    }

    return (
        <Card>
            <CardHeader title={<FormattedMessage id="superAdminSettings" />} />
            <Accordion sx={{ mb: 1 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="crossTenantPreliminaryDocumentsSummary"
                    id="crossTenantPreliminaryDocumentsSummary"
                >
                    <Typography> <FormattedMessage id="showCrossTenantDocuments" /> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row" spacing={2}>
                        {
                            isPageLoading ?
                                <Grid item
                                    lg={2}
                                    md={2}
                                    xs={12}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={180}
                                        height={200}
                                        sx={{ bgcolor: 'grey.300', borderRadius: '10px' }}
                                    />
                                </Grid> :
                                <>
                                    {
                                        crossTenantDocuments.map((document: CrossTenantDocument) => (
                                            <Grid item
                                                lg={2}
                                                md={2}
                                                xs={12}
                                            >
                                                <DocumentCard
                                                    navigatePath={"/superAdminSettings/documents/edit"}
                                                    documentId={document.id!}
                                                    documentName={document.fileName}
                                                    downloadDocument={downloadDocument}
                                                    deleteDocument={deleteDocument}
                                                    icon={<DescriptionIcon sx={{ fontSize: 48 }} />}
                                                />
                                            </Grid>
                                        ))
                                    }
                                    <Grid item
                                        lg={2}
                                        md={2}
                                        xs={12}
                                    >
                                        <BoxAddElement onClick={() => navigate(`/superAdminSettings/documents/create`)} />
                                    </Grid>
                                </>

                        }
                    </Grid>

                </AccordionDetails>
            </Accordion>
        </Card>
    )
}

export default SuperAdminPage;