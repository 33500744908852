import { Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { SerieNumeratoreService } from "services/SerieNumeratoreServices";
import { tipiNumeratore } from "types/enums/TipoNumeratore";
import { SerieNumeratore, defaultSerieNumeratore } from "types/serieNumeratore";
import CardContentLoader from "ui-component/CardContentLoader";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import { composeNotistackMessage, getFormValue } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { object, string, number } from "yup";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

interface NumeratoreUpsertProps {
	isAdd: boolean;
}

const NumeratoreUpsert: FC<NumeratoreUpsertProps> = (props) => {
	const { isAdd } = props;

	const intl = useIntl();

	const serieNumeratoreService = new SerieNumeratoreService();
	const [serieNumeratore, setSerieNumeratore] = useState<SerieNumeratore>(defaultSerieNumeratore);

	let serieNumeratoreValidationSchema = object({
		nome: string().required('nameRequired'),
		anno: number().required('yearRequired').min(1920, 'yearValueMin').typeError('yearValueMustBeNumber'),
		numero: number().required('numberRequired').min(0, 'numberMinZero').typeError('numberMustBeNumber'),
		tipo: number().required('typeRequired'),
	});

	const navigate = useNavigate();

	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsPageLoading(true);
		(async () => {
			if (!isAdd) {
				let serieNumeratoreId: string = window.location.href.split('serieNumeratoreId=')[1] ?? '';
				let retrievedSerieNumeratore: SerieNumeratore = await serieNumeratoreService.GetSerieNumeratoreById(serieNumeratoreId);

				setSerieNumeratore(retrievedSerieNumeratore);
			}
			setIsPageLoading(false);
		})();
	}, [])

	const handleChange = (e: any) => {
		let { name, value } = getFormValue(e);
		setSerieNumeratore((currentFormData: any) => ({ ...currentFormData, [name]: value }));
	}

	const serieNumeratoreUpsert = async () => {
		try {
			await serieNumeratoreValidationSchema.validate(serieNumeratore, { abortEarly: false });

			await serieNumeratoreService.UpsertSerieNumeratore(serieNumeratore);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');

			if (isAdd) navigate('/serieNumerators');
		} catch (validationErrors: any) {
			handleUpsertErrors(validationErrors.inner, intl);
		}
	}

	const [deleteNumeratoreDialogOpen, setDeleteNumeratoreDialogOpen] = useState<boolean>(false);

	const deleteSerieNumeratore = async () => {
		try {
			await serieNumeratoreService.DeleteSerieNumeratore(serieNumeratore.id!);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullyDelete' }), 'success');

			navigate('/serieNumerators');
		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		}
	};

	return (
		<Card>
			<DialogDeleteElement
				open={deleteNumeratoreDialogOpen}
				onClose={() => setDeleteNumeratoreDialogOpen(false)}
				onCancel={() => setDeleteNumeratoreDialogOpen(false)}
				onConfirm={() => {
					setDeleteNumeratoreDialogOpen(false)
					deleteSerieNumeratore();
				}}
				entityToDeleteInfo={serieNumeratore.nome}
			/>
			<CardHeader title={<FormattedMessage id="serieNumeratorData" />} />
			<Divider />
			{
				isPageLoading ?
					<CardContentLoader /> :
					(
						<CardContent>
							<Grid container direction="row" spacing={1}>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="name" />}
										name="nome"
										required
										onChange={handleChange}
										value={serieNumeratore.nome}
									/>
								</Grid>
								<Grid
									item
									lg={2}
									md={2}
									xs={12}
								>
									<TextField
										required
										fullWidth
										size='small'
										label={<FormattedMessage id="year" />}
										name="anno"
										onChange={handleChange}
										value={serieNumeratore.anno}
									/>
								</Grid>
								<Grid
									item
									lg={3}
									md={3}
									xs={12}
								>
									<FormControl fullWidth required size='small'>
										<InputLabel> <FormattedMessage id="type" /> </InputLabel>
										<Select
											name="tipo"
											label={<FormattedMessage id="type" />}
											onChange={handleChange}
											sx={{ color: 'white' }}
											value={serieNumeratore.tipo}
										>
											{
												tipiNumeratore.slice()
													.map((serie) => (
														<MenuItem key={serie.id} value={serie.id}>
															{serie.name}
														</MenuItem>
													))
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									lg={2}
									md={2}
									xs={12}
								>
									<TextField
										required
										fullWidth
										size='small'
										label={<FormattedMessage id="number" />}
										name="numero"
										onChange={handleChange}
										value={serieNumeratore.numero}
									/>
								</Grid>
								<Grid
									item
									lg={2}
									md={2}
									xs={12}>
									<FormControlLabel
										control={
											<Checkbox
												checked={serieNumeratore.default}
											/>
										}
										label={<FormattedMessage id="defaultSeries" />}
										name="default"
										labelPlacement="start"
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</CardContent>
					)
			}
			<Divider />
			<Grid container>
				<Grid item
					xs={6}
					md={6}
					lg={6}
				>
					{!isAdd && <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-start' }}>
						<Button
							color="error"
							type="submit"
							variant="contained"
							onClick={() => setDeleteNumeratoreDialogOpen(true)}
							startIcon={<DeleteIcon />}
						>
							<FormattedMessage id="delete" />
						</Button>
					</Box>}
				</Grid>
				<Grid item
					xs={6}
					md={6}
					lg={6}
				>
					<Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							color="primary"
							type="submit"
							variant="contained"
							onClick={serieNumeratoreUpsert}
							startIcon={<SaveIcon />}
						>
							<FormattedMessage id="save" />
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Card>
	)
};

export default NumeratoreUpsert;
