import { toFirstUpper } from "utils/common";

export enum TemplateType {
    Privacy,
    Preliminare,
    Lapide,
    Altro,
    Comunicazione,
}

export const templateTypeMap: Record<string, TemplateType> = {
    privacy: TemplateType.Privacy,
    preliminare: TemplateType.Preliminare,
    lapide: TemplateType.Lapide,
    altro: TemplateType.Altro,
    comunicazione: TemplateType.Comunicazione,
}