import { Grid, IconButton, Table, TableBody, TableCell, TableRow, FormControlLabel, Checkbox, TableContainer, Typography, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PaymentToDo } from "types/paymentToDo";
import { PaginationResult } from "types/paginationResult";
import { Link as RouterLink } from 'react-router-dom';
import { IconFiles } from '@tabler/icons';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { CommunicationType } from "types/enums/CommunicationType";
import ReceiptIcon from '@mui/icons-material/Receipt';
import { MoreVert } from "@mui/icons-material";
import { getCurrencyByNumber } from "utils/common";

interface PaymentToDoWithMenuProps {
    paymentToDo: PaymentToDo;
    updateEntityToSendCommunication: (paymentToDo: PaymentToDo, communicationType: CommunicationType) => void;
    updateEntityToDownloadBulletin: (paymentToDo: PaymentToDo) => void;
    isPaymentToDoChecked: (paymentToDo: PaymentToDo) => boolean;
    checkPaymentToDo: (e: any, paymentToDo: PaymentToDo) => void;
}

const PaymentToDoWithMenu: FC<PaymentToDoWithMenuProps> = (props) => {
    const { paymentToDo, updateEntityToSendCommunication, updateEntityToDownloadBulletin, isPaymentToDoChecked, checkPaymentToDo } = props;

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={paymentToDo.placeServiceId}>
            <TableCell>
                <Grid container direction="row" sx={{
                    alignItems: 'center',
                }}>
                    <Grid item
                        lg={3}
                        md={3}
                        xs={3}
                    >
                        <Checkbox key={`check-paymentToDo-${paymentToDo.placeServiceId}`}
                            checked={isPaymentToDoChecked(paymentToDo)}
                            onChange={(e: any) => checkPaymentToDo(e, paymentToDo)}
                        />
                    </Grid>
                    <Grid item
                        lg={9}
                        md={9}
                        xs={9}
                    >
                        <Grid container direction="column">
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="name" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {paymentToDo.payerFullName} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="description" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {paymentToDo.placeServiceDescription} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="code" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {paymentToDo.placeServiceBankTransferPaymentCode} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="serviceType" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> <FormattedMessage id={paymentToDo.annual ? 'annual' : 'unaTantum'} /> </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="expireDate" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {paymentToDo.expireDate} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="year" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {paymentToDo.year} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="toPay" /> (€) </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {getCurrencyByNumber(paymentToDo.amountToPay)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="totalAmount" /> (€) </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {getCurrencyByNumber(paymentToDo.totalAmount)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Typography variant="h6" align="center"> <FormattedMessage id="lastNotification" /> </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {paymentToDo.lastWarning} </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`paymentToDo-actions-menu-${paymentToDo.placeServiceId}`}
                    MenuListProps={{
                        'aria-labelledby': `paymentToDo-action-button-${paymentToDo.placeServiceId}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem key={'goToDossier'} onClick={handleClose}>
                        <IconButton component={RouterLink} to={`/dossiers/edit?dossierId=${paymentToDo.dossierId}`}>
                            <IconFiles fontSize="small" style={{ marginRight: '3px' }} />
                            <Typography variant="subtitle1"> <FormattedMessage id="goToDossier" /> </Typography>
                        </IconButton>
                    </MenuItem>
                    {
                        paymentToDo.hasEmail &&
                        <MenuItem>
                            <IconButton onClick={() => updateEntityToSendCommunication(paymentToDo, CommunicationType.Email)}>
                                <EmailIcon fontSize="small" sx={{ mr: '3px' }} />
                                <Typography variant="subtitle1"> <FormattedMessage id="sendEmail" /> </Typography>
                            </IconButton>
                        </MenuItem>
                    }
                    {
                        paymentToDo.hasPhoneNumber &&
                        <MenuItem>
                            <IconButton onClick={() => updateEntityToSendCommunication(paymentToDo, CommunicationType.Whatsapp)}>
                                <WhatsAppIcon fontSize="small" sx={{ mr: '3px' }} />
                                <Typography variant="subtitle1"> <FormattedMessage id="sendWhatsapp" /> </Typography>
                            </IconButton>
                        </MenuItem>
                    }
                    <MenuItem>
                        <IconButton onClick={() => updateEntityToDownloadBulletin(paymentToDo)} >
                            <ReceiptIcon fontSize="small" sx={{ mr: '3px' }} />
                            <Typography variant="subtitle1"> <FormattedMessage id="downloadBulletin" /> </Typography>
                        </IconButton>
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow >
    );
}

interface PaymentsToDoPageMobile {
    paymentsToDo: PaginationResult<PaymentToDo>;
    checkAllPaymentsToDo: (e: any) => void;
    isPaymentToDoChecked: (paymentToDo: PaymentToDo) => boolean;
    checkPaymentToDo: (e: any, paymentToDo: PaymentToDo) => void;
    updateEntityToSendCommunication: (paymentToDo: PaymentToDo, communicationType: CommunicationType) => void;
    updateEntityToDownloadBulletin: (paymentToDo: PaymentToDo) => void;
}

const PaymentsToDoPageMobile: FC<PaymentsToDoPageMobile> = (props) => {
    const { paymentsToDo, checkAllPaymentsToDo, isPaymentToDoChecked, checkPaymentToDo, updateEntityToSendCommunication, updateEntityToDownloadBulletin } = props;

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={<b><FormattedMessage id="all" /></b>}
                                labelPlacement="end"
                                onChange={(e: any) => checkAllPaymentsToDo(e)}
                            />
                        </TableCell>
                    </TableRow>
                    {paymentsToDo && paymentsToDo.items?.map((paymentToDo) => (
                        <PaymentToDoWithMenu
                            key={paymentToDo.placeServiceId}
                            paymentToDo={paymentToDo}
                            updateEntityToSendCommunication={updateEntityToSendCommunication}
                            updateEntityToDownloadBulletin={updateEntityToDownloadBulletin}
                            isPaymentToDoChecked={isPaymentToDoChecked}
                            checkPaymentToDo={checkPaymentToDo}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PaymentsToDoPageMobile;