import { createSlice } from '@reduxjs/toolkit';


export const splitDossierPlacesDossiersSlice = createSlice({
    name: 'splittedDossierPlacesDossiers',
    initialState: {
        listSplittedDossierPlacesDossiers: []
    },
    reducers: {
        setSplittedDossierPlacesDossiers(state, action) {
            state.listSplittedDossierPlacesDossiers = action.payload;
        }
    }
});

export const { setSplittedDossierPlacesDossiers } = splitDossierPlacesDossiersSlice.actions;

export default splitDossierPlacesDossiersSlice.reducer;