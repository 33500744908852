/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { Pagamento } from 'types/pagamento';
import { RecordedPayment } from 'types/recordedPayment';
import { PaymentRedirectData } from 'types/paymentRedirectData';
import { PaymentToDo } from 'types/paymentToDo';
import { PaginationQuery } from 'types/paginationQuery';
import { PaginationResult } from 'types/paginationResult';
import { PaymentDone } from 'types/paymentDone';
import { UnviewedPayment } from 'types/unviewedPayment';

export class PagamentoService {
    async GetServizioPostoPagamenti(servizioPostoId: string): Promise<Array<Pagamento>> {
        const result = await axios.post<Array<Pagamento>>(`${serviceConfig.appServiceBaseUrl}/GetServizioPostoPagamenti`, servizioPostoId);
        return result.data;
    }

    async UpsertServizioPostoPagamento(pagamento: Pagamento): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpsertServizioPostoPagamento`, pagamento);
        return result.data;
    }

    async RemoveServizioPostoPagamento(pagamentoId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/RemovePagamentoFromServizioPosto`, pagamentoId);
        return result.data;
    }

    async RecordPayments(paymentsFileBase64: string): Promise<Array<RecordedPayment>> {
        const result = await axios.post<Array<RecordedPayment>>(`${serviceConfig.appServiceBaseUrl}/RecordPayments`, { fileBase64: paymentsFileBase64 });
        return result.data;
    }

    async GetPagamentiByIds(pagamentiIds: string[]): Promise<Array<Pagamento>> {
        const result = await axios.post<Array<Pagamento>>(`${serviceConfig.appServiceBaseUrl}/GetPayments`, pagamentiIds);
        return result.data;
    }

    async ExecutePayment(amountToPay: string, postPaymentRedirectUri: string): Promise<PaymentRedirectData | null> {
        const result = await axios.post<PaymentRedirectData | null>(`${serviceConfig.appServiceBaseUrl}/ExecutePayment`, {
            amountToPay: amountToPay,
            postPaymentRedirectUri: postPaymentRedirectUri
        });
        return result.data;
    }

    async UpdateUserPayment(paymentData: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpdateUserPayment`, paymentData);
        return result.data;
    }

    async GetPaymentsToDo(getPaymentsToDoData: object, paginationQuery: PaginationQuery): Promise<PaginationResult<PaymentToDo>> {
        const result = await axios.post<PaginationResult<PaymentToDo>>(`${serviceConfig.appServiceBaseUrl}/GetPaymentsToDo`, {
            ...getPaymentsToDoData,
            ...paginationQuery
        });
        return result.data;
    }

    async SendPaymentCommunications(sendPaymentCommunicationsInfo: object): Promise<boolean> {
        const result = await axios.post<boolean>(`${serviceConfig.appServiceBaseUrl}/SendPaymentCommunications`, sendPaymentCommunicationsInfo);
        return result.data;
    }

    async GetPaymentsDone(getPaymentsDone: object, paginationQuery: PaginationQuery): Promise<PaginationResult<PaymentDone>> {
        const result = await axios.post<PaginationResult<PaymentDone>>(`${serviceConfig.appServiceBaseUrl}/GetPaymentsDone`, {
            ...getPaymentsDone,
            ...paginationQuery
        });
        return result.data;
    }

    async GetUnviewedPayments(): Promise<Array<UnviewedPayment>> {
        const result = await axios.post<Array<UnviewedPayment>>(`${serviceConfig.appServiceBaseUrl}/GetUnviewedPayments`);
        return result.data;
    }

    async SetPaymentsViewed(paymentsIds: Array<string>): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/SetPaymentsViewed`, { paymentsIds: paymentsIds });
        return result.data;
    }

    async UpdateUserPaymentByAdmin(updateUserPaymentObject: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpdateUserPaymentByAdmin`, updateUserPaymentObject);
        return result.data;
    }
}