import { FC, useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { PaginationResult } from 'types/paginationResult';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { getShortDate, isNotNullOrUndefined } from 'utils/common';
import { Transfer } from 'types/transfer';
import { Link as RouterLink } from "react-router-dom";
import { MoreVert } from '@mui/icons-material';
import Coffin from "ui-component/icons/Coffin";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';

interface TransferWithMenuProps {
    transfer: Transfer;
}

const TransferWithMenu: FC<TransferWithMenuProps> = (props) => {
    const { transfer } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={`${transfer.date}-${transfer.decuiusName}`}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="date" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getShortDate(transfer.date)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="operator" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {transfer.operatorName.substring(0, 1)} {transfer.operatorSurname.substring(0, 1)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="sourceStructure" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {transfer.sourceStructure} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="sourcePlace" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {transfer.sourcePlace} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="destinationStructure" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {transfer.destinationStructure} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="destinationPlace" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {transfer.destinationPlace} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="decuiusName" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {transfer.decuiusName} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="causal" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {transfer.causal} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`transfer-actions-menu-${transfer.id}`}
                    MenuListProps={{
                        'aria-labelledby': `transfer-actions-menu-${transfer.id}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    {
                        isNotNullOrUndefined(transfer.sourceStructureId) &&
                        <MenuItem key={'goToSourceStructure'} onClick={handleClose}>
                            <IconButton
                                component={RouterLink}
                                to={`/structures/edit?strutturaId=${transfer.sourceStructureId}`}
                            >
                                <UpgradeIcon sx={{ mr: '3px' }} />
                                <Typography variant="subtitle1"> <FormattedMessage id="goToSourceStructure" /> </Typography>
                            </IconButton>
                        </MenuItem>
                    }
                    {
                        isNotNullOrUndefined(transfer.destinationStructureId) &&
                        <MenuItem key={'goToDestinationStructure'} onClick={handleClose}>
                            <IconButton
                                component={RouterLink}
                                to={`/structures/edit?strutturaId=${transfer.destinationStructureId}`}
                            >
                                <VerticalAlignBottomIcon sx={{ mr: '3px' }} />
                                <Typography variant="subtitle1"> <FormattedMessage id="goToDestinationStructure" /> </Typography>
                            </IconButton>
                        </MenuItem>
                    }
                    <MenuItem key={'goToDecuius'} onClick={handleClose}>
                        <IconButton
                            component={RouterLink}
                            to={`/decuius/edit?decuiusId=${transfer.decuiusId}`}
                        >
                            <Coffin sx={{ width: 20, mr: '3px' }} />
                            <Typography variant="subtitle1"> <FormattedMessage id="goToDecuius" /> </Typography>
                        </IconButton>
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
}

interface TransferRegisterPageMobileProps {
    transfers: PaginationResult<Transfer>;
    isPageLoading: boolean;
}

const TransferRegisterPageMobile: FC<TransferRegisterPageMobileProps> = (props) => {
    const { transfers, isPageLoading } = props;

    return (
        <Table>
            <TableBody>
                {
                    isPageLoading ? (
                        <TableRowsLoader rowsNum={3} colsNum={1} />
                    ) :
                        (<>
                            {transfers && transfers.items?.map((transfer, index) => (
                                <TransferWithMenu
                                    key={index}
                                    transfer={transfer}
                                />
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
};

export default TransferRegisterPageMobile;
