import { createSlice } from '@reduxjs/toolkit';


export const invoicesPaymentsIdsSlice = createSlice({
    name: 'invoicesPaymentsIds',
    initialState: {
        listInvoicesPaymentsIds: []
    },
    reducers: {
        setInvoicesPaymentsIds(state, action) {
            state.listInvoicesPaymentsIds = action.payload;
        }
    }
});

export const { setInvoicesPaymentsIds } = invoicesPaymentsIdsSlice.actions;

export default invoicesPaymentsIdsSlice.reducer;