import { createSlice } from '@reduxjs/toolkit';

export const newUserIdToAssignSlice = createSlice({
    name: 'newUserIdToAssign',
    initialState: {
        userIdToAssign: ''
    },
    reducers: {
        setUserIdToAssign(state, action) {
            state.userIdToAssign = action.payload;
        }
    }
});

export const { setUserIdToAssign } = newUserIdToAssignSlice.actions;

export default newUserIdToAssignSlice.reducer;