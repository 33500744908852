import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ZonaService } from "services/ZonaServices";
import { Zona, defaultZona } from "types/zona";
import CardContentLoader from "ui-component/CardContentLoader";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import { composeNotistackMessage, getFormValue } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { object, string } from "yup";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

interface ZonaUpsertProps {
	isAdd: boolean;
}

const ZonaUpsert: FC<ZonaUpsertProps> = (props) => {
	const { isAdd } = props;

	const intl = useIntl();

	const zonaService = new ZonaService();
	const [zona, setZona] = useState<Zona>(defaultZona);

	let zonaValidationSchema = object({
		descrizione: string().required('descriptionRequired')
	});

	const navigate = useNavigate();

	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsPageLoading(true);

		(async () => {
			if (!isAdd) {
				let zonaId: string = window.location.href.split('zonaId=')[1] ?? '';
				let retrievedZona: Zona = await zonaService.GetZonaById(zonaId);

				setZona(retrievedZona);
			}
			setIsPageLoading(false);
		})();
	}, [])

	const handleChange = (e: any) => {
		let { name, value } = getFormValue(e);
		setZona((currentFormData: any) => ({ ...currentFormData, [name]: value }));
	}

	const zonaUpsert = async () => {
		try {
			await zonaValidationSchema.validate(zona, { abortEarly: false });

			await zonaService.UpsertZona(zona);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');

			if(isAdd) navigate('/zones');
		} catch (validationErrors: any) {
			handleUpsertErrors(validationErrors.inner, intl);
		}
	}

	const [deleteZonaDialogOpen, setDeleteZonaDialogOpen] = useState<boolean>(false);

	const deleteZona = async () => {
		try {
			await zonaService.DeleteZona(zona.id!);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullyDelete' }), 'success');

			navigate('/zones');
		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		}
	};

	return (
		<Card>
			<DialogDeleteElement
				open={deleteZonaDialogOpen}
				onClose={() => setDeleteZonaDialogOpen(false)}
				onCancel={() => setDeleteZonaDialogOpen(false)}
				onConfirm={() => {
					setDeleteZonaDialogOpen(false)
					deleteZona();
				}}
				entityToDeleteInfo={zona.descrizione}
			/>
			<CardHeader title={<FormattedMessage id="zoneData" />} />
			<Divider />
			{
				isPageLoading ?
					<CardContentLoader /> :
					(
						<CardContent>
							<Grid container>
								<Grid
									item
									lg={12}
									md={12}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="description" />}
										name="descrizione"
										required
										onChange={handleChange}
										value={zona.descrizione}
									/>
								</Grid>
							</Grid>
						</CardContent>
					)
			}
			<Divider />
			<Grid container>
				<Grid item
					xs={6}
					md={6}
					lg={6}
				>
					{!isAdd && <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-start' }}>
						<Button
							color="error"
							type="submit"
							variant="contained"
							onClick={() => setDeleteZonaDialogOpen(true)}
							startIcon={<DeleteIcon />}
						>
							<FormattedMessage id="delete" />
						</Button>
					</Box>}
				</Grid>
				<Grid item
					xs={6}
					md={6}
					lg={6}
				>
					<Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							color="primary"
							type="submit"
							variant="contained"
							onClick={zonaUpsert}
							startIcon={<SaveIcon />}
						>
							<FormattedMessage id="save" />
						</Button>
					</Box>
				</Grid>
			</Grid>

		</Card>
	)
};

export default ZonaUpsert;
