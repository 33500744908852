/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { Agenzia } from '../types/agenzia';
import { PaginationQuery } from 'types/paginationQuery';
import { PaginationResult } from 'types/paginationResult';

export class AgenziaService {
    async GetAgenzie(paginationQuery: PaginationQuery): Promise<PaginationResult<Agenzia>> {
        const result = await axios.post<PaginationResult<Agenzia>>(`${serviceConfig.appServiceBaseUrl}/GetAgenzie`, paginationQuery);
        return result.data;
    }

    async GetUnpaginatedAgenzie(): Promise<Agenzia[]> {
        const result = await axios.get<Agenzia[]>(`${serviceConfig.appServiceBaseUrl}/GetAllAgenzie`);
        return result.data;
    }

    async DeleteAgenzia(agenziaId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteAgenzia`, agenziaId);
        return result.data;
    }

    async GetAgenziaById(agenziaId: string): Promise<Agenzia> {
        const result = await axios.post<Agenzia>(`${serviceConfig.appServiceBaseUrl}/GetAgenzia`, agenziaId);
        return result.data;
    }

    async UpsertAgenzia(agenzia: Agenzia): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpsertAgenzia`, agenzia);
        return result.data;
    }
}