import { createSvgIcon } from "@mui/material";

const Payments = createSvgIcon(
	<svg fill="#000000" height="24" width="24" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
		<g>
			<g>
            <path d="m12 0a12 12 0 1 0 12 12 12.035 12.035 0 0 0 -12-12zm0 22a10 10 0 1 1 10-10 10.029 10.029 0 0 1 -10 10zm3-13a1 1 0 0 0 2 0 4.012 4.012 0 0 0 -4-4v-1a1 1 0 0 0 -2 0v1a4 4 0 0 0 0 8v4a2.006 2.006 0 0 1 -2-2 .945.945 0 0 0 -1-1 .945.945 0 0 0 -1 1 4.012 4.012 0 0 0 4 4v1a1 1 0 0 0 2 0v-1a4 4 0 0 0 0-8v-4a2.006 2.006 0 0 1 2 2zm0 6a2.006 2.006 0 0 1 -2 2v-4a2.006 2.006 0 0 1 2 2zm-4-4a2 2 0 0 1 0-4z"/>
			</g>
		</g>
	</svg>, 'Payments'

);

export default Payments;