import { Button, Card, CardActionArea, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UserService } from "services/UserServices";
import BackdropLoader from "ui-component/BackdropLoader";
import LoginIcon from '@mui/icons-material/Login';
import ReplayIcon from '@mui/icons-material/Replay';
import { useKeycloak } from "keycloak-react-web";
import { composeNotistackMessage, getFormValue, isNotNullOrUndefined } from "utils/common";
//@ts-ignore
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";

const GenerateCredentials = () => {
    const userService = new UserService();
    const intl = useIntl();

    const { loggedUser } = useSelector((state) => state.loggedUser);

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [messageData, setMessageData] = useState<{ message: string, success: boolean } | null>(null);

    const { keycloak } = useKeycloak();

    const [isAccepted, setIsAccepted] = useState<boolean>(false);

    const [captchaText, setCaptchaText] = useState<string>('');

    const navigate = useNavigate();

    useEffect(() => {
        loadCaptchaEnginge(8, 'green');
    }, [])

    useEffect(() => {
        if (loggedUser && loggedUser != null) {
            navigate('/');
        }
    }, [loggedUser]);

    const generateCredentials = async () => {
        setIsPageLoading(true);

        const url: string = window.location.href;

        let fiscalCode: string = url.split('SVjAUjvmOB=')[1] ?? '';
        if (fiscalCode !== '') {
            fiscalCode = fiscalCode.split('&')[0];
        }

        let mailMobileNumber: string = url.split('eAFaeiwAUU=')[1] ?? '';
        if (mailMobileNumber !== '') {
            mailMobileNumber = mailMobileNumber.split('&')[0];
        }

        let tenantId: string = url.split('jnQncuLoei=')[1] ?? '';
        if (tenantId !== '') {
            tenantId = tenantId.split('&')[0];
        }

        try {
            if (validateCaptcha(captchaText)) {
                const generateCredentialsObject: { fiscalCode: string, mailMobileNumber: string, tenantId?: string } = {
                    fiscalCode: fiscalCode,
                    mailMobileNumber: mailMobileNumber
                };

                if (isNotNullOrUndefined(tenantId)) {
                    generateCredentialsObject.tenantId = tenantId;
                }

                await userService.GenerateCredentials(generateCredentialsObject);

                setMessageData({
                    message: intl.formatMessage({ id: 'credentialsSuccessfullyGenerated' }),
                    success: true
                });
            } else {
                setCaptchaText('');
                composeNotistackMessage(intl.formatMessage({ id: 'wrongCaptcha' }), 'error');
            }
        } catch (e: any) {
            setMessageData({
                message: intl.formatMessage({ id: 'errorGeneratingCredentials' }),
                success: false
            });
        } finally {
            setIsPageLoading(false);
        }
    }

    const handleChange = (e: any) => {
        let { value } = getFormValue(e);
        setCaptchaText(value);
    }

    const reinitializePage = () => {
        setMessageData(null);
        setCaptchaText('');
    }

    const redirectToLogin = () => {
        keycloak.login();
    }

    useEffect(() => {
        if (messageData == null) loadCaptchaEnginge(8, 'green');
    }, [messageData])

    return (
        <>
            <BackdropLoader open={isPageLoading} />

            {
                messageData === null ?
                    <Card
                        sx={{
                            borderRadius: 1,
                            boxShadow: 2,
                            padding: 2,
                        }}
                    >
                        <CardContent>
                            <Grid container direction="row" rowSpacing={3}>
                                <Grid item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    sx={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography variant="subtitle1">
                                        La presente per metterla a conoscenza, che da oggi potrà accedere al nostro portale <a href="https://osiride.orangedev.it">Osiride</a>. La username sarà il suo codice fiscale e la password verrà generata ed inviata via mail dopo aver preso visione ed accettato il testo corrente.<br />
                                        All'interno del portale potrà visionare le pratiche abbinate al posto occupato dal vostro caro estinto, con i servizi ad esso associati e le relative scadenze.<br />
                                        Cogliamo l'occasione inoltre per metterla a conoscenza che tutte le scadenze relative ai servizi di lampada votiva annuale, non verranno più inviate a mezzo posta, ma solo in formato digitale (link Whatsapp o email).
                                        Vi preghiamo inoltre di aggiornare i vostri recapiti telefonici ed indirizzi mail accedendo allo stesso portale.<br />
                                        Cogliamo l'occasione per porgere cordiali Saluti.
                                    </Typography>
                                </Grid>
                                <Grid item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    sx={{
                                        textAlign: 'end'
                                    }}
                                >
                                    <Typography><b>LA DIREZIONE</b></Typography>
                                </Grid>
                                <Grid item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    sx={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAccepted}
                                            />
                                        }
                                        label={<FormattedMessage id="credentialsGenerationAcceptText" />}
                                        name="default"
                                        labelPlacement="start"
                                        onChange={() => setIsAccepted(!isAccepted)}
                                    />
                                </Grid>
                                <Grid item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center'
                                    }}
                                >
                                    <Box sx={{ m: 3, width: '300px' }}>
                                        <LoadCanvasTemplate />
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={<FormattedMessage id="captchaText" />}
                                            name="captchaText"
                                            required
                                            onChange={handleChange}
                                            value={captchaText}
                                            sx={{ mt: 1 }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActionArea>
                            <Button sx={{ width: '100%' }} variant="contained" onClick={generateCredentials} disabled={!isAccepted || !isNotNullOrUndefined(captchaText)}> <FormattedMessage id="generateCredentials" /> </Button>
                        </CardActionArea>
                    </Card> :
                    <Box
                        sx={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            borderRadius: 1,
                            boxShadow: 2,
                            padding: '30px',
                            backgroundColor: messageData.success ? '#92f890' : '#E67D72'
                        }}>
                        <Typography variant="h4">{messageData.message}</Typography>

                        <Typography
                            variant="h5"
                            sx={{
                                mt: 2,
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                            onClick={() => {
                                messageData.success === true ? redirectToLogin() : reinitializePage();
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {
                                    messageData.success === true ?
                                        <>
                                            <FormattedMessage id="login" /> <LoginIcon fontSize="small" sx={{ ml: 1 }} />
                                        </> :
                                        <>
                                            <FormattedMessage id="retry" /> <ReplayIcon fontSize="small" sx={{ ml: 1 }} />
                                        </>
                                }
                            </Box>
                        </Typography>
                    </Box>
            }
        </>
    )
}

export default GenerateCredentials;