import { Decuius } from "./decuius";
import { Tenant } from "./tenant";

export interface Utente {
    id?: string;

    nome: string;
    cognome: string;
    dataNascita?: string;
    luogoNascita?: string;
    provinciaNascita?: string;
    capNascita?: string;
    statoNascita?: string;
    codiceFiscale?: string;
    decuiusId?: string;
    decuius?: Decuius;
    sesso?: number;
    isSuperAdmin?: boolean;
    adminMail?: string;
    currentTenantId?: string;
    username?: string;
    tenantsList?: Array<Tenant>;
    isCurrentTenantAdmin?: boolean;

    indirizzo?: string;
    civico?: string;
    comune?: string;
    provincia?: string;
    cap?: string;
    stato?: string;
    mail?: string;
    telefono?: string;
    cellulare?: string;
    note?: string;

    dateOfInsert?: string;
    isValid?: boolean;

    strutturaId?: string;
    regioneId?: string;
    regioneNascitaId?: string;

    userOfUpdate?: string;
    dateOfUpdate?: string;

    tenantsIds?: Array<string>;

    showQrCodeCommunicationButton?: boolean;
}

export const defaultUtente: Utente = {
    username: '',
    nome: '',
    cognome: '',
    statoNascita: 'Italia',
    stato: 'Italia',
    sesso: 0
}