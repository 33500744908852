import { useMediaQuery, Typography, useTheme } from '@mui/material';
import { Box, Container } from '@mui/system';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const UnauthorizedPage: FC = () => {
    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('sm'));

    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    minHeight: '100%',
                    px: 3,
                    py: '80px'
                }}
            >
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant={mobileDevice ? 'h4' : 'h1'}
                    >
                        <FormattedMessage id="authorizationRequiredTitle" />
                    </Typography>
                    <Typography
                        align="center"
                        color="textSecondary"
                        sx={{ mt: 0.5 }}
                        variant="h5"
                    >
                        <FormattedMessage id="authorizationRequiredDescription" />
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6
                        }}
                    >
                        <Box
                            alt="Under development"
                            component="img"
                            src={`/unauthorized.svg`}
                            sx={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: 400
                            }}
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default UnauthorizedPage;
