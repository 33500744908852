import { Card, CardHeader, Divider, Grid, TablePagination, TextField, debounce, useMediaQuery, useTheme } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { DecuiusService } from "services/DecuiusServices";
import { Decuius } from "types/decuius";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import DepositPageMobile from "./DepositPageMobile";
import DepositPage from "./DepositPage";
import { getFormValue, isNotNullOrUndefined, retrieveCookieByKey, saveFiltersPaginationCookie } from "utils/common";
import { useSelector } from "store";

interface DepositFilters {
    protocollo: string;
    nome: string;
    cognome: string;
}

const DepositList = () => {
    const { loggedUser } = useSelector((state) => state.loggedUser);
    const intl = useIntl();

    const decuiusService = new DecuiusService();

    const [allDecuiusToAssign, setAllDecuiusToAssign] = useState<PaginationResult<Decuius>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    const [filters, setFilters] = useState<DepositFilters>({
        protocollo: "",
        cognome: "",
        nome: "",
    });

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    useEffect(() => {
        const filtersPaginationCookie = retrieveCookieByKey(loggedUser.currentTenantId, 'depositListFilters');
        if (isNotNullOrUndefined(filtersPaginationCookie)) {
            setFilters(filtersPaginationCookie.filters);
            setPaginationQuery(filtersPaginationCookie.paginationQuery);
        }
    }, [])

    useEffect(() => {
        updateAllDecuiusToAssign();
    }, [paginationQuery]);

    const updateAllDecuiusToAssign = () => {
        setIsPageLoading(true);
        getAllDecuiusToAssign(paginationQuery, filters);

        saveFiltersPaginationCookie(loggedUser.currentTenantId, 'depositListFilters', {
            filters: filters,
            paginationQuery: paginationQuery
        }, 1);
    }

    const getAllDecuiusToAssign = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: DepositFilters) => {
        const retrievedAllDecuiusToAssign = await decuiusService.GetDecuiusToAssign(paginationQuery, filters);
        setAllDecuiusToAssign(retrievedAllDecuiusToAssign);

        setIsPageLoading(false);
    }, 700), []);

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    useEffect(() => {
        updateAllDecuiusToAssign();
    }, [paginationQuery, filters]);

    return (<>
        <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid
                item
                lg={4}
                md={4}
                xs={12}
            >
                <TextField
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="protocol" />}
                    name="protocollo"
                    onChange={handleFiltersChange}
                    value={filters.protocollo}
                />
            </Grid>
            <Grid
                item
                lg={4}
                md={4}
                xs={12}
            >
                <TextField
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="surname" />}
                    name="cognome"
                    onChange={handleFiltersChange}
                    value={filters.cognome}
                />
            </Grid>
            <Grid
                item
                lg={4}
                md={4}
                xs={12}
            >
                <TextField
                    fullWidth
                    size='small'
                    label={<FormattedMessage id="name" />}
                    name="nome"
                    onChange={handleFiltersChange}
                    value={filters.nome}
                />
            </Grid>
        </Grid>
        <Card>
            <CardHeader title={<FormattedMessage id="decuiusList" />} />
            <Divider />
            {
                mobileDevice ?
                    <DepositPageMobile
                        allDecuiusToAssign={allDecuiusToAssign}
                        isPageLoading={isPageLoading}
                    /> :
                    <DepositPage
                        allDecuiusToAssign={allDecuiusToAssign}

                        setSortOptions={setSortOptions}
                        sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                        isPageLoading={isPageLoading}
                    />
            }
            <TablePagination
                component="div"
                count={allDecuiusToAssign?.totalCount}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10));
                }}
                onPageChange={(e, page) => {
                    setPageNumber(page);
                }}
                page={paginationQuery.pageNumber}
                rowsPerPage={paginationQuery.pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
            />
        </Card>
    </>
    );
}

export default DepositList;