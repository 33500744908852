import { Button, Card, CardHeader, Divider, Grid, TablePagination, debounce, useMediaQuery, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { AgenziaService } from "services/AgenziaServices";
import { Agenzia } from "types/agenzia";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { Link as RouterLink } from 'react-router-dom';
import AgenziePage from "./AgenziePage";
import AgenziePageMobile from "./AgenziePageMobile";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import { composeNotistackMessage } from "utils/common";
import AddIcon from '@mui/icons-material/Add';

const AgenzieList = () => {
    const intl = useIntl();

    const agenziaService = new AgenziaService();

    const [agenzie, setAgenzie] = useState<PaginationResult<Agenzia>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    useEffect(() => {
        updateAgenzie();
    }, [paginationQuery]);

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    const getAgenzie = useCallback(debounce(async (paginationQuery: PaginationQuery) => {
        const retrievedAgenzie = await agenziaService.GetAgenzie(paginationQuery);
        setAgenzie(retrievedAgenzie);

        setIsPageLoading(false);
    }, 700), []);

    const updateAgenzie = () => {
        setIsPageLoading(true);
        getAgenzie(paginationQuery);
    }

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    return (<>
        <Grid>
            <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Button
                    component={RouterLink}
                    to="create"
                    color="primary"
                    sx={{ m: 2, textAlign: 'center' }}
                    variant="contained"
                    startIcon={<AddIcon />}
                >
                    <FormattedMessage id="addAgency" />
                </Button>
            </Grid>
        </Grid>
        <Card>
            <CardHeader title={<FormattedMessage id="agenciesList" />} />
            <Divider />
            {
                mobileDevice ?
                    <AgenziePageMobile
                        agenzie={agenzie}
                        isPageLoading={isPageLoading}
                    /> :
                    <AgenziePage
                        agenzie={agenzie}

                        setSortOptions={setSortOptions}
                        sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                        isPageLoading={isPageLoading}
                    />
            }
            <TablePagination
                component="div"
                count={agenzie?.totalCount}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10));
                }}
                onPageChange={(e, page) => {
                    setPageNumber(page);
                }}
                page={paginationQuery.pageNumber}
                rowsPerPage={paginationQuery.pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
            />
        </Card>
    </>);
}

export default AgenzieList;