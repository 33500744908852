import { FC, useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Decuius } from 'types/decuius';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { PaginationResult } from 'types/paginationResult';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { getShortDate } from 'utils/common';
import { MoreVert } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';

interface DecuiusWithMenuProps {
    decuius: Decuius;
}

const DecuiusWithMenu: FC<DecuiusWithMenuProps> = (props) => {
    const { decuius } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={decuius.id}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="protocol" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {decuius.protocollo} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="surname" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {decuius.cognome} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="name" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {decuius.nome} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="birthDate" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getShortDate(decuius.dataNascita)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="deathDate" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {getShortDate(decuius.dataMorte)} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`decuius-actions-menu-${decuius.id}`}
                    MenuListProps={{
                        'aria-labelledby': `decuius-action-button-${decuius.id}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    <>
                        <MenuItem key={'edit'} onClick={handleClose}>
                            <IconButton
                                component={RouterLink}
                                to={`/decuius/edit?decuiusId=${decuius.id}`}
                            >
                                <EditIcon fontSize="small" sx={{ mr: '3px' }} />
                                <Typography variant="subtitle1"> <FormattedMessage id="edit" /> </Typography>
                            </IconButton>
                        </MenuItem>
                    </>
                </Menu>
            </TableCell>
        </TableRow>
    );

}

interface DepositPageMobileProps {
    allDecuiusToAssign: PaginationResult<Decuius>;
    isPageLoading: boolean;
}

const DepositPageMobile: FC<DepositPageMobileProps> = (props) => {
    const { allDecuiusToAssign, isPageLoading } = props;

    return (
        <Table>
            <TableBody>
                {
                    isPageLoading ? (
                        <TableRowsLoader rowsNum={3} colsNum={1} />
                    ) :
                        (<>
                            {allDecuiusToAssign && allDecuiusToAssign.items?.map((decuius) => (
                                <DecuiusWithMenu
                                    key={decuius.id}
                                    decuius={decuius}
                                />
                            ))}
                        </>)
                }
            </TableBody>
        </Table>
    );
};

export default DepositPageMobile;
