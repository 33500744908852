import { Card, CardHeader, Divider, CardContent, Grid, TextField, Button, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PraticaService } from "services/PraticaServices";
import { CrossTenantDocument, defaultCrossTenantDocument } from "types/crossTenantDocument";
import CardContentLoader from "ui-component/CardContentLoader";
import SlateEditor from "ui-component/SlateEditor/Editor";
import { deserialize, serialize } from "ui-component/SlateEditor/utils/SlateUtilityFunctions";
import { composeNotistackMessage, getFormValue } from "utils/common";
import EditIcon from '@mui/icons-material/Edit';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { handleUpsertErrors } from "utils/validation";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { TenantService } from "services/TenantServices";
import { Document, Page } from 'react-pdf';

const SuperAdminDocumentEditor = () => {
    const praticaService = new PraticaService();
    const tenantService = new TenantService();

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    const intl = useIntl();
    const navigate = useNavigate();

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setCrossTenantDocument((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const [template, setTemplate] = useState<any[]>([
        {
            type: "paragraph",
            children: [{ text: "" }],
        },
    ]);

    const [templateBase64, setTemplateBase64] = useState<string>('');

    const [crossTenantDocument, setCrossTenantDocument] = useState<CrossTenantDocument>(defaultCrossTenantDocument);

    const [numPages, setNumPages] = useState<number>();

    const [editView, setEditView] = useState<boolean>(true);

    let documentValidationSchema = object({
        fileName: string().required('nameRequired')
    });

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            const locationUrl: string = window.location.href;

            if (!locationUrl.includes('create')) {
                const documentId: string = locationUrl.split('documentId=')[1] ?? '';
                const retrievedDocument: CrossTenantDocument = await tenantService.GetCrossTenantDocument(documentId);
                setCrossTenantDocument(retrievedDocument);

                const htmlToString: string | undefined = retrievedDocument.documentHtmlToString;

                if (htmlToString) {
                    const document = new DOMParser().parseFromString(htmlToString, "text/html");
                    setTemplate(deserialize(document.body));
                }
            }

            setIsPageLoading(false);
        })();
    }, []);

    const handleChangeDocumentView = async () => {
        setIsPageLoading(true);
        if (editView) {
            const dossierDocument = await praticaService.GetFilledDocumentByTemplate({
                templateHtmlToString: serialize(template),
                dataToFillTemplate: null
            });
            setTemplateBase64(dossierDocument);
        }

        setEditView(!editView);
        setIsPageLoading(false);
    }

    const handleEditorStateChange = (editor: any) => {
        setTemplate(editor);
    };

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setNumPages(numPages);
    }

    const saveTemplate = async () => {
        crossTenantDocument.documentHtmlToString = serialize(template);

        try {
            await documentValidationSchema.validate(crossTenantDocument, { abortEarly: false });

            await tenantService.UpsertCrossTenantDocument(crossTenantDocument);

            composeNotistackMessage(intl.formatMessage({ id: 'successfulRescue' }), 'success');

            if (window.location.href.includes('create')) {
                navigate('/superAdminSettings');
            }
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        }
    };


    return (
        <Card>
            <CardHeader title={<FormattedMessage id="editDocument" />} />
            <Divider />
            {
                isPageLoading ?
                    <CardContentLoader /> :
                    (
                        <CardContent>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item sx={{ width: '880px' }}>
                                    <TextField
                                        fullWidth
                                        size='medium'
                                        label={<FormattedMessage id="documentName" />}
                                        name="fileName"
                                        required
                                        onChange={handleChange}
                                        value={crossTenantDocument.fileName}
                                    />
                                </Grid>
                                <Grid item sx={{ width: '880px' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '15px' }}>
                                        <Button size="small" variant="contained" endIcon={editView ? <ChromeReaderModeIcon /> : <EditIcon />} onClick={() => handleChangeDocumentView()}>
                                            <FormattedMessage id={editView ? "view" : "edit"} />
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    {
                                        editView ?
                                            <Box width='880px'>

                                                {template.length > 0 && (
                                                    <SlateEditor initialValue={template} onChange={handleEditorStateChange} readOnly={false} />
                                                )}
                                            </Box> :
                                            <Document
                                                file={templateBase64}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                                loading={<LinearProgress />}
                                                noData={<LinearProgress />}
                                            >
                                                {
                                                    Array.from(
                                                        new Array(numPages),
                                                        (_el, index) => (
                                                            <div key={`div${index + 1}`} style={{ marginTop: '15px', border: '1px solid black' }}>
                                                                <Page
                                                                    width={window.innerWidth > 1024 ? 1024 : window.innerWidth}
                                                                    key={`page_${index + 1}`}
                                                                    pageNumber={index + 1}
                                                                    renderAnnotationLayer={false}
                                                                    renderTextLayer={false}
                                                                />
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </Document>
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    )
            }

            <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={saveTemplate}
                >
                    <FormattedMessage id="save" />
                </Button>
            </Box>
        </Card>
    );
}

export default SuperAdminDocumentEditor;