import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { FormattedMessage } from 'react-intl';
import { IconBrandChrome, IconHelp, IconSitemap, IconBug, IconFiles, IconTool, IconFileSpreadsheet, IconCash } from '@tabler/icons';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from 'store';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { loggedUserIsAdmin } = useSelector((state) => state.loggedUserIsAdmin);
    const { loggedUserIsSuperAdmin } = useSelector((state) => state.loggedUserIsSuperAdmin);

    const icons = {
        IconBrandChrome,
        IconHelp,
        IconSitemap,
        IconBug,
        AccountBalanceIcon,
        PersonIcon,
        InsertDriveFileIcon,
        SettingsIcon,
        IconFiles,
        IconTool,
        IconFileSpreadsheet,
        IconCash,
        AdminPanelSettingsIcon
    };

    const menuItems = [{
        id: 'sample-docs-roadmap',
        type: 'group',
        children: [
            {
                id: 'structures',
                title: <FormattedMessage id="structures" />,
                url: '/structures',
                icon: icons.AccountBalanceIcon,
                type: 'item',
                visible: loggedUserIsAdmin
            },

            {
                id: 'registry',
                title: <FormattedMessage id="registry" />,
                type: 'collapse',
                icon: icons.PersonIcon,
                visible: loggedUserIsAdmin,
                children: [
                    {
                        id: 'users',
                        title: <FormattedMessage id="users" />,
                        type: 'item',
                        url: '/users',
                    },
                    {
                        id: 'decuius',
                        title: <FormattedMessage id="decuius" />,
                        type: 'item',
                        url: '/decuius',
                    },
                    {
                        id: 'agencies',
                        title: <FormattedMessage id="agencies" />,
                        type: 'item',
                        url: '/agencies',
                    }
                ]
            },
            {
                id: 'dossiers',
                title: <FormattedMessage id="dossiers" />,
                type: 'item',
                url: '/dossiers',
                icon: icons.IconFiles,
                visible: loggedUserIsAdmin,
            },

            {
                id: 'tools',
                title: <FormattedMessage id="tools" />,
                type: 'collapse',
                icon: icons.IconTool,
                visible: loggedUserIsAdmin,
                children: [
                    {
                        id: 'deposit',
                        title: <FormattedMessage id="deposit" />,
                        type: 'item',
                        url: '/deposit',
                        target: true
                    },
                    {
                        id: 'generateBulletins',
                        title: <FormattedMessage id="generateBulletins" />,
                        type: 'item',
                        url: '/generateBulletins',
                        target: true
                    },
                    {
                        id: 'recordPayments',
                        title: <FormattedMessage id="recordPayments" />,
                        type: 'item',
                        url: '/recordPayments',
                        target: true
                    },
                    {
                        id: 'transferRegister',
                        title: <FormattedMessage id="transferRegister" />,
                        type: 'item',
                        url: '/transferRegister',
                        target: true
                    },
                ]
            },
            {
                id: 'accounting',
                title: <FormattedMessage id="accounting" />,
                type: 'collapse',
                icon: icons.IconFileSpreadsheet,
                visible: loggedUserIsAdmin,
                children: [
                    {
                        id: 'invoices',
                        title: <FormattedMessage id="invoices" />,
                        type: 'item',
                        url: '/invoices',
                        target: true
                    },
                    {
                        id: 'servicesReport',
                        title: <FormattedMessage id="servicesReport" />,
                        type: 'item',
                        url: '/servicesReport',
                        target: true
                    },
                    {
                        id: 'dossiersReport',
                        title: <FormattedMessage id="dossiersReport" />,
                        type: 'item',
                        url: '/dossiersReport',
                        target: true
                    },
                    {
                        id: 'paymentsToDo',
                        title: <FormattedMessage id="paymentsToDo" />,
                        type: 'item',
                        url: '/paymentsToDo',
                        target: true
                    },
                    {
                        id: 'paymentsDone',
                        title: <FormattedMessage id="paymentsDone" />,
                        type: 'item',
                        url: '/paymentsDone',
                        target: true
                    },
                ]
            },
            {
                id: 'settings',
                title: <FormattedMessage id="settings" />,
                type: 'collapse',
                icon: icons.SettingsIcon,
                visible: loggedUserIsAdmin,
                children: [
                    {
                        id: 'zones',
                        title: <FormattedMessage id="zones" />,
                        type: 'item',
                        url: '/zones',
                    },
                    {
                        id: 'blocks',
                        title: <FormattedMessage id="blocks" />,
                        type: 'item',
                        url: '/blocks',
                    },
                    {
                        id: 'services',
                        title: <FormattedMessage id="services" />,
                        type: 'item',
                        url: '/services',
                    },
                    {
                        id: 'serieNumerators',
                        title: <FormattedMessage id="numerators" />,
                        type: 'item',
                        url: '/serieNumerators',
                    }
                ]
            },
            {
                id: 'yourStructures',
                title: <FormattedMessage id="yourStructures" />,
                type: 'item',
                icon: icons.AccountBalanceIcon,
                url: '/yourStructures',
                visible: !loggedUserIsAdmin,
            },
            {
                id: 'yourDossiers',
                title: <FormattedMessage id="yourDossiers" />,
                type: 'item',
                icon: icons.IconFiles,
                url: '/yourDossiers',
                visible: !loggedUserIsAdmin,
            },
            {
                id: 'yourPayments',
                title: <FormattedMessage id="yourPayments" />,
                type: 'item',
                icon: icons.IconCash,
                url: '/yourPayments',
                visible: !loggedUserIsAdmin,
            },

            {
                id: 'superAdminSettings',
                title: <FormattedMessage id="superAdminSettings" />,
                url: '/superAdminSettings',
                icon: icons.AdminPanelSettingsIcon,
                type: 'item',
                visible: loggedUserIsSuperAdmin
            },
        ]
    }];

    const navItems = menuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
