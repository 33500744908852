import { FC, useEffect, useState } from "react";
import { useSelector } from "store";
import { UserService } from "services/UserServices";
import { composeNotistackMessage, isNotNullOrUndefined } from "./common";
import { TenantPrivacy } from "types/tenantPrivacy";
import { Card, CardContent, CardHeader, Dialog, DialogContent, Typography, LinearProgress, DialogActions, Button, CardActionArea, Grid } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import BackdropLoader from "ui-component/BackdropLoader";
import { useKeycloak } from "keycloak-react-web";

interface PrivacyPolicyProps {
    children: any;
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = (props) => {
    pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

    const { children } = props;
    const { loggedUser } = useSelector((state) => state.loggedUser);
    const { loggedUserIsAdmin } = useSelector((state) => state.loggedUserIsAdmin);

    const intl = useIntl();

    const userService = new UserService();

    const [allTenantPrivacy, setAllTenantPrivacy] = useState<Array<TenantPrivacy>>([]);

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    const updateAllTenantPrivacy = async () => {
        const tenantPrivacy: Array<TenantPrivacy> = await userService.GetTenantPrivacyToAccept(loggedUser!.id);
        setAllTenantPrivacy(tenantPrivacy);
    }

    useEffect(() => {
        if (isNotNullOrUndefined(loggedUser) && isNotNullOrUndefined(loggedUserIsAdmin)) {
            (async () => {
                if (loggedUserIsAdmin !== true) {
                    await updateAllTenantPrivacy();
                }
            })()
        }
    }, [loggedUser, loggedUserIsAdmin])

    const { keycloak } = useKeycloak();

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setNumPages(numPages);
    }

    const [numPages, setNumPages] = useState<number>(0);

    const acceptPrivacy = async () => {
        setIsPageLoading(true);

        try {
            await userService.AcceptPrivacyPolicy(allTenantPrivacy[0].tenantId);

            await updateAllTenantPrivacy();

            composeNotistackMessage(intl.formatMessage({ id: 'privacyAcceptedSuccessfully' }), 'success');
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsPageLoading(false);
        }
    }

    return (
        <>
            <BackdropLoader open={isPageLoading} />

            {
                allTenantPrivacy.length > 0 ?
                    <Dialog
                        open={allTenantPrivacy.length > 0}
                        fullWidth={true}
                        maxWidth='lg'
                        PaperProps={{
                            style: {
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                            },
                        }}
                    >
                        <DialogContent sx={{ p: 0 }}>
                            <Card style={{ overflow: 'auto', borderRadius: '0' }}>
                                <CardHeader title={<Typography variant="h3" component="div"><FormattedMessage id="privacyCardHeader" />: {allTenantPrivacy[0].tenantName} </Typography>} />
                                <CardContent>
                                    <Document
                                        file={allTenantPrivacy[0].privacyBase64}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        loading={<LinearProgress />}
                                        noData={<LinearProgress />}
                                    >
                                        {
                                            Array.from(
                                                new Array(numPages),
                                                (_el, index) => (
                                                    <div key={`div${index + 1}`} style={{ marginTop: '15px', border: '1px solid grey' }}>
                                                        <Page
                                                            width={window.innerWidth > 1024 ? 1024 : window.innerWidth}
                                                            key={`page_${index + 1}`}
                                                            pageNumber={index + 1}
                                                            renderAnnotationLayer={false}
                                                            renderTextLayer={false}
                                                        />
                                                    </div>
                                                )
                                            )
                                        }
                                    </Document>
                                </CardContent>
                                <CardActionArea>
                                    <Grid container sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }} spacing={2}>
                                        <Grid item>
                                            <Button variant="contained" color="error" onClick={() => keycloak.logout()}> <FormattedMessage id="rejectAndLogout" /> </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="success" onClick={acceptPrivacy}> <FormattedMessage id="accept" /> </Button>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </DialogContent>
                    </Dialog>
                    :
                    children
            }
        </>
    );
}

export default PrivacyPolicy;