import { Grid, TextField, Button, Card, CardHeader, Divider, TablePagination, useMediaQuery, useTheme, debounce } from "@mui/material";
import { useState, useEffect, useCallback, FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PraticaService } from "services/PraticaServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { Pratica } from "types/pratica";
import { getFormValue, isNotNullOrUndefined, retrieveCookieByKey, saveFiltersPaginationCookie } from "utils/common";
import { Link as RouterLink } from 'react-router-dom';
import PratichePage from "./PratichePage";
import PratichePageMobile from "./PratichePageMobile";
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from "store";

interface PraticheFilters {
    serie: string;
    numero: string;
    cognome: string;
    nome: string;
    note: string;
    quartoCampo: string;
    placeServiceCode: string;
}

interface PraticheListProps {
    userView?: boolean;
}

const PraticheList: FC<PraticheListProps> = (props) => {
    const { userView } = props;

    const intl = useIntl();

    const { loggedUser } = useSelector((state) => state.loggedUser);

    const praticaService = new PraticaService();

    const [pratiche, setPratiche] = useState<PaginationResult<Pratica>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);
    const [filters, setFilters] = useState<PraticheFilters>({
        serie: "",
        numero: "",
        cognome: "",
        nome: "",
        note: "",
        quartoCampo: "",
        placeServiceCode: ""
    });

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    useEffect(() => {
        const filtersPaginationCookie = retrieveCookieByKey(loggedUser.currentTenantId, 'dossiersListFilters');
        if (isNotNullOrUndefined(filtersPaginationCookie)) {
            setFilters(filtersPaginationCookie.filters);
            setPaginationQuery(filtersPaginationCookie.paginationQuery);
        }
    }, [])

    useEffect(() => {
        updatePratiche();
    }, [paginationQuery, filters, userView]);

    const updatePratiche = () => {
        setIsPageLoading(true);
        getPratiche(paginationQuery, filters);

        saveFiltersPaginationCookie(loggedUser.currentTenantId, 'dossiersListFilters', {
            filters: filters,
            paginationQuery: paginationQuery
        }, 1);
    }

    const getPratiche = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: PraticheFilters) => {
        const retrievedPratiche = userView ? await praticaService.GetUserDossiers(paginationQuery, filters) : await praticaService.GetPratiche(paginationQuery, filters);
        setPratiche(retrievedPratiche);

        setIsPageLoading(false);
    }, 700), [userView]);

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    return (
        <>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="series" />}
                        name="serie"
                        onChange={handleFiltersChange}
                        value={filters.serie}
                    />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="number" />}
                        name="numero"
                        onChange={handleFiltersChange}
                        value={filters.numero}
                    />
                </Grid>
                {
                    !userView &&
                    <>
                        <Grid
                            item
                            lg={4}
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                size='small'
                                label={<FormattedMessage id="surname" />}
                                name="cognome"
                                onChange={handleFiltersChange}
                                value={filters.cognome}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                size='small'
                                label={<FormattedMessage id="name" />}
                                name="nome"
                                onChange={handleFiltersChange}
                                value={filters.nome}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                size='small'
                                label={<FormattedMessage id="notes" />}
                                name="note"
                                onChange={handleFiltersChange}
                                value={filters.note}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                size='small'
                                label={<FormattedMessage id="fourthField" />}
                                name="quartoCampo"
                                onChange={handleFiltersChange}
                                value={filters.quartoCampo}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={4}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                size='small'
                                label={<FormattedMessage id="code" />}
                                name="placeServiceCode"
                                onChange={handleFiltersChange}
                                value={filters.placeServiceCode}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button
                                component={RouterLink}
                                to="create"
                                color="primary"
                                sx={{ m: 2, textAlign: 'center' }}
                                variant="contained"
                                startIcon={<AddIcon />}
                            >
                                <FormattedMessage id="addDossier" />
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
            <Card>
                <CardHeader title={<FormattedMessage id="dossiersList" />} />
                <Divider />
                {
                    mobileDevice ?
                        <PratichePageMobile
                            pratiche={pratiche}
                            isPageLoading={isPageLoading}

                            userView={userView}
                        /> :
                        <PratichePage
                            pratiche={pratiche}

                            setSortOptions={setSortOptions}
                            sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                            isPageLoading={isPageLoading}

                            userView={userView}
                        />
                }
                <TablePagination
                    component="div"
                    count={pratiche?.totalCount}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                    }}
                    onPageChange={(e, page) => {
                        setPageNumber(page);
                    }}
                    page={paginationQuery.pageNumber}
                    rowsPerPage={paginationQuery.pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                />
            </Card>
        </>
    )
}

export default PraticheList;