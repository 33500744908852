import { Button, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { UserService } from "services/UserServices";
import { useSelector } from "store";
import { Tenant } from "types/tenant";

interface ChangeTenantDialogProps {
    handleChangeTenantModal: Function;
}

const ChangeTenantDialog: FC<ChangeTenantDialogProps> = (props) => {
    const { handleChangeTenantModal } = props;

    const { loggedUser } = useSelector((state) => state.loggedUser);

    useEffect(() => {
        if (loggedUser !== undefined && loggedUser.currentTenantId !== undefined) {
            setSelectedTenantSelectItem(loggedUser.currentTenantId);
        }
    }, [loggedUser])

    const [selectedTenantSelectItem, setSelectedTenantSelectItem] = useState<string>('');

    const userService = new UserService();

    const navigate = useNavigate();

    const updateCurrentTenant = async () => {
        if (selectedTenantSelectItem != loggedUser?.currentTenantId) {
            await userService.ChangeCurrentTenant(selectedTenantSelectItem);
            
            navigate('/');
            navigate(0);
        }
        handleChangeTenantModal(false);
    }

    return (
        <Box
            sx={{
                minHeight: '100%',
                p: 2
            }}
        >
            <Typography
                color="textPrimary"
                variant="h6"
            >
                <FormattedMessage id="changeTenant" />
            </Typography>
            <Box sx={{ mt: 3, pb: 3 }}>
                <FormControl fullWidth required>
                    <InputLabel> <FormattedMessage id="tenant" /> </InputLabel>
                    <Select
                        label={<FormattedMessage id="tenant" />}
                        name="currentTenantId"
                        onChange={(e) => {
                            setSelectedTenantSelectItem(e.target.value);
                        }}
                        size='small'
                        sx={{ color: 'white' }}
                        value={selectedTenantSelectItem}
                    >
                        {
                            loggedUser !== undefined && loggedUser.tenantsList.length > 0 &&
                            loggedUser.tenantsList.map((option: Tenant) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
            <Divider />
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    p: 1
                }}
            >
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    color="primary"
                    onClick={() => { handleChangeTenantModal(false) }}
                    variant="text"
                >
                    {<FormattedMessage id="cancel" />}
                </Button>
                <Button
                    color="primary"
                    sx={{ ml: 1 }}
                    type="submit"
                    variant="contained"
                    onClick={updateCurrentTenant}
                >
                    {<FormattedMessage id="confirm" />}
                </Button>
            </Box>
        </Box >
    );
};

export default ChangeTenantDialog;
