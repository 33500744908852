import { TemplateType } from "./enums/TemplateType";

export interface CrossTenantDocument {
    id?: string;
    fileName: string;

    dateOfInsert?: string;
    isValid?: boolean;

    documentHtmlToString?: string;
}

export const defaultCrossTenantDocument: CrossTenantDocument = {
    fileName: '',
}