import { Table, TableRow, TableCell, TableBody, IconButton, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { PostoPraticaData } from "types/postoPraticaData";
import { UserPaymentToDo } from "types/userPaymentToDo";
import { getWithTwoDecimal } from "utils/common";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface PaymentsToDoListMobileProps {
    servicesToPay: UserPaymentToDo[];
    dossierPlaces: PostoPraticaData[];
    executePayment: (amountToPay: string, placeServiceId: string) => void;
}


const PaymentsToDoListMobile: FC<PaymentsToDoListMobileProps> = (props) => {
    const { servicesToPay, dossierPlaces, executePayment } = props;

    return (
        <Table size="small">
            <TableBody>
                {
                    servicesToPay &&
                    servicesToPay.length > 0 &&
                    servicesToPay.map((userPaymentToDo: UserPaymentToDo) => {
                        const dossierPlace = dossierPlaces.find((dossierPlace: PostoPraticaData) => dossierPlace.postoPraticaId === userPaymentToDo.postoPraticaAssociatoId);
                        return (
                            <TableRow>
                                <TableCell>
                                    <Grid container direction="column">
                                        <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                            <Grid item>
                                                <Typography variant="h6" align="center"> <FormattedMessage id="structure" /> </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" align="center"> {dossierPlace?.strutturaDenominazione} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                            <Grid item>
                                                <Typography variant="h6" align="center"> <FormattedMessage id="placeNumber" /> </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" align="center"> {dossierPlace?.postoNumero} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                            <Grid item>
                                                <Typography variant="h6" align="center"> <FormattedMessage id="description" /> </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" align="center"> {userPaymentToDo.descrizione} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                            <Grid item>
                                                <Typography variant="h6" align="center"> <FormattedMessage id="date" /> </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" align="center"> {userPaymentToDo.data} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                            <Grid item>
                                                <Typography variant="h6" align="center"> <FormattedMessage id="year" /> </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" align="center"> {userPaymentToDo.year} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                            <Grid item>
                                                <Typography variant="h6" align="center"> <FormattedMessage id="totalAmount" /> </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" align="center"> {userPaymentToDo.importo * userPaymentToDo.quantita} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                                            <Grid item>
                                                <Typography variant="h6" align="center"> <FormattedMessage id="payed" /> </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" align="center"> {userPaymentToDo.pagato} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell
                                    sx={{ verticalAlign: 'center', textAlign: 'right' }}
                                >
                                    <IconButton onClick={() => {
                                        executePayment(getWithTwoDecimal(userPaymentToDo.importo - userPaymentToDo.pagato!), userPaymentToDo.placeServiceId);
                                    }}>
                                        <AttachMoneyIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    )
}

export default PaymentsToDoListMobile;