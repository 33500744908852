import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { PaginationResult } from "types/paginationResult";
import { ServiceReport } from "types/serviceReport";
import AlternateTableRow from "ui-component/AlternateTableRow";
import TableRowsLoader from "ui-component/TableRowsLoader";
import { getCurrencyByNumber } from "utils/common";
import TableCellWithSorting from "ui-component/TableCellWithSorting";
import ISortOptions from "interfaces/ISortOptions";

interface ServiziReportPageProps {
    serviziReport: PaginationResult<ServiceReport>;
    isPageLoading: boolean;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
}

const ServiziReportPage: FC<ServiziReportPageProps> = (props) => {
    const { serviziReport, isPageLoading, setSortOptions, sortOptions } = props;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="descrizione"
                            title={<FormattedMessage id="description" />}
                        />
                        <TableCell> <FormattedMessage id="servicesNumberOfUses" /> </TableCell>
                        <TableCell align='right'> <FormattedMessage id="totalAmount" /> (€) </TableCell>
                        <TableCell align='right'> <FormattedMessage id="totalPayed" /> (€) </TableCell>
                        <TableCell align='right'> <FormattedMessage id="balance" /> (€) </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isPageLoading ?
                            (<TableRowsLoader rowsNum={10} colsNum={5} />) :
                            (<>
                                {serviziReport && serviziReport.items?.map((servizio) => (
                                    <AlternateTableRow key={servizio.id}>
                                        <TableCell>
                                            <Grid item> {servizio.description} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {servizio.numberOfUses} </Grid>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Grid item> {getCurrencyByNumber(servizio.totalAmount)} </Grid>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Grid item> {getCurrencyByNumber(servizio.totalPayed)} </Grid>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Grid item> {getCurrencyByNumber(servizio.totalAmount - servizio.totalPayed)} </Grid>
                                        </TableCell>
                                    </AlternateTableRow>
                                ))}
                            </>)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ServiziReportPage;