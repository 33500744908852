import { Backdrop, CircularProgress } from "@mui/material";
import { FC } from "react";

interface BackdropLoaderProps {
    open: boolean;
}

const BackdropLoader: FC<BackdropLoaderProps> = (props) => {

    const { open } = props;

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default BackdropLoader;