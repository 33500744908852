import { TableRow } from "@mui/material";
import { styled, Theme } from '@mui/material/styles';
import { isNotNullOrUndefined } from "utils/common";

const AlternateTableRow = styled(TableRow, { name: 'AlternateTableRow' })<{
    theme?: Theme;
    rowColor?: string;
}>(({ theme, rowColor }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: isNotNullOrUndefined(rowColor) ? rowColor : theme.palette.action.hover,
    },
    '&:nth-of-type(even)': {
        backgroundColor: isNotNullOrUndefined(rowColor) ? rowColor : '',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default AlternateTableRow;