import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "store";
import { UserService } from "services/UserServices";
import { handleDownloadFile, isNotNullOrUndefined } from "./common";
import { setQrCodeCommunicationsFileId } from "store/slices/qrCodeCommunicationsFileIdSlice";

interface BackgroundApiCheckProps {
    children: any;
}

const BackgroundApiCheck: FC<BackgroundApiCheckProps> = (props) => {
    const { children } = props;

    const { qrCodeCommunicationsFileId } = useSelector((state) => state.qrCodeCommunicationsFileId);
    const dispatch = useDispatch();
    const userService = new UserService();

    const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
    const qrCodeCommunicationsFileIdRef = useRef(qrCodeCommunicationsFileId);
    const [base64File, setBase64File] = useState<string>('');

    const stopInterval = () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
    };

    useEffect(() => {
        qrCodeCommunicationsFileIdRef.current = qrCodeCommunicationsFileId;
    }, [qrCodeCommunicationsFileId]);

    useEffect(() => {
        if (isNotNullOrUndefined(qrCodeCommunicationsFileIdRef.current)) {
            if (!intervalIdRef.current) {
                intervalIdRef.current = setInterval(async () => {
                    const pdfBase64: string = await userService.GetQrCodeCommunicationsPdf(qrCodeCommunicationsFileIdRef.current);
                    if (isNotNullOrUndefined(pdfBase64)) {
                        setBase64File(pdfBase64);
                    }
                }, 10000);
            }
        } else {
            stopInterval();
        }
    }, [qrCodeCommunicationsFileId]);

    useEffect(() => {
        if (isNotNullOrUndefined(base64File)) {
            handleDownloadFile(base64File, "ComunicazioniQR.pdf");
            dispatch(setQrCodeCommunicationsFileId(''));
        }
    }, [base64File]);

    return children;
};

export default BackgroundApiCheck;
