import { FC } from 'react';
import { Button, Card, CardHeader, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { FormattedMessage, useIntl } from 'react-intl';
import { Decuius } from 'types/decuius';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { PaginationResult } from 'types/paginationResult';
import ISortOptions from 'interfaces/ISortOptions';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { getShortDate, isNotNullOrUndefined } from 'utils/common';
import PlaceIcon from '@mui/icons-material/Place';
import { IconFiles } from '@tabler/icons';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

interface DecuiusPageProps {
    allDecuius: PaginationResult<Decuius>;

    setSortOptions: (sortColumnName: string) => void;
    sortOptions: ISortOptions;
    isPageLoading: boolean;

    fromExternal: boolean;
    assignDecuius?: (decuiusId: string) => void;
    alreadyAssignedDecuiusIds?: Array<string>;
};

const DecuiusPage: FC<DecuiusPageProps> = (props) => {
    const { allDecuius, setSortOptions, sortOptions, isPageLoading, fromExternal, assignDecuius, alreadyAssignedDecuiusIds } = props;

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="cognome"
                            title={<FormattedMessage id="surname" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="nome"
                            title={<FormattedMessage id="name" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="dataNascita"
                            title={<FormattedMessage id="birthDate" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="dataMorte"
                            title={<FormattedMessage id="deathDate" />}
                        />
                        <TableCellWithSorting
                            setSortOptions={setSortOptions}
                            sortOptions={sortOptions}
                            name="comuneNascita"
                            title={<FormattedMessage id="birthPlace" />}
                        />
                        <TableCell> <FormattedMessage id="actions" /> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isPageLoading ?
                            (<TableRowsLoader rowsNum={10} colsNum={6} />) :
                            (<>
                                {allDecuius && allDecuius.items?.map((decuius) => (
                                    <AlternateTableRow key={decuius.id}>
                                        <TableCell>
                                            <Grid item> {decuius.cognome} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {decuius.nome} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {getShortDate(decuius.dataNascita)} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {getShortDate(decuius.dataMorte)} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {decuius.comuneNascita} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                fromExternal ?
                                                    (<Button onClick={() => { assignDecuius!(decuius.id!) }} variant="outlined" disabled={alreadyAssignedDecuiusIds?.includes(decuius.id!)} startIcon={<PersonAddIcon />}>
                                                        <FormattedMessage id={isNotNullOrUndefined(decuius.postoId) ? 'transferDecuius' : 'assignDecuius'} />
                                                    </Button>) :
                                                    (<>
                                                        <Tooltip title={<FormattedMessage id="goToPlace" />} aria-label="Place">
                                                            <IconButton component={RouterLink} to={`/posto/edit?postoId=${decuius.postoId}`}>
                                                                <PlaceIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                                            <IconButton component={RouterLink} to={`edit?decuiusId=${decuius.id}`}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {
                                                            decuius.posto?.postoPratiche !== undefined &&
                                                            decuius.posto?.postoPratiche !== null &&
                                                            decuius.posto?.postoPratiche?.length !== 0 &&
                                                            <Tooltip title={<FormattedMessage id={decuius.posto.postoPratiche.length == 1 ? "goToDossier" : "goToDossiers"} />} aria-label="Vai alle pratiche">
                                                                <IconButton component={RouterLink} to={
                                                                    decuius.posto.postoPratiche.length == 1 ?
                                                                        `/dossiers/edit?dossierId=${decuius.posto.postoPratiche[0].praticaAssociataId}` :
                                                                        `/decuius/dossiersList?decuiusId=${decuius.id}`
                                                                }>
                                                                    <IconFiles fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </>)
                                            }

                                        </TableCell>
                                    </AlternateTableRow>
                                ))}
                            </>)
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default DecuiusPage;
