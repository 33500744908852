/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { PaginationResult } from 'types/paginationResult';
import { Stecca } from 'types/stecca';
import { PaginationQuery } from 'types/paginationQuery';
import { Posto } from 'types/posto';
import { PostoSteccaView } from 'types/postoSteccaView';
import { SteccheInfo } from 'types/steccheInfo';
import { SteccaShortInfo } from 'types/steccaShortInfo';

export class SteccaService {
    async GetSteccheByStrutturaId(paginationQuery: PaginationQuery, strutturaId: string): Promise<SteccheInfo> {
        const result = await axios.post<SteccheInfo>(`${serviceConfig.appServiceBaseUrl}/GetStecche`, {
            ...paginationQuery,
            strutturaId: strutturaId
        });
        return result.data;
    }

    async GetSteccaById(steccaId: string): Promise<Stecca> {
        const result = await axios.post<Stecca>(`${serviceConfig.appServiceBaseUrl}/GetStecca`, steccaId);
        return result.data;
    }

    async UpsertStecca(stecca: Stecca): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpsertStecca`, stecca);
        return result.data;
    }

    async DeleteStecca(steccaId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteStecca`, steccaId);
        return result.data;
    }

    async GetUnpaginatedPostiBySteccaId(steccaId: string): Promise<PostoSteccaView[]> {
        const result = await axios.post<PostoSteccaView[]>(`${serviceConfig.appServiceBaseUrl}/GetAllPostiBySteccaId`, steccaId);
        return result.data;
    }

    async PrintSlat(slatId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/PrintSlat`, slatId);
        return result.data;
    }

    async GetSteccheShortInfoByStrutturaId(strutturaId: string): Promise<SteccaShortInfo[]> {
        const result = await axios.post<SteccaShortInfo[]>(`${serviceConfig.appServiceBaseUrl}/GetSteccheShortInfoByStrutturaId`, strutturaId);
        return result.data;
    }

    async GetPlacesShortInfoBySteccaId(steccaId: string): Promise<PostoSteccaView[]> {
        const result = await axios.post<PostoSteccaView[]>(`${serviceConfig.appServiceBaseUrl}/GetPlacesShortInfoBySteccaId`, steccaId);
        return result.data;
    }
}