import { createSlice } from '@reduxjs/toolkit';

interface LoggedUserIsAdminState {
    loggedUserIsAdmin: boolean | undefined;
}

export const loggedUserIsAdminSlice = createSlice({
    name: 'loggedUserIsAdminSlice',
    initialState: {
        loggedUserIsAdmin: undefined
    } as LoggedUserIsAdminState,
    reducers: {
        setLoggedUserIsAdmin(state, action) {
            state.loggedUserIsAdmin = action.payload;
        }
    }
});

export const { setLoggedUserIsAdmin } = loggedUserIsAdminSlice.actions;

export default loggedUserIsAdminSlice.reducer;