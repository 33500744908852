import { Box } from "@mui/system"
import AddIcon from '@mui/icons-material/Add';
import { FC } from "react";

interface BoxAddElementProps {
    onClick: (...args: any[]) => void;
}

const BoxAddElement: FC<BoxAddElementProps> = (props) => {
    const { onClick } = props;
    return (
        <Box
            onClick={onClick}
            sx={{
                padding: '16px',
                backgroundColor: '#f0f0f0',
                borderRadius: '10px',
                height: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '180px'
            }}
        >
            <Box sx={{
                transition: 'opacity 0.3s',
                ':hover': {
                    opacity: 0.7,
                    cursor: 'pointer'
                },
                padding: '10px',
                backgroundColor: '#dedede',
                borderRadius: '10px',
            }}>
                <AddIcon sx={{ fontSize: 60 }} />
            </Box>
        </Box>
    )
}

export default BoxAddElement