import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PagamentoService } from "services/PagamentoServices";
import VisuallyHiddenInput from "ui-component/VisuallyHiddenInput";
import { composeNotistackMessage, getCurrencyByNumber, isNotNullOrUndefined } from "utils/common";
import ClearIcon from '@mui/icons-material/Clear';
import { RecordedPayment } from "types/recordedPayment";
import AlternateTableRow from "ui-component/AlternateTableRow";
import { Link as RouterLink } from 'react-router-dom';
import { IconFiles } from '@tabler/icons';
import BackdropLoader from "ui-component/BackdropLoader";
import { useDispatch, useSelector } from "store";
import { setRecordedPayments } from "store/slices/recordedPaymentsSlice";
import PaymentsIcon from '@mui/icons-material/Payments';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const RecordPayments = () => {
    const intl = useIntl();

    const pagamentoService = new PagamentoService();

    const [newRecordedPayments, setNewRecordedPayments] = useState<Array<RecordedPayment>>([]);
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    const { listRecordedPayments } = useSelector((state) => state.recordedPayments);

    const recordPayments = () => {
        if (fileInfo == null) {
            composeNotistackMessage(intl.formatMessage({ id: 'fileRequired' }), 'error');
            return;
        }
        setIsTableLoading(true);

        (async () => {
            try {
                const retrievedRecordedPayments: Array<RecordedPayment> = await pagamentoService.RecordPayments(fileInfo!.fileBase64!);
                setNewRecordedPayments(retrievedRecordedPayments);

                dispatch(setRecordedPayments(retrievedRecordedPayments))

                composeNotistackMessage(intl.formatMessage({ id: 'paymentSuccessfullyRecorded' }), 'success');

                setIsTableLoading(false);
            } catch (e: any) {
                composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
            }
        })()
    }

    const [fileInfo, setFileInfo] = useState<{ fileName: string, fileBase64: string } | null>(null);

    const handleUploadFile = async (e: any) => {
        var fileReader: FileReader = new FileReader();
        var file = e.target.files[0];
        if (isNotNullOrUndefined(file)) {
            let newFileName = e.target.files[0].name;
            fileReader.readAsDataURL(e.target.files[0]);
            fileReader.onload = async () => {
                let newFileBase64: string = fileReader.result as string;
                setFileInfo({
                    fileName: newFileName,
                    fileBase64: newFileBase64
                });
            }
        }
    }

    const removeFile = () => setFileInfo(null);

    useEffect(() => {
        if (listRecordedPayments.length !== 0) {
            setNewRecordedPayments(listRecordedPayments);
        }
    }, [listRecordedPayments])

    return (
        <Card>
            <BackdropLoader open={isTableLoading} />

            <CardHeader title={<FormattedMessage id="recordPayments" />} />
            <CardContent>
                <Grid container spacing={1} sx={{ mb: 2 }}>
                    <Grid item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#f5f2f2', padding: '8px', borderRadius: '5px' }}>
                            <Button component="label" variant="outlined" startIcon={<AttachFileIcon />}>
                                <FormattedMessage id="uploadFile" />
                                <VisuallyHiddenInput type="file"
                                    onChange={handleUploadFile}
                                />
                            </Button>
                            {
                                fileInfo !== null &&
                                <Box sx={{ ml: '10px', display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="h5"> {fileInfo?.fileName} </Typography>
                                    <IconButton onClick={removeFile}>
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            }
                        </Box>
                    </Grid>
                    <Grid item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <Box sx={{ padding: '8px' }}>
                            <Button variant="contained" onClick={recordPayments} startIcon={<PaymentsIcon />}>
                                <FormattedMessage id="recordPayments" />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                {
                    newRecordedPayments.length > 0 &&
                    <Box sx={{ mt: '40px' }}>
                        <Typography variant="h4">
                            <FormattedMessage id="recordedPayments" />
                        </Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> <FormattedMessage id="serviceDescription" /> </TableCell>
                                    <TableCell> <FormattedMessage id="fourthField" /> </TableCell>
                                    <TableCell align='right'> <FormattedMessage id="amount" /> </TableCell>
                                    <TableCell align='right'> <FormattedMessage id="location" /> </TableCell>
                                    <TableCell align='right'> <FormattedMessage id="dossier" /> </TableCell>
                                    <TableCell align='right'> <FormattedMessage id="actions" /> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {newRecordedPayments && newRecordedPayments.map((recordedPayment: RecordedPayment, index: number) => (
                                    <AlternateTableRow key={index}>
                                        <TableCell>
                                            <Grid item> {recordedPayment.placeServiceDescription} </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item> {recordedPayment.placeServiceFourthField} </Grid>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Grid item> {getCurrencyByNumber(recordedPayment.amountPayed)} </Grid>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Grid item> {recordedPayment.location} </Grid>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Grid item> {recordedPayment.dossierNumber} </Grid>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={<FormattedMessage id="goToDossier" />} aria-label="Go to dossier">
                                                <IconButton component={RouterLink} to={`/dossiers/edit?dossierId=${recordedPayment.dossierId}`}>
                                                    <IconFiles fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </AlternateTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                }
            </CardContent>
        </Card>
    );
}

export default RecordPayments;