export enum TipoNumeratore {
    Pratica = 1,
    Fattura = 2
}

export const tipiNumeratore =
    [
        {
            id: 1,
            name: 'Pratica'
        },
        {
            id: 2,
            name: 'Fattura'
        },
    ]