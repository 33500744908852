import { Button, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";

interface DocumentCardProps {
    navigatePath: string;
    documentId: string;
    documentName: string;
    downloadDocument: (documentId: string, documentName: string) => void;
    deleteDocument: (documentId: string) => void;
    icon: React.ReactFragment;
}

const DocumentCard: FC<DocumentCardProps> = (props) => {
    const { navigatePath, documentId, documentName, downloadDocument, deleteDocument, icon } = props;

    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '16px',
                backgroundColor: '#f0f0f0',
                borderRadius: '10px',
                height: '200px',
                width: '180px'
            }}
        >
            <Box
                onClick={() => navigate(`${navigatePath}?documentId=${documentId}`)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '5px',
                    transition: 'opacity 0.3s',
                    ':hover': {
                        opacity: 0.7,
                        cursor: 'pointer'
                    }
                }}
            >
                {icon}
            </Box>
            <Tooltip title={documentName}>
                <Typography variant="subtitle1" sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center' }}>
                    {documentName}
                </Typography>
            </Tooltip>
            <Button
                startIcon={<DownloadIcon />}
                variant="contained"
                color="primary"
                sx={{ marginBottom: '8px' }}
                onClick={() => downloadDocument(documentId, `${documentName}.pdf`)}
            >
                <FormattedMessage id="download" />
            </Button>
            <Button
                startIcon={<DeleteIcon />}
                variant="contained"
                color="error"
                sx={{ marginBottom: '8px' }}
                onClick={() => deleteDocument(documentId)}
            >
                <FormattedMessage id="delete" />
            </Button>
        </Box >
    );
}

export default DocumentCard;