import { Grid, Button, Box, Typography, Collapse } from "@mui/material";
import { Utente } from "types/utente";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import UsersList from "pages/Utenti/UsersList";
import { useNavigate } from "react-router-dom";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';

interface HeaderStepProps {
    assignedUser: Utente | undefined;
    assignUserToEntity: (userId: string) => void;
    isAdd: boolean;
    dossierId?: string;
}

const HeaderStep: FC<HeaderStepProps> = (props) => {
    const { assignedUser, assignUserToEntity, isAdd, dossierId } = props;

    const assignUser = (userId: string) => {
        setOpenAssignUserCollapse(false);
        assignUserToEntity(userId);
    }

    const [openAssignUserCollapse, setOpenAssignUserCollapse] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleAddAndAssignHeader = () => {
        const redirectPath: string = isAdd ? '/users/create?fromDossier=true' : `/users/create?dossierId=${dossierId}`
        navigate(redirectPath);
    }

    return (
        <Box>
            {assignedUser && (
                <Box
                    onClick={() => navigate(`/users/edit?userId=${assignedUser.id}`)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        '&:hover': {
                            cursor: 'pointer',
                            color: 'gray',
                        },
                        mt: { xs: 2, md: 0 },
                        mb: 2,
                        textAlign: { xs: 'center', md: 'left' }
                    }}
                >
                    <PersonIcon fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="h5">
                        {assignedUser.nome} {assignedUser.cognome}
                    </Typography>
                </Box>
            )}

            <Grid container spacing={0} alignItems="center" sx={{ mb: 2 }}>
                <Grid item lg={3} md={3} xs={12}>
                    <Button
                        variant="contained"
                        onClick={() => setOpenAssignUserCollapse(!openAssignUserCollapse)}
                        sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 }, width: '100%' }}
                        startIcon={<PersonAddIcon />}
                    >
                        <FormattedMessage id={assignedUser === undefined ? "assignUser" : "changeUser"} />
                    </Button>
                </Grid>
            </Grid>

            <Collapse in={openAssignUserCollapse}>
                <Box sx={{
                    padding: "3px",
                }}>
                    <UsersList
                        fromExternal={true}
                        alreadyAssignedUsersIds={assignedUser?.id !== undefined ? [assignedUser.id] : []}
                        fromWhere="dossier"
                        assignUserToEntity={assignUser}
                        customAddMethod={handleAddAndAssignHeader}
                    />
                </Box>
            </Collapse>
        </Box>
    );
}

export default HeaderStep;