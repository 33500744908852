import React, { useCallback, useEffect, useState } from 'react';
import { ZonaService } from '../../services/ZonaServices';
import { Zona } from '../../types/zona';
import { Box, Button, Card, CardHeader, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Tooltip, Typography, debounce, styled, tableCellClasses, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage, useIntl } from 'react-intl';
import AlternateTableRow from 'ui-component/AlternateTableRow';
import { PaginationQuery, defaultPaginationQuery } from 'types/paginationQuery';
import { PaginationResult, voidPagination } from 'types/paginationResult';
import TableCellWithSorting from 'ui-component/TableCellWithSorting';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import DialogDeleteElement from 'ui-component/DialogDeleteElement';
import AddIcon from '@mui/icons-material/Add';

const ZonePage = () => {
    const intl = useIntl();

    const zonaService = new ZonaService();
    const [zone, setZone] = useState<PaginationResult<Zona>>(voidPagination);
    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    const updateZone = () => {
        setIsPageLoading(true);
        getZone(paginationQuery);
    }

    const getZone = useCallback(debounce(async (paginationQuery: PaginationQuery) => {
        const retrievedZone = await zonaService.GetZone(paginationQuery);
        setZone(retrievedZone);

        setIsPageLoading(false);
    }, 700), []);

    useEffect(() => {
        updateZone();
    }, [paginationQuery]);

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    return (
        <>
            <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Button
                    component={RouterLink}
                    to="create"
                    color="primary"
                    sx={{ m: 2, textAlign: 'center' }}
                    variant="contained"
                    startIcon={<AddIcon />}
                >
                    <FormattedMessage id="addZone" />
                </Button>
            </Grid>
            <Card>
                <CardHeader title={<FormattedMessage id="zonesList" />} />
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellWithSorting
                                setSortOptions={setSortOptions}
                                sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                                title={<FormattedMessage id="description" />}
                                name="descrizione"
                            />

                            <TableCell> <FormattedMessage id="actions" /> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isPageLoading ?
                                (<TableRowsLoader rowsNum={10} colsNum={2} />) :
                                (<>
                                    {zone && zone.items?.map((zona) => (
                                        <AlternateTableRow key={zona.id}>
                                            <TableCell>
                                                <Grid item> {zona.descrizione} </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
                                                    <IconButton component={RouterLink} to={`edit?zonaId=${zona.id}`}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </AlternateTableRow>
                                    ))}
                                </>)
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={zone?.totalCount}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                    }}
                    onPageChange={(e, page) => {
                        setPageNumber(page);
                    }}
                    page={paginationQuery.pageNumber}
                    rowsPerPage={paginationQuery.pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                />
            </Card>
        </>
    );
};

export default ZonePage;
