import { Box, Grid, Paper, Skeleton, Typography, styled } from "@mui/material";
import { darken } from '@mui/material/styles';
import { FormattedMessage } from "react-intl";
import { ServizioService } from "services/ServizioServices";
import { useEffect, useState } from "react";
import { UserPaymentsNumber, defaultUserPaymentsNumber } from "types/userPaymentsNumber";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { StrutturaService } from "services/StrutturaServices";
import { IconFiles } from '@tabler/icons';
import { PraticaService } from "services/PraticaServices";
import { useNavigate } from "react-router-dom";
import Payments from "ui-component/icons/Payments";

const UserDashboard = () => {
    const servizioService = new ServizioService();
    const strutturaService = new StrutturaService();
    const praticaService = new PraticaService();

    const navigate = useNavigate();

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    const [userPaymentsNumber, setUserPaymentsNumber] = useState<UserPaymentsNumber>(defaultUserPaymentsNumber);
    const [userDossiersNumber, setUserDossiersNumber] = useState<number>(0);
    const [userStructuresNumber, setUserStructuresNumber] = useState<number>(0);

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            const retrievedUserPaymentsNumber = await servizioService.GetUserServicesPaymentsNumber();
            const retrievedUserStructuresNumber = await strutturaService.GetUserStructuresNumber();
            const retrievedUserDossiersNumber = await praticaService.GetUserDossiersNumber();

            setUserPaymentsNumber(retrievedUserPaymentsNumber);
            setUserStructuresNumber(retrievedUserStructuresNumber);
            setUserDossiersNumber(retrievedUserDossiersNumber);

            setIsPageLoading(false);
        })()
    }, [])

    const SectionPaper = styled(Paper)(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 150,
        transition: 'opacity 0.3s ease',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: darken('#FFFFFF', 0.05)
        },
    }));

    return (
        <Grid container direction="row" spacing={2}
            sx={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {
                isPageLoading ?
                    <>
                        {
                            [...Array(3)].map((row, index) =>
                                <Grid item
                                    lg={4}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                >
                                    <SectionPaper onClick={() => navigate('')} elevation={3} key={index}>
                                        <Skeleton variant="rounded" height={100} width={100} />

                                        <Box sx={{ paddingLeft: '20px' }}>
                                            <Skeleton variant="text" height={30} width={150} />
                                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
                                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
                                        </Box>
                                    </SectionPaper>
                                </Grid>
                            )
                        }
                    </> :
                    <>
                        <Grid item
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <SectionPaper onClick={() => navigate('/yourStructures')} elevation={3}>
                                <AccountBalanceIcon sx={{ height: '95px', width: 'auto', color: 'black' }} />

                                <Box sx={{ paddingLeft: '20px' }}>
                                    <Typography variant="h2" sx={{ marginBottom: '8px' }}>
                                        <FormattedMessage id="structures" />
                                    </Typography>
                                    <Typography fontSize="40px" sx={{ textAlign: 'center' }}>
                                        {userStructuresNumber}
                                    </Typography>
                                </Box>
                            </SectionPaper>
                        </Grid>
                        <Grid item
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <SectionPaper onClick={() => navigate('/yourPayments')} elevation={3}>
                                <Payments style={{ height: '95px', width: 'auto' }} />

                                <Box sx={{ paddingLeft: '20px' }}>
                                    <Typography variant="h2" sx={{ marginBottom: '8px' }}>
                                        <FormattedMessage id="payments" />
                                    </Typography>
                                    <Typography fontSize="15px" color='black' sx={{ px: '9px', backgroundColor: '#7eff61', borderRadius: '35px', textAlign: 'center', mb: '5px' }}>
                                        <FormattedMessage id="payed" />: {userPaymentsNumber.paymentsDone}
                                    </Typography>
                                    <Typography fontSize="15px" color='black' sx={{ px: '9px', backgroundColor: '#ff6161', borderRadius: '35px', textAlign: 'center' }}>
                                        <FormattedMessage id="toPay" />: {userPaymentsNumber.paymentsToDo}
                                    </Typography>
                                </Box>
                            </SectionPaper>
                        </Grid>
                        <Grid item
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <SectionPaper onClick={() => navigate('/yourDossiers')} elevation={3}>
                                <IconFiles style={{ height: '95px', width: 'auto', color: 'black' }} />

                                <Box sx={{ paddingLeft: '20px' }}>
                                    <Typography variant="h2" sx={{ marginBottom: '8px' }}>
                                        <FormattedMessage id="dossiers" />
                                    </Typography>
                                    <Typography fontSize="40px" sx={{ textAlign: 'center' }}>
                                        {userDossiersNumber}
                                    </Typography>
                                </Box>
                            </SectionPaper>
                        </Grid>
                    </>
            }
        </Grid>
    );
}

export default UserDashboard;