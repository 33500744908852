import { FC, useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage } from 'react-intl';
import { PaginationResult } from 'types/paginationResult';
import { MoreVert } from '@mui/icons-material';
import TableRowsLoader from 'ui-component/TableRowsLoader';
import { SerieNumeratore } from 'types/serieNumeratore';

interface SerieNumeratoreWithMenuProps {
    serieNumeratore: SerieNumeratore;
}

const SerieNumeratoreWithMenu: FC<SerieNumeratoreWithMenuProps> = (props) => {
    const { serieNumeratore } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <TableRow key={serieNumeratore.id}>
            <TableCell>
                <Grid container direction="column">
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="name" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {serieNumeratore.nome} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="year" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {serieNumeratore.anno} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="number" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {serieNumeratore.numero} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="h6" align="center"> <FormattedMessage id="type" /> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {serieNumeratore.tipoNome} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                sx={{ verticalAlign: 'center', textAlign: 'right' }}
            >
                <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                    id={`serieNumeratore-actions-menu-${serieNumeratore.id}`}
                    MenuListProps={{
                        'aria-labelledby': `serieNumeratore-action-button-${serieNumeratore.id}`,
                    }}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem key={'edit'} onClick={handleClose}>
                        <IconButton
                            component={RouterLink}
                            to={`edit?serieNumeratoreId=${serieNumeratore.id}`}
                        >
                            <EditIcon fontSize="small" sx={{ mr: '3px' }} />
                            <Typography variant="subtitle1"> <FormattedMessage id="edit" /> </Typography>
                        </IconButton>
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
}

interface SerieNumeratoriPageMobileProps {
    serieNumeratori: PaginationResult<SerieNumeratore>;
    isPageLoading: boolean;
}

const SerieNumeratoriPageMobile: FC<SerieNumeratoriPageMobileProps> = (props) => {
    const { serieNumeratori, isPageLoading } = props;

    return (
        <>
            <Table>
                <TableBody>
                    {
                        isPageLoading ? (
                            <TableRowsLoader rowsNum={3} colsNum={1} />
                        ) :
                            (<>
                                {serieNumeratori && serieNumeratori.items?.map((serieNumeratore) => (
                                    <SerieNumeratoreWithMenu
                                        key={serieNumeratore.id}
                                        serieNumeratore={serieNumeratore}
                                    />
                                ))}
                            </>)
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default SerieNumeratoriPageMobile;