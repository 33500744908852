import { Button, Card, CardHeader, Divider, Grid, TablePagination, debounce, useMediaQuery, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { Link as RouterLink } from 'react-router-dom';
import { SerieNumeratoreService } from "services/SerieNumeratoreServices";
import { SerieNumeratore } from "types/serieNumeratore";
import SerieNumeratoriPage from "./SerieNumeratoriPage";
import SerieNumeratoriPageMobile from "./SerieNumeratoriPageMobile";
import { composeNotistackMessage } from "utils/common";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import AddIcon from '@mui/icons-material/Add';

const SerieNumeratoriList = () => {
    const intl = useIntl();

    const serieNumeratoreService = new SerieNumeratoreService();

    const [serieNumeratori, setSerieNumeratori] = useState<PaginationResult<SerieNumeratore>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    useEffect(() => {
        updateSerieNumeratori();
    }, [paginationQuery]);

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    const getSerieNumeratori = useCallback(debounce(async (paginationQuery: PaginationQuery) => {
        const retrievedNumeratori = await serieNumeratoreService.GetSerieNumeratori(paginationQuery);
        setSerieNumeratori(retrievedNumeratori);

        setIsPageLoading(false);
    }, 700), []);

    const updateSerieNumeratori = () => {
        setIsPageLoading(true);
        getSerieNumeratori(paginationQuery);
    }

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    return (<>
        <Grid>
            <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Button
                    component={RouterLink}
                    to="create"
                    color="primary"
                    sx={{ m: 2, textAlign: 'center' }}
                    variant="contained"
                    startIcon={<AddIcon />}
                >
                    <FormattedMessage id="addSerieNumerator" />
                </Button>
            </Grid>
        </Grid>
        <Card>
            <CardHeader title={<FormattedMessage id="serieNumeratorsList" />} />
            <Divider />
            {
                mobileDevice ?
                    <SerieNumeratoriPageMobile
                        serieNumeratori={serieNumeratori}
                        isPageLoading={isPageLoading}
                    /> :
                    <SerieNumeratoriPage
                        serieNumeratori={serieNumeratori}

                        setSortOptions={setSortOptions}
                        sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                        isPageLoading={isPageLoading}
                    />
            }
            <TablePagination
                component="div"
                count={serieNumeratori?.totalCount}
                onRowsPerPageChange={(e) => {
                    setPageSize(parseInt(e.target.value, 10));
                }}
                onPageChange={(e, page) => {
                    setPageNumber(page);
                }}
                page={paginationQuery.pageNumber}
                rowsPerPage={paginationQuery.pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
            />
        </Card>
    </>);
}

export default SerieNumeratoriList;