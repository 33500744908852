export interface PaginationQuery {
    pageSize: number,
    pageNumber: number,
    sortColumn: string,
    ascending: boolean,
    tenantId?: string
}

export const defaultPaginationQuery: PaginationQuery = {
    pageSize: 50,
    pageNumber: 0,
    sortColumn: '',
    ascending: false
}