
import { useRef } from 'react'
import Button from '../../common/Button.jsx'
import Icon from '../../common/Icon.jsx'
import defaultSignature from 'assets/images/defaultSignature.png'
import { Transforms } from 'slate'

const LinkButton = (props) => {
    const { editor } = props

    const linkInputRef = useRef(null);

    const handleClick = () => {
        const signatureImageNode = {
            type: 'signature',
            url: defaultSignature,
            children: [{ text: '' }]
        }

        Transforms.insertNodes(editor, signatureImageNode);
        Transforms.insertNodes(editor, {
            type: 'paragraph',
            children: [{ text: '' }]
        })
    };

    return (
        <div ref={linkInputRef} className='popup-wrapper'>
            <Button disabled={!editor.selection} format={'signature'} onClick={handleClick}>
                <Icon icon='signature' />
            </Button>
        </div>
    )
}

export default LinkButton;